import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../Users/user.css";
import DataTable from "react-data-table-component";
import apiClient from "../../../common/http-common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faTrashAlt,
  faPenToSquare,
  faEdit,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import "react-toggle/style.css";
import DownloadIcon from "../../../assets/img/new-dashboard/download-icon.svg";
import {
  Card,
} from "@themesberg/react-bootstrap";
import Swal from "sweetalert2";
import { Routes } from "../../../routes";
import Papa from "papaparse";
import { Link } from "react-router-dom";
import HomeOutlineIcon from "../../../assets/img/icons/home-outline-icon.svg";
import { Alert } from "../../../components/Alert";
import { connect } from "react-redux";
const BadgeList = (state) => {
  const [UserTabData, setUserTabData] = useState([]);
  const [newUser, setNewUser] = useState([]);
    const [isdisabled, setdisabled] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const [logUserId] = useState(state?.user?.Id);


  function capitalizeFirstLetter(name) {
    if (!name) return "";
    return name.charAt(0).toUpperCase() + name.slice(1);
  }

  const navigate = useNavigate();

  const column = [
    {
      name: "Badge Name",
      selector: (param) => <span title={capitalizeFirstLetter(param.Name)}>{capitalizeFirstLetter(param.Name)}</span>,
      sortable: true,
    },
    {
      name: "Minimum Range",
      selector: (param) => <span title={param.MinRange}>{param.MinRange}</span>,
      sortable: true,
    },
    {
      name: "Maximum Range",
      selector: (param) => <span title={param.MaxRange}>{param.MaxRange}</span>,
      sortable: true,
    },
    {
      name : "Actions",
      selector : (param) => (
        <>
        <FontAwesomeIcon
            style={{ color: "#1658a0", cursor: 'pointer' }}
            icon={faEdit}
            onClick={() => navigate(Routes.AddBadge, {
              state: {
                param
              },
            })}
            title="Edit Badge"
          />
          <FontAwesomeIcon
            style={{ color: "#1658a0", cursor: 'pointer' }}
            icon={faTrash}
            onClick={() => DeleteData(param.Id)}
            title="Delete Badge"
          />
        </>
      )
    },
];

const DeleteData = (id) => {
  // setLoading(true);
  Swal.fire({
    title: "",
    text: "Are you sure, you want to Delete?",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#1658a0",
    cancelButtonColor: "#1658a0",
    confirmButtonText: "Delete",
    allowOutsideClick: false,
    allowEscapeKey: false,
  }).then((result) => {
    if (result.isConfirmed) {
      apiClient
        .post("kmarticle/km_article_badges_delete", { badgeId: id })
        .then((res) => {
          getAllUsers();
          Alert("succ", "Badge Deleted successfully");
        })
        .catch((error) => {
          Alert("error", "Please Try Again")
        });
    }
  })
}

  const getAllUsers = () => {
    setdisabled(true);
    apiClient
      .post("/user/get_badges_details", {
        Id : null
      })
      .then((res) => {

          setUserTabData(res.data.user);
          setFilteredData(res.data.user);
          setNewUser(res.data.user);
          setdisabled(false);
          setLoading(false);
      })
      .catch((error) => {
        if (error.response && error.response.status === 500) {
          Alert("error", "Internal Server Error");
        } else {
          Alert("error", "Please Try Again");
        }
      });
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  const filterData = (value) => {
    const lowerCaseValue = value.toLowerCase().trim();
    const filteredData = UserTabData.filter(
      (item) =>
        item.Name?.toLowerCase().includes(lowerCaseValue)
    );
    setFilteredData(filteredData);
  };

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchValue(value);
    filterData(value);
  };

  function handleExport(data) {
    const headers = [
      { label: "Badge Name", key: "Name" },
      { label: "Minimum Range", key: "MinRange" },
      { label: "Maximum Range", key: "MaxRange" }
    ];

    const exportData = data.map((row) =>
      headers.reduce((acc, header) => {
        if (row[header.key] === "start_date" || row[header.key] === "end_date")
          acc[header.label] = changeStartDateFormat(row[header.key]);
        else acc[header.label] = row[header.key];
        return acc;
      }, {})
    );
    const csvData = Papa.unparse(exportData);

    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
    saveAs(blob, "Badge Details.csv");
  }

  const handleEnterKey = (e) => {
    if (e.key === "Enter") {
      searchUser();
    }
  };
    return (
    <div className="fsMain">
      <div className="flex  md:flex-row gap-4 md:items-center md:justify-between my-2">
        <div className="maincontent__breadcrumb">
          <img
            className="cursor_pointer"
            src={HomeOutlineIcon}
            alt="home"
            onClick={() => {
              navigate(state.defaultpage);
            }}
          />
          <span className="maincontent__breadcrumb--divider">/</span>
          <span>Badges Configuration</span>
          <span className="maincontent__breadcrumb--divider">/</span>
          <span className="maincontent__breadcrumb--active">Badges</span>
        </div>
          <button
            className="maincontent__btn maincontent__btn--primaryblue w-fit ml-auto"
            onClick={() => {
              navigate(Routes.AddBadge);
            }}
          >
            +Add Badge
          </button>
      </div>

      <div className="maincontent__card">
        <div
          className="maincontent__card--body"
          style={{ margin: "10px 0 0 0 " }}
        >
          <div className="maincontent__card--header ">
            <h2 className="maincontent__card--header-title text-black">
              Badges
            </h2>
          </div>
          <div className="maincontent__card--content  rdt_Pagination">
            <div className="secDiv">
              <div
                className="maincontent__card--tableheader-right"
                style={{ margin: "10px 0px 10px 0px" }}
              >
                <div className="relative search-containerKMArti kmarticle-seactform">
                  <input
                    type="search"
                    placeholder="Search by Badge Name"
                    className="w-full pt-2 pb-2 pl-2 pr-[26%] text-xs border-0 rounded-[28px] outline-0 h-[34px]"
                    value={searchValue}
                    onChange={handleSearch}
                  />
                  <button
                    type="button"
                    className="absolute top-1 right-1 bg-[rgba(0,0,0,60%)] rounded-[28px] h-[26px] text-white text-[10px] font-bold button"
                  >
                    Search
                  </button>
                </div>
                <Link className="flexVerandHorCenter">
                  <img
                    src={DownloadIcon}
                    onClick={() => handleExport(UserTabData)}
                    className="p-2 bg-[rgba(0,0,0,60%)] rounded-md"
                  ></img>
                </Link>
              </div>

              <Card.Body align="center">
                {loading ? (
                  <div class="circle__loader items-center my-0 mx-auto"></div>
                ) : (
                  <DataTable
                    columns={column}
                    data={filteredData}
                    highlightOnHover
                    pagination
                    paginationRowsPerPageOptions={[5, 10, 15]}
                    paginationPerPage={10}
                  />
                )}
              </Card.Body>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  ...state,
});
export default connect(mapStateToProps)(BadgeList);
