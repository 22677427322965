import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { Routes } from "../../routes";
import apiClient from "../../common/http-common";
import { Alert } from "../../components/Alert";
import DataTable from "react-data-table-component";
import { ddmmyyyyFormat, yyyymmdd, datetimeClockFormat } from "../../common/Helper";
import { Modal, Col,Card } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faEdit, faQuestionCircle, faTrash, faEye } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import Select from "react-select";
const  JiraIntegration = ({onClose, defaultPage}) => {
    let currentpath = window.location.hash.replace("#", "");
  let haumbstatus = defaultPage === currentpath ? false : true;
  const [HideHomeBreadCumb] = useState(haumbstatus);
  const getState = sessionStorage.getItem("state");
  const getUserId = JSON.parse(getState);
  const [logUserId] = useState(getUserId?.user?.Id);
  const [UserTabData, setUserTabData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [addUserTabData, setAddUserTabData] = useState([]);
  const navigate = useNavigate();
  const [projectList, setProjectList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [clientlist, setClientList] = useState([]);
  const [selectVal, setSelectVal] = useState({
    project: { value: "Select Project", label: "Select Project" },
    article : { value : "Select Article", label : "Select Article"},
    item : { value : "Select Folder", label : "Select Folder"},
  });
  const [path , setPath] = useState("");
  const [id, setId] = useState(null);
  const [tenantId, SetTenantId] = useState("");
  const [clientId, setClientId] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [item , setItem] = useState();
  const [itemName, setItemName] = useState("");
  const [itemList, setItemList] = useState([]);
  const [project, setProject] = useState();
  const [category, setCategory] = useState(0);
  const [article, setArticle] = useState();
  const [shareId, setShareId] = useState();
  const [host, setHost] = useState();
  const [site, setSite] = useState();
  const [drive, setDrive] = useState();
  const [postProjectList, setPostProjectList] = useState([]);
  const [postCategoryList, setPostCategoryList] = useState([]);
  const [postArticleList, setPostArticleList] = useState([]);
  const [postShareList, setPostShareList] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  let [filteredData, setFilteredData] = useState();
  const [SharePointData, setSharePointData] = useState("");
  const [validation, setValidation] = useState(false);
  const [showJiraModal, setShowJiraModal] = useState(false);

  const [showDefault, setShowDefault] = useState(false);
  const [showAddDefault, setShowAddDefault] = useState(false);
  const [showEditDefault, setShowEditDefault] = useState(false);
  const [itemKey, setItemKey] = useState();


  const handleClose = () => {
    setShowDefault(false);
    setShowJiraModal(false);
    setShowAddDefault(false);
    setShowEditDefault(false);
    setProject("");
    setArticle("");
    setHost("");
    setPath("");
    SetTenantId("");
    setClientId("");
    setClientSecret("");
    setItem("");
    setItemName("");
    setItemKey("");
    setSite("");
    setDrive("");
    setFormErrors("");
    setId(null);
    setFilteredData(SharePointData);
  };

  const handleAddClose = () => {
    setShowDefault(true);
    setShowJiraModal(true);
    setShowAddDefault(false);
    setShowEditDefault(false);
    setProject("");
    setArticle("");
    setHost("");
    setPath("");
    SetTenantId("");
    setClientId("");
    setClientSecret("");
    setItem("");
    setItemName("");
    setItemKey("");
    setSite("");
    setDrive("");
    setFormErrors("");
    setId(null);
    setFilteredData(SharePointData);
    setValidation(false);
  };
  
//   const [showHelp, setShowHelp] = useState(false);

// // Toggle help text visibility
// const toggleHelp = () => {
//   setShowHelp(!showHelp);
// };



  const clearFieldError = (fieldName) => {
    setFormErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      delete updatedErrors[fieldName];
      return updatedErrors;
    });
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    const Jira = async () => {
      const errors = {};
    
      if (!item) {
        errors.item = "Jira Project is required";
      }
    
      if (!project) {
        errors.project = "Project is required";
      }
    
      if (!article) {
        errors.article = "Article Type is required";
      }
    
      setFormErrors(errors);
    
      const isValid = Object.keys(errors).length === 0;
    
      if (isValid) {
        try {
          const shareData = {
            Id: id || null,
            JiraProjectId: item || selectVal.item.value,
            JiraProjectName: itemName || selectVal.item.label,
            ProjectId: project || selectVal.project.value,
            categoryId: category,
            ArticleType: article || selectVal.article.value,
            keyss : itemKey || selectVal.item.keys,
          };
    
         await apiClient.post("/cloud/jira/integration_apply", shareData);
         
    
          Alert("succ", "Data Added Successfully");
          setId(null);
          DataList();
          handleAddClose();
    
        } catch (error) {
          Alert("error", "An error occurred");
        }
      }
    };
    
    await Jira();
    
    };

useEffect(() => {
  DataList();
},[]);

useEffect(() => {

    const fetchArticleCategories = async () => {
      try {
        const response = await apiClient.get("/lookup/ArticalCategory/1");
        const categories = response.data.lookup.map((element) => ({
          value: element.Id,
          label: element.Name
        }));
        
        const generalCategory = categories.find(category => category.label.toLowerCase() === 'general');
        if (!generalCategory) {
          await apiClient.post(`/lookup/add`, {
            metadataId: "ArticalCategory",
            id: null,
            name: "General",
            description: "General",
            isActive: 1,
            userId: parseInt(logUserId, 10),
          });
          return fetchArticleCategories();
        }
        
        setPostCategoryList(categories);
        setCategory(generalCategory.value);
      } catch (error) {
        Alert("error", "Please Try Again");
      }
    };

    fetchArticleCategories();


  apiClient
  .post("project/searchall", {})
  .then((response) => {
    let parr = [];
    if (response.data.project.length > 0) {
      response.data.project.forEach((element) => {
        parr.push({ value: element.id, label: element.Name });
      });
    }
    setPostProjectList(parr);
  })
  .catch(() => {});

  apiClient
  .get("cloud/jira/projects")
  .then((response) => {
    let jarr = [];
    if (response.data.length > 0) {
      response.data.forEach((element) => {
        jarr.push({ value: element.id, label: element.name, keys: element.key });
      });
    }
    setItemList(jarr);
  })
  .catch(() => {});

  apiClient
  .get("cloud/search")
  .then((response) => {
    let sarr = [];
    if (response.data.length > 0) {
      response.data.forEach((element) => {
        sarr.push({ value: element.Id, label: element.Id });
      });
    }
    setPostShareList(sarr);
    if (sarr.length > 0) {
      setShareId(sarr[0].value);
    }
  })
  .catch(() => {});



  apiClient
      .get("/lookup/ArticleType/1")
      .then((response) => {
        let aarr = [];
        if (response.data.lookup.length > 0) {
          response.data.lookup.forEach((element) => {
            aarr.push({ value: element.Id, label: element.Name });
          });
        }
        setPostArticleList(aarr);
      })
      .catch(() => {
        Alert("error", "Please Try Again");
      });

},[]);

function DataList() {
  apiClient
    .get("cloud/jira/integration_details")
    .then((res) => {
      setSharePointData(res.data);
      setFilteredData(res.data);
    })
    .catch(() => {});
}


  const filterData = (value) => {
    const lowerCaseValue = value.toLowerCase().trim();
    filteredData = SharePointData.filter((item) =>
      item.ProjectName.toLowerCase().includes(lowerCaseValue) ||
      item.ArticleTypeName.toLowerCase().includes(lowerCaseValue) ||
      item.CategoryName.toLowerCase().includes(lowerCaseValue) ||
      item.JiraProjectName.toLowerCase().includes(lowerCaseValue)
    );
    setFilteredData(filteredData);
  };
  const [searchValue, setSearchValue] = useState("");
  const handleSearch = (event) => {
    setSearchValue(event.target.value);
    filterData(event.target.value);
  };

  const columnAR = [
    {
      name: "Project",
      sortable: true,
      selector: (param) => (param.ProjectName),
      format: (param) => (
        <span title={param.ProjectName}>{param.ProjectName}</span>
      ),
    },
    {
      name: "Article Type",
      sortable: true,
      selector: (param) => (param.ArticleTypeName),
      format: (param) => (
        <span title={param.ArticleTypeName}>{param.ArticleTypeName}</span>
      ),
    },
    {
      name: "Category",
      sortable: true,
      selector: (param) => (param.CategoryName),
      format: (param) => (
        <span title={param.CategoryName}>{param.CategoryName}</span>
      ),
    },
    {
      name: "Jira Project",
      sortable: true,
      selector: (param) => (param.JiraProjectName),
      format: (param) => (
        <span title={param.JiraProjectName}>{param.JiraProjectName}</span>
      ),
    }, 
    {
      name: "Runtime",
      sortable: true,
      selector: (param) => param.Runtime,
      format: (param) => {
        if (!param.Runtime) {
          return <span title="NA">NA</span>;
        } else {
          const date = new Date(param.Runtime);
          const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
          const formattedTime = date.toLocaleTimeString([], { hour: "numeric", minute: "2-digit", hour12: true });
          const dateTimeString = `${formattedDate}, ${formattedTime}`; // Concatenate formatted date and time
          return <span title={dateTimeString}>{dateTimeString}</span>;
        }
      },
    },
    {
      name : "Actions",
      selector : (param) => (
        <>
        <FontAwesomeIcon
            style={{ color: "#1658a0", cursor: 'pointer', marginLeft:"3px" }}
            icon={faEdit}
            onClick={() =>{ setShowEditDefault(true); setValidation(true);
              selectVal.item = {
                value: param.JiraProjectId,
                label: param.JiraProjectName,
              }
              selectVal.project = {
                value: param.ProjectId,
                label: param.ProjectName,
              };
              selectVal.article = {
                value: param.ArticleType,
                label: param.ArticleTypeName,
              };
            setProject(param.ProjectId);
            setArticle(param.ArticleType);
            setItemKey(param.keyss);
            setId(param.Id);
            setItem(param.JiraProjectId);
            setItemName(param.JiraProjectName);
            } }
            title="Edit Data"
          />
          <FontAwesomeIcon
            style={{ color: "#1658a0", cursor: 'pointer' }}
            icon={faTrash}
            onClick={() => DeleteData(param.Id)}
            title="Delete Data"
          />
        </>
      )
    },
  ];

  const DeleteData = (id) => {
    // setLoading(true);
    Swal.fire({
      title: "",
      text: "Are you sure, you want to Delete?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1658a0",
      cancelButtonColor: "#1658a0",
      confirmButtonText: "Delete",
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((result) => {
      if (result.isConfirmed) {
        apiClient
          .post("cloud/jira/integration_delete", { Id: id })
          .then((res) => {
            DataList();
            Alert("succ", "Data Deleted successfully");
          })
          .catch((error) => {
          });
      }
    })
  }

  const handleOnClose = () => {
    onClose();
  };
  
  return (
    <div className="usrCrd sharetable card">
            <div className="bg-[#d0d2d9] rounded-[20px] p-2">
            <FontAwesomeIcon
              icon={faClose}
              onClick={handleOnClose}
              style={{ borderRadius: "20px", backgroundColor: "#03022a" }}
              className="cursor-pointer closeIcon"
            />
            <p className="font-semibold text-black text-[17px]">Jira Details</p>
          </div>
            <div className="maincontent__card--tableheader-right mt-2 mr-2">
              <div className="relative search-containerKMArti kmarticle-seactform">
                <input
                  type="search"
                  placeholder="Search by Project, Article Type, Category, Jira Project"
                  className="w-full pt-2 pb-2 pl-2 pr-[26%] text-xs border-0 rounded-[28px] outline-0 h-[34px]"
                  value={searchValue}
                  onChange={handleSearch}
                />
                <button
                  type="button"
                  className="absolute top-1 right-1 bg-[rgba(0,0,0,60%)] rounded-[28px] h-[26px] text-white text-[10px] font-bold button"
                >
                  Search
                </button>
              </div>
              <button
            className="maincontent__btn maincontent__btn--primaryblue tableBottomBtn mb-2"
            onClick={() => setShowAddDefault(true)}
          >
            Add Data
          </button>
            </div>
            {showAddDefault && (
            <Modal
                  as={Modal.Dialog}
                  centered
                  show={showAddDefault}
                  backdrop="static"
                  onHide={() => setShowAddDefault(false)}
                >
                  <div
                    className="maincontent__card--body bg-[#fff]"
                    style={{ marginTop: "10px", width: "110%" }}
                  >
                    <div className="bg-[#d0d2d9] rounded-[20px] p-2">
                    <FontAwesomeIcon
                      icon={faClose}
                      onClick={() => handleAddClose()}
                      style={{ borderRadius: "20px", backgroundColor: "#03022a" }}
                      className="cursor-pointer closeIcon"
                    />
                    <p className="font-semibold text-black text-[17px]">Jira Data</p>
                  </div>
                    <div className="maincontent__card--content bg-[#fff] rounded-[20px]">
                      <form>
                        <Col>
                        <div className="grid grid-cols-1 md:grid-cols-1 gap-2">
                              <div className="flex flex-col gap-2">
                              <p>Project</p>
                              <Select className="selectshare"
                                options={postProjectList.map((project) => ({
                                  value: project.value,
                                  label: project.label,
                                }))}
                                placeholder=""
                                onChange={(selectedOption) => {
                                  setProject(selectedOption.value);
                                  clearFieldError("project");
                                }}
                              />
                              {formErrors.project && (
                                <div className="text-danger">{formErrors.project}</div>
                              )}
                            </div>

                            <div className="flex flex-col gap-2">
                              <p>Article Type</p>
                              <Select className="selectshare"
                                options={postArticleList.map((article) => ({
                                  value: article.value,
                                  label: article.label,
                                }))}
                                placeholder=""
                                onChange={(selectedOption) => {
                                  setArticle(selectedOption.value);
                                  clearFieldError("article");
                                }}
                              />
                              {formErrors.article && (
                                <div className="text-danger">{formErrors.article}</div>
                              )}
                            </div>

                            <div className="flex flex-col gap-2">
                              <p>Jira Project</p>
                              <Select className="selectshare"
                                options={itemList.map((folder) => ({
                                  value: folder.value,
                                  label: folder.label,
                                  keys : folder.keys,
                                }))}
                                placeholder=""
                                onChange={(selectedOption) => {
                                  setItem(selectedOption.value);
                                  setItemName(selectedOption.label);
                                  setItemKey(selectedOption.keys);
                                  clearFieldError("item");
                                }}
                              />
                              {formErrors.item && (
                                <div className="text-danger">{formErrors.item}</div>
                              )}
                            </div>

                            <div className="flex flex-col gap-2 hidden">
                              <p>SharePoint ID</p>
                              <Select className="selectshare"
                                options={postShareList.map((share) => ({
                                  value: share.value,
                                  label: share.label,
                                }))}
                                placeholder=""
                                onChange={(selectedOption) => {
                                  setShareId(selectedOption.value);
                                  clearFieldError("sharepoint");
                                }}
                              />
                              {formErrors.sharepoint && (
                                <div className="text-danger">{formErrors.sharepoint}</div>
                              )}
                            </div>
                          </div>
                        </Col>
                        <div
                          align="right"
                          style={{ marginTop: "2%" }}
                        >
                            <button
                            type="submit"
                            className="btn btn-info bg-[#050346] border-none rounded-[20px]"
                            onClick={handleSubmit}
                          >
                            Save
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </Modal>
            )}

{showEditDefault && (
            <Modal
                  as={Modal.Dialog}
                  centered
                  show={showEditDefault}
                  backdrop="static"
                  onHide={() => setShowEditDefault(false)}
                >
                  <div
                    className="maincontent__card--body bg-[#fff]"
                    style={{ marginTop: "10px", width: "110%" }}
                  >
                    <div className="bg-[#d0d2d9] rounded-[20px] p-2">
                    <FontAwesomeIcon
                      icon={faClose}
                      onClick={() => handleAddClose()}
                      style={{ borderRadius: "20px", backgroundColor: "#03022a" }}
                      className="cursor-pointer closeIcon"
                    />
                    <p className="font-semibold text-black text-[17px]">Jira Edit Data</p>
                  </div>
                    <div className="maincontent__card--content bg-[#fff] rounded-[20px]">
                      <form>
                        <Col>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
                              <div className="flex flex-col gap-2">
                              <p>Project</p>
                              <Select className="selectshare"
                                options={postProjectList.map((project) => ({
                                  value: project.value,
                                  label: project.label,
                                }))}
                                value={selectVal.project}
                                placeholder=""
                                onChange={(selectedOption) => {
                                  setProject(selectedOption.value);
                                  setSelectVal({ ...selectVal, project: selectedOption });
                                  clearFieldError("project");
                                }}
                              />
                              {formErrors.project && (
                                <div className="text-danger">{formErrors.project}</div>
                              )}
                            </div>

                            <div className="flex flex-col gap-2">
                              <p>Article Type</p>
                              <Select className="selectshare"
                                options={postArticleList.map((article) => ({
                                  value: article.value,
                                  label: article.label,
                                }))}
                                placeholder=""
                                value={selectVal.article}
                                onChange={(selectedOption) => {
                                  setArticle(selectedOption.value);
                                  setSelectVal({ ...selectVal, article: selectedOption });
                                  clearFieldError("article");
                                }}
                              />
                              {formErrors.article && (
                                <div className="text-danger">{formErrors.article}</div>
                              )}
                            </div>

                            <div className="flex flex-col gap-2 hidden">
                              <p>SharePoint ID</p>
                              <Select className="selectshare"
                                options={postShareList.map((share) => ({
                                  value: share.value,
                                  label: share.label,
                                }))}
                                placeholder=""
                                onChange={(selectedOption) => {
                                  setShareId(selectedOption.value);
                                  clearFieldError("sharepoint");
                                }}
                              />
                              {formErrors.sharepoint && (
                                <div className="text-danger">{formErrors.sharepoint}</div>
                              )}
                            </div>

                            <div className="flex flex-col gap-2">
                              <p>Jira Project</p>
                              <Select className="selectshare"
                                options={itemList.map((folder) => ({
                                  value: folder.value,
                                  label: folder.label,
                                  keys : folder.keys,
                                }))}
                                value={selectVal.item}
                                placeholder=""
                                onChange={(selectedOption) => {
                                  setItem(selectedOption.value);
                                  setItemName(selectedOption.label);
                                  setItemKey(selectedOption.keys);
                                  setSelectVal({ ...selectVal, item: selectedOption });
                                  clearFieldError("item");
                                }}
                              />
                              {formErrors.item && (
                                <div className="text-danger">{formErrors.item}</div>
                              )}
                            </div>

                          </div>
                        </Col>
                        <div
                          align="right"
                          style={{ marginTop: "2%" }}
                        >
                            <button
                            type="button"
                            className="btn btn-info bg-[#050346] border-none rounded-[20px]"
                            onClick={handleSubmit}
                          >
                            Save
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </Modal>
            )}


            <div className="cardContent Cctent">
              <Card.Body className="rmroldcb">
                <DataTable
                  columns={columnAR}
                  data={filteredData}
                  highlightOnHover
                  pagination
                  paginationRowsPerPageOptions={[5, 10, 15]}
                  paginationPerPage={5}
                />
              </Card.Body>
            </div>
    </div>
  )
}
const mapStateToProps = (state) => ({
    defaultPage: state.defaultpage,
  });


export default connect(mapStateToProps)(JiraIntegration);