import axios from "axios";
import store from "../store";
import Swal from "sweetalert2";
import {jwtDecode} from "jwt-decode"; // Correct import statement

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

apiClient.interceptors.request.use(
  (config) => {
    const token = store.getState().token;
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response.status === 400) {
      refreshToken();
    }
    return Promise.reject(error);
  }
);

function useTokenRefresh() {
  const interval = setInterval(() => {
      const token = store?.getState()?.token;
      if (token) {
          const { exp } = jwtDecode(token);
          const currentTime = Math.floor(Date.now() / 1000); // Convert to integer
          if (currentTime >= exp) {
            window.location.href = "/";
        } else if (exp - currentTime < 300) {
            refreshToken();
        }
      }
  }, 60000);
  return () => clearInterval(interval);
}

useTokenRefresh();

const refreshToken = () => {
  Swal.fire({
    title: "",
    text: "Token was expired Please Login again",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#1658a0",
    cancelButtonColor: "#1658a0",
    confirmButtonText: "Extend",
  })
    .then(async (result) => {
      if (result.isConfirmed) {
        try {
          await apiClient
            .post("/auth/tokenExtend", { user: store.getState().user })
            .then((res) => {
              if (res.data.token) {
                store.getState().token = res.data.token;
              } else {
                window.location.href = "/";
              }
            })
            .catch((err) => {
              window.location.href = "/";
            });
        } catch (error) {
          window.location.href = "/";
        }
      }
    })
    .catch((err) => {
    });
};

export default apiClient;
