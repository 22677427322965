import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate,useLocation } from "react-router-dom";
import "react-toggle/style.css";
import DataTable from "react-data-table-component";
import apiClient from "../../../common/http-common";
import { Card } from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Routes } from "../../../routes";
import { Alert } from "../../../components/Alert";
import HomeOutlineIcon from "../../../assets/img/icons/home-outline-icon.svg";
import DownloadIcon from "../../../assets/img/new-dashboard/download-icon.svg";
import { faPenToSquare, faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import Papa from "papaparse";
const ArtifactCard = (state) => {
    const location = useLocation();
    const [card, setcard] = useState(location?.state?.card || null);
    let currentpath = window.location.hash.replace("#", "");
    let haumbstatus = state.defaultpage === currentpath ? false : true;
    const [isAdmin] = useState(state.isAdmin);
    const [HideHomeBreadCumb] = useState(haumbstatus)
    const navigate = useNavigate();
    const [logUserId] = useState(state?.user?.Id);
    const [articleList, setArticleList] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [tableData, setTableData] = useState([]);


    const [loading, setLoading] = useState(true);

    const fetchArticleList = async () => {
        setLoading(true);
        try {
            const response = await apiClient.post("kmarticle/get_all_artifact_details", {
                 CategoryId: location.state.category,
                 ClientId: location.state.client,
                 domainId: location.state.domain,
                 month: location.state.month,
                 projectId: location.state.project,
                 regionId: location.state.region,
                 year : location.state.year,
                 type : location.state.article
                });
            const articles = response.data;
            setArticleList(articles);
            setTableData(articles);
            setLoading(false);
        } catch (error) {
            Alert("error", error.message);
        }
    };

    useEffect(() => {
        fetchArticleList();
    }, []);



    const handleSearch = (event) => {
        setSearchValue(event.target.value);
        filterData(event.target.value);
    };

    const filterData = (value) => {
        const lowerCaseValue = value.toLowerCase().trim();
        const filteredData = tableData.filter(
            (item) =>
                item.title.toLowerCase().includes(lowerCaseValue) ||
                item.ArticleByUserName.toLowerCase().includes(lowerCaseValue)
        );
        setArticleList(filteredData);
    };

    function handleExport(data) {
        const headers = [
            { label: "Title", key: "title" },
            { label: "Student Name", key: "ArticleByUserName" },
        ];
    
        if (card === "artifact") {
            headers.push({ label: "Likes", key: "Likes" });
            headers.push({ label: "Comments", key: "Comments" });

        } else if (card === "asr") {
            headers.push({ label: "Upvotes", key: "UpVotes" });
            headers.push({ label: "Downvotes", key: "DownVotes" });

        }
        headers.push({ label: "Status", key: "Status" });

        const exportData = data.map((row) =>
        headers.reduce((acc, header) => {
          if (row[header.key] === "start_date" || row[header.key] === "end_date"){
            acc[header.label] = changeStartDateFormat(row[header.key]);
  
           }
           else if(header.key === "Status"){
            let statusText;
            switch (row.StatusId) {
              case 1:
                statusText = "Published";
                break;
              case 2:
                statusText = "Submitted";
                break;
              case 4:
                statusText = "Draft";
                break;
              default:
                statusText = "Rework";
            }
            acc[header.label] = statusText;
           } else acc[header.label] = row[header.key];
          return acc;
        }, {})
      );
        const csvData = Papa.unparse(exportData);
        const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
        saveAs(blob, "Artifacts Details.csv");
    }
    
    function capitalizeFirstLetter(title) {
        if (!title) return "";
        return title.charAt(0).toUpperCase() + title.slice(1);
    }

    function capitalizeFirstLetter(str) {
        return str ? str.charAt(0).toUpperCase() + str.slice(1) : '';
    }


const columns = [
    {
        name: "Title",
        selector: (param) => capitalizeFirstLetter(param.title),
        format: (param) => {
            const title = param.title || "No Title";
            if (title.length > 30) {
                return (
                    <span title={title}>
                        {capitalizeFirstLetter(title.substring(0, 30)) + "..."}
                    </span>
                );
            } else {
                return (
                    <span title={title}>
                        {capitalizeFirstLetter(title)}
                    </span>
                );
            }
        },
        sortable: true,
    },
    {
        name: "Student Name",
        selector: (param) => capitalizeFirstLetter(param.ArticleByUserName),
        format: (param) => {
            const title = param.ArticleByUserName || "No Student";
            if (title.length > 30) {
                return (
                    <span title={title}>
                        {capitalizeFirstLetter(title.substring(0, 30)) + "..."}
                    </span>
                );
            } else {
                return (
                    <span title={title}>
                        {capitalizeFirstLetter(title)}
                    </span>
                );
            }
        },
        sortable: true,
    },
    ...(card === 'artifact' ? [
        {
            name: "Likes",
            selector: (param) => param.Likes,
            format: (param) => (
                <span title={param.Likes}>{param.Likes}</span>
            ),
            sortable: true,
        },
        {
            name: "Comments",
            selector: (param) => param.Comments,
            format: (param) => (
                <span title={param.Comments}>{param.Comments}</span>
            ),
            sortable: true,
        }
    ] : []),
    ...(card === 'asr' ? [
        {
            name: "Upvotes",
            selector: (param) => param.UpVotes,
            format: (param) => (
                <span title={param.UpVotes}>{param.UpVotes}</span>
            ),
            sortable: true,
        },
        {
            name: "Downvotes",
            selector: (param) => param.DownVotes,
            format: (param) => (
                <span title={param.DownVotes}>{param.DownVotes}</span>
            ),
            sortable: true,
        }
    ] : []),
    {
        name: "Status",
        id : "status",
        sortable: true,
        selector: (param) => param.StatusId,
        format: (param) => (
            <>
                {param.StatusId === 1 ? (
                    <div class="maincontent__table--status maincontent__table--status-updated">
                        Published
                    </div>
                ) : param.StatusId === 2 ? (
                    <div class="maincontent__table--status maincontent__table--status-notupdated bg-info scheduled">
                        Submitted
                    </div>
                ) : param.StatusId === 4 ? (
                    <div class="maincontent__table--status maincontent__table--status-notupdated draftbtnMyArticle">
                        Draft
                    </div>
                ) : (
                    <div class="maincontent__table--status maincontent__table--status-review" style={{ backgroundColor: "#eb954f" }}>
                        Rework
                    </div>
                )}
            </>
        ),
    },
];

    return (
        <>
            <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center justify-between">
                {HideHomeBreadCumb && (
                    <div className="maincontent__breadcrumb mb-2 lg:mb-0">
                        <img
                            className="cursor_pointer"
                            src={HomeOutlineIcon}
                            alt="home"
                            onClick={() => {
                                navigate(state.defaultpage);
                            }}
                        />
                        <span className="maincontent__breadcrumb--divider">/</span>
                        <span>{card === 'artifact' ? "Artifacts List" : "Average Satisfaction Rate"}</span>
                    </div>
                )}

                <div className="flex  gap-2 mt-2 lg:mt-0">
                    <div className="relative search-containerKMArti kmarticle-seactform mb-2 lg:mb-0">
                        <input
                            type="search"
                            placeholder="Search by Title, Student Name"
                            className="w-full pt-2 pb-2 pl-2 pr-[26%] text-xs border-0 rounded-[28px] outline-0 h-[34px]"
                            value={searchValue}
                            onChange={handleSearch}
                        />
                        <button
                            type="button"
                            className="absolute top-1 right-1 bg-[rgba(0,0,0,60%)] rounded-[28px] h-[26px] text-white text-[10px] font-bold button"
                        >
                            Search
                        </button>
                    </div>
                    <Link className="flexVerandHorCenter ml-auto">
                        <img
                            src={DownloadIcon}
                            onClick={() =>{ setcard(card) ;  handleExport(articleList)}}
                            className="p-2 bg-[rgba(0,0,0,60%)] rounded-md"
                        ></img>
                    </Link>
                </div>
            </div>

            <div className=" mt-2 maincontent__card--body">
                <div className="maincontent__card--content">
                    <Card.Body id="unpublishedDatatble" align="center">
                        {loading ? (
                            <div class="circle__loader"></div>
                        ) : (
                            <DataTable
                                columns={columns}
                                data={articleList}
                                highlightOnHover
                                pagination
                                paginationRowsPerPageOptions={[5, 10, 15]}
                                paginationPerPage={10}
                                defaultSortFieldId="status"
                                defaultSortAsc={true}
                                className="myartifacts"
                            />

                        )}
                    </Card.Body>
                    <div className="flex justify-center gap-4 mt-8 lg:justify-end">
                        <button onClick={() => { navigate(state.defaultpage);}}className="maincontent__btn maincontent__btn--primaryblue space_btn">
                            Back
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    ...state,
});
export default connect(mapStateToProps)(ArtifactCard);
