import React, { useState, useEffect } from "react";
import ProfilePic from "../../assets/img/profile.jpg";
import apiClient from "../../common/http-common";
import { connect } from "react-redux";
import { Alert } from "../../components/Alert";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { datetimeClockFormat } from "../../common/Helper";
import {
  faTrash,
  faPaperPlane,
  faHeart,
  faMessage,
  faClose,
  faPlay,
  faPause,
} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import { Card } from "@themesberg/react-bootstrap";
import PDFIcon from "../../assets/img/new_design/pdf.svg";
import XLIcon from "../../assets/img/new_design/xlsx.svg";
import GalleryIcon from "../../assets/img/new_design/gallery.svg";
import CommentIcon from "../../assets/img/new_design/comment.svg";
import addRewardPoints from "../../common/AddRewardPoints";
import parse from "html-react-parser";
import upvoteIcon from "../../assets/img/icons/upvoteIcon.svg";
import downvoteIcon from "../../assets/img/icons/downvoteIcon.svg";
import upvoteActive from "../../assets/img/icons/upgreen.png";
import downvoteActive from "../../assets/img/icons/downred.png";
import { userRewardPoints } from "../../actions/actions";
import store from "../../store";

const ViewArticle = ({
  article = "",
  user,
  isAdminState,
  rewardsData,
  onClose,
  postRender,
  postVoteRender,
  getState,
}) => {
  const location = useLocation();
  const initialArticleDetails = article || location?.state?.article;
  const [articleDetails, setArticleDetails] = useState(initialArticleDetails);
  console.log("art", articleDetails);
  const [logUserName] = useState(user.FirstName + " " + user.LastName);
  const [logUserId] = useState(user.Id);
  const [isAdmin] = useState(isAdminState);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showCommentList, setShowCommentList] = useState(false);
  const [commentList, SetCommentList] = useState([]);
  const [upvoted, setUpvoted] = useState(articleDetails.upvoteStatus);
  const [downvoted, setDownvoted] = useState(articleDetails.downvoteStatus);

  const [speechSynthesis, setSpeechSynthesis] = useState(null);
  const speak = (text) => {
    if (speechSynthesis) {
      speechSynthesis.cancel();
    }
    const synth = window.speechSynthesis;
    const utterance = new SpeechSynthesisUtterance(text);
    setSpeechSynthesis(synth);
    if (!isPlaying) {
      setIsPlaying(true);
      utterance.onend = () => {
        setIsPlaying(false);
      };
      synth.speak(utterance);
    } else {
      setIsPlaying(false);
      synth.pause();
    }
  };
  const handleOnClose = () => {
    if (speechSynthesis) {
      speechSynthesis.cancel();
    }
    onClose();
  };
  function capitalizeFirstLetter(str) {
    return str ? str.charAt(0).toUpperCase() + str.slice(1) : "";
  }
  function responseChange(e, cmdId = "") {
    const inputValue = e.target.value;
    if (inputValue.length <= 400) {
      const list = { ...articleDetails };
      list["response"] = inputValue;
      setArticleDetails(list);
    } else {
      Alert("warn", "Comments cannot exceed 400 characters");
    }
  }

  const submitLike = async () => {
    if (articleDetails.Id) {
      let data = {
        articalId: articleDetails.Id,
        likedBy: logUserId,
        UserId: logUserId,
      };
      try {
        apiClient.post("kmarticle/addlike", data).then(() => {
          const updateDetalis = { ...articleDetails };
          if (updateDetalis.ArticleBy !== logUserId) {
            addRewardPoints(
              rewardsData[7].Points,
              logUserId,
              rewardsData[7].Id,
              logUserId,
              updateDetalis.Id
            );
            addRewardPoints(
              rewardsData[4].Points,
              updateDetalis.ArticleBy,
              rewardsData[4].Id,
              logUserId,
              updateDetalis.Id
            );
          }
          if (updateDetalis.Likes > 0 && updateDetalis.Likes % 25 === 0) {
            addRewardPoints(
              rewardsData[14].Points,
              updateDetalis.ArticleBy,
              rewardsData[14].Id,
              logUserId,
              updateDetalis.Id
            );
          }
          updateDetalis.Likes = updateDetalis.Likes + 1;
          updateDetalis.likestatus = true;
          setArticleDetails(updateDetalis);
          postRender(updateDetalis);
        });
      } catch (error) {
        Alert("error", "Please Try Again");
      }
    } else {
      Alert("error", "Please Try Again");
    }
  };

  const [dislikeDisabled, setDislikeDisabled] = useState(false);

  const disLike = async () => {
    if (articleDetails.Id) {
      if (!dislikeDisabled) {
        setDislikeDisabled(true);

        let data = {
          articalId: articleDetails.Id,
          likedBy: logUserId,
        };

        let showWarning = false;

        try {
          const response = await apiClient.post("kmarticle/dislike", data);
          const updateDetalis = { ...articleDetails };
          if (updateDetalis.Likes > 0) {
            updateDetalis.Likes = updateDetalis.Likes - 1;
          }
          updateDetalis.likestatus = false;
          setArticleDetails(updateDetalis);
          postRender(updateDetalis);
          store.dispatch(userRewardPoints(response?.data[0]?.LV_RewardPoints));
        } catch (error) {
          Alert("error", "Please Try Again");
          showWarning = true;
        } finally {
          // Re-enable the button whether the request succeeds or fails
          setDislikeDisabled(false);
        }
      }
    } else {
      Alert("error", "Please Try Again");
    }
  };

  const handlesubcomment = (i, id, cmdId) => {
    const textarea = document.getElementById("subcmd_" + id);
    const inputValue = textarea.value.trim();

    if (inputValue.trim().length <= 400) {
      inputValue && submtComment(i, cmdId, inputValue, id);
    } else {
      Alert("warn", "Comments cannot exceed 400 characters");
    }
  };

  const submtComment = async (i = null, cmdId = null, textvalue = null, id = null) => {
    setDislikeDisabled(true);
 
    try {
      if (articleDetails.Id && (articleDetails.response || textvalue)) {
        const cmdres = (textvalue || articleDetails.response).trim();
 
        if (cmdres === "") {
          Alert("warn", "Please Enter Valid Comments");
        } else {
          // Use a regular expression to find URLs in the comment text
          const parts = cmdres.split(/(https?:\/\/\S+)/gi);
 
          // Render as separate elements with hyperlink if a link is present
          const commentContent = parts.map((part, index) => {
            if (/https?:\/\/\S+/i.test(part)) {
              return (
                <a key={index} href={part} target="_blank" rel="noopener noreferrer">
                  {part}
                </a>
              );
            } else {
              return part;
            }
          });
 
          const data = {
            articalId: articleDetails.Id,
            commentId: cmdId,
            comments: cmdres,
            commentby: logUserId,
            UserId: logUserId,
          };
 
          const response = await apiClient.post("/kmarticle/addcomment", data);
          const updatedArticleDetails = { ...articleDetails };
 
          updatedArticleDetails["Comments"] = updatedArticleDetails["Comments"] + 1;
 
          // Ensure commentList is an array
          updatedArticleDetails["commentList"] = Array.isArray(updatedArticleDetails["commentList"])
            ? updatedArticleDetails["commentList"]
            : [];
 
          // Update the commentList without losing existing formatting
          updatedArticleDetails["commentList"] = updatedArticleDetails["commentList"].map(comment => {
            return {
              ...comment,
              Comments: Array.isArray(comment.Comments)
                ? [...comment.Comments, ...commentContent]
                : commentContent,
            };
          });
 
          updatedArticleDetails["response"] = "";
          if (id) {
            const textarea = document.getElementById("subcmd_" + id);
            textarea.value = "";
          }
          setArticleDetails(updatedArticleDetails);
          commentlistbyarticle(articleDetails.Id);
          postRender(updatedArticleDetails);
          if (articleDetails.ArticleBy !== logUserId) {
            addRewardPoints(
              rewardsData[6].Points,
              logUserId,
              rewardsData[6].Id,
              logUserId,
              articleDetails.Id
            );
            addRewardPoints(
              rewardsData[5].Points,
              articleDetails.ArticleBy,
              rewardsData[5].Id,
              logUserId,
              articleDetails.Id
            );
            setDislikeDisabled(false);
          }
          if (
            articleDetails.Comments > 0 &&
            articleDetails.Comments % 25 === 0
          ) {
            addRewardPoints(
              rewardsData[13].Points,
              articleDetails.ArticleBy,
              rewardsData[13].Id,
              logUserId,
              articleDetails.Id
            );
          }
          setDislikeDisabled(false);
          // Alert("succ", "Comment Added Successfully");
        }
      } else {
        Alert("warn", "Please Enter Comments");
      }
    } catch (error) {
      Alert("error", "Please Try Again");
    } finally {
      setDislikeDisabled(false);
    }
  };
 
 
 
  const expandToViewComments = async () => {
    if (showCommentList) {
      setShowCommentList(false);
    } else {
      try {
        const response = await apiClient.post("/kmarticle/articlecomments", {
          id: articleDetails.Id,
        });
 
        // Extract URLs from comments and create hyperlinks
        const commentsWithLinks = response.data.map(comment => {
          const parts = comment.Comments.split(/(https?:\/\/\S+)/gi);
 
          const commentContent = parts.map((part, index) => {
            if (/https?:\/\/\S+/i.test(part)) {
              return (
                <a key={index} href={part} target="_blank" rel="noopener noreferrer">
                  {part}
                </a>
              );
            } else {
              return part;
            }
          });
 
          return { ...comment, Comments: commentContent };
        });
        console.log(commentList);
        console.log(commentsWithLinks);
        // Set the updated comment list with hyperlinks
        SetCommentList(commentsWithLinks);
        setShowCommentList(true);
      } catch (error) {
        Alert("error", error);
      }
    }
  };
 

  const commentlistbyarticle = (id) => {
    apiClient
      .post("/kmarticle/articlecomments", { id: id })
      .then((res) => {
        const comments = res.data;
        SetCommentList(comments);
      })
      .catch(() => {});
  };

  const deletePostComment = (id, ArticleId, parentCmd) => {
    if (id) {
      Swal.fire({
        title: "",
        text: "Are you sure, you want to remove?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1658a0",
        cancelButtonColor: "#1658a0",
        confirmButtonText: "Yes",
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((result) => {
        if (result.isConfirmed) {
          let data = {
            articalId: ArticleId,
            id: id,
            parentCmd: parentCmd,
          };
          apiClient
            .post("/kmarticle/deletecomment", data)
            .then((res) => {
              const latestlist = { ...articleDetails };
              console.log("lat", latestlist);
              latestlist["Comments"] =
                latestlist["Comments"] - res.data[0].SUBCMDCNT;
              setArticleDetails(latestlist);
              postRender(latestlist);
              apiClient
                .post("/kmarticle/articlecomments", {
                  id: articleDetails.Id,
                })
                .then((response) => {
                  SetCommentList(response.data);
                });
            })
            .catch(() => {
              Alert("error", "Please Try Again!");
            });
        }
      });
    }
  };

  const toggleAccordionFunc = (event) => {
    const accordionContent = event.target.nextElementSibling;
    accordionContent &&
      accordionContent.classList.toggle("accordion-content-active");
  };

  const htmlToView = (htmlText) => {
    // Parse the HTML text
    htmlText = parse(htmlText);
    
    // Function to extract the YouTube video ID
    const extractYouTubeVideoId = (text) => {
        const match = text.match(/<oembed url="https:\/\/www.youtube.com\/watch\?v=(.*?)"><\/oembed>/);
        return match ? match[1] : null;
    };
    
    // Check if the HTML text contains a YouTube video URL
    const videoId = extractYouTubeVideoId(htmlText);
    
    // Check if the HTML text contains a hyperlink
    const containsLink = /<a\s+(?:[^>]*?\s+)?href=["'](.*?)["']/gi.test(htmlText);

    const convertURLsToLinks = (text) => {
        return text.replace(/(?<!href=["'])((?:https?|ftp):\/\/[^\s<]+)/g, function (url) {
            const encodedUrl = encodeURI(url);
            return `<a href="${encodedUrl}" target="_blank">${url}</a>`;
        });
    };

    const addBlankTargetToLinks = (html) => {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        const links = doc.querySelectorAll('a');
        links.forEach(link => {
            link.setAttribute('target', '_blank');
            link.setAttribute('rel', 'noopener noreferrer');
        });
        return doc.documentElement.innerHTML;
    };

    if (videoId) {
        // Generate the iframe tag for the YouTube video
        const videoIframe = `<div style="position: relative; padding-top: 56.25%; /* 16:9 aspect ratio */"><iframe style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;" src="https://www.youtube.com/embed/${videoId}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>`;
        // Return the iframe embed alongside the other content
        return (
            <div>
                <div dangerouslySetInnerHTML={{ __html: videoIframe }} />
                <div dangerouslySetInnerHTML={{ __html: htmlText }} />
            </div>
        );
    } else {
        // Convert non-hyperlinked URLs to clickable links
        const convertedText = convertURLsToLinks(htmlText);
        // Add blank target to hyperlinked URLs
        const finalHtml = addBlankTargetToLinks(convertedText);

        if (containsLink) {
            return (
                <div>
                    <div dangerouslySetInnerHTML={{ __html: finalHtml }} />
                </div>
            );
        } else {
            return (
                <div>
                    <div dangerouslySetInnerHTML={{ __html: convertedText }} />
                </div>
            );
        }
    }
};

  const addUpVote = async () => {
    try {
      if (upvoted || articleDetails.upvoteStatus === 1) {
        Alert("warn", "Cannot upvote twice");
        return;
      }

      const response = await apiClient.post(
        "kmarticle/kmarticle_up_down_vote",
        {
          userId: logUserId,
          articleId: initialArticleDetails.Id,
          type: 1,
        }
      );

      const updatedVotes = response.data;

      setArticleDetails((prevDetails) => {
        const updatedDetails = {
          ...prevDetails,
          UpVotes: prevDetails.UpVotes + 1,
          upvoteStatus: 1,
        };
        postVoteRender(updatedDetails);
        return updatedDetails;
      });

      setUpvoted(1);
      setDownvoted(0);
    } catch (err) {
      Alert("error", err);
    }
  };

  const addDownVote = async () => {
    try {
      if (downvoted || articleDetails.downvoteStatus === 1) {
        Alert("warn", "Cannot downvote twice");
        return;
      }

      const response = await apiClient.post(
        "kmarticle/kmarticle_up_down_vote",
        {
          userId: logUserId,
          articleId: initialArticleDetails.Id,
          type: 2,
        }
      );

      const updatedVotes = response.data;

      setArticleDetails((prevDetails) => {
        const updatedDetails = {
          ...prevDetails,
          DownVotes: prevDetails.DownVotes + 1,
          downvoteStatus: 1,
        };
        postVoteRender(updatedDetails);
        return updatedDetails;
      });

      setDownvoted(1);
      setUpvoted(0);
    } catch (err) {
      Alert("error", err);
    }
  };

  const revertVote = async (typeToRevert) => {
    try {
      const response = await apiClient.post(
        "/kmarticle/kmarticle_up_down_vote_revert",
        {
          userId: logUserId,
          articleId: initialArticleDetails.Id,
          type: typeToRevert,
        }
      );

      const updatedVotes = response.data;

      setArticleDetails((prevDetails) => {
        const updateDetails = { ...prevDetails };

        if (typeToRevert === 1) {
          // Reverting an upvote
          updateDetails.UpVotes = Math.max(0, updateDetails.UpVotes - 1);
          updateDetails.upvoteStatus = 0;
          setUpvoted(false);
        } else if (typeToRevert === 2) {
          // Reverting a downvote
          updateDetails.DownVotes = Math.max(0, updateDetails.DownVotes - 1);
          updateDetails.downvoteStatus = 0;
          setDownvoted(false);
        }
        postVoteRender(updateDetails);
        return updateDetails;
      });
    } catch (err) {
      Alert("error", err);
    }
  };

  useEffect(() => {
    // Perform any other side effects or updates you need
  }, [articleDetails]);

  return (
    <Card>
      <Card.Body>
        <div className="">
          {" "}
          {/* articles__container */}
          <div className="">
            {" "}
            {/* articles__left */}
            <div className="articles__articlelist grid-cols-1">
              <div className={`articles__article pt-0`}>
                <div className="articles__article--header justify-start gap-4">
                  <img
                    src={ProfilePic}
                    className="articles__article--profilepic w-24 h-24"
                  />
                  <div className="flex flex-col">
                    <span className="articles__article--author text-xl font-bold text-capitalize">
                      {articleDetails.ArticleName}
                    </span>
                    <span className="articles__article--level text-base">
                      {articleDetails.Badge}
                    </span>
                  </div>
                  <span className="articles__article--line h-7"></span>
                  <div className="flex items-center gap-4">
                    <span className="articles__article--date font-bold text-base">
                      {articleDetails.postdate}
                    </span>
                    <div className="flex gap-2 items-center">
                      <span className="articles__article--likescount">
                        {articleDetails.likestatus ? (
                          <FontAwesomeIcon
                            className="cursor-pointer activeHeart w-5 h-5"
                            icon={faHeart}
                            onClick={() => disLike()}
                          />
                        ) : (
                          <>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 512 512"
                              id="IconChangeColor"
                              onClick={() => submitLike()}
                            >
                              <title>Like</title>
                              <path
                                d="M352.92,80C288,80,256,144,256,144s-32-64-96.92-64C106.32,80,64.54,124.14,64,176.81c-1.1,109.33,86.73,187.08,183,252.42a16,16,0,0,0,18,0c96.26-65.34,184.09-143.09,183-252.42C447.46,124.14,405.68,80,352.92,80Z"
                                id="mainIconPathAttribute"
                                stroke-width="0.3"
                                stroke="#ff0000"
                                fill="#737373"
                                filter="url(#shadow)"
                              ></path>
                              <filter id="shadow">
                                <feDropShadow
                                  id="shadowValue"
                                  stdDeviation="0.3"
                                  dx="-1"
                                  dy="-0.8"
                                  flood-color="black"
                                ></feDropShadow>
                              </filter>
                            </svg>
                          </>
                        )}
                      </span>
                      <span className="articles__article--likes text-lg">
                        {articleDetails.Likes}
                      </span>
                    </div>
                    <span
                      title="Comment"
                      className="articles__article--comments gap-2"
                      onClick={() => expandToViewComments()}
                    >
                      <FontAwesomeIcon
                        className="flex items-center justify-between gap-2 cursor-pointer commentBoxClr"
                        icon={faMessage}
                      />
                      <span
                        className="articles__article--commentscount text-lg"
                        onClick={() => expandToViewComments()}
                      >
                        {articleDetails.Comments}
                      </span>
                    </span>
                    <span title="Upvote">
                      <img
                        src={upvoted ? upvoteActive : upvoteIcon}
                        className="h-5 w-5 cursor-pointer"
                        onClick={() => {
                          if (downvoted) {
                            revertVote(2); // Revert downvote before upvoting
                          }
                          addUpVote();
                        }}
                      ></img>
                    </span>
                    <span className="articles__article--likes text-lg">
                      {articleDetails.UpVotes}
                    </span>
                    <span title="Downvote">
                      <img
                        src={downvoted ? downvoteActive : downvoteIcon}
                        className="h-5 w-5 cursor-pointer"
                        onClick={() => {
                          if (upvoted) {
                            revertVote(1); // Revert upvote before downvoting
                          }
                          addDownVote();
                        }}
                      ></img>
                    </span>
                    <span className="articles__article--likes text-lg">
                      {articleDetails.DownVotes}
                    </span>
                  </div>
                  <FontAwesomeIcon
                    icon={faClose}
                    onClick={handleOnClose}
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                      cursor: "pointer",
                      borderRadius: "20px",
                      backgroundColor: "#03022a",
                    }}
                    className="cursor-pointer closeIcon"
                  />
                </div>
                <div>
                  <h2 className="articles__article--title text-xl">
                    {capitalizeFirstLetter(articleDetails.title)}&nbsp; &nbsp;
                    <FontAwesomeIcon
                      icon={isPlaying ? faPause : faPlay}
                      className="cursor-pointer"
                      onClick={() => {
                        speak(parse(article.Description));
                      }}
                      title={isPlaying ? "Pause" : "Play"}
                    />
                  </h2>
                  <p className="overflow-y-auto max-h-[320px]" style={{ wordBreak: 'break-word' }}>
                    {htmlToView(articleDetails?.Description)}
                </p>
                </div>
                <div className="mt-4 clrBlue">
                  <p>{articleDetails.KeyWords}</p>
                </div>
                {articleDetails?.FilePath?.length > 0 && (
                  <>
                    <h2 className="articles__article--title text-xl text-decoration-underline">
                      Attachments
                    </h2>
                    <div className="articles__attachments">
                      <div className="flex flex-col gap-2">
                        {articleDetails?.FilePath?.map((row, index) => {
                          const filenameArray = row.FileName.split(",");
                          const filepathArray = row.FilePath.split(",");

                          return filenameArray.map((file, i) => {
                            const fileExt = file.split(".").pop().toLowerCase();
                            const isImage = [
                              "png",
                              "jpg",
                              "jpeg",
                              "svg",
                            ].includes(fileExt);

                            let fileLink;
                           

                            if (articleDetails.CloudType != 2) {

                              fileLink =
                                process.env.REACT_APP_IMG_PATH +
                                filepathArray[i];
                               

                            } else {
                              fileLink = filepathArray[i];
                            }
                            {
                              /* const fileLink =
                              process.env.REACT_APP_IMG_PATH + filepathArray[i]; */
                            }

                            return (
                              <div key={`${index}-${i}`}>
                                {isImage ? (
                                  <a
                                    href={fileLink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    download={file}
                                  >
                                    <img
                                      src={fileLink}
                                      className="inline-flex object-contain max-h-64 w-80"
                                      alt={file}
                                    />
                                  </a>
                                ) : (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                    }}
                                  >
                                    {fileExt === "pdf" ? (
                                      <img
                                        src={PDFIcon}
                                        className="articles__attachments--img"
                                        alt="PDF"
                                      />
                                    ) : fileExt === "xlsx" ||
                                      fileExt === "csv" ||
                                      fileExt === "xls" ? (
                                      <img
                                        src={XLIcon}
                                        className="articles__attachments--img"
                                        alt="Excel"
                                      />
                                    ) : (
                                      <img
                                        src={GalleryIcon}
                                        className="articles__attachments--img"
                                        alt="Image"
                                      />
                                    )}
                                    <a
                                      style={{
                                        color: "#6d1acd",
                                        textAlign: "center",
                                      }}
                                      download={
                                        getState?.systemDetails[0]
                                          ?.DownloadStatus === 1
                                          ? true
                                          : false
                                      }
                                      href={fileLink}
                                      target="_blank"
                                    >
                                      {file}
                                    </a>
                                  </div>
                                )}
                              </div>
                            );
                          });
                        })}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            {showCommentList && (
              <div className="items-center justify-between mtb1">
                <div className="commentsSection">
                  {commentList.length > 0 ? (
                    <Card className="commentCard">
                      <Card.Body className="commentCardBody">
                        {commentList.map((comment, index) => (
                          <>
                            <div key={index}>
                              <div className="flex items-center gap-2">
                                <img
                                  src={
                                    comment.profile
                                      ? comment.profile
                                      : ProfilePic
                                  }
                                  alt="Profile"
                                  className="maincontent__card--kmDashboard-contributorsPic commentorProfile"
                                />
                                <p className="flex flex-col">
                                  <span className="font-semibold commentor">
                                    {comment.Author}
                                  </span>
                                  <span className="clrPrimaryBlue commentor">
                                    {datetimeClockFormat(comment.CreatedAt)}
                                  </span>
                                </p>
                              </div>
                              <div class="comments">
                                <p
                                  style={{
                                    marginLeft: "50px",
                                    display: "inline-flex",
                                    whiteSpace: "pre-line",
                                  }}
                                  className="commentsList"
                                >
                                  {comment.Comments}
                                </p>
                                <br />
                                <span
                                  className="cursor-pointer reply_space font_small"
                                  onClick={toggleAccordionFunc}
                                >
                                  Reply
                                  {logUserId === comment.CommentedBy ||
                                  isAdmin ? (
                                    <>
                                      {" "}
                                      |{" "}
                                      <FontAwesomeIcon
                                        className="cursor-pointer font_small"
                                        style={{
                                          color: "red",
                                        }}
                                        onClick={() =>
                                          deletePostComment(
                                            comment.Id,
                                            comment.ArticleId,
                                            true
                                          )
                                        }
                                        icon={faTrash}
                                      />
                                    </>
                                  ) : null}
                                </span>
                                <div className="w-2/4 hide_sub_reply_box">
                                  <textarea
                                    type="text"
                                    rows="1"
                                    id={"subcmd_" + comment.Id}
                                    className="maincontent__card--kmArticles-articleResponse"
                                    placeholder="Type your comments here ....."
                                  />
                                  <button
                                    id="sub_replay_cmt"
                                    className="maincontent__card--kmArticles-articleBtn"
                                    onClick={() =>
                                      handlesubcomment(
                                        index,
                                        comment.Id,
                                        comment.Id
                                      )
                                    }
                                  >
                                    <FontAwesomeIcon icon={faPaperPlane} />
                                  </button>
                                </div>
                              </div>
                            </div>
                            {comment.subcomments.length > 0
                              ? comment.subcomments.map((row, i) => {
                                  return (
                                    <>
                                      <div key={i} className="ml-10">
                                        <div className="flex items-center gap-2">
                                          <img
                                            src={
                                              row.profile
                                                ? row.profile
                                                : ProfilePic
                                            }
                                            alt="Profile"
                                            className="maincontent__card--kmDashboard-contributorsPic commentorProfile"
                                          />
                                          <p className="flex flex-col">
                                            <span className="font-semibold commentor">
                                              {row.Author}
                                            </span>
                                            <span className="clrPrimaryBlue commentor">
                                              {datetimeClockFormat(
                                                row.CreatedAt
                                              )}
                                            </span>
                                          </p>
                                        </div>

                                        <div class="comments">
                                          <p class="commentsList reply_space inline-flex	">
                                            {row.Comments}
                                          </p>

                                          <br />
                                          <span
                                            className="cursor-pointer reply_space font_small"
                                            onClick={toggleAccordionFunc}
                                          >
                                            Reply
                                            {logUserId === row.CommentedBy ||
                                            isAdmin ? (
                                              <>
                                                {" "}
                                                |{" "}
                                                <FontAwesomeIcon
                                                  className="cursor-pointer font_small"
                                                  style={{
                                                    color: "red",
                                                  }}
                                                  onClick={() =>
                                                    deletePostComment(
                                                      row.Id,
                                                      row.ArticleId,
                                                      false
                                                    )
                                                  }
                                                  icon={faTrash}
                                                />
                                              </>
                                            ) : null}
                                          </span>
                                          <div className="w-2/4 hide_sub_reply_box">
                                            <textarea
                                              type="text"
                                              rows="1"
                                              id={"subcmd_" + row.Id}
                                              className="maincontent__card--kmArticles-articleResponse"
                                              placeholder="Type your comments here ....."
                                            />
                                            <button
                                              id="sub_replay_cmt"
                                              className="maincontent__card--kmArticles-articleBtn"
                                              onClick={() =>
                                                handlesubcomment(
                                                  i,
                                                  row.Id,
                                                  comment.Id
                                                )
                                              }
                                            >
                                              <FontAwesomeIcon
                                                icon={faPaperPlane}
                                              />
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  );
                                })
                              : null}
                          </>
                        ))}

                        <p
                          className="cursor-pointer font-semibold float-right"
                          style={{
                            color: "blue",
                            fontSize: "12px",
                          }}
                          onClick={() => expandToViewComments()}
                        >
                          View Less
                        </p>
                      </Card.Body>
                    </Card>
                  ) : null}
                </div>
              </div>
            )}
            <div className="articles__comments">
              <textarea
                className="articles__comments--input h-12"
                style={{ backgroundColor: "#f2f2f4" }}
                placeholder="Type your comments here..."
                value={articleDetails.response}
                onChange={(e) => responseChange(e)}
              ></textarea>
              <button
                type="button"
                className="articles__comments--btn py-2"
                onClick={() => submtComment()}
                disabled={dislikeDisabled}
              >
                <img src={CommentIcon} alt="Comment Icon" />
              </button>
            </div>
          </div>
          <div className=""> </div>
        </div>
      </Card.Body>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  isAdminState: state.isAdmin,
  rewardsData: state.rewards,
});

export default connect(mapStateToProps)(ViewArticle);