import React from "react";
import { Line } from "react-chartjs-2";
import chart from "chart.js/auto";

function LineChart(props) {
  const { title, data = [], casedata = [], knowdata = [], vx = [] } = props;
  const labels = data.map((d) => d.label);
  const caselabels = casedata.map((d) => d.label);
  const knowlabels = knowdata.map((d) => d.label);
  const vxlabels = vx.map((d) => d.label);
  let mergedArray = [
    ...new Set([...labels, ...caselabels, ...knowlabels, ...vxlabels]),
  ];

  const compareMonthNames = (a, b) => {
    const monthOrder = {
      Jan: 1,
      Feb: 2,
      Mar: 3,
      Apr: 4,
      May: 5,
      Jun: 6,
      Jul: 7,
      Aug: 8,
      Sep: 9,
      Oct: 10,
      Nov: 11,
      Dec: 12,
    };
  
    const [monthA, yearA] = (a || "").split(" ");
    const [monthB, yearB] = (b || "").split(" ");
  
    if (yearA !== yearB) {
      return parseInt(yearA) - parseInt(yearB);
    }
  
    return monthOrder[monthA] - monthOrder[monthB];
  };
  

  mergedArray = mergedArray.sort(compareMonthNames);

  const createValues = (arr, dataMap) =>
    mergedArray.map((label) => ({
      label,
      value: dataMap.has(label) ? dataMap.get(label) : 0,
    }));

  const aMap = new Map(data.map((item) => [item.label, item.value]));
  const bMap = new Map(casedata.map((item) => [item.label, item.value]));
  const cMap = new Map(knowdata.map((item) => [item.label, item.value]));
  const dMap = new Map(vx.map((item) => [item.label, item.value]));

  const values = createValues(mergedArray, aMap);
  const caseval = createValues(mergedArray, bMap);
  const knowval = createValues(mergedArray, cMap);
  const vxwval = createValues(mergedArray, dMap);

  const color = data.map((d) => d.color);
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          boxWidth: 5,
          boxHeight: 5,
          font: {
            size: 11,
          },
        },
      },
      title: {
        display: true,
        text: title,
        position: "left",
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 10,
          },
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 10,
          },
        },
      },
    },
  };

  const datas = {
    labels: mergedArray,
    datasets: [
      {
        label: "Artifacts",
        data: values.map((d) => d.value),
        backgroundColor: "#9bc55b",
        borderWidth: 2,
        borderRadius: 5,
        borderSkipped: false,
        borderColor: "#9bc55b",
      },
      {
        label: "Searches",
        data: caseval.map((d) => d.value),
        backgroundColor: "#7c5ea0",
        borderWidth: 2,
        borderRadius: 5,
        borderSkipped: false,
        borderColor: "#7c5ea0",
      },
      // {
      //   label: "Knowledge",
      //   data: knowval.map((d) => d.value),
      //   backgroundColor: "#b44555",
      //   borderWidth: 2,
      //   borderRadius: 5,
      //   borderSkipped: false,
      //   borderColor: "#b44555",
      // },
      // {
      //   label: "Value Adds",
      //   data: vxwval.map((d) => d.value),
      //   backgroundColor: "#eb954f",
      //   borderWidth: 2,
      //   borderRadius: 5,
      //   borderSkipped: false,
      //   borderColor: "#eb954f",
      // },
    ],
  };
  return (
    <div
      className="chart-container"
      style={{
        width: "auto",
        maxWidth: "100%",
        margin: "0 auto",
        marginTop: "10px",
      }}
    >
      <Line options={options} data={datas} />
    </div>
  );
}

export default LineChart;
