import React, { useState, useEffect } from "react";
import "./createuser.css";
import { Modal, Card, Form } from "@themesberg/react-bootstrap";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import apiClient from "../../../common/http-common";
import { Routes } from "../../../routes";
import HomeOutlineIcon from "../../../assets/img/icons/home-outline-icon.svg";
import { Alert } from "../../../components/Alert";
import { connect ,useDispatch} from "react-redux";
import Swal from "sweetalert2";
import { unsavedChange } from "../../../actions/actions";

const CreateUser = (state) => {
  const [showDefault, setShowDefault] = useState(false);
  const handleClose = () => (setShowDefault(false), setSearchValue(""), setFilteredData(rolePopUp));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let isAdmin = state?.isAdmin;
  const[unsavedChanges,setunsavedChanges] = useState(false)

  const [rolePopUp, setRolePopUp] = useState([]);
  let [filteredData, setFilteredData] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [department, setdepartment] = useState([]);
  const [rolesData, setRolesData] = useState([]);
  const [isdisabled, setdisabled] = useState(false);
  const [logUserId] = useState(state?.user?.Id);
  let [userDetails, setUserDetails] = useState({
    id: null,
    emailId: "",
    password: "",
    firstName: "",
    lastName: "",
    phoneNo: "",
    isActive: 0,
    isLocked: 0,
    isApproved : 0,
    loginType: "1",
    userId: parseInt(logUserId,10),
  });

  const [departmentList, setdepartmentList] = useState([]);
  const [UserStatus, setUserStatus] = useState(true);
  const [LockStatus, setLockStatus] = useState(false);

  const [showRolesTable, setShowRolesTable] = useState(false);
  // const [fieldDisabled, setFieldDisabled] = useState(false);

const validateSpecialCharacters = (email) => {
  const specialCharactersCheck = /^[\w.@]+$/;
  return specialCharactersCheck.test(email);
};

const validateEmailFormat = (email) => {
  const emailFormatCheck = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailFormatCheck.test(email);
};

const validateEmail = (email) => {
  const isValidSpecialCharacters = validateSpecialCharacters(email);
  const isValidEmailFormat = validateEmailFormat(email);
  return isValidSpecialCharacters && isValidEmailFormat;
};

        
  
  const checkEmailAvailability = async (email) => {
    try {
      const response = await apiClient.post("/user/check-email", { email });
      return response.data.status === "exists";
    } catch (error) {
      return false;
    }
  };

  const handleUserStatusChange = (event) => {
    setUserStatus(event.target.checked);
  };

  const handleLockStatusChange = (event) => {
    setLockStatus(event.target.checked);
  };

  const AddRoleData = (param) => {
    setSelectedRows(param.selectedRows);
  };
  const [ addbuttonrole,setaddbuttonrole] = useState("Add");
  const postRoleData = async () => {
    if(addbuttonrole === "Adding..."){
      return;
    }
    setaddbuttonrole("Adding...");
    setdisabled(true);
    try {
      if (selectedRows.length > 0) {
        const promises = selectedRows.map((row) => {
          return apiClient.post("/user/role", {
            userId: userDetails.id,
            roleId: row.Id,
          });
        });
 
        await Promise.all(promises);
        Alert("succ", "Roles Added Successfully...");
         getRolesData();
        handleClose();
        setdisabled(false);
        setaddbuttonrole("Add");
        setSelectedRows([]);
      } else {
        Alert("warn", "Please Choose Role..");
        setaddbuttonrole("Add");
        setdisabled(false);
      }
    } catch (error) {
      Alert("error", "Please Try Again");
      setdisabled(false);
    }

  };

  const getRolesData = () => {
    apiClient
      .get(`/user/roles/${userDetails.id}`)
      .then((response) => {
        if (response?.data?.user) {
          setRolesData(response.data.user);
          // let deflist = [];
          // response.data.user.forEach((element) => {
          //   deflist.push({
          //     label: element.ModuleName,
          //     value: element.DefaultPageId,
          //   });
          // });
          // setDefaultList(removeDuplicatesByValue(deflist));
          // setDefaultPage({
          //   label: location.state.user.ModuleName,
          //   value: location.state.user.DefaultpageId,
          // });
        }
      });
  };


  // const deleteRole = (param) => {
  //   Swal.fire({
  //     title: "",
  //     text: "Are you sure, you want to remove?",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#1658a0",
  //     cancelButtonColor: "#1658a0",
  //     confirmButtonText: "Yes",
  //     allowOutsideClick: false,
  //     allowEscapeKey: false
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       let newRoles = rolesData.filter((role) => role !== param);
  //       apiClient
  //         .delete("/user/role", {
  //           data: {
  //           userId: userDetails.id,
  //           roleId: param.Id,
  //           },
  //         })
  //         .then((response) => {});
  //       setRolesData(newRoles);
  //       getRolesData();
  //       Alert("succ"," User Role Deleted Successfully");
  //     } else {
  //       handleClose();
  //     }
  //   });
  // };

  const deleteRole = (param) => {
    Swal.fire({
      title: "",
      text: "Are you sure, you want to remove?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1658a0",
      cancelButtonColor: "#1658a0",
      confirmButtonText: "Yes",
      allowOutsideClick: false,
      allowEscapeKey: false
    }).then((result) => {
      if (result.isConfirmed) {
        apiClient
          .delete("/user/role", {
            data: {
              userId: userDetails.id,
              roleId: param.RoleId.toString(),
            },
          })
          .then((response) => {
            if (response.data.error) {
              Alert("error", response.data.error);
            } else {
              Alert("succ", response.data.user);
              getRolesData();
            }
          });
      } else {
        handleClose();
      }
    });
  };


  const [saveButtonText, setSaveButtonText] = useState('Save');

  function postNewUser() {

    if (saveButtonText === 'Saving...') {
      return;
    }
    setSaveButtonText('Saving...');
    setdisabled(true);

    if (
      userDetails.firstName.trim() === "" &&
      !userDetails.firstName &&
      userDetails.emailId.trim() === "" &&
      !userDetails.emailId &&
      !department.value
    ) {
      Alert("warn", "User Name, Email ID & Department can't be empty!");
      setSaveButtonText('Save');
      setdisabled(false);
    } else if (userDetails.firstName.trim() === "" || !userDetails.firstName) {
      Alert("warn", "User Name can't be empty!");
      setSaveButtonText('Save');
      setdisabled(false);
    } else if (userDetails.firstName.trim().length > 100) {
      Alert("warn", "Name cannot exceed 100 characters");
      setSaveButtonText('Save');
      setdisabled(false);
    } else if (userDetails.emailId.trim().length > 100) {
      Alert("warn", "Email ID cannot exceed 100 characters");
      setSaveButtonText('Save');
      setdisabled(false);
    } else if (userDetails.emailId.trim() === "" || !userDetails.emailId) {
      Alert("warn", "Email ID can't be empty!");
      setSaveButtonText('Save');
      setdisabled(false);
    } else if (!department.value) {
      Alert("warn", "Department can't be empty!");
      setSaveButtonText('Save');
      setdisabled(false);
    } else if (userDetails.firstName.length > 50 || userDetails.emailId.length > 50) {
      Alert ("warn", "User Name and Email Cannot exceed 50 characters")
      setSaveButtonText('Save');
      setdisabled(false);
    } else if (!validateEmail(userDetails.emailId)) {
      Alert("warn", "Invalid email format");
      setSaveButtonText('Save');
      setdisabled(false);
    } else {
      userDetails.firstName = userDetails.firstName.trim();
      userDetails.emailId = userDetails.emailId.trim();
      userDetails.departmentId = department.value;

      if (UserStatus) userDetails.isActive = 1;
      else userDetails.isActive = 0;
      if (LockStatus) userDetails.isLocked = 1;
      else userDetails.isLocked = 0;
      let Approved = isAdmin ? 1 : 0;
      userDetails.isApproved = Approved;


      const apiCallPromise = apiClient.post("/user/update", userDetails);

      const showAlertPromise = apiCallPromise.then((response) => {
        if (response?.data?.user?.length > 0) {
          if (userDetails.id) {
            Alert("succ", "User Updated Successfully");
            setSaveButtonText('Save');
            setdisabled(false);
          } else {
            if (response.data.user === "Email Id is already registered") {
              Alert("warn", response.data.user);
              setSaveButtonText('Save');
              setdisabled(false);
            } else {
              setShowRolesTable(true);
              Alert("succ", "User Created Successfully");
              userDetails.id = response.data.user[0].UserId;
              setUserDetails(userDetails);
              setunsavedChanges(false);
              dispatch(unsavedChange(false));
              setSaveButtonText('Save');
              setdisabled(true);
              // setFieldDisabled(true);
            }
          }
        } else {
          Alert("error", "Please Try Again");
          setSaveButtonText('Save');
        }
      });

      Promise.all([apiCallPromise, showAlertPromise])
        .then(() => {
        })
        .catch((error) => {
          setdisabled(false);
        });
    }
  }
    const handleKeyDown = (e) => {
    if (e.key === "Enter"&& !isdisabled) {
      e.preventDefault();
      postNewUser();
    }
  };

  function capitalizeFirstLetter(str) {
    return str ? str.charAt(0).toUpperCase() + str.slice(1) : '';
  }

  const columnAR = [
    {
      name: "Role Name",
      sortable : true,
      selector: (param) => capitalizeFirstLetter(param.RoleName),
    },
    {
      name: "Role Description",
      sortable: true,
      selector: (param) => (
        <span title={param.RoleDescription}>
          {capitalizeFirstLetter(param.RoleDescription.length > 20
            ? param.RoleDescription.substring(0, 20) + "..."
            : param.RoleDescription)}
        </span>
      ),
    },
  ];

  const column = [
    // {
    //   name: "SERO_ID",
    //   sortable:true,
    //   selector: (param) => param.RoleId,
    // },
    {
      name: "Role Name",
      sortable: true,
      selector: (param) => capitalizeFirstLetter(param.RoleName),
    },
    {
      name: "Role Description",
      selector: (param) => (
        <span title={param.RoleDescription}>
          {capitalizeFirstLetter(param.RoleDescription.length > 40
            ? param.RoleDescription.substring(0, 40) + "..."
            : param.RoleDescription)}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Actions",
      cell: (param) => (
        <FontAwesomeIcon
          icon={faTrash}
          size="lg"
          color="#1475DF"
          onClick={() => deleteRole(param)}
        />
      ),
      ignoreRowClick: true,
      allowOverflow: true,
    },
  ];
  useEffect(()=>{
    if(userDetails.firstName.length>0 || userDetails.emailId.length>0 || department.value){
      setunsavedChanges(true);
      dispatch(unsavedChange(true));
    }else{
      setunsavedChanges(false)
      dispatch(unsavedChange(false));
    }
  },[userDetails,department])
const handleCloseuser = ()=>{
  setShowDefault(false)
  if(unsavedChanges){
    Swal.fire({
      title: "Discard Changes?",
      text: "Do you want to save the changes ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1658a0",
      cancelButtonColor: "#d33",
      confirmButtonText: "Save ",
      cancelButtonText: "Discard ",
      allowOutsideClick: false,
      allowEscapeKey: false
    }).then((result) => {
      if (result.isConfirmed) {
        // Save changes and navigate away
      postNewUser()
      } else {
        setunsavedChanges(false);
        navigate(Routes.UserM);
        dispatch(unsavedChange(false));
      }
      });
  } else {
    navigate(Routes.UserM);
  }

}
const handleCloserole = ()=>{
  setShowDefault(false)
  setSelectedRows([]);
  if(selectedRows.length>0){
    Swal.fire({
      title: "Discard Changes?",
      text: "Do you want to save the changes ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1658a0",
      cancelButtonColor: "#d33",
      confirmButtonText: "Save ",
      cancelButtonText: "Discard ",
      allowOutsideClick: false,
      allowEscapeKey: false
    }).then((result) => {
      if (result.isConfirmed) {
        // Save changes and navigate away
      postRoleData()
      }
      else{

      }
  });
     
  } else {
    handleClose();
  }
}
  useEffect(() => {
    apiClient
      .get("/lookup/Department/1")
      .then((response) => {
        if (response.data.lookup.length > 0) {
          const arr = [];
          response?.data?.lookup?.map((user) => {
            const obj = { value: user.Id, label: user.Name };
            arr.push(obj);
            setdepartmentList(arr);
          });
        }
      })
      .catch((err) => {
        Alert("warn", "Please Try Again");
      });
  }, []);

  // useEffect(() => {
  //   apiClient.get("/user/roles/role").then((response) => {
  //     if (response?.data?.user) {
  //       setRolePopUp(response.data.user);
  //       setFilteredData(response.data.user);
  //     }
  //   });
  // }, []);
  // useEffect(() => {
  //   apiClient.get("/user/roles/role").then((response) => {
  //     if (response?.data?.user) {
  //       const allRoles = response.data.user;
  //       const assignedRoleIds = rolesData.map((role) => role.Id);
  
  //       // Filter out roles that are already assigned
  //       const availableRoles = allRoles.filter(
  //         (role) => !assignedRoleIds.includes(role.Id)
  //       );
  
  //       setRolePopUp(availableRoles);
  //       setFilteredData(availableRoles);
  //     }
  //   });
  // }, [rolesData]);

  useEffect(() => {
    const fetchFilteredModules = () => {
      apiClient.get("/user/roles/role").then((response) => {
        if (response?.data?.user) {
          const filteredModules = response.data.user;
          const uniqueArray = filteredModules.filter(
            (item1) => !rolesData.some((item2) => item2.RoleId === item1.Id)
          );
          setRolePopUp(uniqueArray);
          setFilteredData(uniqueArray);
        }
      });
    };
    fetchFilteredModules();
  }, [rolesData]);
 
  
  
  

  const filterData = (value) => {
    const lowerCaseValue = value.toLowerCase().trim();
    filteredData = rolePopUp.filter(
      (item) =>
        item.RoleName.toLowerCase().includes(lowerCaseValue)
    );
    setFilteredData(filteredData);
  };
  const [searchValue, setSearchValue] = useState("");
  const handleSearch = (event) => {
    setSearchValue(event.target.value);
    filterData(event.target.value);
  };
  
  const [selectedRowsForDeletion, setSelectedRowsForDeletion] = useState([]);

// Update handleRowSelected function to add selected row to selectedRowsForDeletion
const handleRowSelected = (row) => {
  setSelectedRowsForDeletion([...selectedRowsForDeletion, row]);
};
const deleteSelectedRole = (roleId) => {
  return apiClient.delete("/user/role", {
    data: {
      userId: userId,
      roleId: roleId.toString(),
    },
  });
};
// Update deleteSelectedRows function to use selectedRowsForDeletion state
const deleteSelectedRows = () => {
  if (selectedRowsForDeletion.length > 0) {

    Swal.fire({
      title: "",
      text: "Are you sure you want to remove?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1658a0",
      cancelButtonColor: "#1658a0",
      confirmButtonText: "Yes",
      allowOutsideClick: false,
      allowEscapeKey: false
    }).then((result) => {
      if (result.isConfirmed) {
        
        const promises = selectedRows.map(row => deleteSelectedRole(row.RoleId));

        

        // Handle delete request promises
        Promise.all(promises)
          .then((responses) => {
            // Check if any response is undefined or lacks status property
            const isError = responses.some(response => !response || response.status === undefined);

            if (isError) {
              // Handle error case
              Alert("error", "An error occurred while deleting roles.");
            } else {
              // Handle success case
              Alert("succ", "Roles deleted successfully.");
              // Refresh roles data or perform any necessary action
              getRolesData();
              setSelectedRowsForDeletion([]);
            }
          })
          .catch((error) => {
            // Handle promise rejection
            Alert("error", "An error occurred while deleting roles.");
            console.error("Delete request failed:", error);
          });
      } else {
        handleClose();
      }
    });
  } else {
    Alert("warn", "Please Choose Role.");
  }
};

const [nameError, setNameError] = useState("");
const [emailError, setEmailError] = useState("");


const handleNameChange = (e) => {
  const value = e.target.value;

  if (value.trim().length > 100) {
    setNameError('Name cannot exceed 100 characters!');
  } else {
    setNameError('');
  }
};

const handleEmailChange = (e) => {
  const value = e.target.value;

  if (value.trim().length > 100) {
    setEmailError('Email Id cannot exceed 100 characters!');
  } else {
    setEmailError('');
  }
};


  return (
    <div>
      <div className="maincontent__breadcrumb">
        <img
          className="cursor_pointer"
          src={HomeOutlineIcon}
          alt="home"
          onClick={() => {
            navigate(state.defaultpage);
          }}
        />
        <span className="maincontent__breadcrumb--divider">/</span>
        <span
          className="cursor_pointer"
          onClick={() => {
            navigate(Routes.UserM);
          }}
        >
          Users
        </span>
        <span className="maincontent__breadcrumb--divider">/</span>
        <span className="maincontent__breadcrumb--active">Create User</span>
      </div>

      <div className="mt-4 maincontent__card--body">
        <div className="maincontent__card--header">
          <h2 className="maincontent__card--header-title">Create User</h2>
        </div>

        <div className="maincontent__card--content ">
          <div className="grid grid-cols-1 gap-4 md:grid-cols-3 md:justify-between">
            <Form.Group>
              <Form.Label>User Name <span className="required">*</span></Form.Label>
              <Form.Control
                placeholder="User Name"
                value={userDetails.firstName}
                // disabled={fieldDisabled}
                onChange={(e) => {
                  setUserDetails({ ...userDetails, firstName: e.target.value });
                  handleNameChange(e);
                }}
                onKeyDown={handleKeyDown}
              />
              {nameError && (  
                    <p className="error-message">{nameError}</p>
                  )}
            </Form.Group>

            <Form.Group>
              <Form.Label>Email ID <span className="required">*</span></Form.Label>
              <Form.Control
                type="email"
                placeholder="email@example.com"
                value={userDetails.emailId}
                // disabled={fieldDisabled}
                onChange={(e) => {
                  setUserDetails({
                    ...userDetails,
                    emailId: e.target.value.replace(/\s/g, ""),
                  });
                  handleEmailChange(e);
                }}
                onKeyDown={handleKeyDown}
              />
              {emailError && (  
                    <p className="error-message">{emailError}</p>
                  )}
            </Form.Group>

            <div className="flex flex-col gap-2">
              <Form.Group className="flex items-center justify-start gap-8">
                <Form.Label>Is Active</Form.Label>
                <Form.Check
                  type="checkbox"
                  checked={UserStatus}
                  // disabled={fieldDisabled}
                  onChange={handleUserStatusChange}
                />
              </Form.Group>

              <Form.Group className="flex items-center justify-start gap-6">
                <Form.Label>Is Locked</Form.Label>
                <Form.Check
                  type="checkbox"
                  checked={LockStatus}
                  // disabled={fieldDisabled}
                  onChange={handleLockStatusChange}
                />
              </Form.Group>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-3 mt-8 md:justifY-between">
            <Form.Group>
              <Form.Label>Department <span className="required">*</span></Form.Label>
              <div className="input-group">
                <div className="input-group-prepend"></div>
                <Select
                  options={departmentList}
                  required
                  value={department}
                  // isDisabled={fieldDisabled}
                  onChange={(e) => {
                    setdepartment(e);
                  }}
                  onKeyDown={handleKeyDown}
                />
              </div>
            </Form.Group>
          </div>
          <div className="flex justify-center gap-4 mt-8 md:justify-end">
            <button
              className="maincontent__btn maincontent__btn--primaryblue"
              onClick={postNewUser}
              disabled={isdisabled}
            >
             {saveButtonText}
            </button>
            <button
              className="maincontent__btn maincontent__btn--primaryblue"
              onClick={() => {handleCloseuser()
                // navigate(Routes.UserM);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>

      {showRolesTable &&
        userDetails.firstName.trim() &&
        userDetails.emailId && (
          <div className="maincontent__card" style={{ margin: "1rem 0 0 0" }}>
            <div className="maincontent__card--header">
              <h2 className="maincontent__card--header-title">
                Roles Assigned
              </h2>
            </div>

            <div className="maincontent__pageheader-right">
              <button
                className="maincontent__btn maincontent__btn--primaryblue tableBottomBtn"
                onClick={() => setShowDefault(true)}
              >
                +Add Roles
              </button>

              <div className="addspace"></div>

              <Modal
                className="mdlclspop"
                as={Modal.Dialog}
                centered
                show={showDefault}
                onHide={handleClose}
              >
                <Card
                  centered
                  show={showDefault}
                  onHide={handleClose}
                  className="usrCrd"
                >
                  <Card.Header className="rhdivCard">
                    <div className="maincontent__card--header">
                      <h2 className="maincontent__card--header-title">Roles</h2>
                    </div>
                  </Card.Header>
                  <div className="maincontent__card--tableheader-right mt-2 mr-2">
                  <div className="relative search-containerKMArti kmarticle-seactform">
                <input
                  type="search"
                  placeholder="Search by Role Name"
                  className="w-full pt-2 pb-2 pl-2 pr-[26%] text-xs border-0 rounded-[28px] outline-0 h-[34px]"
                  value={searchValue}
                  onChange={handleSearch}
                />
                <button
                  type="button"
                  className="absolute top-1 right-1 bg-[rgba(0,0,0,60%)] rounded-[28px] h-[26px] text-white text-[10px] font-bold button"
                >
                  Search
                </button>
              </div>
              </div>
                  <div className="cardContent Cctent">
                    <Card.Body className="rmroldcb">
                      <DataTable
                        columns={columnAR}
                        data={filteredData}
                        selectableRows
                        onSelectedRowsChange={AddRoleData}
                        highlightOnHover
                        pagination
                        paginationRowsPerPageOptions={[5, 10, 15]}
                        paginationPerPage={5}
                      />
                    </Card.Body>
                  </div>

                  <div
                    className="flex justify-end gap-4 mt-4"
                    style={{
                      gap: "10px",
                      marginRight: "10px",
                      paddingBottom: "1rem",
                    }}
                  >
                    {selectedRows.length > 0 && (
                    <button
                      className="maincontent__btn maincontent__btn--primaryblue"
                      onClick={postRoleData}
                    >
                      {addbuttonrole}
                    </button>
                    )}
                    <button
                      className="maincontent__btn maincontent__btn--primaryblue"
                      onClick={()=>{handleCloserole()}}
                    >
                      Cancel
                    </button>
                  </div>
                </Card>
              </Modal>
            </div>
            <div className="addspace"></div>
            <div className="maincontent__card--content ">
              <Card.Body className="rmroldcb">
              {selectedRowsForDeletion.length === 0 ? (
    <DataTable
      columns={column}
      data={rolesData}
      selectableRows
      onSelectedRowsChange={({ selectedRows }) => setSelectedRowsForDeletion(selectedRows)}
      highlightOnHover
      pagination
      paginationRowsPerPageOptions={[5, 10, 15]}
      paginationPerPage={5}
    />
  ) : (
    <DataTable
      columns={column.slice(0, -1)} // Remove the action column
      data={rolesData}
      selectableRows
      onSelectedRowsChange={({ selectedRows }) => setSelectedRowsForDeletion(selectedRows)}
      highlightOnHover
      pagination
      paginationRowsPerPageOptions={[5, 10, 15]}
      paginationPerPage={5}
    />
  )}
  {selectedRowsForDeletion.length > 0 && (
    <button 
      className="maincontent__btn maincontent__btn--primaryblue"
      onClick={deleteSelectedRows}
    >
      Delete
    </button>
  )}
              </Card.Body>
            </div>
          </div>
        )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  ...state,
  isAdmin: state.isAdmin,
});
export default connect(mapStateToProps)(CreateUser);
