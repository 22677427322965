import React, { useState } from "react";
import { connect } from "react-redux";
import { Modal, Col, Card } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faEdit, faQuestionCircle, faTrash, faEye } from "@fortawesome/free-solid-svg-icons";
import { Upload } from "@aws-sdk/lib-storage";
import { S3Client } from "@aws-sdk/client-s3"; 
const AWSFileUpload = ({   onClose,defaultPage }) => {
    let currentpath = window.location.hash.replace("#", "");
    let haumbstatus = defaultPage === currentpath ? false : true;
    const [HideHomeBreadCumb] = useState(haumbstatus);
    // const [selectedFile, setSelectedFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    // const [message, setMessage] = useState("");
    // const [showDefault, setShowDefault] = useState(false);
    const [file, setFile] = useState(null);
    const [progress, setProgress] = useState(0);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    // // AWS S3 configuration
    // const S3_BUCKET = 'newbucketlist45';
    // const REGION = 'Asia Pacific (Sydney) ap-southeast-2';
 
    // AWS.config.update({
    //     accessKeyId: 'AKIAXYKJUJN2YQ47ZPAF',
    //     secretAccessKey: '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'
    // });
 
    // const myBucket = new AWS.S3({
    //     params: { Bucket: S3_BUCKET },
    //     region: REGION,
    // });
const handleClose = () =>{
    setShowDefault(false)
 
}
    const handleFileInput = (e) => {
        setFile(e.target.files[0]);
    };
 
    const handleUpload = async() => {
        if (!file) {
            setError("No file selected.");
            return;
          }

        const target = {
            Bucket: "newbucketlist45",
            Key: file.name,
            Body: file,
          };
      
          const cred = {
            accessKeyId: "AKIAXYKJUJN2YQ47ZPAF", secretAccessKey: "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",
          };
      
          try {
            const parallelUploads3 = new Upload({
              client: new S3Client({
                region: 'ap-southeast-2',
                credentials: cred,
              }),
              params: target,
              leavePartsOnError: false,
            });
      
            parallelUploads3.on("httpUploadProgress", (progress) => {
              console.log(progress);
              setProgress(Math.round((progress.loaded / progress.total) * 100));
            });
      
            await parallelUploads3.done();
            setSuccess(true);
            setError(null);
          } catch (e) {
            console.error(e);
            setError("Upload failed, please try again.");
            setSuccess(false);
            setUploading(false);
          }
 
 
       
    };
    const handleOnClose = () => {
        onClose();
      };
     
    return (
            <div className="usrCrd sharetable card">
            <div className="bg-[#d0d2d9] rounded-[20px] p-2">
            <FontAwesomeIcon
              icon={faClose}
              onClick={handleOnClose}
              style={{ borderRadius: "20px", backgroundColor: "#03022a" }}
              className="cursor-pointer closeIcon"
            />
            <p className="font-semibold text-black text-[17px]">File Upload Details</p>
          </div>
          <div  className=" mt-8 ml-6 text-center">
            <input type="file" onChange={handleFileInput} />
         
            </div>
            <div class ="mt-8 p-2 text-center">
            <button
            type="button"
            className="btn btn-info bg-[#050346] border-none rounded-[20px]"
 
            onClick={handleUpload} disabled={uploading}>
                {uploading ? 'Uploading...' : 'Upload'}
            </button>
            {progress > 0 && <div>Upload Progress: {progress}%</div>}
      {success && <div>File uploaded successfully!</div>}
      {error && <div>{error}</div>}
            </div>
        </div>
    );
};
 
const mapStateToProps = (state) => ({
    defaultPage: state.defaultpage,
});
 
export default connect(mapStateToProps)(AWSFileUpload);
 