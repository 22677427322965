import React, { useEffect, useState } from "react";
import apiClient from "../../../common/http-common";
import DataTable from "react-data-table-component";
import "./createrole.css";
import { useNavigate, useLocation } from "react-router-dom";
import { Routes } from "../../../routes";
import HomeOutlineIcon from "../../../assets/img/icons/home-outline-icon.svg";
import { Modal, Card, Form, Accordion } from "@themesberg/react-bootstrap";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { Alert } from "../../../components/Alert";
import { connect , useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { unsavedChange } from "../../../actions/actions";
 
const CreateRole = (state) => {
  const [Rname, setRname] = useState("");
  const [Rdes, setRdes] = useState("");
  const [defaultlPage, setdefaultlPage] = useState({});
  const [ModuleTabData, setModuleTabData] = useState([]);
  let [UserTabData, setUserTabData] = useState([]);
  const [addModuleTabData, setAddModuleTabData] = useState([]);
  const [moduleTabList, setModuleTabListData] = useState([]);
  const [addUserTabData, setAddUserTabData] = useState([]);
  const [getrows, setgetrows] = useState([]);
  const [getusers, setgetusers] = useState([]);
  const [ShowDef, setShowDef] = useState(false);
  const [showDefault, setShowDefault] = useState(false);
  const handleClose = () => (setShowDefault(false), setShowDef(false),
  setSearchValue(""),
   setFilteredData(addModuleTabData),
   setSearchValueUser(""),
   setFilteredUserData(addUserTabData)
   );
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [showTables, setShowTables] = useState(false);
  const [userIdd, setuserIdd] = useState();
  const [logUserId] = useState(state?.user?.Id);
  const [isdisabled, setIsdisabled] = useState(false);
  let [filteredData, setFilteredData] = useState();
  let [filteredUserData, setFilteredUserData] = useState();
  let [userDetails, setUserDetails] = useState({
    id: null,
    emailId: "",
    password: "",
    firstName: "",
    lastName: "",
    phoneNo: "",
    isActive: 0,
    isLocked: 0,
    loginType: "1",
    userId: parseInt(logUserId,10),
  });
 const [unsavedChanges ,setunsavedChanges] = useState(false);
  const AddModulePermission = (param) => {
    setgetrows(param.selectedRows);
  };
 
  const postModulePermission = (obj) => {
    if (getrows.length > 0) {
      let err = 0;
      const selectedModules = getrows.map((data) => ({
        id: parseInt( data.id, 10),
        ModuleName: data.ModuleName,
        ModuleDescription: data.ModuleDescription,
      }));
      setModuleTabData((prevModuleTabData) => [
        ...prevModuleTabData,
        ...selectedModules,
      ]);
 
      getrows.forEach((data) => {
        let params = {
          roleId: parseInt(userIdd, 10),
          moduleId: parseInt(data.id, 10),
          userId: parseInt(1, 10),
        };
        apiClient.post("user/roles/module", params).catch(() => {
          err++;
        });
      });
 
      if (err) Alert("error", "Please Try Again!..");
      else {
        Alert("succ", "Module Updated");
      setgetrows([])
      handleClose();
    };

    } else {
      Alert("warn", "At least Please Choose One Module");
    }
  };

  const handleClosemodal = () => {
    setShowDefault(false), setShowDef(false)
    setgetrows([]);
    if (getrows.length >0) {
 
      Swal.fire({
        title: "Discard Changes?",
        text: "Do you want to save the changes ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1658a0",
        cancelButtonColor: "#d33",
        confirmButtonText: "Save ",
        cancelButtonText: "Discard ",
        allowOutsideClick: false,
        allowEscapeKey: false  
      }).then((result) => {
        if (result.isConfirmed) {
          // Save changes and navigate away
          postModulePermission();
          setSearchValue("");
          setFilteredData(addModuleTabData);
        } else {
          setSearchValue("");
          setFilteredData(addModuleTabData);
        }
      });
    } else {
      setSearchValue("");
      setFilteredData(addModuleTabData);
    }
  };

 
  const AddUsers = (param) => {
    setgetusers(param.selectedRows);
  };
 
  const postUsers = (obj) => {
    if (getusers.length > 0) {
      let err = 0;
      const selectedUsers = getusers.map((data) => ({
        Id: parseInt(data.Id, 10),
        FirstName: data.FirstName,
        LastName: data.LastName,
        EmailId: data.EmailId,
      }));
      setUserTabData((prevUserTabData) => [
        ...prevUserTabData,
        ...selectedUsers,
      ]);
 
      getusers.forEach((data) => {
        let params = {
          roleId: parseInt(userIdd, 10),
          userId: parseInt(data.Id, 10),
        };
 
        apiClient.post("/user/role", params).catch(() => {
          err++;
        });
      });
 
      if (err) Alert("error", "Please Try Again!..");
      else {
        Alert("succ", "User Updated");
        setgetusers([]);
        handleClose();
      }
    } else {
      Alert("warn", "At least Please Choose One User");
    }
  };

    const handleCloseuser = () => {
    setShowDefault(false), setShowDef(false);
    setgetusers([]);
    if (getusers.length >0) {
 
      Swal.fire({
        title: "Discard Changes?",
        text: "Do you want to save the changes ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1658a0",
        cancelButtonColor: "#d33",
        confirmButtonText: "Save ",
        cancelButtonText: "Discard ",
        allowOutsideClick: false,
        allowEscapeKey: false  
      }).then((result) => {
        if (result.isConfirmed) {
          // Save changes and navigate away
          postUsers();
          setSearchValueUser("");
          setFilteredUserData(addUserTabData);
        } else {
          setSearchValueUser("");
          setFilteredUserData(addUserTabData);
        }
      });
    } else {
      setSearchValueUser("");
      setFilteredUserData(addUserTabData);
    }
  };
 
  const handleModuleDelete = async (id) => {
    Swal.fire({
      title: "",
      text: "Are you sure, you want to remove?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1658a0",
      cancelButtonColor: "#1658a0",
      confirmButtonText: "Yes",
      allowOutsideClick: false,
      allowEscapeKey: false
    }).then((result) => {
      if (result.isConfirmed) {
        apiClient
          .delete("/user/roles/module", {
            data: {
              roleId: parseInt(userIdd, 10),
              moduleId: parseInt(id, 10),
              userId: parseInt(1, 10),
            },
          })
          .then((response) => {
            if (response.data.error) {
              Alert("error", response.data.error);
            } else {
              Alert("succ", response.data.user);
            }
          })
          .catch((err) => {
            Alert("error", "Please Try Again");
          });
 
        const modadd = ModuleTabData.filter((module) => {
          return module.id !== id;
        });
        setModuleTabData(modadd);
      } else {
        handleClose();
      }
    });
  };
 
  const handleUserDelete = async (Id) => {
    Swal.fire({
      title: "",
      text: "Are you sure, you want to remove?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1658a0",
      cancelButtonColor: "#1658a0",
      confirmButtonText: "Yes",
      allowOutsideClick: false,
      allowEscapeKey: false
    }).then((result) => {
      if (result.isConfirmed) {
        apiClient
          .delete("/user/role", {
            data: {
              roleId: parseInt(userDetails.id, 10),
              userId: parseInt(Id, 10),
            },
          })
          .then((response) => {
            if (response.data.error) {
              Alert("error", response.data.error);
            } else {
              Alert("succ", response.data.user);
            }
          });
 
        const useradd = UserTabData.filter((user) => {
          return user.Id !== Id;
        });
        setUserTabData(useradd);
      } else {
        handleClose();
      }
    });
  };

  function capitalizeFirstLetter(str) {
    return str ? str.charAt(0).toUpperCase() + str.slice(1) : '';
  }


  const column1 = [
    // {
    //   name: "SEMO_ID",
    //   selector: (param) => param.id,
    //   sortable: true,
    // },
    {
      name: "Module Name",
      selector: (param) => capitalizeFirstLetter(param.ModuleName),
      sortable: true,
    },
    {
      name: "Description",
      selector: (param) => capitalizeFirstLetter(param.ModuleDescription),
      sortable: true,
    },
    {
      name: "Actions",
      id: "id",
      cell: (param) => (
        <a>
          <FontAwesomeIcon
            icon={faTrash}
            size="lg"
            color="#1475DF"
            onClick={() => {
              handleModuleDelete(param.id);
            }}
          />
        </a>
      ),
 
      ignoreRowClick: true,
      allowOverflow: true,
    },
  ];
 
  const columnAddModulePermission = [
    {
      name: "Module Name",
      selector: (param) => capitalizeFirstLetter(param.ModuleName),
      sortable: true,
    },
    {
      name: "Description",
      selector: (param) => capitalizeFirstLetter(param.ModuleDescription),
      sortable: true,
    },
  ];
 
  const columnAddUser = [
    {
      name: "User Name",
      selector: (param) => capitalizeFirstLetter(param.FirstName + " " + param.LastName),
      sortable: true,
    },
    {
      name: "User ID",
      selector: (param) => param.EmailId,
      sortable: true,
    },
  ];
 
  const column2 = [
    // {
    //   name: "SEUS_ID",
    //   selector: (param) => param.Id,
    //   sortable: true,
    // },
    {
      name: "User Name",
      selector: (param) => capitalizeFirstLetter(param.FirstName + " " + param.LastName),
      sortable: true,
    },
    {
      name: "User ID",
      selector: (param) => param.EmailId,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (param) => (
        <a>
          <FontAwesomeIcon
            icon={faTrash}
            size="lg"
            color="#1475DF"
            onClick={() => {
              handleUserDelete(param.Id);
            }}
          />
        </a>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
    },
  ];
 
  useEffect(() => {
    const fetchFilteredModules = async () => {
      try {
        const response = await apiClient.get("/user/role/module/list");
        const filteredModules = response.data.user;
        let defVal = [];
        filteredModules.map((row) => {
          defVal.push({ value: row.id, label: row.ModuleName });
        });
        setModuleTabListData(defVal);
        const uniqueModules = filteredModules.filter((module) => {
          return !ModuleTabData.some(
            (existingModule) => existingModule.id === module.id
          );
        });
        setAddModuleTabData(uniqueModules);
        setFilteredData(uniqueModules);
      } catch (error) {

      }
    };
  
    fetchFilteredModules();
  }, [ModuleTabData]);
  
 
  useEffect(() => {
    const fetchFilteredModules = async () => {
      try {
        const response = await apiClient.post("/user/search", {
          firstName: "",
          lastName: "",
          emailId: "",
          isApproved: null,
          isActive: null,
          isLocked: null,
          userId: parseInt(1, 10),
        });
 
        const filteredModules = response.data.user;
 
        const filteredUsers = response?.data?.user?.filter(user => user.IsDeleted != "1");
 
        const uniqueArray = filteredUsers.filter(
          (item1) => !UserTabData.some((item2) => item2.Id === item1.Id)
        );
 
        setAddUserTabData(uniqueArray);
        setFilteredUserData(uniqueArray);
      } catch (error) {
        // Handle the error, perhaps show an error message to the user
      }
    };
 
    fetchFilteredModules();
  }, [UserTabData]);
 
 
  const handleKeyDown = (e) => {
    if (e.key === "Enter"&& !isdisabled) {
      e.preventDefault();
      postRole();
    }
  };
  const [nameError, setNameError] = useState("");
  const [desError, setDesError] = useState("");
 
  // useEffect(() => {
  //  if(Rname.length > 100) {
  //     setNameError("Description cannot exceed 5000 characters")
  //   }
  // })

  const postRole = () => {
    
    if (!defaultlPage) {
      setdefaultlPage(moduleTabList[0]);
    }
    if (saveButtonText === 'Saving...') {
      return;
    }
    setIsdisabled(true);
 
    if (
      Rname.trim() === "" ||
      defaultlPage.value === undefined ||
      defaultlPage.value === ""
    ) {
      Alert("warn", "Role Name, Default Page can't be empty!");
      setIsdisabled(false);
    } else if (Rname.trim() === "") {
      Alert("warn", "Role Name Cannot be Empty!");
      setIsdisabled(false);
    } 
    // else if(Rdes.trim() === "") {
    //   // Alert("warn", "Role Description cannot be Empty!");
    //   setIsdisabled(false);
    // } 
    else if (defaultlPage.value === undefined && defaultlPage.value === ""){
      Alert("warn", "Default Page cannot be Empty!");
      setIsdisabled(false);
    } else if (Rname.trim().length > 100) {
      Alert("warn", "Role Name cannot exceed 100 characters!");
      setIsdisabled(false);
    } else if (Rdes.trim().length > 250) {
      Alert("warn", "Role Description cannot exceed 250 characters!");
      setIsdisabled(false);
    } else {
      setSaveButtonText('Saving...');
 
      const rolePromise = apiClient.post("/user/roles", {
        id: parseInt(userDetails.id, 10),
        roleName: Rname.trim(),
        roleDescription: Rdes.trim(),
        defaultlPage: parseInt(defaultlPage.value, 10),
        isActive: parseInt(userDetails.isActive, 10),
        userId: parseInt(userDetails.userId, 10),
      });
 
      const showAlertPromise = rolePromise
        .then((response) => {
          setShowTables(true);
          setuserIdd(response.data.user[0].LV_ID);
          userDetails.id = response.data.user[0].LV_ID;
          Alert('succ', "Role Created Successfully");
          setunsavedChanges(false);
          dispatch(unsavedChange(false));
        });
 
      const allPromises = [rolePromise, showAlertPromise];
 
      Promise.all(allPromises)
        .then(() => {
          setIsdisabled(true);
          setSaveButtonText('Save');
        })
        .catch((error) => {
          setSaveButtonText('Save');
        });
    }
  };
 
  // Add state for the button text
  const [saveButtonText, setSaveButtonText] = useState('Save');

  useEffect(()=>{
    if (Rname.trim().length > 0 || Rdes.trim().length > 0 || !defaultlPage){
      setunsavedChanges(true);
      dispatch(unsavedChange(true));
    }else{
      setunsavedChanges(false);
      dispatch(unsavedChange(false));
    }
  },[Rname,Rdes,defaultlPage])
  const handleCloseRole = () => {
    setShowDefault(false), setShowDef(false)
    if (unsavedChanges) {
      Swal.fire({
        title: "Discard Changes?",
        text: "Do you want to save the changes ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1658a0",
        cancelButtonColor: "#d33",
        confirmButtonText: "Save ",
        cancelButtonText: "Discard ",
        allowOutsideClick: false,
        allowEscapeKey: false  
      }).then((result) => {
        if (result.isConfirmed) {
          // Save changes and navigate away
          postRole();
        } else {
          navigate(Routes.Roles);
          dispatch(unsavedChange(false));
        } 
      });
    } else {
      navigate(Routes.Roles);
    }
  };

  const filterData = (value) => {
    const lowerCaseValue = value.toLowerCase().trim();
    filteredData = addModuleTabData.filter(
      (item) =>
        item.ModuleName.toLowerCase().includes(lowerCaseValue)
    );
    setFilteredData(filteredData);
  };
  const [searchValue, setSearchValue] = useState("");
  const handleSearch = (event) => {
    setSearchValue(event.target.value);
    filterData(event.target.value);
  };

  const filterUserData = (value) => {
    const lowerCaseValue = value.toLowerCase().trim();
    filteredUserData = addUserTabData.filter(
      (item) =>
        item.FirstName.toLowerCase().includes(lowerCaseValue) ||
        item.EmailId.toLowerCase().includes(lowerCaseValue)
    );
    setFilteredUserData(filteredUserData);
  };
  const [searchValueUser, setSearchValueUser] = useState("");
  const handleUserSearch = (event) => {
    setSearchValueUser(event.target.value);
    filterUserData(event.target.value);
  };


  const handleNameChange = (e) => {
    const value = e.target.value;
    setRname(value);

    if (value.trim().length > 100) {
      setNameError('Role Name cannot exceed 100 characters!');
    } else {
      setNameError('');
    }
  };

  const handleDesChange = (e) => {
    const value = e.target.value;
    setRdes(value);

    if (value.trim().length > 250) {
      setDesError('Role Description cannot exceed 250 characters!');
    } else {
      setDesError('');
    }
  };


   
  return (
    <div>
      <div className="maincontent__breadcrumb">
        <img
          className="cursor_pointer"
          src={HomeOutlineIcon}
          alt="home"
          onClick={() => {
            navigate(state.defaultpage);
          }}
        />
        <span className="maincontent__breadcrumb--divider">/</span>
        <span
          className="cursor_pointer"
          onClick={() => {
            navigate(Routes.Roles);
          }}
        >
          Roles
        </span>
        <span className="maincontent__breadcrumb--divider">/</span>
        <span className="maincontent__breadcrumb--active">Create Role</span>
      </div>
 
      <div className="mt-4 maincontent__card--body">
        <div className="maincontent__card--header">
          <h2 className="maincontent__card--header-title">Create Role</h2>
        </div>
 
        <div className="maincontent__card--content ">
          <Form className="grid grid-cols-1 gap-4 md:grid-cols-3">
            <Form.Group className="mb-3 mb3form">
              <Form.Label>Name <span className="required">*</span></Form.Label>
              <Form.Control
                className="formconus"
                value={Rname}
                onChange={handleNameChange}
                onKeyDown={handleKeyDown}
                type=""
                placeholder="Role Name"
              />
              {nameError && (  
                    <p className="error-message">{nameError}</p>
                  )}
            </Form.Group>
            <Form.Group className="mb-3 mb3form">
              <Form.Label>Description</Form.Label>
              <Form.Control
                className="formconus"
                value={Rdes}
                onChange={handleDesChange}
                onKeyDown={handleKeyDown}
                type=""
                placeholder="Role Description"
              />
              {desError && (
                    <p className="error-message">{desError}</p>
                  )}
            </Form.Group>
            <Form.Group className="mb-3 mb3form">
              <Form.Label>Default Page <span className="required">*</span></Form.Label>
              <Select
                options={moduleTabList}
                placeholder=""
                value={defaultlPage}
                onChange={(e) => {
                  setdefaultlPage(e);
                }}
                onKeyDown={handleKeyDown}
              />
            </Form.Group>
          </Form>
          <div className="flex justify-center gap-4 mt-4 md:justify-end">
          <button
              className="maincontent__btn maincontent__btn--primaryblue"
              onClick={() => postRole()}
              disabled={isdisabled}
            >
              {saveButtonText}
            </button>
            <button
              className="maincontent__btn maincontent__btn--primaryblue"
              onClick={() => {handleCloseRole()
                // navigate(Routes.Roles);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
 
      {showTables && (
        <div className="maincontent__card " style={{ margin: "1rem 0 0 0" }}>
 
          <Accordion defaultActiveKey="1" style={{ marginTop: "10px" }}>
            <Accordion.Item eventKey="1">
              <Accordion.Header className="accordionheader">
                MODULE PERMISSIONS
              </Accordion.Header>
              <Accordion.Body
                className="accordionnew"
                style={{
                  visibility: "visible",
                  color: "#1658a0",
                  padding: "0px",
                }}
              >
                <div className="maincontent__card--content accordionnew">
                  <>
                    <div className="flex justify-end maincontent__pageheader-right">
                      <button
                        className="maincontent__btn maincontent__btn--primaryblue"
                        onClick={() => setShowDefault(true)}
                        style={{ margin: "0 0 20px 0" }}
                      >
                        +Add Module Permissions
                      </button>
                      <Modal
                        as={Modal.Dialog}
                        centered
                        show={showDefault}
                        onHide={handleClose}
                        className="midpop"
                      >
                        <Card
                          centered
                          show={showDefault}
                          onHide={handleClose}
                          className="usrCrd"
                        >
                          <Card.Header className="rhdivCard">
                            <div className="maincontent__card--header">
                              <h2 className="maincontent__card--header-title">
                                Modules
                              </h2>
                            </div>
                          </Card.Header>
                          <div className="maincontent__card--tableheader-right mb-4 mr-2">
                            <div className="relative search-containerKMArti kmarticle-seactform">
                          <input
                            type="search"
                            placeholder="Search by Module Name"
                            className="w-full pt-2 pb-2 pl-2 pr-[26%] text-xs border-0 rounded-[28px] outline-0 h-[34px]"
                            value={searchValue}
                            onChange={handleSearch}
                          />
                          <button
                            type="button"
                            className="absolute top-1 right-1 bg-[rgba(0,0,0,60%)] rounded-[28px] h-[26px] text-white text-[10px] font-bold button"
                          >
                            Search
                          </button>
                        </div>
                        </div>
                          <div className="cardContent Cctent">
                            <Card.Body>
                              <DataTable
                                columns={columnAddModulePermission}
                                data={filteredData}
                                highlightOnHover
                                pagination
                                onSelectedRowsChange={AddModulePermission}
                                selectableRows
                                paginationRowsPerPageOptions={[5, 10, 15]}
                                paginationPerPage={5}
                              />
                            </Card.Body>
                          </div>
 
                          <div className="flex justify-end gap-4 p-4">
                            <button
                              className="maincontent__btn maincontent__btn--primaryblue"
                              onClick={postModulePermission}
                            >
                              Add
                            </button>
                            <button
                              className="maincontent__btn maincontent__btn--primaryblue"
                              onClick={()=>handleClosemodal()}
                            >
                              Cancel
                            </button>
                          </div>
                        </Card>
                      </Modal>
                    </div>
                    <Card.Body>
                      <DataTable
                        columns={column1}
                        data={ModuleTabData}
                        highlightOnHover
                        pagination
                        paginationRowsPerPageOptions={[5, 10, 15]}
                        paginationPerPage={5}
                      />
                    </Card.Body>
                  </>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
 
          <Accordion defaultActiveKey="1" style={{ marginTop: "10px" }}>
            <Accordion.Item eventKey="1">
              <Accordion.Header className="accordionheader">
                USERS
              </Accordion.Header>
              <Accordion.Body
                className="accordionnew"
                style={{
                  visibility: "visible",
                  color: "#1658a0",
                  padding: "0px",
                }}
              >
                <div className="maincontent__card--content accordionnew">
                  <div className="flex justify-end maincontent__pageheader-right ">
                    <button
                      className="mb-4 maincontent__btn maincontent__btn--primaryblue"
                      onClick={() => setShowDef(true)}
                    >
                      +Add Users
                    </button>
 
                    <Modal
                      className="mdlclspop midpop"
                      as={Modal.Dialog}
                      centered
                      show={ShowDef}
                      onHide={handleClose}
                    >
                      <Card
                        centered
                        show={ShowDef}
                        onHide={handleClose}
                        className="usrCrd"
                      >
                        <Card.Header className="rhdivCard">
                          <div className="maincontent__card--header">
                            <h2 className="maincontent__card--header-title">
                              Users
                            </h2>
                          </div>
                        </Card.Header>
                        <div className="maincontent__card--tableheader-right mt-4 mr-2">
                        <div className="relative search-containerKMArti kmarticle-seactform">
                      <input
                        type="search"
                        placeholder="Search by User Name, User Email"
                        className="w-full pt-2 pb-2 pl-2 pr-[26%] text-xs border-0 rounded-[28px] outline-0 h-[34px]"
                        value={searchValueUser}
                        onChange={handleUserSearch}
                      />
                      <button
                        type="button"
                        className="absolute top-1 right-1 bg-[rgba(0,0,0,60%)] rounded-[28px] h-[26px] text-white text-[10px] font-bold button"
                      >
                        Search
                      </button>
                    </div>
                    </div>
                        <div className="cardContent Cctent rdt_Pagination">
                          <Card.Body className="rmroldcb rdt_Pagination">
                            <DataTable
                              columns={columnAddUser}
                              data={filteredUserData}
                              highlightOnHover
                              pagination
                              onSelectedRowsChange={AddUsers}
                              selectableRows
                              paginationRowsPerPageOptions={[5, 10, 15]}
                              paginationPerPage={5}
                            />
                          </Card.Body>
                        </div>
 
                        <div className="flex justify-end gap-4 p-4">
                          <button
                            className="maincontent__btn maincontent__btn--primaryblue"
                            onClick={postUsers}
                          >
                            Add
                          </button>
                          <button
                            className="maincontent__btn maincontent__btn--primaryblue"
                            onClick={()=>handleCloseuser()}
                          >
                            Cancel
                          </button>
                        </div>
                      </Card>
                    </Modal>
                  </div>
                  <Card.Body>
                    <DataTable
                      columns={column2}
                      data={UserTabData}
                      highlightOnHover
                      pagination
                      paginationRowsPerPageOptions={[5, 10, 15]}
                      paginationPerPage={5}
                    />
                  </Card.Body>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )}
    </div>
  );
};
 
const mapStateToProps = (state) => ({
  ...state,
});
export default connect(mapStateToProps)(CreateRole);