import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../routes";
import "./Signin.css";
import apiClient from "../../common/http-common";
import { connect } from "react-redux";
import { Alert } from "../../components/Alert";
import ElementsLogo from "../../assets/img/new-dashboard/5 Elements_update_final-01-01.png";
import CryptoJS from 'crypto-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";

const ReChangePassword = ({ dispatch }) => {
  const navigate = useNavigate();
  const [newPwd, setNewPwd] = useState("");
  const [confirmPwd, setConfirmPwd] = useState("");
  const [message, setMessage] = useState("");
  const [apiError, setApiError] = useState();
  const [newPwdInputClass, setNewPwdInputClass] = useState();
  const [confirmPwdInputClass, setConfirmPwdInputClass] = useState();
  const [isDisable, setIsDisable] = useState(true);
  const [newPwdError, setNewPwdError] = useState("");
  const [confirmPwdError, setConfirmPwdError] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const newPwdValidation = (val) => {
    setNewPwd(val);
    const regex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;
    if (!val) {
      setNewPwdError("Please enter a new password");
      setNewPwdInputClass("input-error");
      setApiError("");
    } else if (!regex.test(val)) {
      setNewPwdError("Password must be at least 8 characters long and include a number and a symbol");
      setNewPwdInputClass("input-error");
      setApiError("");
    } else {
      setNewPwdError("");
      setNewPwdInputClass("input-success");
      if (val && confirmPwd) setIsDisable(false);
    }
  };

  const confirmPwdValidation = (val) => {
    setConfirmPwd(val);
    if (!val) {
      setConfirmPwdError("Please confirm your password");
      setConfirmPwdInputClass("input-error");
      setApiError("");
    } else if (val !== newPwd) {
      setConfirmPwdError("Passwords do not match");
      setConfirmPwdInputClass("input-error");
      setApiError("");
    } else {
      setConfirmPwdError("");
      setConfirmPwdInputClass("input-success");
      if (newPwd && val) setIsDisable(false);
    }
  };

  const isFormValid = () => {
    if (newPwd !== "" && confirmPwd !== "") return true;
    else {
      newPwdValidation(newPwd);
      confirmPwdValidation(confirmPwd);
    }
  };

  useEffect(() => {
    // Extract token from URL
    const hash = window.location.hash;
    const params = new URLSearchParams(hash.split('?')[1]);
    const token = params.get("token");
    if (!token) {
      setApiError("Invalid Link or Link Expired");
    }
  }, []);

  const [submitClicked, setSubmitClicked] = useState(false);

  let handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitClicked(true);
    if (isFormValid() && !newPwdError && !confirmPwdError) {
      var request = { 
        password: CryptoJS.AES.encrypt(newPwd, process.env.REACT_APP_PASSKEY).toString(),
      };

      const hash = window.location.hash;
    const params = new URLSearchParams(hash.split('?')[1]);
    const token = params.get("token");
      axios
        .post(process.env.REACT_APP_API_URL + "/auth/reset-password", request, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          setNewPwd("");
          setNewPwdError("");
          setConfirmPwd("");
          setConfirmPwdError("");
          Alert("succ", "Password Changed Successfully");
          navigate(Routes.Signin);
        })
        .catch((err) => {
          console.log(err);
          Alert("error", err);
          setApiError("Bad Request!!");
        });
    } else {
      !newPwd ? newPwdValidation() : confirmPwdValidation();
    }
  };
  const toggleNewPasswordVisibility = () => {
    if (newPwd !== "") {
      setShowNewPassword(!showNewPassword);
    }
  };

  const toggleConfirmPasswordVisibility = () => {
    if (confirmPwd !== "") {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };

  return (
    <>
      <div className="flex flex-col Main signin-bg">
        <div className="newUser"></div>
        <div className="w-2/3">
          <img
            src={ElementsLogo}
            className="w-[330px] pt-[1rem] pb-[2rem] my-0 mx-auto"
          />
          <div className="mainContainer bg-[rgba(255,255,255,70%)] rounded-[20px] p-8">
            <div className="flex flex-col w-full gap-4">
              <h5 className="font-medium text-[24px] text-[#202020]">
                {" "}
                Reset Password
              </h5>
              <form onSubmit={handleSubmit}>
                <div className="midSection" id="signin">
                  <label className="pl-0 text-[#4a5073] text-sm font-semibold">
                    New Password
                  </label>
                  <div className="relative">
                    <input
                      className={`${newPwd ? newPwdInputClass : ""} pr-[40px]`}
                      type={showNewPassword ? 'text' : 'password'}
                      value={newPwd}
                      autoComplete="off"
                      onChange={(e) => newPwdValidation(e.target.value)}
                    />
                    {newPwd && newPwd.length > 0 && (
                      <button
                        type="button"
                        onClick={toggleNewPasswordVisibility}
                        className="absolute right-0 flex items-center px-3 focus:outline-none"
                        style={{ top: "50%", transform: "translateY(-50%)" }}
                      >
                        <FontAwesomeIcon icon={showNewPassword ? faEyeSlash : faEye} />
                      </button>
                    )}
                  </div>
                  { newPwdError ? (
                    <div className="errorsignin">
                      <p>{newPwdError}</p>
                    </div>
                  ) : null}
                </div>
                <div className="midSection" id="signin">
                  <label className="pl-0 text-[#4a5073] text-sm font-semibold">
                    Confirm Password
                  </label>
                  <div className="relative">
                    <input
                      className={`${confirmPwd ? confirmPwdInputClass : ""} pr-[40px]`}
                      type={showConfirmPassword ? 'text' : 'password'}
                      value={confirmPwd}
                      autoComplete="off"
                      onChange={(e) => confirmPwdValidation(e.target.value)}
                    />
                    {confirmPwd && confirmPwd.length > 0 && (
                      <button
                        type="button"
                        onClick={toggleConfirmPasswordVisibility}
                        className="absolute right-0 flex items-center px-3 focus:outline-none"
                        style={{ top: "50%", transform: "translateY(-50%)" }}
                      >
                        <FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye} />
                      </button>
                    )}
                  </div>
                  { confirmPwdError ? (
                    <div className="errorsignin">
                      <p>{confirmPwdError}</p>
                    </div>
                  ) : null}
                </div>
                {apiError ? (
                  <div className="errorsignin">
                    <p>{apiError}</p>
                  </div>
                ) : null}
                <div className="downSection">
                <a
                    href="javascript:void(0)"
                    onClick={() => {
                      navigate(Routes.Signin);
                    }}
                  >
                    Back to SignIn
                  </a>
                  <div className="flex gap-4">
                    <button className="signinBtn cursor-pointer" type="submit" disabled={isDisable}>
                      Reset 
                    </button>
                  </div>
                </div>
              </form>
              <div className="message">{message ? <p>{message}</p> : null}</div>
            </div>{" "}
          </div>{" "}
          <footer className="flex justify-center w-full py-8">
            <p className="text-[12px] text-[#fff]">
              Subject to the <a href="javascript:void(0)">Privacy Policy</a> and{" "}
              <a href="javascript:void(0)">Terms of Service</a>.
            </p>
          </footer>
        </div>
      </div>
    </>
  );
};

export default connect()(ReChangePassword);
