import HomeOutlineIcon from "../../assets/img/icons/home-outline-icon.svg";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { Routes } from "../../routes";
import apiClient from "../../common/http-common";
import { Alert } from "../../components/Alert";
import DataTable from "react-data-table-component";
import SharePointIcon from '../../assets/img/new_design/SharepointIcon.svg';
import TeamsIcon from '../../assets/img/new_design/TeamsIcon.svg';
import OnedriveIcon from '../../assets/img/new_design/OnedriveIcon.svg';
import JiiraIcon from '../../assets/img/new_design/JiiraIcon.svg';
import WikiIcon from '../../assets/img/new_design/WikiIcon.png';
import ServiceNowIcon from '../../assets/img/new_design/ServiceNowIcon.svg';
import GithubIcon from '../../assets/img/new_design/Githubicon.svg';
import SlackIcon from '../../assets/img/new_design/SlackIcon.svg';
import S3BucketIcon from '../../assets/img/new_design/S3BucketIcon.svg';
import AzureDevIcon from '../../assets/img/new_design/AzureIcon.svg';
import { ddmmyyyyFormat, yyyymmdd, datetimeClockFormat } from "../../common/Helper";
import { Modal, Col,Card } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faEdit, faQuestionCircle, faTrash, faEye } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import Select from "react-select";
import JiraIntegration from "../jira/JiraIntegration";
import S3BucketFileupload from "../AWS/S3BucketFileupload";
const Integration = (state) => {
  let currentpath = window.location.hash.replace("#", "");
  let haumbstatus = state.defaultpage === currentpath ? false : true;
  const [HideHomeBreadCumb] = useState(haumbstatus);
  const getState = sessionStorage.getItem("state");
  const getUserId = JSON.parse(getState);
  const [logUserId] = useState(getUserId?.user?.Id);
  const [UserTabData, setUserTabData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [addUserTabData, setAddUserTabData] = useState([]);
  const navigate = useNavigate();
  const [projectList, setProjectList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [clientlist, setClientList] = useState([]);
  const [selectVal, setSelectVal] = useState({
    project: { value: "Select Project", label: "Select Project" },
    article : { value : "Select Article", label : "Select Article"},
    item : { value : "Select Folder", label : "Select Folder"},
  });
  const [path , setPath] = useState("");
  const [id, setId] = useState(null);
  const [tenantId, SetTenantId] = useState("");
  const [clientId, setClientId] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [item , setItem] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [project, setProject] = useState();
  const [category, setCategory] = useState(0);
  const [article, setArticle] = useState();
  const [shareId, setShareId] = useState();
  const [host, setHost] = useState();
  const [site, setSite] = useState();
  const [drive, setDrive] = useState();
  const [postProjectList, setPostProjectList] = useState([]);
  const [postCategoryList, setPostCategoryList] = useState([]);
  const [postArticleList, setPostArticleList] = useState([]);
  const [postShareList, setPostShareList] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  let [filteredData, setFilteredData] = useState();
  const [SharePointData, setSharePointData] = useState("");
  const [validation, setValidation] = useState(false);

  const [showDefault, setShowDefault] = useState(false);
  const [showAddDefault, setShowAddDefault] = useState(false);
  const [showEditDefault, setShowEditDefault] = useState(false);


  const handleClose = () => {
    setShowDefault(false);
    setshowFileupload(false);
    setShowJiraIntegration(false);
    setShowAddDefault(false);
    setShowEditDefault(false);
    setProject("");
    setArticle("");
    setHost("");
    setPath("");
    SetTenantId("");
    setClientId("");
    setClientSecret("");
    setItem("");
    setItemList("");
    setSite("");
    setDrive("");
    setFormErrors("");
    setId(null);
    setFilteredData(SharePointData);
  };

  const handleAddClose = () => {
    setShowDefault(true);    
    setShowAddDefault(false);
    setShowEditDefault(false);
    setProject("");
    setArticle("");
    setHost("");
    setPath("");
    SetTenantId("");
    setClientId("");
    setClientSecret("");
    setItem("");
    setItemList("");
    setSite("");
    setDrive("");
    setFormErrors("");
    setId(null);
    setFilteredData(SharePointData);
    setValidation(false);
  };
  
//   const [showHelp, setShowHelp] = useState(false);

// // Toggle help text visibility
// const toggleHelp = () => {
//   setShowHelp(!showHelp);
// };

  let AdminId = state?.isAdmin;


  const clearFieldError = (fieldName) => {
    setFormErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      delete updatedErrors[fieldName];
      return updatedErrors;
    });
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
  const SharePoint = async () => {

      const errors = {};

     if(!path || path.trim() === ""){
      errors.path = "Path is required"
     }

     if(item && item.length === 0) {
      errors.item = "Folder Name is required"
     }

     if(!project){
      errors.project = "Project is required"
     }

     if(!article) {
      errors.article = "Article Type is required"
     }

     if(!host) {
      errors.host = "Path is invalid"
     }

     if(!site || site.trim() === ""){
      errors.site = "Site Name is required"
     }

     if(!drive || drive.trim() === ""){
      errors.drive = "Drive Name is required"
     }

     if(!shareId) {
      errors.sharepoint = "SharePoint Id required"
     }

      setFormErrors(errors);

      const isValid = Object.keys(errors).length === 0;

      const itemArray = item.map(({ value }) => value);

    if (isValid) {
      try {
        const shareData = {
          Id : id ? id : null,
          path : path,
          host : host,
          Site : site,
          Item : JSON.stringify(itemArray),
          Drive : drive,
          ProjectId : project ? project : selectVal.project.value,
          categoryId : category,
          ArticleType : article ? article : selectVal.article.value,
          sharePointId : shareId
        };
  
        const eventResponse = await apiClient.post("/chat/sharepoint_details_apply", shareData)
        .then((res) => {
          Alert("succ", "Data Added Successfully");
          setId(null);
          DataList();
          handleAddClose();
        });
  
      } catch (error) {

      }
    } 
  };
  await SharePoint();
};

useEffect(() => {
  DataList();
},[]);

useEffect(() => {

    const fetchArticleCategories = async () => {
      try {
        const response = await apiClient.get("/lookup/ArticalCategory/1");
        const categories = response.data.lookup.map((element) => ({
          value: element.Id,
          label: element.Name
        }));
        
        const generalCategory = categories.find(category => category.label.toLowerCase() === 'general');
        if (!generalCategory) {
          await apiClient.post(`/lookup/add`, {
            metadataId: "ArticalCategory",
            id: null,
            name: "General",
            description: "General",
            isActive: 1,
            userId: parseInt(logUserId, 10),
          });
          return fetchArticleCategories();
        }
        
        setPostCategoryList(categories);
        setCategory(generalCategory.value);
      } catch (error) {
        Alert("error", "Please Try Again");
      }
    };

    fetchArticleCategories();


  apiClient
  .post("project/searchall", {})
  .then((response) => {
    let parr = [];
    if (response.data.project.length > 0) {
      response.data.project.forEach((element) => {
        parr.push({ value: element.id, label: element.Name });
      });
    }
    setPostProjectList(parr);
  })
  .catch(() => {});

  apiClient
  .get("cloud/search")
  .then((response) => {
    let sarr = [];
    if (response.data.length > 0) {
      response.data.forEach((element) => {
        sarr.push({ value: element.Id, label: element.Id });
      });
    }
    setPostShareList(sarr);
    if (sarr.length > 0) {
      setShareId(sarr[0].value);
    }
  })
  .catch(() => {});



  apiClient
      .get("/lookup/ArticleType/1")
      .then((response) => {
        let aarr = [];
        if (response.data.lookup.length > 0) {
          response.data.lookup.forEach((element) => {
            aarr.push({ value: element.Id, label: element.Name });
          });
        }
        setPostArticleList(aarr);
      })
      .catch(() => {
        Alert("error", "Please Try Again");
      });

},[]);

const validatePath = (e) => {
  e.preventDefault();
  apiClient
    .post("cloud/pathValidation", { sharePointId: shareId, path: path })
    .then((response) => {
      if (response.data.success) {
        let farr = [];
        response?.data?.folders.map((folder) => {
          const obj = { value: folder, label: folder };
          farr.push(obj);
        });
        setItemList(farr);
        setItem(farr);
        setDrive(response.data.driveName);
        setHost(response.data.host);
        setSite(response.data.siteName);
        // setItem(response.data.folders);
        Alert("succ", "Path Validated Successfully");
        setValidation(false);
      } else if (response.data.error) {
        Alert("error", response.data.error);
      } else {
        Alert("error", "Unknown error occurred.");
      }
    })
    .catch(() => {
      Alert("error", "Failed to validate path.");
    });
};


function DataList() {
  apiClient
    .get("chat/Sharepoint_Details_Get", { Id : null})
    .then((res) => {
      setSharePointData(res.data);
      setFilteredData(res.data);
    })
    .catch(() => {});
}



  const filterData = (value) => {
    const lowerCaseValue = value.toLowerCase().trim();
    filteredData = SharePointData.filter((item) =>
      item.ProjectName.toLowerCase().includes(lowerCaseValue) ||
      item.drive.toLowerCase().includes(lowerCaseValue) ||
      item.site.toLowerCase().includes(lowerCaseValue) ||
      item.item.toLowerCase().includes(lowerCaseValue)
    );
    setFilteredData(filteredData);
  };
  const [searchValue, setSearchValue] = useState("");
  const handleSearch = (event) => {
    setSearchValue(event.target.value);
    filterData(event.target.value);
  };

  const columnAR = [
    {
      name: "Project",
      sortable: true,
      selector: (param) => (param.ProjectName),
      format: (param) => (
        <span title={param.ProjectName}>{param.ProjectName}</span>
      ),
    },
    {
      name: "Site",
      sortable: true,
      selector: (param) => (param.site),
      format: (param) => (
        <span title={param.site}>{param.site}</span>
      ),
    },
    {
      name: "Drive",
      sortable: true,
      selector: (param) => (param.drive),
      format: (param) => (
        <span title={param.drive}>{param.drive}</span>
      ),
    },
    {
      name: "Folder",
      sortable: true,
      selector: (param) => {
          if (param.item) {
              const itemsArray = JSON.parse(param.item);
              return itemsArray.join(", ");
          } else {
              return "";
          }
      },
      format: (param) => (
          <span title={param.item ? JSON.parse(param.item) : ""}>
              {param.item ? JSON.parse(param.item).join(", ") : ""}
          </span>
      ),
    },
    {
      name: "Runtime",
      sortable: true,
      selector: (param) => param.Runtime,
      format: (param) => {
        if (!param.Runtime) {
          return <span title="NA">NA</span>;
        } else {
          const date = new Date(param.Runtime);
          const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
          const formattedTime = date.toLocaleTimeString([], { hour: "numeric", minute: "2-digit", hour12: true });
          const dateTimeString = `${formattedDate}, ${formattedTime}`; // Concatenate formatted date and time
          return <span title={dateTimeString}>{dateTimeString}</span>;
        }
      },
    },
    {
      name : "Actions",
      selector : (param) => (
        <>

          <FontAwesomeIcon
            style={{ color: "#1658a0", cursor: 'pointer' }}
            icon={faEye}
            onClick={() => navigate(Routes.SharePointRuntimeHistory, {
              state: {
                Id : param.Id,
              },
            })}
            title="Runtime History"
          />

        <FontAwesomeIcon
            style={{ color: "#1658a0", cursor: 'pointer', marginLeft:"3px" }}
            icon={faEdit}
            onClick={() =>{ setShowEditDefault(true); setValidation(true);
              const foldersArray = JSON.parse(param.item);
              const farr = foldersArray.map((folder) => ({ value: folder, label: folder }));
              selectVal.project = {
                value: param.ProjectId,
                label: param.ProjectName,
              };
              selectVal.article = {
                value: param.ArticleType,
                label: param.ArticleTypeName,
              };
            setProject(param.ProjectId);
            setArticle(param.ArticleType);
            setPath(param.path);
            setId(param.Id);
            setItem(farr);
            } }
            title="Edit Data"
          />
          <FontAwesomeIcon
            style={{ color: "#1658a0", cursor: 'pointer' }}
            icon={faTrash}
            onClick={() => DeleteData(param.Id)}
            title="Delete Data"
          />
        </>
      )
    },
  ];

  const DeleteData = (id) => {
    // setLoading(true);
    Swal.fire({
      title: "",
      text: "Are you sure, you want to Delete?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1658a0",
      cancelButtonColor: "#1658a0",
      confirmButtonText: "Delete",
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((result) => {
      if (result.isConfirmed) {
        apiClient
          .post("chat/sharepoint_details_delete", { Id: id })
          .then((res) => {
            DataList();
            Alert("succ", "Data Deleted successfully");
          })
          .catch((error) => {
          });
      }
    })
  }
  
  const [showJiraIntegration, setShowJiraIntegration] = useState(false);

  const handleJiraClick = () => {
    setShowJiraIntegration(true);
  }
 
const [showFileupload,setshowFileupload] = useState(false);
const handleFileUploadClick = () =>{
  setshowFileupload(true);
}
  return (
    <>
      <div className="flex items-center justify-between">
        {HideHomeBreadCumb && (
          <div className="maincontent__breadcrumb">
            <img
              className="cursor_pointer"
              src={HomeOutlineIcon}
              alt="home"
              onClick={() => {
                navigate(state.defaultpage);
              }}
            />
            <span className="maincontent__breadcrumb--divider">/</span>
            <span className="maincontent__breadcrumb--active">
              Chat Integration
            </span>
          </div>
        )}
      </div>
      {/* {loading ? (
        <div
          class="circle__loader"
          style={{ alignItems: "center", margin: "0 auto" }}
        ></div>
      ) : ( */}
        <div className="">
          {/* <div className="maincontent__card--header">
            <h2 className="maincontent__card--header-title text-black">
                Chat Integration
            </h2>
          </div> */}
          <div className="maincontent__card--content">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 text-black">
          <div className="overflow-hidden text-black maincontent__card--content rounded-lg bg-[#E0E2EA] cursor-pointer" onClick={()=> setShowDefault(true)}>
            <div className="flex flex-col gap-1 w75">
              <div className="flex flex-col gap-1">
                <span className="chat-integration-name font-bold text-[#0D656C]">
                  SharePoint
                </span>
              </div>
            </div>
            <div className="bg-[#d2d4db] chat-integration-circle bg-[rgba(0,0,0,10%)]"></div>
            <img
              src={SharePointIcon}
              className="z-0 chat-integration-img"
            />
          </div>
          <div className="overflow-hidden text-black maincontent__card--content rounded-lg bg-[#E0E2EA] cursor-pointer">
            <div className="flex flex-col gap-1 w75">
              <div className="flex flex-col gap-1">
                <span className="chat-integration-name font-bold text-[#5D5BBF]">
                  Teams Channel
                </span>
              </div>
            </div>
            <div className="chat-integration-circle bg-[#d7d9e0]"></div>
            <img
              src={TeamsIcon}
              className="z-0 chat-integration-img"
            />
          </div>

          <div className="overflow-hidden text-black maincontent__card--content rounded-lg bg-[#E0E2EA] cursor-pointer">
            <div className="flex flex-col gap-1 w75">
              <div className="flex flex-col gap-1">
                <span className="chat-integration-name font-bold text-[#0762B2]">
                  OneDrive
                </span>
              </div>
            </div>
            <div className="bg-[#d3d5dc] chat-integration-circle"></div>
            <img
              src={OnedriveIcon}
              className="z-0 chat-integration-img"
            />
          </div>
          <div className="overflow-hidden text-black maincontent__card--content rounded-lg bg-[#E0E2EA] cursor-pointer">
            <div className="flex flex-col gap-1 w75" onClick={handleJiraClick}>
              <div className="flex flex-col gap-1">
                <span className="chat-integration-name font-bold text-[#3585F5]">
                  JIRA
                </span>
              </div>
            </div>
            <div className="chat-integration-circle bg-[#d8dae1]"></div>
            <img
              src={JiiraIcon}
              className="z-0 chat-integration-img w-[42px]"
            />
          </div>
          {showJiraIntegration && (
          <Modal
        className="mdlclspop"
        as={Modal.Dialog}
        centered
        show={showJiraIntegration}
        onHide={handleClose}
        backdrop="static"
      >
          <JiraIntegration onClose= {handleClose}  />
      </Modal> 
          )}

          <div className="overflow-hidden text-black maincontent__card--content rounded-lg bg-[#E0E2EA] cursor-pointer">
            <div className="flex flex-col gap-1 w75">
              <div className="flex flex-col gap-1">
                <span className="chat-integration-name font-bold text-[#66696D]">
                  Wiki
                </span>
              </div>
            </div>
            <div className="bg-[#d3d5dc] chat-integration-circle"></div>
            <img
              src={WikiIcon}
              className="z-0 chat-integration-img"
            />
          </div>

          <div className="overflow-hidden text-black maincontent__card--content rounded-lg bg-[#E0E2EA] cursor-pointer">
            <div className="flex flex-col gap-1 w75">
              <div className="flex flex-col gap-1">
                <span className="chat-integration-name font-bold text-[#2F3D41]">
                  Servicenow
                </span>
              </div>
            </div>
            <div className="chat-integration-circle bg-[#d8dae1]"></div>
            <img
              src={ServiceNowIcon}
              className="z-0 chat-integration-img"
            />
          </div>
          <div className="overflow-hidden text-black maincontent__card--content rounded-lg bg-[#E0E2EA] cursor-pointer">
            <div className="flex flex-col gap-1 w75">
              <div className="flex flex-col gap-1">
                <span className="chat-integration-name font-bold text-[#26272D]">
                  GitHub
                </span>
              </div>
            </div>
            <div className="bg-[#d3d5dc] chat-integration-circle"></div>
            <img
              src={GithubIcon}
              className="z-0 chat-integration-img w-[35px]"
            />
          </div>
          <div className="overflow-hidden text-black maincontent__card--content rounded-lg bg-[#E0E2EA] cursor-pointer">
            <div className="flex flex-col gap-1 w75">
              <div className="flex flex-col gap-1">
                <span className="chat-integration-name font-bold text-[#07090D]">
                  Slack
                </span>
              </div>
            </div>
            <div className="bg-[#d6d7de] chat-integration-circle"></div>
            <img
              src={SlackIcon}
              className="z-0 chat-integration-img w-[40px]"
            />
          </div>
          <div className="overflow-hidden text-black maincontent__card--content rounded-lg bg-[#E0E2EA] cursor-pointer">
            <div className="flex flex-col gap-1 w75" onClick={handleFileUploadClick}>
              <div className="flex flex-col gap-1">
                <span className="chat-integration-name font-bold text-[#DB5945]">
                  S3 Bucket
                </span>
              </div>
              </div>
              {showFileupload && (
          <Modal
        className="mdlclspop"
        as={Modal.Dialog}
        centered
        show={showFileupload}
        onHide={handleClose}
        backdrop="static"
      >
          <S3BucketFileupload onClose= {handleClose}  />
      </Modal>
          )}
                </div>

          <div className="overflow-hidden text-black maincontent__card--content rounded-lg bg-[#E0E2EA] cursor-pointer">
            <div className="flex flex-col gap-1 w75">
              <div className="flex flex-col gap-1">
                <span className="chat-integration-name font-bold text-[#288BDD]">
                  Azure Devops
                </span>
              </div>
            </div>
            <div className="bg-[#ced0d7] chat-integration-circle"></div>
            <img
              src={AzureDevIcon}
              className="z-0 chat-integration-img"
            />
          </div>
      </div>

          </div>
          {showDefault && (
          <Modal
          className="mdlclspop"
          as={Modal.Dialog}
          centered
          show={showDefault}
          onHide={handleClose}
          backdrop="static"
        >
          <Card
            centered
            show={showDefault}
            onHide={handleClose}
            className="usrCrd sharetable"
          >
            <Card.Header className="rhdivCard">
            <div className="bg-[#d0d2d9] rounded-[20px] p-2">
            <FontAwesomeIcon
              icon={faClose}
              onClick={handleClose}
              style={{ borderRadius: "20px", backgroundColor: "#03022a" }}
              className="cursor-pointer closeIcon"
            />
            <p className="font-semibold text-black text-[17px]">SharePoint Details</p>
          </div>
            </Card.Header>
            <div className="maincontent__card--tableheader-right mt-2 mr-2">
              <div className="relative search-containerKMArti kmarticle-seactform">
                <input
                  type="search"
                  placeholder="Search by SharePoint Details"
                  className="w-full pt-2 pb-2 pl-2 pr-[26%] text-xs border-0 rounded-[28px] outline-0 h-[34px]"
                  value={searchValue}
                  onChange={handleSearch}
                />
                <button
                  type="button"
                  className="absolute top-1 right-1 bg-[rgba(0,0,0,60%)] rounded-[28px] h-[26px] text-white text-[10px] font-bold button"
                >
                  Search
                </button>
              </div>
              <button
            className="maincontent__btn maincontent__btn--primaryblue tableBottomBtn mb-2"
            onClick={() => setShowAddDefault(true)}
          >
            Add Data
          </button>
            </div>
            {showAddDefault && (
            <Modal
                  as={Modal.Dialog}
                  centered
                  show={showAddDefault}
                  backdrop="static"
                  onHide={() => setShowAddDefault(false)}
                >
                  <div
                    className="maincontent__card--body bg-[#fff]"
                    style={{ marginTop: "10px", width: "110%" }}
                  >
                    <div className="bg-[#d0d2d9] rounded-[20px] p-2">
                    <FontAwesomeIcon
                      icon={faClose}
                      onClick={() => handleAddClose()}
                      style={{ borderRadius: "20px", backgroundColor: "#03022a" }}
                      className="cursor-pointer closeIcon"
                    />
                    <p className="font-semibold text-black text-[17px]">SharePoint Data</p>
                  </div>
                    <div className="maincontent__card--content bg-[#fff] rounded-[20px]">
                      <form>
                        <Col>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                              <div className="flex flex-col gap-2">
                              <p>Project</p>
                              <Select className="selectshare"
                                options={postProjectList.map((project) => ({
                                  value: project.value,
                                  label: project.label,
                                }))}
                                placeholder=""
                                onChange={(selectedOption) => {
                                  setProject(selectedOption.value);
                                  clearFieldError("project");
                                }}
                              />
                              {formErrors.project && (
                                <div className="text-danger">{formErrors.project}</div>
                              )}
                            </div>

                            <div className="flex flex-col gap-2">
                              <p>Article Type</p>
                              <Select className="selectshare"
                                options={postArticleList.map((article) => ({
                                  value: article.value,
                                  label: article.label,
                                }))}
                                placeholder=""
                                onChange={(selectedOption) => {
                                  setArticle(selectedOption.value);
                                  clearFieldError("article");
                                }}
                              />
                              {formErrors.article && (
                                <div className="text-danger">{formErrors.article}</div>
                              )}
                            </div>

                            <div className="flex flex-col gap-2 hidden">
                              <p>SharePoint ID</p>
                              <Select className="selectshare"
                                options={postShareList.map((share) => ({
                                  value: share.value,
                                  label: share.label,
                                }))}
                                placeholder=""
                                onChange={(selectedOption) => {
                                  setShareId(selectedOption.value);
                                  clearFieldError("sharepoint");
                                }}
                              />
                              {formErrors.sharepoint && (
                                <div className="text-danger">{formErrors.sharepoint}</div>
                              )}
                            </div>

                            {/* <div className="midSection flex flex-col relative gap-2" id="signin" style={{ marginTop: "2%" }}>
                            <input
                              type="text"
                              placeholder="Tenant id"
                              value={tenantId}
                              onChange={(e) => {
                                SetTenantId(e.target.value);
                                clearFieldError("tenantId")
                              }}
                            />
                            {formErrors.tenantId && (
                              <div className="text-danger">{formErrors.tenantId}</div>
                            )}

                           <div className="absolute top-1/2 transform -translate-y-1/2 right-2">
                              <FontAwesomeIcon icon={faQuestionCircle} className="fas fa-question-circle text-black cursor-pointer hover:text-gray-600" onMouseEnter={toggleHelp} onMouseLeave={toggleHelp}/>
                            </div>

                            {showHelp && (
                              <div className="absolute top-full right-0 bg-white border border-gray-300 p-2 rounded shadow">
                                Help me text
                              </div>
                            )} 
                            </div> */}

                            {/* <div className="midSection flex flex-col gap-2" id="pwdId" style={{ marginTop: "2%" }}>
                              <input
                                type="text"
                                placeholder="Client Id"
                                value={clientId}
                                onChange={(e) => {
                                  setClientId(e.target.value);
                                  clearFieldError("clientId")
                                }}
                              />
                              {formErrors.clientId && (
                                <div className="text-danger">{formErrors.clientId}</div>
                              )}
                            </div> */}

                            {/* <div className="midSection flex flex-col gap-2" id="pwdId" style={{ marginTop: "2%" }}>
                              <input
                                type="text"
                                placeholder="Client Secret"
                                value={clientSecret}
                                onChange={(e) => {
                                  setClientSecret(e.target.value);
                                  clearFieldError("clientSecret")
                                }}
                              />
                              {formErrors.clientSecret && (
                                <div className="text-danger">{formErrors.clientSecret}</div>
                              )}
                            </div> */}

                            <div className="midSection flex flex-col gap-2 md:col-span-2 relative" id="signin" style={{ marginTop: "2%" }}>
                            <p>Path</p>
                              <input
                                type="text"
                                placeholder="Path"
                                value={path}
                                onChange={(e) => {
                                  setPath(e.target.value);
                                  clearFieldError("path");
                                  setValidation(true);
                                }}
                              />
                              {formErrors.path && (
                                <div className="text-danger">{formErrors.path}</div>
                              )}
                            </div>


                            <div className="midSection flex flex-col gap-2 md:col-span-2 hidden" id="pwdId" style={{ marginTop: "2%" }}>
                              <input
                                type="text"
                                placeholder="Host"
                                value={host}
                                disabled
                                onChange={(e) => {
                                  setHost(e.target.value);
                                  clearFieldError("host")
                                }}
                              />
                              {(formErrors.host) && (
                                <div className="text-danger">{formErrors.host}</div>
                              )}
                            </div>

                            <div className="midSection flex flex-col gap-2 hidden" id="signin" style={{ marginTop: "2%" }}>
                              <input
                                type="text"
                                placeholder="Site Name"
                                value={site}
                                disabled
                                onChange={(e) => {
                                  setSite(e.target.value);
                                  clearFieldError("site")
                                }}
                              />
                              {formErrors.site && (
                                <div className="text-danger">{formErrors.site}</div>
                              )}
                            </div>

                            <div className="midSection flex flex-col gap-2 hidden" id="signin" style={{ marginTop: "2%" }}>
                              <input
                                type="text"
                                placeholder="Drive Name"
                                value={drive}
                                disabled
                                onChange={(e) => {
                                  setDrive(e.target.value);
                                  clearFieldError("drive")
                                }}
                              />
                              {formErrors.drive && (
                                <div className="text-danger">{formErrors.drive}</div>
                              )}
                            </div>

                            <div className="midSection flex flex-col gap-2 md:col-span-2" id="signin" style={{ marginTop: "2%" }}>
                              <p>Folder Name</p>
                            <Select
                                className="selectshare"
                                isMulti
                                isDisabled = {validation}
                                options={itemList}
                                value={item}
                                onChange={(selectedOptions) => {
                                  setItem(selectedOptions);
                                }}
                              />
                              {formErrors.item && (
                                <div className="text-danger">{formErrors.item}</div>
                              )}
                            </div>

                          </div>
                        </Col>
                        <div
                          align="right"
                          style={{ marginTop: "2%" }}
                        >
                          {validation ? (
                          <button
                            type="submit"
                            className="btn btn-info bg-[#050346] border-none rounded-[20px]"
                            onClick={validatePath}
                          >
                            Validate Path
                          </button>
                          ) : (
                            <button
                            type="submit"
                            className="btn btn-info bg-[#050346] border-none rounded-[20px]"
                            onClick={handleSubmit}
                          >
                            Save
                          </button>
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                </Modal>
            )}

{showEditDefault && (
            <Modal
                  as={Modal.Dialog}
                  centered
                  show={showEditDefault}
                  backdrop="static"
                  onHide={() => setShowEditDefault(false)}
                >
                  <div
                    className="maincontent__card--body bg-[#fff]"
                    style={{ marginTop: "10px", width: "110%" }}
                  >
                    <div className="bg-[#d0d2d9] rounded-[20px] p-2">
                    <FontAwesomeIcon
                      icon={faClose}
                      onClick={() => handleAddClose()}
                      style={{ borderRadius: "20px", backgroundColor: "#03022a" }}
                      className="cursor-pointer closeIcon"
                    />
                    <p className="font-semibold text-black text-[17px]">SharePoint Edit Data</p>
                  </div>
                    <div className="maincontent__card--content bg-[#fff] rounded-[20px]">
                      <form>
                        <Col>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                              <div className="flex flex-col gap-2">
                              <p>Project</p>
                              <Select className="selectshare"
                                options={postProjectList.map((project) => ({
                                  value: project.value,
                                  label: project.label,
                                }))}
                                value={selectVal.project}
                                placeholder=""
                                onChange={(selectedOption) => {
                                  setProject(selectedOption.value);
                                  setSelectVal({ ...selectVal, project: selectedOption });
                                  clearFieldError("project");
                                }}
                              />
                              {formErrors.project && (
                                <div className="text-danger">{formErrors.project}</div>
                              )}
                            </div>

                            <div className="flex flex-col gap-2">
                              <p>Article Type</p>
                              <Select className="selectshare"
                                options={postArticleList.map((article) => ({
                                  value: article.value,
                                  label: article.label,
                                }))}
                                placeholder=""
                                value={selectVal.article}
                                onChange={(selectedOption) => {
                                  setArticle(selectedOption.value);
                                  setSelectVal({ ...selectVal, article: selectedOption });
                                  clearFieldError("article");
                                }}
                              />
                              {formErrors.article && (
                                <div className="text-danger">{formErrors.article}</div>
                              )}
                            </div>

                            <div className="flex flex-col gap-2 hidden">
                              <p>SharePoint ID</p>
                              <Select className="selectshare"
                                options={postShareList.map((share) => ({
                                  value: share.value,
                                  label: share.label,
                                }))}
                                placeholder=""
                                onChange={(selectedOption) => {
                                  setShareId(selectedOption.value);
                                  clearFieldError("sharepoint");
                                }}
                              />
                              {formErrors.sharepoint && (
                                <div className="text-danger">{formErrors.sharepoint}</div>
                              )}
                            </div>

                            <div className="midSection flex flex-col gap-2 md:col-span-2 relative" id="signin" style={{ marginTop: "2%" }}>
                            <p>Path</p>
                              <input
                                type="text"
                                placeholder="Path"
                                value={path}
                                onChange={(e) => {
                                  setPath(e.target.value);
                                  clearFieldError("path");
                                  setValidation(true);
                                }}
                              />
                              {formErrors.path && (
                                <div className="text-danger">{formErrors.path}</div>
                              )}
                            </div>


                            <div className="midSection flex flex-col gap-2 md:col-span-2 hidden" id="pwdId" style={{ marginTop: "2%" }}>
                              <input
                                type="text"
                                placeholder="Host"
                                value={host}
                                disabled
                                onChange={(e) => {
                                  setHost(e.target.value);
                                  clearFieldError("host")
                                }}
                              />
                              {(formErrors.host && !host.trim()) && (
                                <div className="text-danger">{formErrors.host}</div>
                              )}
                            </div>

                            <div className="midSection flex flex-col gap-2 hidden" id="signin" style={{ marginTop: "2%" }}>
                              <input
                                type="text"
                                placeholder="Site Name"
                                value={site}
                                disabled
                                onChange={(e) => {
                                  setSite(e.target.value);
                                  clearFieldError("site")
                                }}
                              />
                              {formErrors.site && (
                                <div className="text-danger">{formErrors.site}</div>
                              )}
                            </div>

                            <div className="midSection flex flex-col gap-2 hidden" id="signin" style={{ marginTop: "2%" }}>
                              <input
                                type="text"
                                placeholder="Drive Name"
                                value={drive}
                                disabled
                                onChange={(e) => {
                                  setDrive(e.target.value);
                                  clearFieldError("drive")
                                }}
                              />
                              {formErrors.drive && (
                                <div className="text-danger">{formErrors.drive}</div>
                              )}
                            </div>

                            <div className="midSection flex flex-col gap-2 md:col-span-2" id="signin" style={{ marginTop: "2%" }}>
                            <p>Folder Name</p>
                            <Select
                                className="selectshare"
                                isMulti
                                isDisabled = {validation}
                                options={itemList}
                                value={item}
                                onChange={(selectedOptions) => {
                                  setItem(selectedOptions);
                                }}
                              />
                              {formErrors.item && (
                                <div className="text-danger">{formErrors.item}</div>
                              )}
                            </div>

                          </div>
                        </Col>
                        <div
                          align="right"
                          style={{ marginTop: "2%" }}
                        >
                          {validation ? (
                          <button
                            type="submit"
                            className="btn btn-info bg-[#050346] border-none rounded-[20px]"
                            onClick={validatePath}
                          >
                            Validate Path
                          </button>
                          ) : (
                            <button
                            type="button"
                            className="btn btn-info bg-[#050346] border-none rounded-[20px]"
                            onClick={handleSubmit}
                          >
                            Save
                          </button>
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                </Modal>
            )}


            <div className="cardContent Cctent">
              <Card.Body className="rmroldcb">
                <DataTable
                  columns={columnAR}
                  data={filteredData}
                  highlightOnHover
                  pagination
                  paginationRowsPerPageOptions={[5, 10, 15]}
                  paginationPerPage={5}
                />
              </Card.Body>
            </div>
          </Card>
        </Modal>
            )} 
        </div>
      {/* )} */}
    </>
  );
};

const mapStateToProps = (state) => ({
  ...state,
});
export default connect(mapStateToProps)(Integration);
