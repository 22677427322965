  import React, { useState, useEffect } from "react";
  import "./EditUser.css";
  import { Modal, Card, Form, Accordion } from "@themesberg/react-bootstrap";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import { faTrash } from "@fortawesome/free-solid-svg-icons";
  import DataTable from "react-data-table-component";
  import { useLocation, useNavigate } from "react-router-dom";
  import apiClient from "../../../common/http-common";
  import { Routes } from "../../../routes";
  import HomeOutlineIcon from "../../../assets/img/icons/home-outline-icon.svg";
  import { Alert } from "../../../components/Alert";
  import Swal from "sweetalert2";
  import Select from "react-select";
  import { unsavedChange } from "../../../actions/actions";
  import { connect, useDispatch } from "react-redux";
 
  const EditUser = (state) => {
    const dispatch = useDispatch();
    const [showDefault, setShowDefault] = useState(false);
    const handleClose = () => (setShowDefault(false) ,setSearchValue(""),setsearchbar(""), setFilteredData(rolePopUp),setFilteredroleData(rolesData));
    const navigate = useNavigate();
    const location = useLocation();
    const [userId, setUserId] = useState("");
    const [userName, setUserName] = useState("");
    const [emailId, setEmailId] = useState("");
    const [isdisabled, setdisabled] = useState(false);
    const [UserStatus, setUserStatus] = useState(false);
    const [LockStatus, setLockStatus] = useState(true);
    const [rolePopUp, setRolePopUp] = useState([]);
    const [rolesData, setRolesData] = useState([]);
    const [defaultList, setDefaultList] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [showResetField, setShowResetField] = useState(false);
    const [OldPwd, setOldPwd] = useState("changeme");
    const [pwd, setPwd] = useState("");
    let [filteredData, setFilteredData] = useState();
    let [filterroledata,setFilteredroleData]=useState()
    const [confirmPwd, setConfirmPwd] = useState("");
    const [apiError, setApiError] = useState();
    const [pwdInputClass, setPwdInputClass] = useState();
    const [defaultPage, setDefaultPage] = useState({ label: "", value: "" });
    const [resetPasswd, setResetPasswd] = useState("");
    const [department, setdepartment] = useState([]);
    const [departmentList, setdepartmentList] = useState([]);
    const [logUserId] = useState(state.user.Id);
    const [initialValues, setInitialValues] = useState({
      userName: "",
      department: "",
      defaultPage: "",
      isActive: false,
      isLocked: true,
    });
    const[unsavedChanges, setUnsavedChanges] = useState(false);
    const oldPasswordValidation = (pwd) => {
      setOldPwd(pwd);
      if (!pwd) {
        setApiError("Please enter Old Password");
        setPwdInputClass("input-error");
      } else {
        setApiError("");
        setPwdInputClass("input-success");
      }
    };
 
    const isAdminch = location?.state?.user?.Id === 1;
 
    const passwordValidation = (pwd) => {
      let re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
 
      setPwd(pwd);
      if (re.test(pwd)) {
        setApiError("");
        setPwdInputClass("input-success");
      } else {
        setApiError("Enter a valid Password");
        setPwdInputClass("input-error");
      }
    };
 
    function validateConfirmPassword(password) {
      setConfirmPwd(password);
      if (password == pwd) {
        setApiError("");
        setPwdInputClass("input-success");
      } else {
        setApiError("Passwords do not match");
        setPwdInputClass("input-error");
      }
    }
 
    const AddRoleData = (param) => {
      setSelectedRows(param.selectedRows);
    };
 const [addbuttonrole,setaddbuttonrole] =useState("Add");
    const postRoleData = async () => {
      if(addbuttonrole==="Adding..."){
        return;
      }
      setaddbuttonrole("Adding...");
      setdisabled(true);
      try {
        if (selectedRows.length > 0) {
          const promises = selectedRows.map((row) => {
            return apiClient.post("/user/role", {
              userId: userId.toString(),
              roleId: row.Id,
            });
          });
   
          await Promise.all(promises);
          Alert("succ", "Roles Added Successfully...");
          await getRolesData();
          handleClose();
          setdisabled(false);
          setaddbuttonrole("Add");
          setSelectedRows([]);
        } else {
          Alert("warn", "Please Choose Role..");
          setaddbuttonrole("Add");
          setdisabled(false);
        }
      } catch (error) {
        Alert("error", "Please Try Again");
        setaddbuttonrole("Add");
        setdisabled(false);
      }
    };
   
    const deleteRole = (param) => {
      Swal.fire({
        title: "",
        text: "Are you sure, you want to remove?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1658a0",
        cancelButtonColor: "#1658a0",
        confirmButtonText: "Yes",
        allowOutsideClick: false,
        allowEscapeKey: false
      }).then((result) => {
        if (result.isConfirmed) {
          apiClient
            .delete("/user/role", {
              data: {
                userId: userId,
                roleId: param.RoleId.toString(),
              },
            })
            .then((response) => {
              if (response.data.error) {
                Alert("error", response.data.error);
              } else {
                Alert("succ", response.data.user);
                getRolesData();
              }
            });
        } else {
          handleClose();
        }
      });
    };
 
    function capitalizeFirstLetter(str) {
      return str ? str.charAt(0).toUpperCase() + str.slice(1) : '';
    }
 
 
    const columnAR = [
      {
        name: "Role Name",
        selector: (param) => capitalizeFirstLetter(param.RoleName),
      },
      {
        name: "Role Description",
        selector: (param) =>(
          <span title={param.RoleDescription}>
            {capitalizeFirstLetter(param.RoleDescription.length > 20
              ? param.RoleDescription.substring(0, 20) + "..."
              : param.RoleDescription)}
          </span>
        ),
      },
    ];
    const handleKeyDown = (e) => {
      if (e.key === "Enter"&& !isdisabled) {
        e.preventDefault();
        postNewUser();
      }
    };
    const column = [
      // {
      //   name: "SERO_ID",
      //   selector: (param) => param.RoleId,
      //   sortable: true,
      // },
      {
        name: "Role Name",
        selector: (param) => capitalizeFirstLetter(param.RoleName),
        sortable: true,
      },
      {
        name: "Role Description",
        selector: (param) => (
          <span title={param.RoleDescription}>
            {capitalizeFirstLetter(param.RoleDescription.length > 40
              ? param.RoleDescription.substring(0, 40) + "..."
              : param.RoleDescription)}
          </span>
        ),
        sortable: true,
      },
      {
        name: "Actions",
        cell: (param) => (
          <FontAwesomeIcon
            className="cursor-pointer"
            icon={faTrash}
            size="lg"
            color="#1475DF"
            onClick={() => deleteRole(param)}
          />
        ),
        ignoreRowClick: true,
        allowOverflow: true,
      },
    ];
 
    useEffect(() => {
      if (location?.state?.user) {
        setUserId(location?.state?.user?.Id);
        setUserName(location?.state?.user?.FirstName);
        setEmailId(location?.state?.user?.EmailId);
        setdepartment({
          label: location.state.user.DepartmentName,
          value: location.state.user.DepartmentId,
        });
        setInitialValues((prevValues) => ({
          ...prevValues,
          userName: location?.state?.user?.FirstName,
          department: location.state.user.DepartmentId,
          isActive: location.state.user.IsActive === "Active", // Set initial value of isActive
          isLocked: location.state.user.IsLocked === "InActive", // Set initial value of isLocked
 
        }));
        if (location?.state?.user?.IsActive === "Active") {
          setUserStatus(true);
        } else {
          setUserStatus(false);
        }
        if (location?.state?.user?.IsLocked === "InActive") {
          setLockStatus(false);
        } else {
          setLockStatus(true);
        }
      }
      getRolesData();
    }, []);
    useEffect(() =>{
      if(
        initialValues.userName != userName ||
        initialValues.department != department.value ||
        (initialValues.defaultPage === null && defaultPage.value !== null || initialValues.defaultPage != defaultPage.value)||
        initialValues.isActive !== UserStatus || // Check for changes in isActive
        initialValues.isLocked !== !LockStatus
      ) {
      setUnsavedChanges(true);
      dispatch(unsavedChange(true));
      } else {
        setUnsavedChanges(false);
        dispatch(unsavedChange(false));
      }
    })  

 
    useEffect(() => {
      apiClient.get("/lookup/department/1").then((response) => {
        if (response.data.lookup.length > 0) {
          const arr = [];
          response?.data?.lookup?.map((user) => {
            const obj = { value: user.Id, label: user.Name };
            arr.push(obj);
            setdepartmentList(arr);
          });
        }
      });
    }, []);
    const resetPwd = () => {
      apiClient
        .post("auth/change-password", {
          id: emailId,
          oldPassword: OldPwd,
          newPassword: resetPasswd,
        })
 
        .then((res) => {
          Alert("succ", "password changed");
          setOldPwd(resetPasswd);
          backResetting();
        })
        .catch((err) => {});
    };
 
    useEffect(() => {
      const fetchFilteredModules = () => {
        apiClient.get("/user/roles/role").then((response) => {
          if (response?.data?.user) {
            const filteredModules = response.data.user;
            const uniqueArray = filteredModules.filter(
              (item1) => !rolesData.some((item2) => item2.RoleId === item1.Id)
            );
            setRolePopUp(uniqueArray);
            setFilteredData(uniqueArray)
          }
        });
      };
      fetchFilteredModules();
    }, [rolesData]);
 
    const [isSaving, setIsSaving] = useState(false);
 
    const postNewUser = () => {
      if (!unsavedChanges) {
        navigate(Routes.UserM);
        return;
      }
 
      setdisabled(true);
      setIsSaving(true);
 
      if (isAdminch) {
        Alert("error", "Admin user cannot be edited.");
        setIsSaving(false);
        return;
      }
 
      let usts = 0;
      let lsts = 0;
      if (UserStatus) usts = 1;
      if (LockStatus) lsts = 1;
 
      let userDetails = {
        id: location?.state?.user?.Id,
        emailId: emailId,
        firstName: userName,
        lastName: location?.state?.user?.LastName,
        defaultlPageId: defaultPage.value,
        departmentId: department.value,
        isActive: usts,
        isLocked: lsts,
        loginType: "1",
        userId: parseInt(logUserId,10),
      };
 
      if (
        userName.trim() === "" ||
        userName === null ||
        emailId.trim() === "" ||
        emailId === null ||
        defaultPage.value === undefined ||
        defaultPage.value === "" ||
        department.value === undefined ||
        department.value === ""
      ) {
        Alert("warn", "Username, Email can't be empty");
        setdisabled(false);
        setIsSaving(false);
      } else if (userName.trim().length > 100) {
        Alert("warn", "User Name cannot exceed 100 characters");
        setIsSaving(false);
        setdisabled(false);
      } else if (emailId.trim().length > 100) {
        Alert("warn", "Email ID cannot exceed 100 characters");
        setIsSaving(false);
        setdisabled(false);
      } else {
        const apiCallPromise = apiClient.post("/user/update", userDetails);
 
        const showAlertPromise = apiCallPromise.then((response) => {
          if (response?.data?.user.length > 0) {
            Alert("succ", "User Updated Successfully");
            navigate(Routes.UserM);
            dispatch(unsavedChange(false));
          } else {
            Alert("error", "Please Try Again");
          }
        });
 
        Promise.all([apiCallPromise, showAlertPromise])
          .then(() => {
            setdisabled(false);
            setIsSaving(false);
            dispatch(unsavedChange(false));
          })
          .catch((error) => {
          })
      }
    };
   
    const getRolesData = () => {
      apiClient
        .get(`/user/roles/${location?.state?.user?.Id}`)
        .then((response) => {
          if (response?.data?.user) {
            setRolesData(response.data.user);
            let deflist = [];
            response.data.user.forEach((element) => {
              deflist.push({
                label: element.ModuleName,
                value: element.DefaultPageId,
              });
            });
            setDefaultList(removeDuplicatesByValue(deflist));
            setDefaultPage({
              label: location.state.user.ModuleName,
              value: location.state.user.DefaultpageId,
            });
            setInitialValues((prevValues) => ({
              ...prevValues,
              defaultPage : location.state.user.DefaultpageId
            }));
          }
        });
    };
    function removeDuplicatesByValue(array) {
      const uniqueValues = new Set();
      return array.filter((item) => {
        if (!uniqueValues.has(item.value)) {
          uniqueValues.add(item.value);
          return true;
        }
        return false;
      });
    }
 
    const backResetting = () => {
      setShowResetField(false);
    };
    const handlecloseuser = () =>{
      setShowDefault(false)
 
      if(unsavedChanges){
        Swal.fire({
          title: "Discard Changes?",
          text: "Do you want to save the changes ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#1658a0",
          cancelButtonColor: "#d33",
          confirmButtonText: "Save ",
          cancelButtonText: "Discard ",
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then((result) => {
          if (result.isConfirmed) {
            // Save changes and navigate away
          postNewUser()
          }
          else{
            navigate(Routes.UserM);
          }
      });
      }
      else{
        navigate(Routes.UserM);
      }
    }
    const handlecloserole=()=>{
      setShowDefault(false)
      setSelectedRows([]);
      if(selectedRows.length>0){
        Swal.fire({
          title: "Discard Changes?",
          text: "Do you want to save the changes ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#1658a0",
          cancelButtonColor: "#d33",
          confirmButtonText: "Save ",
          cancelButtonText: "Discard ",
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then((result) => {
          if (result.isConfirmed) {
            // Save changes and navigate away
          postRoleData()
          }
          else{
         
          }
      });
      } else {
        handleClose();
      }
   
    }
   
  const filterData = (value) => {
    const lowerCaseValue = value.toLowerCase().trim();
    filteredData = rolePopUp.filter(
      (item) =>
        item.RoleName.toLowerCase().includes(lowerCaseValue)
    );
    setFilteredData(filteredData);
  };
  const [searchValue, setSearchValue] = useState("");
  const handleSearch = (event) => {
    setSearchValue(event.target.value);
    filterData(event.target.value);
  };
  let [filterRolData,setFilteredRoleData]=useState();
  const filterRoleData = (value) => {
    const lowerCaseValue = value.toLowerCase().trim();
    const filteredData = rolesData.filter((item) =>
      item.RoleName?.toLowerCase().includes(lowerCaseValue)
    );
    setFilteredRoleData(filteredData);
    // setRolePopUp(filteredData);
  };
 
  const [searchbar,setsearchbar]=useState("")
  const hadnlesarchrole = (e) =>{
    setsearchbar(e.target.value);
    filterRoleData(e.target.value);
  };
  
  const [selectedRowsForDeletion, setSelectedRowsForDeletion] = useState([]);

// Update handleRowSelected function to add selected row to selectedRowsForDeletion
// Update handleRowSelected function to add selected row to selectedRowsForDeletion
const handleRowSelected = (row) => {
  setSelectedRowsForDeletion([...selectedRowsForDeletion, row]);
};

// Update deleteSelectedRows function to use selectedRowsForDeletion state
const deleteSelectedRows = () => {
  if (selectedRowsForDeletion.length > 0) {
    const roleIdsToDelete = selectedRowsForDeletion.map(row => row.RoleId);

    Swal.fire({
      title: "",
      text: "Are you sure you want to remove?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1658a0",
      cancelButtonColor: "#1658a0",
      confirmButtonText: "Yes",
      allowOutsideClick: false,
      allowEscapeKey: false
    }).then((result) => {
      if (result.isConfirmed) {
        const promises = roleIdsToDelete.map(roleId =>
          apiClient.delete("/user/role", {
            data: {
              userId: userId,
              roleId: roleId.toString(),
            },
          })
        );

        // Handle delete request promises
        Promise.all(promises)
          .then((responses) => {
            // Check if any response is undefined or lacks status property
            const isError = responses.some(response => !response || response.status === undefined);

            if (isError) {
              // Handle error case
              Alert("error", "An error occurred while deleting roles.");
            } else {
              // Handle success case
              Alert("succ", "Roles deleted successfully.");
              // Refresh roles data or perform any necessary action
              getRolesData();
              setSelectedRowsForDeletion([]);
            }
          })
          .catch((error) => {
            // Handle promise rejection
            Alert("error", "An error occurred while deleting roles.");
            console.error("Delete request failed:", error);
          });
      } else {
        handleClose();
      }
    });
  } else {
    Alert("warn", "Please Choose Role.");
  }
};

const [nameError, setNameError] = useState("");


const handleNameChange = (e) => {
  const value = e.target.value;

  if (value.trim().length > 100) {
    setNameError('Name cannot exceed 100 characters!');
  } else {
    setNameError('');
  }
};

    return (
      <div>
        <div className="maincontent__breadcrumb">
          <img
            className="cursor_pointer"
            src={HomeOutlineIcon}
            alt="home"
            onClick={() => {
              navigate(state.defaultpage);
            }}
          />
          <span className="maincontent__breadcrumb--divider">/</span>
          <span
            onClick={() => {
              navigate(Routes.UserM);
            }}
            style={{ cursor: "pointer" }}
          >
            Users
          </span>
          <span className="maincontent__breadcrumb--divider">/</span>
          <span className="maincontent__breadcrumb--active">Edit User</span>
        </div>
        <div className="mt-4 maincontent__card">
          <div className="maincontent__card--header">
            <h2 className="maincontent__card--header-title">Edit User</h2>
          </div>
 
          <div className="maincontent__card--content ">
            <div className="grid grid-cols-1 gap-4 md:grid-cols-3 md:justify-between">
              <Form.Group>
                <Form.Label className="formlblus">User Name <span className="required">*</span></Form.Label>
                <Form.Control
                  className="formconus"
                  type="text"
                  value={userName.trim()}
                  onChange={(e) => {setUserName(e.target.value); handleNameChange(e);}}
                  onKeyDown={handleKeyDown}
                />
                {nameError && (  
                    <p className="error-message">{nameError}</p>
                  )}
              </Form.Group>
 
              <Form.Group>
                <Form.Label className="formlblus">Email ID <span className="required">*</span></Form.Label>
                <Form.Control
                  className="formconus"
                  type="email"
                  value={emailId.trim()}
                  onChange={(e) => setEmailId(e.target.value)}
                  onKeyDown={handleKeyDown}
                  disabled
                />
              </Form.Group>
              <Form.Group>
                <Form.Label className="formlblus">Default Page</Form.Label>
                <Select
                  labelKey="name"
                  onChange={(e) => {
                    setDefaultPage(e);
                  }}
                  onKeyDown={handleKeyDown}
                  options={defaultList}
                  placeholder=""
                  value={defaultPage}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label className="formlblus">Department <span className="required">*</span></Form.Label>
                <Select
                  labelKey="name"
                  options={departmentList}
                  value={department}
                  onChange={(e) => {
                    setdepartment(e);
                  }}
                />
              </Form.Group>
              <div className="flex flex-col gap-2">
                <Form.Group>
                  <Form.Label className="formlblus">Is Active</Form.Label>
                  <Form.Check
                    className="formconus chckbx"
                    type="checkbox"
                    checked={UserStatus}
                    value={UserStatus}
                    onClick={() => setUserStatus(!UserStatus)}
                  />
                </Form.Group>
 
                <Form.Group>
                  <Form.Label className="formlblus">Is Locked</Form.Label>
                  <Form.Check
                    className="formconus chckbx"
                    type="checkbox"
                    checked={LockStatus}
                    value={LockStatus}
                    onClick={() => setLockStatus(!LockStatus)}
                  />
                </Form.Group>
              </div>
              <div className="mt-8 md:justify-end">
                <button
                  disabled={isdisabled}
                  className="maincontent__btn maincontent__btn--primaryblue"
                  onClick={() => handlecloseuser()}
                  style={{ float: "right" }}
                >
                  Cancel
                </button>
                <button
                  disabled={isdisabled}
                  className="maincontent__btn maincontent__btn--primaryblue"
                  onClick={postNewUser}
                  style={{ float: "right", marginRight: "5px" }}
                >
                  {isSaving ? 'Saving...' : 'Save'}
                </button>
              </div>
            </div>
 
            {showResetField && (
              <div className="inputContainer d-flex justify-content-end">
                <input
                  type="password"
                  id="inputField"
                  placeholder="New Password"
                  onChange={(e) =>
                    passwordValidation(setResetPasswd(e.target.value))
                  }
                />
                <button
                  className="maincontent__btn maincontent__btn--primaryblue"
                  style={{ marginLeft: "5px", marginRight: "5px" }}
                  onClick={resetPwd}
                >
                  {" "}
                  Save{" "}
                </button>
                <button
                  className="maincontent__btn maincontent__btn--primaryblue"
                  onClick={backResetting}
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
        </div>
 
        <div>
          <Accordion defaultActiveKey="1" style={{ marginTop: "10px" }}>
            <Accordion.Item eventKey="1">
              <Accordion.Header className="accordionheader">
                ROLES ASSIGNED
              </Accordion.Header>
              <Accordion.Body
                className="accordionnew"
                style={{
                  visibility: "visible",
                  color: "#1658a0",
                  padding: "0px",
                }}
              >
                <Modal
                  className="mdlclspop midpop"
                  as={Modal.Dialog}
                  centered
                  show={showDefault}
                  onHide={handleClose}
                >
                  <Card
                    centered
                    show={showDefault}
                    onHide={handleClose}
                    className="usrCrd"
                  >
                    <Card.Header className="rhdivCard">
                      <div className="maincontent__card--header">
                        <h2 className="maincontent__card--header-title">Roles</h2>
                      </div>
                    </Card.Header>
                    <div className="maincontent__card--tableheader-right mt-2 mr-2">
                    <div className="relative search-containerKMArti kmarticle-seactform">
                  <input
                    type="search"
                    placeholder="Search by Role Name"
                    className="w-full pt-2 pb-2 pl-2 pr-[26%] text-xs border-0 rounded-[28px] outline-0 h-[34px]"
                    value={searchValue}
                    onChange={handleSearch}
                  />
                  <button
                    type="button"
                    className="absolute top-1 right-1 bg-[rgba(0,0,0,60%)] rounded-[28px] h-[26px] text-white text-[10px] font-bold button"
                  >
                    Search
                  </button>
                </div>
                </div>
                    <div className="cardContent Cctent">
                      <Card.Body className="rmroldcb rdt_Pagination">
                        <DataTable
                          columns={columnAR}
                          data={filteredData}
                          selectableRows
                          onSelectedRowsChange={AddRoleData}
                          highlightOnHover
                          pagination
                          paginationRowsPerPageOptions={[5, 10, 15]}
                          paginationPerPage={5}
                        />
                      </Card.Body>
                    </div>
 
                    <div className="flex justify-end gap-4 p-4">
                      <button
                        className="maincontent__btn maincontent__btn--primaryblue"
                        onClick={postRoleData}
                        disabled={isdisabled}
                      >
                        {addbuttonrole}
                      </button>
                      <button
                        className="maincontent__btn maincontent__btn--primaryblue"
                        onClick={()=>{handlecloserole()}}
                      >
                        Cancel
                      </button>
                    </div>
                  </Card>
                </Modal>
                <div className="maincontent__card--content bg-purple-100 rdt_Pagination">
                  <div className="flex justify-end maincontent__pageheader-right">
 
                <div className="relative search-containerKMArti kmarticle-seactform mr-2">
                  <input
                    type="search"
                    placeholder="Search by Role Name"
                    className="w-full pt-2 pb-2 pl-2 pr-[26%] text-xs border-0 rounded-[28px] outline-0 h-[34px]"
                    value={searchbar}
                    onChange={hadnlesarchrole}
                  />
                  <button
                    type="button"
                    className="absolute top-1 right-1 bg-[rgba(0,0,0,60%)] rounded-[28px] h-[26px] text-white text-[10px] font-bold button"
                  >
                    Search
                  </button>
                </div>
                    <button
                      className="mb-4 maincontent__btn maincontent__btn--primaryblue"
                      onClick={() => setShowDefault(true)}
                    >
                      +Add Roles
                    </button>
                  </div>
                  <div className="cardContent Cctent">
                  <Card.Body className="rmroldcb rdt_Pagination">
                  <Card.Body className="p-0">

  {selectedRowsForDeletion.length === 0 ? (

    <DataTable
      columns={column}
      data={filterRolData ||  rolesData}
      selectableRows
      onSelectedRowsChange={({ selectedRows }) => setSelectedRowsForDeletion(selectedRows)}
      highlightOnHover
      pagination
      paginationRowsPerPageOptions={[5, 10, 15]}
      paginationPerPage={5}
    />
  ) : (
    <DataTable
      columns={column.slice(0, -1)} // Remove the action column
      data={ filterRolData   ||rolesData}
      selectableRows
      onSelectedRowsChange={({ selectedRows }) => setSelectedRowsForDeletion(selectedRows)}
      highlightOnHover
      pagination
      paginationRowsPerPageOptions={[5, 10, 15]}
      paginationPerPage={5}
    />
  )}
  {selectedRowsForDeletion.length > 0 && (
    <button 
      className="maincontent__btn maincontent__btn--primaryblue"
      onClick={deleteSelectedRows}
    >
      Delete
    </button>
  )}
</Card.Body>

                  </Card.Body>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    );
  };
 
  const mapStateToProps = (state) => ({
    ...state,
  });
  export default connect(mapStateToProps)(EditUser);