import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import Select from "react-select";
import axios from "axios";
import ImgIcon from "../../components/ImageIcon";
import {
  faPenToSquare,
  faTrashAlt,
  faClose,
} from "@fortawesome/free-solid-svg-icons";

import { Modal } from "@themesberg/react-bootstrap";
import { Routes } from "../../routes";
import apiClient from "../../common/http-common";
import { Alert } from "../../components/Alert";
import addRewardPoints from "../../common/AddRewardPoints";
import MyEditor from "../components/MyCkEditor";
import Swal from "sweetalert2";

const PostArtifact = ({
  design,
  postRender,
  user,
  approveReview,
  getArticles,
  rewardsData,
  token,
}) => {
  const inputRefs = useRef([]);
  const editorRef = useRef(null);
  const [editorReady, setEditorReady] = useState(false);
  const [removedFileName, setRemovedFileName] = useState("");
  const [showDefault, setShowDefault] = useState(false);
  const [ShowDef, setShowDef] = useState(false);
  let [isLoading, setIsLoading] = useState(true);
  const [charLimit, setCharLimit] = useState(200);
  const [remainingChar, setRemainingChar] = useState(200);

  const regex = /^[0-9]*(\.[0-9]{0,2})?$/;
  const handleClose = () => (
    setShowDefault(false),
    setShowDef(false),
    setTitle(""),
    setContent(""),
    setKeywords(""),
    setRevenue(revenue),
    setRevenueClient(""),
    setRevenueInternal(""),
    setCost(cost),
    setCostClient(""),
    setCostInternal(""),
    setPersonDays(""),
    setImage([]),
    setTypeError(""),
    setProjectError(""),
    setClientError(""),
    setCategoryError(""),
    setTitleError(""),
    setContentError(""),
    setRevenueError(""),
    setRevenueClientError(""),
    setRevenueInternalError(""),
    setCostError(""),
    setCostClientError(""),
    setCostInternalError(""),
    setPersonDaysError(""),
    setSourceError(""),
    setSourceTitleError(""),
    setKeywordserror(""),
    setEditorValue("")
  );

  useEffect(() => {
    if (editorRef.current) {
      const editor = editorRef.current.editor;
      if (editor.isInitialized) {
        setEditorReady(true);
      } else {
        editor.init().then(() => {
          setEditorReady(true);
        });
      }
    }
  }, []);

  const handleDestroEditor = () => {
    if (editorReady && editorRef.current) {
      const editor = editorRef.current.editor;
      editor.destroy();
    } else {
    }
  };

  const navigate = useNavigate();
  const [logUserId] = useState(user?.Id);
  const [image, setImage] = useState([]);
  const [category, setCategory] = useState();
  const [type, setType] = useState();
  const [tableData, setTableData] = useState([]);
  const [isdisabled, setDisabled] = useState(false);
  const [categoryList, setCategoryList] = useState([
    { value: null, label: "All" },
  ]);

  const [postcategoryList, setPostCategoryList] = useState([]);
  const [postArticleType, setPostArticleType] = useState([]);
  const [articleType, setArticleType] = useState([
    { value: null, label: "All" },
  ]);

  const [project, setProject] = useState();
  const [clients, setClients] = useState();
  const [projectList, setProjectList] = useState([
    { value: null, label: "All" },
  ]);
  const [postProjectList, setPostProjectList] = useState([]);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [Keyword, setKeywords] = useState("");
  const [clientList, setClientList] = useState([{ value: null, label: "All" }]);

  const [typeError, setTypeError] = useState("");
  const [projectError, setProjectError] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [titleError, setTitleError] = useState("");
  const [contentError, setContentError] = useState("");
  const [clientError, setClientError] = useState("");
  const [Keywordserror, setKeywordserror] = useState("");
  const [editorValue, setEditorValue] = useState("");

  const handleEditorChange = (newEditorValue) => {
    const textContent = newEditorValue.replace(/<[^>]*>/g, "");
    if (textContent.length === 0) {
      setContentError("Please Enter Description");
    } else if (textContent.length > 5000) {
      setContentError("Description cannot exceed 5000 characters")
    } else {
      setContentError("");
    }
  };

  useEffect(() => {
    const content = editorValue.replace(/<[^>]*>/g, "");
   if(content.length > 5000) {
      setContentError("Description cannot exceed 5000 characters")
    }
  })
const [roles, setRoles] = useState([]);
  const fetchUserRoles = async () => {
    try {
      const response = await apiClient.get(`/user/roles/${logUserId}`);
      const roles = response.data.user.map(user => user.RoleName);
      setRoles(roles);
    } catch (error) {
      console.error("Error fetching roles:", error);
    }
  };
 
 useEffect(()=>{
  fetchUserRoles();
 },[])
 const [match, setMatch] = useState(false); // State variable to track category matching with roles
 
 const handleCategoryChange = (selectedOption) => {
  const selectedCategory = selectedOption.label; // Get the value of the selected category
  const matchArray = roles.map(RoleName => RoleName === selectedCategory); // Create an array of booleans
  const isMatch = matchArray.includes(true);  
  setMatch(isMatch); // Update match state based on the selected category

  // Log statements for debugging 
  if (!category) {
      setCategory(selectedOption.value); // Update the category state with the selected category value
  } else {
      setCategoryError("");
  }
};
 

 
  const postArticle = (draft = false) => {
    let revenueval = "";
    let costval = "";

    if (type !== "4") {
      setDisabled(true);
      setTypeError("");
      setProjectError("");
      setCategoryError("");
      setTitleError("");
      setContentError("");
    } else {
      setTypeError("");
      setClientError("");
      setCategoryError("");
      setTitleError("");
      setContentError("");
      setRevenueError("");
      setRevenueClientError("");
      setRevenueInternalError("");
      setCostError("");
      setCostClientError("");
      setCostInternalError("");
      setPersonDaysError("");
      setSourceError("");
      setSourceTitleError("");
      if (revenue) {
        revenueval = 0;
      } else {
        revenueval = 1;
      }
      if (cost) {
        costval = 0;
      } else {
        costval = 1;
      }
    }

    setDisabled(true);
    let hasError = false;

    if (type === "4") {
      if (!type) {
        setDisabled(false);
        setTypeError("Please Choose the Article Type");
        hasError = true;
      }
      if (!clients) {
        setDisabled(false);
        setClientError("Please Choose the Client");
        hasError = true;
      }
      if (!category) {
        setDisabled(false);
        setCategoryError("Please Choose the Category");
        hasError = true;
      }
      if (!source) {
        setDisabled(false);
        setSourceError("Please Choose the Source");
        hasError = true;
      }
      if (!sourceTitle?.value) {
        setDisabled(false);
        setSourceTitleError("Please Enter Source Title");
        hasError = true;
      }
      if (!draft) {
        if (title === "" || title.length < 3) {
          setDisabled(false);
          setTitleError(
            "Enter Value Experience, must contain 3 characters atleast"
          );
          hasError = true;
        }
        if (editorValue === "") {
          setDisabled(false);
          setContentError("Please Enter Business Case");
          hasError = true;
        }
      }
    } else {
      if (!type) {
        setDisabled(false);
        setTypeError("Please Choose the Article Type");
        hasError = true;
      }
      if (!project) {
        setDisabled(false);
        setProjectError("Please Choose the CS Department");
        hasError = true;
      }
      if (!category) {
        setDisabled(false);
        setCategoryError("Please Choose the Category");
        hasError = true;
      }
        if (title.trim() === "" || title.length < 3) {
          setDisabled(false);
          setTitleError("Enter title, must contain 3 characters atleast");
          hasError = true;
        }
        if (title && title.trim().length > 100) {
          setDisabled(false);
          setTitleError("Title cannot exceed 100 characters");
          hasError = true;
        }
        if (Keyword && Keyword.trim().length > 100) {
          setDisabled(false);
          setKeywordserror("Keywords cannot exceed 100 characters");
          hasError = true;
        }

        if (editorValue === "") {
          setDisabled(false);
          setContentError("Please Enter Description");
          hasError = true;
        }
        if (editorValue && editorValue.trim().length > 5000) {
          setDisabled(false);
          setContentError("Description cannot exceed 5000 characters");
          hasError = true;
        }
    }

    if (hasError) {
      setDisabled(false);
      return;
    } else {
      let postStatus = approveReview  ||  match ? 1 : 2;

      let status = draft ? 4 : postStatus;
      
      let details = {
        Id: null,
        type: type,
        title: title,
        description: editorValue,
        articalby: logUserId,
        projectid: project,
        categoryid: category,
        chathint: chatHint,
        statusid: status,
        UserId: logUserId,
        Keywords: Keyword,
        viewstatus: 1,
      };
      apiClient
        .post("/kmarticle/add", details)
        .then(async (response) => {
          if (response.data[0].Id) {
            setTimeout(async () => {
              let serverPath = process.env.REACT_APP_API_URL;
              let promises = [];
              if (image.length > 0) {
                for (const row of image) {
                  let formData = new FormData();
                  formData.append("image", row);
                  formData.append("id", null);
                  formData.append("articleId", response.data[0].Id);
                  formData.append("userId", logUserId);
                  axios.defaults.headers.common["Authorization"] = token;
                  let promise = axios.post(
                    serverPath + "/kmarticle/attachment",
                    formData
                  );
                  promises.push(promise);
                }
              }
              await Promise.all(promises)
                .then((res) => {
                  let ElasticPath = process.env.REACT_APP_ELASTIC_URL;
                  axios
                    .get(ElasticPath)
                    .then(() => {})
                    .catch(() => {});
                  const successMessage =  approveReview  || match 
                    ? "Artifacts Published"
                    : "Artifacts sent for Approval";
                  if (details.statusid === 1 ) {
                    getArticles();
                  }
                  if (!draft) {
                    addRewardPoints(
                      rewardsData[2].Points,
                      logUserId,
                      rewardsData[2].Id,
                      logUserId,
                      response.data[0].Id
                    );
                    if (approveReview || match ) {
                      addRewardPoints(
                        rewardsData[3].Points,
                        logUserId,
                        rewardsData[3].Id,
                        logUserId,
                        response.data[0].Id
                      );
                    }
                    Alert("succ", successMessage);
                  } else {
                    Alert("succ", "Draft Saved");
                  }
                  setDisabled(false);
                  postRender();
                  setShowDefault(false);
                  clearFields();
                })
                .catch((err) => {
                  Alert("error", "Article Saved but Attachment failed");
                  setDisabled(false);
                  postRender();
                  getArticles();
                  setShowDefault(false);
                  clearFields();
                });
            }, 2000);
          }
         })
        .catch((error) => {
          setDisabled(false);
          Alert("error", "Please Try Again");
        });
  }
// }
  };

  useEffect(() => {
    isLoading = true;
    apiClient
      .get("/lookup/ArticalCategory/1")
      .then((response) => {
        let arr = [{ value: null, label: "All" }];
        let carr = [];
        if (response.data.lookup.length > 0) {
          response.data.lookup.forEach((element) => {
            if (element.Name !== "General") {
              arr.push({ value: element.Id, label: element.Name });
              carr.push({ value: element.Id, label: element.Name });
            }
          });
        }
        setCategoryList(arr);
        setPostCategoryList(carr);
      })
      .catch(() => {
        Alert("error", "Please Try Again");
      });

      apiClient
      .get("/lookup/ArticleType/1")
      .then((response) => {
        let arr = [{ value: null, label: "All" }];
        let aarr = [];
        if (response.data.lookup.length > 0) {
          response.data.lookup.forEach((element) => {
            arr.push({ value: element.Id, label: element.Name });
            aarr.push({ value: element.Id, label: element.Name });
          });
        }
        setArticleType(arr);
        setPostArticleType(aarr);
      })
      .catch(() => {
        Alert("error", "Please Try Again");
      });

    apiClient
      .post("project/searchall", {})
      .then((response) => {
        let arr = [{ value: null, label: "All" }];
        let parr = [];
        if (response.data.project.length > 0) {
          response.data.project.forEach((element) => {
              const capitalizedLabel = element.Name.charAt(0).toUpperCase() + element.Name.slice(1);
              arr.push({ value: element.id, label: capitalizedLabel });
              parr.push({ value: element.id, label: capitalizedLabel });
            
          });
        }
        setProjectList(arr);
        setPostProjectList(parr);
      })
      .catch(() => {});

    apiClient
      .post("/client/search", {
        clientId: "0",
        domainId: "0",
        towerId: "0",
        organizationId: "0",
        userId: "0",
      })
      .then((response) => {
        let arr = [{ value: null, label: "All" }];
        let parr = [];
        if (response.data.client.length > 0) {
          response.data.client.forEach((element) => {
            arr.push({ value: element.Id, label: element.Name });
            parr.push({ value: element.Id, label: element.Name });
          });
        }
        setClientList(arr);
        setPostClientList(parr);
      })
      .catch((error) => {
        Alert("error", "Please Try Again");
      });
  }, []);

  const removeFile = (i) => {
    let sno = 0;
    let resarr = [];
    image.map((row, key) => {
      if (i !== key) resarr[sno++] = row;
    });
    setImage(resarr);
  };

  const handleChange = (e) => {
    const inputValue = e.target.value;
    const words = inputValue.split(" ");
    const modifiedWords = words.map((word) => {
      if (word.startsWith("#") && word.length > 1) {
        return word;
      } else if (!word.startsWith("#") && word.trim() !== "") {
        return `#${word}`;
      }
      return word;
    });

    const modifiedValue = modifiedWords.join(" ");
    setKeywords(modifiedValue);
  };

  const [source, setSource] = useState();

  const autofill = () => {
    if (source) {
      apiClient
        .get("/vxarticle/get_source_title/" + source)
        .then((response) => {
          let parr = [];
          if (response.data.length > 0) {
            response.data.forEach((element) => {
              parr.push({ value: element.Id, label: element.title });
            });
          }
          setSourceTitleSugg(parr);
        })
        .catch((error) => {
          setDisabled(false);
          Alert("error", "Please Try Again");
        });
    }
  };

  const [sourceTitle, setSourceTitle] = useState();
  const [revenueError, setRevenueError] = useState("");
  const [revenueClientError, setRevenueClientError] = useState("");
  const [revenueInternalError, setRevenueInternalError] = useState("");
  const [costError, setCostError] = useState("");
  const [costClientError, setCostClientError] = useState("");
  const [costInternalError, setCostInternalError] = useState("");
  const [personDaysError, setPersonDaysError] = useState("");
  const [sourceError, setSourceError] = useState("");
  const [sourceTitleError, setSourceTitleError] = useState("");
  const [sourceTitleSugg, setSourceTitleSugg] = useState([]);
  const [revenue, setRevenue] = useState();
  const [cost, setCost] = useState();
  const [revenueClient, setRevenueClient] = useState("");
  const [revenueInternal, setRevenueInternal] = useState("");
  const [costClient, setCostClient] = useState("");
  const [costInternal, setCostInternal] = useState("");
  const [personDays, setPersonDays] = useState("");
  const [chatHint, setChatHint] = useState("");

  const [postClientList, setPostClientList] = useState([]);

  useEffect(() => {
    autofill();
  }, [source]);

  const clearFields = () => {
    setType("");
    setClients("");
    setCategory("");
    setChatHint("");
    setSource("");
    setSourceTitle("");
    setTitle("");
    setContent("");
    setRevenue(true);
    setRevenueClient("");
    setRevenueInternal("");
    setCost(true);
    setCostClient("");
    setCostInternal("");
    setPersonDays("");
    setImage([]);
    setProject("");
    setEditorValue("");
    setKeywords("");

    setTypeError("");
    setClientError("");
    setCategoryError("");
    setSourceError("");
    setSourceTitleError("");
    setTitleError("");
    setContentError("");
    setRevenueError("");
    setRevenueClientError("");
    setRevenueInternalError("");
    setCostError("");
    setCostClientError("");
    setCostInternalError("");
    setPersonDaysError("");
    setProjectError("");
    setKeywordserror("");
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault;
      postArticle();
    }
  };

  const handleGetInputValues = () => {
    const allInputValuesselect = inputRefs.current.map((ref, index) => {
      if (Array.isArray(ref?.props?.value)) {
        const trimmedValues = ref.props.value.map((selectedOption) => {
          return selectedOption.label ? selectedOption.label.trim() : "";
        });
        return trimmedValues;
      } else {
        const value = ref?.props?.value;
        const trimmedValue = value && value.label ? value.label.trim() : "";
        return trimmedValue;
      }
    });

    const allInputValues = inputRefs.current.map((ref) => {
      return ref.value ? ref.value.trim() : "";
    });

    const lengthsOfValuesSelect = allInputValuesselect.map(
      (value) => value.length
    );
    const lengthsOfValues = allInputValues.map((value) => value.length);

    const totalLengthSelect = lengthsOfValuesSelect.reduce(
      (acc, length) => acc + length,
      0
    );
    const totalLength = lengthsOfValues.reduce(
      (acc, length) => acc + length,
      0
    );

    const Total = totalLengthSelect + totalLength;

    if (Total > 0 || editorValue && editorValue.trim().length > 0 || image.length > 0) {
      Swal.fire({
        title: "Discard Changes?",
        text: "Do you want to save the changes ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1658a0",
        cancelButtonColor: "#d33",
        confirmButtonText: "Draft",
        cancelButtonText: "Discard ",
        allowOutsideClick: false,
        allowEscapeKey: false  
      }).then((result) => {
        if (result.isConfirmed) {
          postArticle(true)
        } else {
          handleClose();
        }
      });
    } else {
      handleClose();
    }
  };


  return (
    <>
      <div className="md:w-auto w-full col-span-2">
        {design ? (
          <>
            <button
              className="maincontent__btn maincontent__btn--primarygreen bg-[#e0e0e0] w-full"
              onClick={() => setShowDefault(true)}
            >
              <FontAwesomeIcon className="mr-2" icon={faPenToSquare} />
              <span className="whitespace-nowrap overflow-hidden text-ellipsis">
                Post Artifact
              </span>
            </button>
          </>
        ) : (
          <>
            <button
              onClick={() => setShowDefault(true)}
              type="button"
              className="articles__search--btn articles__search--postbtn"
              style={{ color: "white", backgroundColor: "#441391" }}
            >
              <FontAwesomeIcon className="mr-2" icon={faPenToSquare} />
              <span className="whitespace-nowrap overflow-hidden text-ellipsis">
                Post Artifact
              </span>
            </button>
          </>
        )}
      </div>
      <Modal
        as={Modal.Dialog}
        centered
        show={showDefault}
        onHide={handleClose}
        backdrop="static"
      >
        <div className="maincontent__postarticle py-0 px-0 ck-body-wrapper">
          <div className="bg-[#d0d2d9] rounded-[20px] p-2">
            <FontAwesomeIcon
              icon={faClose}
              onClick={handleGetInputValues}
              style={{ borderRadius: "20px", backgroundColor: "#03022a" }}
              className="cursor-pointer closeIcon"
            />
            <p className="font-semibold text-black text-[17px]">Post</p>
          </div>
          {isdisabled ? (
            <>
              <div align="center">
                <div className="circle__loader"></div>
              </div>
            </>
          ) : (
            <>
              <div className="flex flex-col gap-4 md:grid md:grid-cols-3 pt-2 pb-0 px-4">
                <div className="flex flex-col flex-1 gap-2">
                  <p>
                    Article Type <span className="required">*</span>{" "}
                  </p>
                  <Select
                    options={postArticleType.map((article) => ({
                      value: article.value,
                      label: article.label,
                    }))}
                    placeholder=""
                    onChange={(selectedOption) => {
                      setType(selectedOption.value);
                      setTypeError("");
                    }}
                    onKeyDown={handleKeyDown}
                    ref={(el) => (inputRefs.current[0] = el)}
                  />
                  {typeError && <p className="error-message">{typeError}</p>}
                </div>

                {type !== "4" ? (
                  <>
                    <div className="flex flex-col flex-1 gap-2">
                      <p>
                        CS Department <span className="required">*</span>{" "}
                      </p>
                      <Select
                        options={postProjectList.map((project) => ({
                          value: project.value,
                          label: project.label,
                        }))}
                        placeholder=""
                        onChange={(selectedOption) => {
                          setProject(selectedOption.value);
                          setProjectError("");
                        }}
                        onKeyDown={handleKeyDown}
                        ref={(el) => (inputRefs.current[1] = el)}
                      />
                      {projectError && (
                        <p className="error-message">{projectError}</p>
                      )}
                    </div>
                    <div className="flex flex-col flex-1 gap-2">
                      <p>
                        Category <span className="required">*</span>
                      </p>
                      <Select
                        options={postcategoryList.map((category) => ({
                          value: category.value,
                          label: category.label,
                        }))}
                        placeholder=""
                        // onChange={(selectedOption) => {
                        //   setCategory(selectedOption.value);
                        //   setCategoryError("");
                        // }}
                        onChange={handleCategoryChange}
                                            onKeyDown={handleKeyDown}
                        ref={(el) => (inputRefs.current[2] = el)}
                      />
                      {categoryError && (
                        <p className="error-message">{categoryError}</p>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="flex flex-col flex-1 gap-2">
                      <p>
                        Client <span className="required">*</span>{" "}
                      </p>
                      <Select
                        options={postClientList.map((client) => ({
                          value: client.value,
                          label: client.label,
                        }))}
                        placeholder=""
                        onChange={(selectedOption) => {
                          setClients(selectedOption.value);
                          setClientError("");
                        }}
                      />
                      {clientError && (
                        <p className="error-message">{clientError}</p>
                      )}
                    </div>
                    <div className="flex flex-col flex-1 gap-2">
                      <p>
                        Category <span className="required">*</span>{" "}
                      </p>
                      <Select
                        options={postcategoryList.map((category) => ({
                          value: category.value,
                          label: category.label,
                        }))}
                        placeholder=""
                        // onChange={(selectedOption) => {
                        //   setCategory(selectedOption.value);
                        //   handleCategoryChange
                        //   setCategoryError("");
                        // }}
                        onChange={handleCategoryChange}
                      />
                      {categoryError && (
                        <p className="error-message">{categoryError}</p>
                      )}
                    </div>
                    <div className="flex flex-col flex-1 gap-2 md:col-span-1">
                      <p>Source</p>
                      <Select
                        options={postcategoryList.map((source) => ({
                          value: source.value,
                          label: source.label,
                        }))}
                        placeholder=""
                        onChange={(selectedOption) => {
                          setSource(selectedOption.value);
                          setSourceError("");
                          setSourceTitle({ value: "", label: "" });
                          autofill();
                        }}
                      />
                      {sourceError && (
                        <p className="error-message">{sourceError}</p>
                      )}
                    </div>
                    <div className="flex flex-col flex-1 gap-2 md:col-span-2">
                      <p>Source Title </p>
                      <Select
                        placeholder=""
                        options={sourceTitleSugg}
                        value={sourceTitle}
                        onChange={(e) => {
                          setSourceTitle(e);
                          setSourceTitleError("");
                        }}
                      />
                      {sourceTitleError && (
                        <p className="error-message">{sourceTitleError}</p>
                      )}
                    </div>
                  </>
                )}

                <div className="flex flex-col flex-1 gap-2 md:col-span-3">
                  <p>
                    {type === "4" ? "Value Exp" : "Title"}{" "}
                    <span className="required">*</span>{" "}
                  </p>
                  <input
                    type="text"
                    placeholder="Title"
                    className="maincontent__postarticle--input"
                    value={title}
                    onChange={(e) => {
                      setTitle(e.target.value);
                      setTitleError("");
                    }}
                    onKeyDown={handleKeyDown}
                    ref={(el) => (inputRefs.current[3] = el)}
                  />
                  {titleError && <p className="error-message">{titleError}</p>}
                </div>
                <div className="flex flex-col flex-1 col-span-3 gap-2 z-0">
                  <p>
                    {type === "4" ? "Bus Case Exp" : "Description"}{" "}
                    <span className="required">*</span>
                  </p>

                  <MyEditor
                    data={editorValue}
                    setState={setEditorValue}
                    clearDescErr={handleEditorChange}
                    ref={(el) => (inputRefs.current[4] = el)}
                  />

                  {contentError && (
                    <p className="error-message">{contentError}</p>
                  )}
                </div>

                {type === "4" ? (
                  <>
                    <div className="flex flex-1 gap-4 md:col-span-3">
                      <div className="grid grid-cols-5 gap-2">
                        <div className="flex items-center gap-2 col-span-1">
                          <p>Revenue</p>
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input cursor-pointer"
                              type="checkbox"
                              id="flexSwitchCheckChecked"
                              value={revenue}
                              onChange={() => {
                                setRevenue(!revenue);
                              }}
                              checked={revenue ? 0 : 1}
                            />
                          </div>
                        </div>
                        <div className="flex gap-2 items-center col-span-2">
                          <p>Client</p>
                          <input
                            type="text"
                            className="maincontent__postarticle--input w-9/12"
                            value={revenueClient.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })}
                            placeholder="$ 0.00"
                            onChange={(e) => {
                              const inputText = e.target.value;
                              if (regex.test(inputText)) {
                                setRevenueClient(inputText);
                                setRevenueClientError("");
                              }
                            }}
                          />
                          {revenueClientError && (
                            <p className="error-message">
                              {revenueClientError}
                            </p>
                          )}
                        </div>
                        <div className="flex gap-2 items-center col-span-2">
                          <p>Internal</p>
                          <input
                            type="text"
                            className="maincontent__postarticle--input w-9/12"
                            value={revenueInternal.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })}
                            placeholder="$ 0.00"
                            onChange={(e) => {
                              const inputText = e.target.value;
                              if (regex.test(inputText)) {
                                setRevenueInternal(inputText);
                                setRevenueInternalError("");
                              }
                            }}
                          />
                          {revenueInternalError && (
                            <p className="error-message">
                              {revenueInternalError}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-1 gap-4 md:col-span-3">
                      <div className="grid grid-cols-5 gap-2">
                        <div className="flex gap-2 items-center col-span-1">
                          <p>Cost</p>
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input cursor-pointer"
                              type="checkbox"
                              id="flexSwitchCheckDefault"
                              value={cost}
                              onChange={() => {
                                setCost(!cost);
                              }}
                              checked={cost ? 0 : 1}
                            />
                          </div>
                        </div>
                        <div className="flex gap-2 items-center col-span-2">
                          <p>Client</p>
                          <input
                            type="text"
                            className="maincontent__postarticle--input w-9/12"
                            value={costClient.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })}
                            placeholder="$ 0.00"
                            onChange={(e) => {
                              const inputText = e.target.value;
                              if (regex.test(inputText)) {
                                setCostClient(inputText);
                                setCostClientError("");
                              }
                            }}
                          />
                          {costClientError && (
                            <p className="error-message">{costClientError}</p>
                          )}
                        </div>
                        <div className="flex gap-2 items-center col-span-2">
                          <p>Internal</p>
                          <input
                            type="text"
                            className="maincontent__postarticle--input w-9/12"
                            value={costInternal.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })}
                            placeholder="$ 0.00"
                            onChange={(e) => {
                              const inputText = e.target.value;
                              if (regex.test(inputText)) {
                                setCostInternal(inputText);
                                setCostInternalError("");
                              }
                            }}
                          />
                          {costInternalError && (
                            <p className="error-message">{costInternalError}</p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-1 gap-4 items-center md:col-span-3">
                      <p>Person Days</p>
                      <input
                        type="text"
                        className="maincontent__postarticle--input"
                        value={personDays}
                        onChange={(e) => {
                          const inputText = e.target.value;
                          if (/^\d*$/.test(inputText)) {
                            setPersonDays(inputText);
                            setPersonDaysError("");
                          }
                        }}
                      />
                      {personDaysError && (
                        <p className="error-message">{personDaysError}</p>
                      )}
                    </div>
                  </>
                ) : null}

                <div className="flex flex-col flex-1 col-span-3 gap-2">
                  <p>Keywords</p>
                  <textarea
                    placeholder="Write a Caption"
                    className="maincontent__postarticle--input clrBlue"
                    value={Keyword}
                    onChange={handleChange}
                    ref={(el) => (inputRefs.current[5] = el)}
                  />
                  {Keywordserror && (
                    <p className="error-message">{Keywordserror}</p>
                  )}
                </div>

                <div className="flex flex-col flex-1 col-span-3 gap-2">
                  <p>AskGenie Hint</p>
                  <textarea
                    placeholder="Write a Chat Hint"
                    className="maincontent__postarticle--input"
                    value={chatHint}
                    onChange={(e)=> setChatHint(e.target.value)}
                  />
                </div>
              </div>
              <div className="flex justify-between gap-4 items-center w-full p-4">
                <ImgIcon
                  image={image}
                  setImage={setImage}
                  removedFileName={removedFileName}
                  className="maincontent__postarticle--attachicon"
                />
                <div className="flex gap-4">
                  <button
                    className="mt-4 maincontent__btn articles__search--postbtn w-fit md:m-0 draftsBtn font-semibold text-[15px]"
                    onClick={() => postArticle(true)}
                  >
                    Draft
                  </button>

                  {type === "4" ? (
                    <>
                      <div className="flex flex-1 justifyEnd">
                        {design ? (
                          <button
                            type="button"
                            disabled={isdisabled}
                            className="articles__search--btn articles__search--postbtn font-semibold text-[15px]"
                            onClick={() => postArticle()}
                            style={{ backgroundColor: "#441391" }}
                          >
                            {" "}
                            Post Artifact{" "}
                          </button>
                        ) : (
                          <button
                            type="button"
                            disabled={isdisabled}
                            className="articles__search--btn articles__search--postbtn font-semibold text-[15px] min-w-[140px]"
                            onClick={() => postArticle()}
                            style={{
                              backgroundColor: "#441391",
                              color: "white",
                            }}
                          >
                            {" "}
                            Post Artifact{" "}
                          </button>
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      {design ? (
                        <button
                          type="button"
                          disabled={isdisabled}
                          className="articles__search--btn articles__search--postbtn font-semibold text-[15px]"
                          onClick={() => postArticle()}
                          style={{ backgroundColor: "#441391" }}
                        >
                          {" "}
                          Post Artifact{" "}
                        </button>
                      ) : (
                        <button
                          type="button"
                          disabled={isdisabled}
                          className="articles__search--btn articles__search--postbtn font-semibold text-[15px] min-w-[140px]"
                          onClick={() => postArticle()}
                          style={{ backgroundColor: "#441391", color: "white" }}
                        >
                          {" "}
                          Post Artifact{" "}
                        </button>
                      )}
                    </>
                  )}
                </div>
              </div>

              <div className="p-4">
                {image &&
                  image?.map((attachment, i) => (
                    <>
                      <div className="d-flex" key={i}>
                        <ul className="list-disc">
                          <li>
                            <span>
                              {attachment.name}
                              <FontAwesomeIcon
                                onClick={() => removeFile(i)}
                                icon={faTrashAlt}
                                style={{
                                  marginLeft: "15px",
                                  cursor: "pointer",
                                  color: "red",
                                }}
                              />
                            </span>
                          </li>
                        </ul>
                      </div>
                    </>
                  ))}
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  approveReview: state.approveReview,
  rewardsData: state.rewards,
  token: state.token,
});

export default connect(mapStateToProps)(PostArtifact);
