import React, { useState, useEffect } from "react";
import ProfilePic from "../../assets/img/profile.jpg";
import PostArtifact from "../components/PostArtifact";
import apiClient from "../../common/http-common";
import { connect, useDispatch } from "react-redux";
import { Alert } from "../../components/Alert";
import { Route, useNavigate, useLocation } from "react-router-dom";
import { Routes } from "../../routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Pagination from "@mui/material/Pagination";
import ViewArticle from "../KnowledgeManagement/ViewArticle";
import { Modal } from "@themesberg/react-bootstrap";
import {
  faArrowRight,
  faHeart,
  faMessage,
  faTimes,
  faStar,
  faPause,
  faPlay
} from "@fortawesome/free-solid-svg-icons";
import MyArticleCard from "../KnowledgeManagement/MyArticleCard";
import { checkRolePermission } from "../../common/Helper";
import articleoftheweek from "../../assets/img/brand/articleoftheweek.svg";
import shootingstar from "../../assets/img/brand/shootingstar.svg";
import addRewardPoints from "../../common/AddRewardPoints";
import lifetimeachiever from "../../assets/img/brand/lifetimeachiever.svg";
import parse from "html-react-parser";
import axios from "axios";
import PermissionGuard from "../../common/gaurd/PermissionGuard";
import { userRewardPoints } from "../../actions/actions";
import store from "../../store";
import GenieChat from "../KnowledgeManagement/GenieChat";
import { geniePopup } from "../../actions/actions";

const NewArticles = (state) => {
  const location = useLocation();
  const dispatch = useDispatch();
  let [searchQuery, setSearchQuery] = useState("");
  const [genieChatOpen, setGenieChatOpen] = useState(false);
  const [paginationcount, setPaginationCount] = useState();
  const [sortedArticleList, setSortedArticleList] = useState([]);
  const [articleList, setArticleList] = useState([]);
  const [logUserId] = useState(state?.user?.Id);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [askLoader, setAskLoader] = useState(false);
  const [articleViewDetails, setArticleViewDetails] = useState({});
  const [isLoader, setIsLoader] = useState(true);
  const [start, setStart] = useState(0);
  const [showDefault, setShowDefault] = useState(false);
  const [rightCardChange, setRightCardChange] = useState(0);
  const handleClose = () => (
    setShowDefault(false), setShowDef(false), setArticleViewDetails({})
  );
  const [ShowDef, setShowDef] = useState(false);
  const limit = 9;
  const [sortOption, setSortOption] = useState(location?.state?.card === "new-dashboard"? "popular" : "recent");
  const [sortFeed, setSortFeed] = useState(location?.state?.card === "new-dashboard" ? 2 : 1);
  const [artifactList, setArtifactList] = useState([
    { value: null, label: "All" },
  ]);
  const handlePageChange = (event, page) => {
    const newStart = (page - 1) * limit;
    setCurrentPage(page);
    setStart(newStart);
  };
  const [showChat, setShowChat] = useState(false);
  const [chatResponse, setChatResponse] = useState("");
  const currentYr = new Date().getFullYear();
  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const [selectVal, setSelectVal] = useState({
    article : { value : null, label: "All"},
  });

  const [articleType, setArticleType] = useState(null);

  const currentMonthIndex = new Date().getMonth();
  const currentMonth = months[currentMonthIndex];

  let [addReviewPermission, setAddReviewPermission] = useState(false);

  useEffect(async () => {
    addReviewPermission = await PermissionGuard(Routes.KMUnpublished);
    setAddReviewPermission(addReviewPermission);
  }, []);
  function capitalizeFirstLetter(str) {
    return str ? str.charAt(0).toUpperCase() + str.slice(1) : "";
  }
  const [isPlaying, setIsPlaying] = useState(false);
  const [speechSynthesis, setSpeechSynthesis] = useState(null);
  const speak = (text) => {
    if (speechSynthesis) {
      speechSynthesis.cancel();
    }
    const synth = window.speechSynthesis;
    const utterance = new SpeechSynthesisUtterance(text);
    setSpeechSynthesis(synth);
    if (!isPlaying) {
      setIsPlaying(true);
      utterance.onend = () => {
        setIsPlaying(false);
      };
      synth.speak(utterance);
    } else {
      setIsPlaying(false);
      synth.pause();
    }
  };


  function getPaginationCount(articleId = null) {
    apiClient
      .post("kmarticle/latestArticlenew", {
        UserId: logUserId,
        year: currentYr,
        month: currentMonth,
        sort: sortFeed,
        limit: null,
        start: 0,
        des: searchQuery,
        articleId: articleId,
        type : parseInt(articleType),
      })
      .then((res) => {
        const articleList = Array.isArray(res.data.data)
          ? res.data.data
          : [res.data.data];
        const totalRecords = articleList.length;
        const recordsPerPage = 9;
        const paginationCount = Math.ceil(totalRecords / recordsPerPage);
        setPaginationCount(paginationCount);
      })
      .catch((err) => {
        Alert("error", err);
      });
  }

  useEffect(() => {
    getArticles();
  }, [sortFeed, start, articleType]);

  const emptyRecord = () => {
    setCurrentPage(1);
    setPaginationCount(0);
    setArticleList([]);
    setSortedArticleList([]);
    setIsLoader(false);
  };

  const ArtifactList= () => {
    apiClient
      .get("/lookup/ArticleType/1")
      .then((response) => {
        let arr = [{ value: null, label: "All" }];
        if (response.data.lookup.length > 0) {
          response.data.lookup.forEach((element) => {
            arr.push({ value: element.Id, label: element.Name });
          });
        }
        setArtifactList(arr);
      })
      .catch(() => {
        Alert("error", "Please Try Again");
      });
  };

  useEffect(() => {
    ArtifactList();
  }, []);


  const [searchCountType, setSearchCountType] = useState();

  const handleSearchCount = async () => {
    setSearchCountType(
      chatResponse.includes("Sorry I don't know." || "Sorry, I don't know.")
        ? 2
        : 1
    );
    try {
      const searchCountResponse = await apiClient.post(
        "kmarticle/searchcount",
        {
          userId: logUserId,
          question: searchQuery,
          type: searchCountType,
        }
      );
    } catch (error) {
      Alert("error", error);
    }
  };

  const getArticles = () => {
    setIsLoader(true);
    setShowChat(false);

    if (searchQuery) {
      let chatQuestion = searchQuery.trim()
      if (searchQuery.trim() !== "" && !searchQuery.trim().endsWith("?")) {
        chatQuestion = searchQuery.trim() + "?";
      }
      setAskLoader(true);

      let ChatPath = process.env.REACT_APP_CHAT_URL;

      axios
        .post(
          ChatPath,
          {
            input_text: chatQuestion,
          },
          { headers: { "Content-type": "application/json" } }
        )
        .then((res) => {
          if (res.data.response) {
            const responses = "Sorry I don't know";
            const chatresponses = res.data.response;
            setChatResponse(res.data.response);
            let searchType = 2;
            if (chatresponses !== responses) searchType = 1;
            apiClient
              .post("kmarticle/searchcount", {
                userId: logUserId,
                question: searchQuery,
                type: searchType,
              })
              .then((searchCountResponse) => {
                setAskLoader(false);
                setShowChat(true);
              })
              .catch((error) => {
                Alert("error", error);
                setAskLoader(false);
                setShowChat(true);
              });
          } else {
            setChatResponse("");
            setAskLoader(false);
            setShowChat(true);
          }
        })
        .catch((err) => {
          Alert("error", err);
          setChatResponse("");
          setAskLoader(false);
          setShowChat(false);
        });

      let SearchPath = process.env.REACT_APP_SEARCH_URL;
      axios
        .post(
          SearchPath,
          {
            input_text: searchQuery,
          },
          { headers: { "Content-type": "application/json" } }
        )
        .then((res) => {
          if (res.data.message === "Results found") {
            if (res.data.results.length > 0) {
              let articleIds = [];
              res.data.results.map((item) => {
                if (item.article_id) {
                  let artVal = item.article_id.split("_");
                  articleIds.push(artVal[0]);
                }
              });
              const idString = `(${articleIds.join(", ")})`;
              getPaginationCount(idString);
              apiClient
                .post("kmarticle/latestArticlenew", {
                  UserId: logUserId,
                  sort: sortFeed,
                  year: currentYr,
                  month: currentMonth,
                  limit: limit,
                  start: 0,
                  des: searchQuery,
                  articleId: idString,
                  type : parseInt(articleType)
                })
                .then((res) => {
                  const articles = Array.isArray(res.data.data)
                    ? res.data.data
                    : [res.data.data];
                  setArticleList(articles);
                  setSortedArticleList([...articles]);
                  setIsLoader(false);
                })
                .catch((err) => {
                  emptyRecord();
                });
            }
          } else {
            emptyRecord();
          }
        })
        .catch((err) => {
          emptyRecord();
        });
    } else {
      setShowChat(false);
      apiClient
        .post("kmarticle/latestArticlenew", {
          UserId: logUserId,
          sort: sortFeed,
          year: currentYr,
          month: currentMonth,
          limit: limit,
          start: start,
          des: searchQuery,
          type : parseInt(articleType)
        })
        .then((res) => {
          const articles = Array.isArray(res.data.data)
            ? res.data.data
            : [res.data.data];
          setArticleList(articles);
          setSortedArticleList([...articles]);
          setIsLoader(false);
        })
        .catch((err) => {
          emptyRecord();
        });
      getPaginationCount();
    }
  };

  const handlePublishArtifact = () => {
    setRightCardChange(rightCardChange + 1);
  };
  const handleViewLikeCmd = (articleDetails) => {
    let foundObject = "";
    sortedArticleList.map((item, i) => {
      if (item.Id == articleDetails.Id) {
        foundObject = i;
      }
    });
    if (foundObject >= 0) {
      const letData = { ...sortedArticleList[foundObject] };
      letData.Likes = articleDetails.Likes;
      letData.Comments = articleDetails.Comments;
      letData.likestatus = articleDetails.likestatus;
      sortedArticleList[foundObject] = letData;
      setSortedArticleList(sortedArticleList);
    }
    setRightCardChange(rightCardChange + 1);
  };

  const handleVoteCmd = (updatedDetails) => {
    let foundObject = "";
    sortedArticleList.forEach((item, i) => {
      if (item.Id === updatedDetails.Id) {
        foundObject = i;
      }
    });

    if (foundObject >= 0) {
      const updatedArticle = { ...sortedArticleList[foundObject] };
      updatedArticle.UpVotes = updatedDetails.UpVotes;
      updatedArticle.DownVotes = updatedDetails.DownVotes;
      updatedArticle.upvoteStatus = updatedDetails.upvoteStatus;
      updatedArticle.downvoteStatus = updatedDetails.downvoteStatus;

      sortedArticleList[foundObject] = updatedArticle;
      setSortedArticleList([...sortedArticleList]);
    }

    setRightCardChange(rightCardChange + 1);
  };

  const handleSortChange = (option) => {
    setSortOption(option);
    if (option === "recent") {
      setSortFeed(1);
    } else if (option === "popular") {
      setSortFeed(2);
    }
  };

  useEffect(() => {
    handlePageChange(null, 1);
  }, [sortOption]);

  const handleGenieChat = () => {
      // setGenieChatOpen(!genieChatOpen);
      dispatch(geniePopup(true));
  };

  const [likeInProgress, setLikeInProgress] = useState(false);

  const submitLike = async (i) => {
    if (likeInProgress) return; // Prevent multiple like requests
    setLikeInProgress(true);
    if (articleList[i].Id) {
      let data = {
        articalId: articleList[i].Id,
        likedBy: logUserId,
        UserId: logUserId,
      };
      try {
        await apiClient.post("kmarticle/addlike", data);
        const updatedArticleList = [...articleList];
        updatedArticleList[i]["Likes"] = updatedArticleList[i]["Likes"] + 1;
        updatedArticleList[i]["likestatus"] = true;
        setArticleList(updatedArticleList);
        setRightCardChange(rightCardChange + 1);
        if (articleList[i].ArticleBy !== logUserId) {
          addRewardPoints(
            state.rewards[7].Points,
            logUserId,
            state.rewards[7].Id,
            logUserId,
            articleList[i].Id
          );
          addRewardPoints(
            state.rewards[4].Points,
            articleList[i].ArticleBy,
            state.rewards[4].Id,
            logUserId,
            articleList[i].Id
          );
        }
        if (articleList[i].Likes % 25 === 0) {
          addRewardPoints(
            state.rewards[14].Points,
            articleList[i].ArticleBy,
            state.rewards[14].Id,
            logUserId,
            articleList[i].Id
          );
        }
      } catch (error) {
        Alert("error", "Please Try Again");
      }
      finally {
        setLikeInProgress(false);
      }
    } else {
      Alert("error", "Please Try Again");
    }
  };

  const [dislikeDisabled, setDislikeDisabled] = useState(false);

  const disLike = async (i) => {
    if (articleList[i].Id) {
      if (!dislikeDisabled) {
        setDislikeDisabled(true);

        const updatedArticleList = [...articleList];
        const currentArticle = updatedArticleList[i];

        if (currentArticle.likestatus) {
          let data = {
            articalId: currentArticle.Id,
            likedBy: logUserId,
          };
          try {
            const response = await apiClient.post("kmarticle/dislike", data);

            if (currentArticle.Likes > 0) {
              currentArticle.Likes--;
            }
            currentArticle.likestatus = false;
            setArticleList(updatedArticleList);
            setRightCardChange(rightCardChange + 1);
            setDislikeDisabled(false);
            store.dispatch(
              userRewardPoints(response?.data[0]?.LV_RewardPoints)
            );
          } catch (error) {
            Alert("error", "Please Try Again");
            setDislikeDisabled(false);
          }
        } else {
          Alert("warn", "You have already disliked this article.");
          setDislikeDisabled(false);
        }
      }
    } else {
      Alert("error", "Please Try Again");
    }
  };

  const resetSearch = () => {
    setSearchQuery("");
    if (speechSynthesis) {
      speechSynthesis.cancel();
    }
    getArticles();
  };

  useEffect(() => {
    if (articleViewDetails?.Id) {
      setShowDefault(true);
    }
  }, [articleViewDetails]);

  const htmlToView = (htmlText) => {
    // Parse the HTML text
    htmlText = parse(htmlText);
 
    // Check if the HTML text contains any links
    const containsLink = /<a\s+(?:[^>]*?\s+)?href=["'](.*?)["']/gi.test(htmlText);
 
    // Function to convert plain URLs into hyperlinks
    const convertURLsToLinks = (text) => {
        return text.replace(/(https?:\/\/[^\s]+)/g, (match, url) => {
            // Encode the URL to ensure it's properly formatted
            const encodedUrl = encodeURI(url);
            return `<a href="${encodedUrl}"  target="_blank">${url}</a>`;
        });
    };
    const addBlankTargetToLinks = (html) => {
      const doc = new DOMParser().parseFromString(html, 'text/html');
      const links = doc.querySelectorAll('a');
      links.forEach(link => {
          link.setAttribute('target', '_blank');
          link.setAttribute('rel', 'noopener noreferrer');
      });
      return doc.documentElement.innerHTML;
  };
  htmlText = addBlankTargetToLinks(htmlText);

    // Function to check if the HTML contains any media URLs
    const containsMediaUrl = /<figure class="media">\s*<oembed url="(.*?)"><\/oembed>\s*<\/figure>/gi.test(htmlText);
 
    // If the HTML text contains any links, render it safely
    if (containsLink) {
        return (
            <div>
                <div dangerouslySetInnerHTML={{ __html: htmlText }} />
            </div>
        );
    } else if (containsMediaUrl) {
        // If the HTML contains a media URL, handle it differently
        const mediaUrl = htmlText.match(/<oembed url="(.*?)"><\/oembed>/)[1];
        return (
            <div>
                {/* Display the media URL as a clickable link */}
                <a href={mediaUrl} target="_blank">{mediaUrl}</a>
            </div>
        );
    } else {
        // Otherwise, convert plain URLs to hyperlinks and render them
        return (
            <div>
                <div dangerouslySetInnerHTML={{ __html: convertURLsToLinks(htmlText) }} />
            </div>
        );
    }
};


  return (
    <>
      <div className="articles__container pb-2">
        <div className="articles__left">
          <div>
            <div className="grid grid-cols-1 md:grid-cols-12 gap-4 pb-[8px] articles__search">
              <div className="relative w-full md:col-span-6">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  {searchQuery && (
                    <div className="absolute top-0 mt-2 text-2xl left-2">
                      <FontAwesomeIcon
                        icon={faTimes}
                        onClick={(e) => {
                          searchQuery = null;
                          resetSearch();
                        }}
                        className="text-2xl cursor-pointer closeicon"
                      />
                    </div>
                  )}
                  <input
                    type="text"
                    placeholder="Ask Genie"
                    className="pl-10 articles__search--input"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && searchQuery.trim() !== "") {
                        e.preventDefault();
                        getArticles();
                      }
                    }}
                  />
                  <button
                    type="button"
                    className="articles__search--btn articles__search--searchbtn max-w-[140px]"
                    onClick={(e) => getArticles()}
                  >
                    Ask Genie
                  </button>
                </form>
              </div>

              {state?.systemDetails[0]?.GenieChat && checkRolePermission(Routes.ChatGPT) ? (
        <div className="md:col-span-2">
          {/* {genieChatOpen ? ( // Render GenieChat component only if genieChatOpen is true
            <GenieChat GenieChat = {handleGenieChat} genieChatOpen = {genieChatOpen} />
          ) : ( */}
          { state?.geniePopup === false ? (
            <button
              onClick={() => handleGenieChat()} // Set genieChatOpen to true when the button is clicked
              className="mb-0 articles__search--btn articles__search--reviewbtn md:col-span-2"
            >
              GenieChat
            </button>
          ) : null }
          {/* )} */}
        </div>
      ) : null}

              {checkRolePermission(Routes.KMUnpublished) &&
                addReviewPermission && (
                  <div className="md:col-span-2">
                    <button
                      onClick={() => {
                        navigate(Routes.KMUnpublished);
                      }}
                      className="mb-0 articles__search--btn articles__search--reviewbtn md:col-span-2"
                    >
                      Review
                    </button>
                  </div>
                )}

               <div className="md:col-span-2">
                <PostArtifact
                  design={false}
                  postRender={handlePublishArtifact}
                  getArticles={getArticles}
                />
              </div>
              <Modal
                as={Modal.Dialog}
                centered
                show={showDefault}
                onHide={handleClose}
                backdrop="static"
              >
                <ViewArticle
                  article={articleViewDetails}
                  postRender={handleViewLikeCmd}
                  postVoteRender={handleVoteCmd}
                  onClose={handleClose}
                  getState={state}
                />
              </Modal>
            </div>
            <div className="relative flex items-center justify-between w-full gap-1 md:w-auto">
            <span className="flex-grow h-[0.8px] bg-gray-500 mr-4"></span>
            <div className="flex items-center space-x-1 mr-2">
              <label htmlFor="sort-select" className="whitespace-nowrap">Filter   :</label>
              <select
            id="sort-select"
            className="cursor-pointer sortfeedSelect articles__sortfeedSelect articletype"
            value={articleType}
            onChange={(e) => {
              setArticleType(e.target.value);
            }}
          >
            {artifactList.map((artifact) => (
              <option key={artifact.value} value={artifact.value}>
                {artifact.label}
              </option>
            ))}
          </select>

  </div>
  <div className="flex items-center space-x-1 mr-2">
    <label htmlFor="sort-select" className="whitespace-nowrap">Sort by   :</label>
    <select
      id="sort-select"
      className="cursor-pointer sortfeedSelect articles__sortfeedSelect"
      value={sortOption}
      onChange={(e) => handleSortChange(e.target.value)}
    >
      <option style={{ color: "black" }} value="recent">
        Recent
      </option>
      <option style={{ color: "black" }} value="popular">
        Popular
      </option>
    </select>
  </div>
</div>



            {askLoader ? (
              <>
                <div className="chat__loader items-center my-0 mx-auto">
                  <div className="typing-indicator">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className={`articles__searchresult ${showChat ? "" : "hidden"}`}>
                  <div className="flex items-center">
                    <FontAwesomeIcon
                      icon={isPlaying ? faPause : faPlay}
                      className="cursor-pointer mr-2"
                      onClick={() => {
                        speak(chatResponse);
                      }}
                      title={isPlaying ? "Pause" : "Play"}
                    />
                    <p>{chatResponse}</p>
                  </div>
                </div>
              </>
            )}
          </div>
          <div
            className={
              isLoader || !sortedArticleList.length
                ? `articles__articlelist grid-cols-1`
                : `articles__articlelist grid-cols-3`
            }
          >
            {isLoader ? (
              <div class="circle__loader items-center my-0 mx-auto"></div>
            ) : (
              <>
                {sortedArticleList.length > 0 ? (
                  sortedArticleList.map((article, i) => (
                    <div
                      key={i}
                      className={`articles__article articles__article--${i % 2 === 0 ? "darkgray" : "lightgray"
                        }`}
                    >
                      <div className="flex flex-col gap-[5px] w-full">
                        <div className="articles__article--header">
                          <img
                            src={ProfilePic}
                            className="articles__article--profilepic"
                            alt={`Profile pic of ${article.Author}`}
                          />
                          <div className="flex flex-col max-w-[30%] min-w-[16%]">
                            <span className="articles__article--author">
                              {article.ArticleName}
                            </span>
                            <span className="articles__article--level">
                            {article.Badge}
                            </span>
                          </div>
                          <span className="articles__article--line"></span>
                          <span className="articles__article--date">
                            {article.postdate}
                          </span>
                          <div className="flex gap-3">
                            <span className="articles__article--likes">
                              {article.likestatus ? (
                                <FontAwesomeIcon
                                  className="cursor-pointer activeHeart"
                                  icon={faHeart}
                                  onClick={() => disLike(i)}
                                />
                              ) : (
                                <>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 512 512"
                                    id="IconChangeColor"
                                    onClick={() => submitLike(i)}
                                    className={`${likeInProgress ? 'disabled' : ''}`}

                                  >
                                    <title>Like</title>
                                    <path
                                      d="M352.92,80C288,80,256,144,256,144s-32-64-96.92-64C106.32,80,64.54,124.14,64,176.81c-1.1,109.33,86.73,187.08,183,252.42a16,16,0,0,0,18,0c96.26-65.34,184.09-143.09,183-252.42C447.46,124.14,405.68,80,352.92,80Z"
                                      id="mainIconPathAttribute"
                                      stroke-width="0.3"
                                      stroke="#ff0000"
                                      fill="#737373"
                                      filter="url(#shadow)"
                                    ></path>
                                    <filter id="shadow">
                                      <feDropShadow
                                        id="shadowValue"
                                        stdDeviation="0.3"
                                        dx="-1"
                                        dy="-0.8"
                                        flood-color="black"
                                      ></feDropShadow>
                                    </filter>
                                  </svg>
                                </>
                              )}
                              <span className="articles__article--likescount">
                                {article.Likes}
                              </span>
                            </span>
                            <span
                              className="articles__article--comments"
                              title="Comment"
                            >
                              <FontAwesomeIcon
                                className="flex items-center justify-between gap-2 cursor-pointer commentBoxClr"
                                icon={faMessage}
                              />
                              <span className="articles__article--commentscount">
                                {article.Comments}
                              </span>
                            </span>
                          </div>
                        </div>
                        <div className="badgeClass2">
                          {/* {article.golden_star === "true" ? (
                            <FontAwesomeIcon
                              className="cursor-pointer"
                              style={{
                                color: "gold",
                              }}
                              title="Golden Star"
                              icon={faStar}
                            />
                          ) : (
                            ""
                          )}

                          {article.articleOfWeek === "true" ? (
                            <img
                              style={{ width: "20px" }}
                              className="cursor-pointer"
                              title="Article of the Week"
                              src={articleoftheweek}
                            />
                          ) : (
                            ""
                          )}

                          {article.shootingStart === "true" ? (
                            <img
                              style={{ width: "20px" }}
                              className="cursor-pointer"
                              title="Shooting Star"
                              src={shootingstar}
                            />
                          ) : (
                            ""
                          )}

                          {article.lifeTimeAchiver === "true" ? (
                            <img
                              style={{ width: "20px" }}
                              className="cursor-pointer"
                              title="Lifetime Achiever"
                              src={lifetimeachiever}
                            />
                          ) : (
                            ""
                          )} */}

                          <h2 className="text-[16px] articles__article--title">
                            {capitalizeFirstLetter(article.title)}
                          </h2>
                        </div>
                        <p className="articles__article--para">
  {htmlToView(article.Description)?.props?.dangerouslySetInnerHTML?.__html?.length > 150
    ? htmlToView(
        `${article.Description.substring(0, 150)}...`
      )
    : htmlToView(article.Description)}
</p>


                      </div>
                      <div className="flex justify-between w-full">
                      <button
                        className="articles__article--readmorebtn"
                        onClick={() => {
                          setArticleViewDetails(article);
                          addRewardPoints(
                            state.rewards[9].Points,
                            logUserId,
                            state.rewards[9].Id,
                            logUserId,
                            article.Id
                          );
                        }}
                      >
                        Read More {""} <FontAwesomeIcon className="ml-2" icon={faArrowRight} />
                      </button>
                      <button className="articles__article--readmorebtn" style={{width:"150px", backgroundColor:"#e8e9ff"}}>
                       {article.ArtifactType}
                      </button>
                    </div>
                    </div>
                  ))
                ) : (
                  <div className="text-center">No Record Found</div>
                )}
              </>
            )}
          </div>
          <div className="py-4">
            {!isLoader && sortedArticleList.length > 0 && (
              <Pagination
                count={paginationcount}
                color="primary"
                className="flex justify-center absolute left-0 right-0"
                size="large"
                page={currentPage}
                onChange={handlePageChange}
                siblingCount={0}
                boundaryCount={1}
              />
            )}
          </div>
        </div>
        <div className="articles__right h-[100%]">
          <MyArticleCard
            changeData={rightCardChange}
            viewArticle={setArticleViewDetails}
            getState={state}
            // design={false}
            // postRender={handlePublishArtifact}
            // getArticles={getArticles}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps)(NewArticles);
