import React, {useRef} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";
import HomeOutlineIcon from "../../../assets/img/icons/home-outline-icon.svg";
import { Alert } from "../../../components/Alert";
import { faUser, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import "./AddClient.css";
import {
  Col,
  Row,
  Card,
  Accordion,
  Form,
} from "@themesberg/react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import DropzoneComponent from "../../../components/DropZoneComponent";
import apiClient from "../../../common/http-common";
import { Routes } from "../../../routes";
import ClientNameIcon from "../../../assets/img/icons/icon-client.svg";
import ClientCodeIcon from "../../../assets/img/icons/icon-client-code.svg";
import IndustryIcon from "../../../assets/img/icons/icon-industry.svg";
import CapabilityIcon from "../../../assets/img/icons/icon-capability.svg";
import ClientEffectCodeIcon from "../../../assets/img/icons/icon-client-effect-code.svg";
import ClientTypeIcon from "../../../assets/img/icons/icon-client-type.svg";
import ClientDesignationIcon from "../../../assets/img/icons/icon-client-designation.svg";
import EngagementLeaderIcon from "../../../assets/img/icons/icon-engagement-leader.svg";
import ServiceTypeIcon from "../../../assets/img/icons/icon-service-type.svg";
import POLocationIcon from "../../../assets/img/icons/icon-po-location.svg";
import DeliveryLocationIcon from "../../../assets/img/icons/icon-delivery-location.svg";
import { connect, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { unsavedChange } from "../../../actions/actions";

const AddClient = (state) => {
  const inputRefs = useRef([]);
  const dispatch = useDispatch();
   const [clientName, setClientName] = useState();
  const [clientCode, setClientCode] = useState();
  const [industry, setindustry] = useState();
  const [clientEffectiveDate, setclientEffectiveDate] = useState();
  const [aboutClient, setAboutClient] = useState();
  const [clientType, setClientType] = useState();
  const [clientPOC, setClientPOC] = useState();
  const [clientDesignation, setClientDesignation] = useState();
  const [engagementLeader, setEngagementLeader] = useState();
  const [serviceType, setServiceType] = useState();
  const [capability, setCapability] = useState();
  const [deliveryLocation, setDeliveryLocation] = useState();
  const [poLocation, setPoLocation] = useState();
  const [penaltyClause, setPenaltyClause] = useState(false);
  const [globalClient, setGlobalClient] = useState(false);
  const [image, setImage] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [clientTypeList, setClientTypeList] = useState([]);
  const [clientDesignationList, setClientDesignationList] = useState([]);
  const [engagementList, setEngagementList] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [capabilityList, setCapabilityList] = useState([]);
  const [poLocationList, setPoLocationList] = useState([]);
  const [deliveryLocationList, setDeliveryLocationList] = useState([]);
  const [totalChange, setTotalChange] = useState(0);
  const [industryLookup, setIndustryLookup] = useState([]);

  const navigate = useNavigate();

  const optionList = [
    { value: "red", label: "Red" },
    { value: "green", label: "Green" },
    { value: "yellow", label: "Yellow" },
    { value: "blue", label: "Blue" },
    { value: "white", label: "White" },
  ];

  useEffect(() => {
    function convert(str) {
      var date = new Date(str),
        month = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [date.getFullYear(), mnth, day].join("-");
    }

    apiClient.get("/user/role/3").then((response) => {
      if (response.data.user.length > 0) {
        const arr = [];
        response?.data?.user?.map((user) => {
          arr.push({
            value: user.Id,
            label: `${user.FirstName} ${user.LastName}`,
            email: user.EmailId,
          });
          setEngagementList(arr);
        });
      }
    })
    .catch(()=>{});
    apiClient.get("/lookup/domain/1").then((response) => {
      if (response.data.lookup.length > 0) {
        const arr = [];
        response.data.lookup.map((user) => {
          const obj = { value: user.Id, label: user.Name };

          arr.push(obj);
          setIndustryLookup(arr);
        });
      }
    })
    .catch(()=> {});
    apiClient.get("/lookup/clientType/1").then((response) => {
      if (response.data.lookup.length > 0) {
        const arr = [];
        response.data.lookup.map((user) => {
          const obj = { value: user.Id, label: user.Name };
          arr.push(obj);
          setClientTypeList(arr);
        });
      }
    })
    .catch(()=> {});
    apiClient.get("/lookup/designation/1").then((response) => {
      if (response.data.lookup.length > 0) {
        const arr = [];
        response?.data?.lookup?.map((user) => {
          const obj = { value: user.Id, label: user.Name };
          arr.push(obj);
          setClientDesignationList(arr);
        });
      }
    })
    .catch(()=> {});

    apiClient.get("/lookup/serviceType/1").then((response) => {
      if (response.data.lookup.length > 0) {
        const arr = [];
        response?.data?.lookup?.map((user) => {
          const obj = { value: user.Id, label: user.Name };
          arr.push(obj);
          setServiceList(arr);
        });
      }
    })
    .catch(() => {});
    apiClient.get("/lookup/tower/1").then((response) => {
      if (response.data.lookup.length > 0) {
        const arr = [];
        response?.data?.lookup?.map((user) => {
          const obj = { value: user.Id, label: user.Name };
          arr.push(obj);
          setCapabilityList(arr);
        });
      }
    })
    .catch(()=> {});
    apiClient.get("/lookup/poLocation/1").then((response) => {
      if (response.data.lookup.length > 0) {
        const arr = [];
        response?.data?.lookup?.map((user) => {
          const obj = { value: user.Id, label: user.Name };
          arr.push(obj);
          setPoLocationList(arr);
        });
      }
    })
    .catch(()=> {});
    apiClient.get("/lookup/deliveryLocation/1").then((response) => {
      if (response.data.lookup.length > 0) {
        const arr = [];
        response?.data?.lookup?.map((user) => {
          const obj = { value: user.Id, label: user.Name };
          arr.push(obj);

          setDeliveryLocationList(arr);
        });
      }
    })
    .catch(()=> {});
  }, []);
  useEffect(() => {
    if (
      clientName &&
      clientCode &&
      industry &&
      clientEffectiveDate &&
      aboutClient &&
      clientType &&
      clientPOC &&
      clientDesignation &&
      serviceType &&
      capability &&
      poLocation &&
      deliveryLocation
    ) {
      setIsDisabled(false);
    } else {
    }
  }, [
    clientCode,
    clientName,
    industry,
    clientEffectiveDate,
    aboutClient,
    clientType,
    clientPOC,
    clientDesignation,
    engagementLeader,
    serviceType,
    capability,
    poLocation,
    deliveryLocation,
  ]);
  const handleInputFocus = (fieldName) => {
    setFormErrors((errors) => ({
      ...errors,
      [fieldName]: "",
    }));
  };

  useEffect(() => {
    const errors = {}
    if (aboutClient && aboutClient.trim().length > 5000) {
      errors.aboutClient = "About the Department cannot exceed 5000 characters";
    }
    setFormErrors(errors);
  },[aboutClient])


  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const SaveAddClient = async () => {
      setIsSaving(true);
      setIsDisabled(true);
  
      const errors = {};
      let isValid = true;
  
      // Validation checks
      if (!clientName || clientName.trim() === "") {
        errors.clientName = "Department Name is required";
        isValid = false;
      } else if (clientName.trim().length > 100) {
        errors.clientName = "Department Name cannot exceed 100 characters";
        isValid = false;
      }
  
      if (!clientCode || clientCode.trim() === "") {
        errors.clientCode = "Department Code is required";
        isValid = false;
      } else if (clientCode.trim().length > 100) {
        errors.clientCode = "Department Code cannot exceed 100 characters";
        isValid = false;
      }
  
      if (!industry) {
        errors.industry = "Major is required";
        isValid = false;
      }
  
      if (!aboutClient || aboutClient.trim() === "") {
        errors.aboutClient = "About the Department is required";
        isValid = false;
      } else if (aboutClient.trim().length > 5000) {
        errors.aboutClient = "About the Department cannot exceed 5000 characters";
        isValid = false;
      }
  
      if (!engagementLeader) {
        errors.engagementLeader = "HOD is required";
        isValid = false;
      }
  
      setFormErrors(errors);
  
      if (!isValid) {
        setIsDisabled(false);
        setIsSaving(false);
        return;
      }
  
      try {
        const response = await apiClient.post("/client/add", {
          clientId: null,
          code: clientCode.trim(),
          clientName: clientName.trim(),
          about: aboutClient.trim(),
          effectiveDt: "15-08-1986",
          status: "1",
          endDt: null,
          poc: null,
          designationId: null,
          engagementLead: parseInt(engagementLeader.value, 10),
          engagementEmail: engagementLeader.email,
          domainId: parseInt(industry.value, 10),
          organizationId: 1,
          clientType: null,
          globalClient: null,
          towerId: null,
          serviceTypeId: null,
          poLocId: null,
          penaltyClauseApplicable: null,
          userId: state.user.Id,
        });
  
        if (response?.status === 200) {
          if (response.data.client === "Can't allow duplicate entry, Please Check You") {
            Alert("error", "Can't allow duplicate Department!");
          } else {
            const serverPath = process.env.REACT_APP_API_URL;
  
            const attachmentPromises = image.map((row) => {
              let formData = new FormData();
              formData.append("image", row);
              formData.append("attachmentTypeId", 1);
              formData.append("clientId", response.data.client[0].LV_Id);
              formData.append("userId", state.user.Id);
  
              axios.defaults.headers.common["Authorization"] = state?.token;
              return axios.post(`${serverPath}/client/attachment/add`, formData);
            });
  
            await Promise.all(attachmentPromises);
  
            Alert("succ", "Department added successfully");
            navigate(Routes.SearchClient);
            dispatch(unsavedChange(false));
          }
        } else {
          Alert("error", "Please Try Again!...");
        }
      } catch (err) {
        Alert("error", "Please Try Again");
      } finally {
        setIsDisabled(false);
        setIsSaving(false);
      }
    };
  
    await SaveAddClient();
  };
    const removeFile = (i) => {
    let sno = 0;
    let resarr = [];
    image.map((row, key) => {
      if (i !== key) resarr[sno++] = row;
    });
    setImage(resarr);
  };


  useEffect(() => {
    const allInputValuesselect = inputRefs.current.map((ref, index) => {
      if (Array.isArray(ref?.props?.value)) {
        const trimmedValues = ref.props.value.map((selectedOption) => {
          return selectedOption.label ? selectedOption.label.trim() : '';
        });
        return trimmedValues;
      } else {
        const value = ref?.props?.value;
        const trimmedValue = value && value.label ? value.label.trim() : '';
        return trimmedValue;
      }
    });  
    
    const allInputValues = inputRefs.current.map((ref) => {
      return ref.value ? ref.value.trim() : '';
    });
    
    const lengthsOfValuesSelect = allInputValuesselect.map((value) => value.length);
    const lengthsOfValues = allInputValues.map((value) => value.length);
    
    const totalLengthSelect = lengthsOfValuesSelect.reduce((acc, length) => acc + length, 0);
    const totalLength = lengthsOfValues.reduce((acc, length) => acc + length, 0);
    
    const Total = totalLengthSelect + totalLength;
    setTotalChange(Total);
  
    if (Total >= 4 && Total !== 5 || image.length > 0) {
      dispatch(unsavedChange(true));
    } else {
      dispatch(unsavedChange(false));
    }
  });
  

  const handleGetInputValues = (e) => {
    e.preventDefault()

    if (totalChange >= 4 && totalChange !==5 || image.length > 0) {
      Swal.fire({
        title: "Discard Changes?",
        text: "Do you want to save the changes ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1658a0",
        cancelButtonColor: "#d33",
        confirmButtonText: "Continue",
        cancelButtonText: "Discard ",
        allowOutsideClick: false,
        allowEscapeKey: false  
      }).then((result) => {
        if (result.isConfirmed) {
          // handleSubmit();
        } else {
          navigate(Routes.SearchClient);
          dispatch(unsavedChange(false));
        }
      });
    } else {
      navigate(Routes.SearchClient);
    }
  };
useEffect(()=>{
  const errors = {};
  if (clientName?.length > 100) {
    errors.clientName = "Department Name cannot exceed 100 characters";
  }
  if (clientCode?.length > 100) {
    errors.clientCode = "Department Code cannot exceed 100 characters";
  }
  if (clientPOC?.length > 100) {
    errors.clientPOC = "Department POC cannot exceed 100 characters";
  }
  setFormErrors(errors);
},[clientName,clientCode,clientPOC])
  return (
    <>
      <div className="maincontent__breadcrumb">
        <img
          className="cursor_pointer"
          src={HomeOutlineIcon}
          alt="home"
          onClick={() => {
            navigate(state.defaultpage);
          }}
        />
        <span className="maincontent__breadcrumb--divider">/</span>
        <span
          className="cursor_pointer"
          onClick={() => {
            navigate(Routes.SearchClient);
          }}
        >
          Department Management
        </span>
        <span className="maincontent__breadcrumb--divider">/</span>
        <span className="maincontent__breadcrumb--active">Add Department</span>
      </div>
      <div className="maincontent__card--body">
        <div className="maincontent__card--header">
          <h2 className="maincontent__card--header-title">Add Department</h2>
        </div>
        <div border="light" className="maincontent__card--content">
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={3} className="mb-3">
                <Form.Group id="clientName">
                  <Form.Label>Department Name <span className="required">*</span></Form.Label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text icon-container">
                        <img src={ClientNameIcon} className="input-icon" />
                      </span>
                    </div>
                    <Form.Control
                      // required
                      type="text"
                      className="input-font"
                      value={clientName}
                      onChange={(e) => setClientName(e.target.value)}
                      onFocus={() => handleInputFocus("clientName")}
                      ref={(el) => (inputRefs.current[0] = el)}
                    />
                  </div>
                  {formErrors.clientName && (
                    <div className="text-danger">{formErrors.clientName}</div>
                  )}
                </Form.Group>
              </Col>

              <Col md={3} className="mb-3">
                <Form.Group id="clientCode">
                  <Form.Label>Department Code <span className="required">*</span></Form.Label>
                  <div className="dropdown-container">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text icon-container">
                          <img src={ClientCodeIcon} className="input-icon" />
                        </span>
                      </div>
                      <Form.Control
                        // required
                        type="text"
                        value={clientCode}
                        onChange={(e) => setClientCode(e.target.value)}
                        onFocus={() => handleInputFocus("clientCode")}
                        ref={(el) => (inputRefs.current[1] = el)}
                      />
                    </div>
                  </div>
                  {formErrors.clientCode && (
                    <div className="text-danger">{formErrors.clientCode}</div>
                  )}
                </Form.Group>
              </Col>
              <Col md={3} className="mb-3">
                <Form.Group id="industry">
                  <Form.Label>Major <span className="required">*</span></Form.Label>
                  <div className="dropdown-container">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text icon-container">
                          <img src={IndustryIcon} className="input-icon" />
                        </span>
                      </div>
                      <Select
                        options={industryLookup}
                        value={industry}
                        onChange={(e) => setindustry(e)}
                        onFocus={() => handleInputFocus("industry")}
                        ref={(el) => (inputRefs.current[2] = el)}
                      />
                    </div>
                  </div>
                  {formErrors.industry && (
                    <div className="text-danger">{formErrors.industry}</div>
                  )}
                </Form.Group>
              </Col>
              <Col md={3} className="mb-3">
                <Form.Label>HOD <span className="required">*</span></Form.Label>
                <div className="dropdown-container">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text icon-container">
                        <img
                          src={EngagementLeaderIcon}
                          className="input-icon"
                        />
                      </span>
                    </div>
                    <Select
                      options={engagementList}
                      value={engagementLeader}
                      onChange={(e) => {
                        setEngagementLeader(e);
                      }}
                      onFocus={() => handleInputFocus("engagementLeader")}
                      ref={(el) => (inputRefs.current[8] = el)}
                    />
                  </div>
                  {formErrors.engagementLeader && (
                    <div className="text-danger">
                      {formErrors.engagementLeader}
                    </div>
                  )}
                </div>
              </Col>
              {/* <Col md={3} className="mb-3">
                <Form.Group id="clientEffectiveDate">
                  <Form.Label>Department Effective Date <span className="required">*</span></Form.Label>
                  <div className="dropdown-container">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text icon-container">
                          <img
                            src={ClientEffectCodeIcon}
                            className="input-icon"
                          />
                        </span>
                      </div>
                      <Form.Control
                        type="date"
                        value={clientEffectiveDate}
                        onKeyDown={(e) => e.preventDefault()}
                        onChange={(e) =>
                          setclientEffectiveDate(e.target.value)
                        }
                        onFocus={() =>
                          handleInputFocus("clientEffectiveDate")
                        }
                        min={new Date().toISOString().split('T')[0]} // Set the minimum date to today
                        ref={(el) => (inputRefs.current[3] = el)}
                      />
                    </div>
                  </div>
                  {formErrors.clientEffectiveDate && (
                    <div className="text-danger">
                      {formErrors.clientEffectiveDate}
                    </div>
                  )}
                </Form.Group>
              </Col> */}
            </Row>
            <Row>
              <Col md={12}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>About The Department <span className="required">*</span></Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={aboutClient}
                    onChange={(e) => setAboutClient(e.target.value)}
                    onFocus={() => handleInputFocus("aboutClient")}
                    ref={(el) => (inputRefs.current[4] = el)}
                  />
                  {formErrors.aboutClient && (
                    <div className="text-danger">
                      {formErrors.aboutClient}
                    </div>
                  )}
                </Form.Group>
              </Col>
            </Row>
            {/* <Row> */}
              {/* <Col md={3} className="mb-3">
                <Form.Label>Department Type <span className="required">*</span></Form.Label>
                <div className="dropdown-container">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text icon-container">
                        <img src={ClientTypeIcon} className="input-icon" />
                      </span>
                    </div>
                    <Select
                      options={clientTypeList}
                      value={clientType}
                      onChange={(e) => {
                        setClientType(e);
                      }}
                      onFocus={() => handleInputFocus("clientType")}
                      ref={(el) => (inputRefs.current[5] = el)}
                    />
                  </div>
                  {formErrors.clientType && (
                    <div className="text-danger">{formErrors.clientType}</div>
                  )}
                </div>
              </Col>

              <Col md={3} className="mb-3">
                <Form.Label>Department POC <span className="required">*</span></Form.Label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text icon-container">
                      <FontAwesomeIcon icon={faUser} />
                    </span>
                  </div>
                  <Form.Control
                    type="text"
                    value={clientPOC}
                    onChange={(e) => setClientPOC(e.target.value)}
                    onFocus={() => handleInputFocus("clientPOC")}
                    ref={(el) => (inputRefs.current[6] = el)}
                  />
                </div>
                {formErrors.clientPOC && (
                  <div className="text-danger">{formErrors.clientPOC}</div>
                )}
              </Col>

              <Col md={3} className="mb-3">
                <Form.Label>Department  POC Designation <span className="required">*</span></Form.Label>
                <div className="dropdown-container">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text icon-container">
                        <img
                          src={ClientDesignationIcon}
                          className="input-icon"
                        />
                      </span>
                    </div>
                    <Select
                      options={clientDesignationList}
                      value={clientDesignation}
                      onChange={(e) => {
                        setClientDesignation(e);
                      }}
                      onFocus={() => handleInputFocus("clientDesignation")}
                      ref={(el) => (inputRefs.current[7] = el)}
                    />
                  </div>
                  {formErrors.clientDesignation && (
                    <div className="text-danger">
                      {formErrors.clientDesignation}
                    </div>
                  )}
                </div>
              </Col> */}
            {/* </Row> */}

            {/* <Row>
              <Col md={3} className="mb-3">
                <Form.Label>Service Type <span className="required">*</span></Form.Label>
                <div className="dropdown-container">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text icon-container">
                        <img src={ServiceTypeIcon} className="input-icon" />
                      </span>
                    </div>
                    <Select
                      options={serviceList}
                      value={serviceType}
                      onChange={(e) => {
                        setServiceType(e);
                      }}
                      onFocus={() => handleInputFocus("serviceType")}
                      ref={(el) => (inputRefs.current[9] = el)}
                    />
                  </div>
                  {formErrors.serviceType && (
                    <div className="text-danger">
                      {formErrors.serviceType}
                    </div>
                  )}
                </div>
              </Col>

              <Col md={3} className="mb-3">
                <Form.Label>Capability <span className="required">*</span></Form.Label>
                <div className="dropdown-container">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text icon-container">
                        <img src={CapabilityIcon} className="input-icon" />
                      </span>
                    </div>
                    <Select
                      options={capabilityList}
                      value={capability}
                      onChange={(e) => {
                        setCapability(e);
                      }}
                      onFocus={() => handleInputFocus("capability")}
                      ref={(el) => (inputRefs.current[10] = el)}
                    />
                  </div>
                </div>
                {formErrors.capability && (
                  <div className="text-danger">{formErrors.capability}</div>
                )}
              </Col>
              <Col md={3} className="mb-3">
                <Form.Label>PO Location <span className="required">*</span></Form.Label>
                <div className="dropdown-container">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text icon-container">
                        <img src={POLocationIcon} className="input-icon" />
                      </span>
                    </div>
                    <Select
                      options={poLocationList}
                      value={poLocation}
                      onChange={(e) => {
                        setPoLocation(e);
                      }}
                      onFocus={() => handleInputFocus("poLocation")}
                      ref={(el) => (inputRefs.current[11] = el)}
                    />
                  </div>
                  {formErrors.poLocation && (
                    <div className="text-danger">{formErrors.poLocation}</div>
                  )}
                </div>
              </Col>

              <Col md={3} className="mb-3">
                <Form.Label>Delivery Location <span className="required">*</span></Form.Label>
                <div className="dropdown-container">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text icon-container">
                        <img
                          src={DeliveryLocationIcon}
                          className="input-icon"
                        />
                      </span>
                    </div>
                    <Select
                      options={deliveryLocationList}
                      value={deliveryLocation}
                      onChange={(selectedOptions) => {
                        setDeliveryLocation(selectedOptions);
                      }}
                      onFocus={() => handleInputFocus("deliveryLocation")}
                      ref={(el) => (inputRefs.current[12] = el)}
                      isMulti
                    />
                  </div>
                </div>
                {formErrors.deliveryLocation && (
                  <div className="text-danger">
                    {formErrors.deliveryLocation}
                  </div>
                )}
              </Col>
            </Row> */}

            {/* <div className="flex flex-col justify-start gap-4 mt-4 mb-4 md:flex-row"> */}
              {/* <div className="flex items-center justify-start gap-4 cursor-pointer">
                  <p>Penalty Clause if applicable as per SOW</p>
                  <div class="form-check form-switch">
                    {!penaltyClause && (
                      <label
                        class="form-check-label"
                        for="flexSwitchCheckChecked"
                      >
                        No
                      </label>
                    )}
                    <input
                      class="form-check-input cursor-pointer"
                      type="checkbox"
                      id="flexSwitchCheckChecked"
                      value={penaltyClause}
                      onChange={() => {
                        setPenaltyClause(!penaltyClause);
                      }}
                      checked={penaltyClause ? "checked" : ""}
                    />
                    {penaltyClause && (
                      <label
                        class="form-check-label"
                        for="flexSwitchCheckChecked"
                      >
                        Yes
                      </label>
                    )}
                  </div>
                </div> */}
              {/* <div className="flex items-center justify-start gap-4">
                <p>Global Department</p>
                <div class="form-check form-switch">
                  <input
                    class="form-check-input cursor-pointer"
                    type="checkbox"
                    id="flexSwitchCheckDefault"
                    value={globalClient}
                    onChange={() => {
                      setGlobalClient(!globalClient);
                    }}
                    checked={globalClient ? "checked" : ""}
                    ref={(el) => (inputRefs.current[13] = el)}
                  />
                  {globalClient && (
                    <label
                      class="form-check-label"
                      for="flexSwitchCheckDefault"
                    >
                      Yes
                    </label>
                  )}
                  {!globalClient && (
                    <label
                      class="form-check-label"
                      for="flexSwitchCheckDefault"
                    >
                      No
                    </label>
                  )}
                </div>
              </div>
            </div> */}

            <Accordion defaultActiveKey="0" className="mt-4">
              <Accordion.Item eventKey="1">
                <Accordion.Header className="accordionheader">
                  Add Attachments
                </Accordion.Header>
                <Accordion.Body
                  className="accordionnew"
                  style={{
                    visibility: "visible",
                    color: "#1658a0",
                  }}
                >
                  <div className="cursor-pointer">
                    <DropzoneComponent image={image} setImage={setImage} />
                  </div>

                  <div className="flex flex-col">
                    {image &&
                      image?.map((attachment, i) => (
                        <>
                          <div className="d-flex" key={i}>
                            <ul class="list-disc">
                              <li>
                                <span>
                                  {attachment.name}
                                  <FontAwesomeIcon
                                    onClick={() => removeFile(i)}
                                    icon={faTrashAlt}
                                    style={{
                                      marginLeft: "15px",
                                      cursor: "pointer",
                                      color: "red",
                                    }}
                                  />
                                </span>
                              </li>
                            </ul>
                          </div>
                        </>
                      ))}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            <div className="flex flex-row items-center justify-end md:justify-end mt-4 md:mt-8 gap-2">
              <button
                className="maincontent__btn maincontent__btn--primaryblue"
                disabled={isDisabled}
                type="submit"
              // onClick={() => SaveAddClient()}
              >
                {isSaving ? "Saving..." : "Save"}
              </button>
              <button
                className="maincontent__btn maincontent__btn--primaryblue"
                onClick={(e) => {
                  // navigate(Routes.SearchClient);
                  handleGetInputValues(e)
                }}
              >
                Back
              </button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  ...state,
});
export default connect(mapStateToProps)(AddClient);
