import React, { useEffect, useState } from "react";
import apiClient from "../../../common/http-common";
import "../CreateRoles/createrole.css";
import { useNavigate, useLocation } from "react-router-dom";
import { Routes } from "../../../routes";
import HomeOutlineIcon from "../../../assets/img/icons/home-outline-icon.svg";
import {  Form } from "@themesberg/react-bootstrap";
import { Alert } from "../../../components/Alert";
import { connect } from "react-redux";
import Swal from "sweetalert2";
 
const CreateBadges = (state) => {
  const location = useLocation();
  const [logUserId] = useState(state?.user?.Id);
  const [badgename, setBadgename] = useState(location?.state?.param.Name ? location?.state?.param.Name : "");
  const [minrange, setMinRange] = useState(location?.state?.param.MinRange != undefined ? location?.state?.param.MinRange : "");
  const [maxrange, setMaxRange] = useState(location?.state?.param.MaxRange != undefined ? location?.state?.param.MaxRange : "");
  const navigate = useNavigate();
  const BadgeId = location?.state?.param ? location?.state?.param.Id : null;
  const CreatedBy = location?.state?.param.CreatedBy ? location?.state?.param.CreatedBy : logUserId ; 
  const ModifiedBy = location?.state?.param.ModifiedBy ? location?.state?.param.ModifiedBy : logUserId ; 
  const [isdisabled, setIsdisabled] = useState(false);
  
  const handleKeyDown = (e) => {
    if (e.key === "Enter"&& !isdisabled) {
      e.preventDefault();
      postBadge();
    }
  };
 
  const postBadge = () => {
    if (saveButtonText === "Saving") {
        return;
    } else if (badgename.trim() === ""){
        Alert("warn", "Badge Name is required");
        return;
    } else if (!minrange) {
        Alert("warn", "Minimum Range is required");
        return;
    } else if ( !maxrange) {
        Alert("warn", "Maximum Range is required");
        return;
    } else {
        setSaveButtonText("Saving");

        apiClient.post("/kmarticle/km_article_badges_apply", {
          badgeId : BadgeId,
          createdBy : CreatedBy,
          modifiedBy : ModifiedBy,
          badgeName : badgename,
          minRange : minrange,
          maxRange : maxrange
        })
        .then((res) => {
            setSaveButtonText("Save");
            Alert("succ", "Badge Added Successfully");
            navigate(Routes.Badges);
        })
        .catch((err) => {
            Alert("error", err);
            setSaveButtonText("Save");
        })
    }
};
 
  // Add state for the button text
  const [saveButtonText, setSaveButtonText] = useState('Save');

  const handleCloseRole = () => {
    setShowDefault(false), setShowDef(false)
    if (badgename.trim().length > 0 || minrange.trim().length > 0 || maxrange.trim().length > 0) {
      Swal.fire({
        title: "Discard Changes?",
        text: "Do you want to save the changes ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1658a0",
        cancelButtonColor: "#d33",
        confirmButtonText: "Save ",
        cancelButtonText: "Discard ",
        allowOutsideClick: false,
        allowEscapeKey: false  
      }).then((result) => {
        if (result.isConfirmed) {
          // Save changes and navigate away
          postBadge();
        } else {
          navigate(Routes.Badges);
        }
      });
    } else {
      navigate(Routes.Badges);
    }
  };

   
  return (
    <div>
      <div className="maincontent__breadcrumb">
        <img
          className="cursor_pointer"
          src={HomeOutlineIcon}
          alt="home"
          onClick={() => {
            navigate(state.defaultpage);
          }}
        />
        <span className="maincontent__breadcrumb--divider">/</span>
        <span
          className="cursor_pointer"
          onClick={() => {
            navigate(Routes.Badges);
          }}
        >
          Badge Configuration
        </span>
        <span className="maincontent__breadcrumb--divider">/</span>
        <span className="maincontent__breadcrumb--active">Create Badge</span>
      </div>
 
      <div className="mt-4 maincontent__card--body">
        <div className="maincontent__card--header">
          <h2 className="maincontent__card--header-title">Create Badge</h2>
        </div>
 
        <div className="maincontent__card--content ">
          <Form className="grid grid-cols-1 gap-4 md:grid-cols-3">
            <Form.Group className="mb-3 mb3form">
              <Form.Label>Badge Name <span className="required">*</span></Form.Label>
              <Form.Control
                className="formconus"
                value={badgename}
                onChange={(e) => setBadgename(e.target.value)}
                onKeyDown={handleKeyDown}
                type="text"
                placeholder="Badge Name"
              />
            </Form.Group>
 
            <Form.Group className="mb-3 mb3form">
              <Form.Label>Minimum Range <span className="required">*</span></Form.Label>
              <Form.Control
                className="formconus"
                value={minrange}
                onChange={(e) => setMinRange(e.target.value)}
                onKeyDown={handleKeyDown}
                type="number"
                placeholder="Minimum Range"
                min='0'
                onKeyPress={(e) => {
                    const charCode = e.charCode || e.keyCode;
                    const inputValue = e.target.value + String.fromCharCode(charCode);
                    if (!/^[0-9]\d*$/.test(inputValue)) {
                      e.preventDefault();
                    }
                  }}
              />
            </Form.Group>
            <Form.Group className="mb-3 mb3form">
              <Form.Label>Maximum Range <span className="required">*</span></Form.Label>
              <Form.Control
                className="formconus"
                value={maxrange}
                onChange={(e) => setMaxRange(e.target.value)}
                onKeyDown={handleKeyDown}
                type="number"
                placeholder="Maximum Range"
                min='0'
                onKeyPress={(e) => {
                    const charCode = e.charCode || e.keyCode;
                    const inputValue = e.target.value + String.fromCharCode(charCode);
                    if (!/^[0-9]\d*$/.test(inputValue)) {
                      e.preventDefault();
                    }
                  }}
              />
            </Form.Group>
          </Form>
          <div className="flex justify-center gap-4 mt-4 md:justify-end">
          <button
              className="maincontent__btn maincontent__btn--primaryblue"
              onClick={() => postBadge()}
              disabled={isdisabled}
            >
              {saveButtonText}
            </button>
            <button
              className="maincontent__btn maincontent__btn--primaryblue"
              onClick={() => {
                // handleCloseRole()
                navigate(Routes.Badges);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
 
const mapStateToProps = (state) => ({
  ...state,
});
export default connect(mapStateToProps)(CreateBadges);