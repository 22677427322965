import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate,useLocation } from "react-router-dom";
import "react-toggle/style.css";
import DataTable from "react-data-table-component";
import apiClient from "../../../common/http-common";
import { Card } from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Routes } from "../../../routes";
import { Alert } from "../../../components/Alert";
import HomeOutlineIcon from "../../../assets/img/icons/home-outline-icon.svg";
import DownloadIcon from "../../../assets/img/new-dashboard/download-icon.svg";
import { faPenToSquare, faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import Papa from "papaparse";
import { yyyymmdd,datetimeClockFormat } from "../../../common/Helper";
const SharePointRuntimeHistory = (state) => {
    const location = useLocation();
    const Id = location?.state?.Id ? location?.state?.Id : null;
    let currentpath = window.location.hash.replace("#", "");
    let haumbstatus = state.defaultpage === currentpath ? false : true;
    const [isAdmin] = useState(state.isAdmin);
    const [HideHomeBreadCumb] = useState(haumbstatus)
    const navigate = useNavigate();
    const [logUserId] = useState(state?.user?.Id);
    const [articleList, setArticleList] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [tableData, setTableData] = useState([]);


    const [loading, setLoading] = useState(true);

    const fetchArticleList = async () => {
        setLoading(true);
        try {
            const response = await apiClient.post("chat/sharepoint_runtime_history_get", { Id: Id});
            const articles = response.data;
            setArticleList(articles);
            setTableData(articles);
            setLoading(false);
        } catch (error) {
            Alert("error", error.message);
        }
    };

    useEffect(() => {
        fetchArticleList();
    }, []);



    // const handleSearch = (event) => {
    //     setSearchValue(event.target.value);
    //     filterData(event.target.value);
    // };

    // const filterData = (value) => {
    //     const lowerCaseValue = value.toLowerCase().trim();
    //     const filteredData = tableData.filter(
    //         (item) =>
    //             item.Title.toLowerCase().includes(lowerCaseValue) ||
    //             item.SpeakerName.toLowerCase().includes(lowerCaseValue) ||
    //             item.Location.toLowerCase().includes(lowerCaseValue)

    //     );
    //     setArticleList(filteredData);
    // };

    // function getStatusText(status, row) {
    //     const dateFor = new Date();
    //     const currentDate =
    //       dateFor.getFullYear() +
    //       "-" +
    //       (dateFor.getMonth() + 1).toString().padStart(2, "0") +
    //       "-" +
    //       dateFor.getDate().toString().padStart(2, "0");
    //     const startDate = yyyymmdd(row.Start_Date);
    //     const endDate = yyyymmdd(row.End_Date);
    //     const startTime = row.Start_Time;
    //     const endTime = row.End_Time;
    //     const hours = dateFor.getHours().toString().padStart(2, "0");
    //     const minutes = dateFor.getMinutes().toString().padStart(2, "0");
    //     const seconds = dateFor.getSeconds().toString().padStart(2, "0");
    //     const currentTime = hours + ":" + minutes + ":" + seconds;
      
    //     if (status === 2) {
    //       return "Cancelled";
    //     } else if (currentDate > endDate || (currentDate === endDate && currentTime > endTime)) {
    //       return "Completed";
    //     } else if (
    //       currentDate >= startDate &&
    //       currentTime >= startTime &&
    //       currentDate <= endDate &&
    //       startTime <= endTime
    //     ) {
    //       return "Ongoing";
    //     } else {
    //       return "Scheduled";
    //     }
    //   }    

    // function handleExport(data) {
    //     const headers = [
    //       { label: "Event Name", key: "Title" },
    //       { label : "Speaker Name", key : "SpeakerName"},
    //       { label: "Start Date", key: "Start_Date" },
    //       { label: "End Date", key: "End_Date" },
    //       { label: "Start Time", key: "Start_Time" },
    //       { label: "End Time", key: "End_Time" },
    //       { label: "Location", key: "Location" },
    //       { label : "Registered  Users", key : "ParticipatedUsers"},
    //       { label: "Status", key: "Status" }, // Add Status to headers
    //     ];
      
    //     const exportData = data.map((row) =>
    //       headers.reduce((acc, header) => {
    //         if (header.key === "Start_Date" || header.key === "End_Date") {
    //           acc[header.label] = formatDate(row[header.key]);
    //         } else if (header.key === "Start_Time" || header.key === "End_Time") {
    //           acc[header.label] = formatTime(row[header.key]);
    //         }  else if (header.key === "Status") {
    //           acc[header.label] = getStatusText(row["Status"], row); // Pass entire row to getStatusText
    //         } else {
    //           acc[header.label] = row[header.key];
    //         }
    //         return acc;
    //       }, {})
    //     );
      
    //     const csvData = Papa.unparse(exportData);
    //     const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
    //     saveAs(blob, "Events List.csv");
    //   }


    function formatTime(timeString) {
        const utcTimeString = timeString;
        const utcTime = new Date(utcTimeString);
        const istTime = new Date(utcTime.toLocaleString("en-IN", { timeZone: "Asia/Kolkata" }));
        
        const formattedTime = istTime.toLocaleTimeString([], {
          hour: "numeric",
          minute: "2-digit",
          hour12: true,
          timeZone: "Asia/Kolkata" // Indian Standard Time (IST)
        });
        
        return formattedTime;
      }
      
      

      const columns = [
        {
          name: "Date",
          id: "Date",
          sortable: true,
          selector: (param) => param.Runtime,
          format: (param) => {
            if (param.Runtime) {
              const date = new Date(param.Runtime);
              const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
              return formattedDate;
            } else {
              return "";
            }
          },
        },
        {
          name: "Runtime",
          sortable: true,
          selector: (param) => param.Runtime,
          format: (param) => {
            if (!param.Runtime) {
              return <span title="NA">NA</span>;
            } else {
              const date = new Date(param.Runtime);
              const formattedTime = date.toLocaleTimeString([], { hour: "numeric", minute: "2-digit", hour12: true });
              return formattedTime;
            }
          },
        },
        {
            name: "No.of Artifacts",
            selector: (param) => param.article,
            format: (param) => (
                <span title={param.article}>{param.article}</span>
            ),
            sortable: true,
        },    
        {
            name : "Actions",
            selector : (param) => (
                <FontAwesomeIcon
                  style={{ color: "#1658a0", cursor: 'pointer' }}
                  icon={faEye}
                  onClick={() => navigate(Routes.SharePointArticleRuntime, {
                    state: {
                      Id : param.Id,
                      ShareId : Id,
                    },
                  })}
                  title="Runtime Article History"
                />
            )
          },
      
      ];
            
    return (
        <>
            <div className=" mt-2 flex flex-col lg:flex-row justify-between items-start lg:items-center justify-between">
                {HideHomeBreadCumb && (
                    <div className="maincontent__breadcrumb mb-2 lg:mb-0">
                        <img
                            className="cursor_pointer"
                            src={HomeOutlineIcon}
                            alt="home"
                            onClick={() => {
                                navigate(state.defaultpage);
                            }}
                        />
                        <span className="maincontent__breadcrumb--divider">/</span>
                        <span>SharePoint Runtime History List</span>
                    </div>
                )}

                {/* <div className="flex  gap-2 mt-2 lg:mt-0">
                    <div className="relative search-containerKMArti kmarticle-seactform mb-2 lg:mb-0">
                        <input
                            type="search"
                            placeholder="Search by Name, Speaker Name, Location"
                            className="w-full pt-2 pb-2 pl-2 pr-[26%] text-xs border-0 rounded-[28px] outline-0 h-[34px]"
                            value={searchValue}
                            onChange={handleSearch}
                        />
                        <button
                            type="button"
                            className="absolute top-1 right-1 bg-[rgba(0,0,0,60%)] rounded-[28px] h-[26px] text-white text-[10px] font-bold button"
                        >
                            Search
                        </button>
                    </div>
                    <Link className="flexVerandHorCenter ml-auto">
                        <img
                            src={DownloadIcon}
                            onClick={() => handleExport(articleList)}
                            className="p-2 bg-[rgba(0,0,0,60%)] rounded-md"
                        ></img>
                    </Link>
                </div> */}
            </div>

            <div className=" mt-2 maincontent__card--body">
                <div className="maincontent__card--content">
                    <Card.Body id="unpublishedDatatble" align="center">
                        {loading ? (
                            <div class="circle__loader"></div>
                        ) : (
                            <DataTable
                                columns={columns}
                                data={articleList}
                                highlightOnHover
                                pagination
                                paginationRowsPerPageOptions={[5, 10, 15]}
                                paginationPerPage={10}
                                defaultSortFieldId="Date"
                                defaultSortAsc={false}
                            />

                        )}
                    </Card.Body>
                    <div className="flex justify-center gap-4 mt-8 lg:justify-end">
                        <button onClick={() => { navigate(Routes.Integration);}}className="maincontent__btn maincontent__btn--primaryblue space_btn">
                            Back
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    ...state,
});
export default connect(mapStateToProps)(SharePointRuntimeHistory);
