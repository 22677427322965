export const addUser = (user) => ({
  type: "USER",
  user,
});

export const userRewardPoints = (rewardPoints) => ({
  type: "REWARDPOINTS",
  rewardPoints,
});

export const addRoles = (roles) => ({
  type: "ROLES",
  roles,
});

export const collapseIcon = (collapse) => ({
  type: "COLLAPSE",
  collapse,
});

export const defaultPage = (defaultpage) => ({
  type: "DEFAULTPAGE",
  defaultpage,
});

export const isAdmin = (isAdmin) => ({
  type: "ISADMIN",
  isAdmin,
});

export const leveDetails = (levels) => ({
  type: "LEVELS",
  levels,
});

export const rewardPoints = (rewards) => ({
  type: "REWARDS",
  rewards,
});

export const showSideBar = (showSideBar) => ({
  type: "SIDEBARSHOW",
  showSideBar,
});

export const approveReview = (approveReview) => ({
  type: "APPROVEREVIEW",
  approveReview,
});

export const token = (token) => ({
  type: "TOKEN",
  token,
});

export const systemDetails = (systemDetails) => ({
  type: "SYSTEMDETAILS",
  systemDetails,
});

export const unsavedChange = (unsavedChange) => ({
  type: "UNSAVEDCHANGE",
  unsavedChange,
});

export const badgesDetails = (badgesDetails) => ({
  type: "BADGESDETAILS",
  badgesDetails,
});

export const geniePopup = (geniePopup) => ({
  type: "GENIEPOPUP",
  geniePopup,
});