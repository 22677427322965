import React, { useState, useEffect } from "react";
import ClientNameIcon from "../../assets/img/icons/project-management/icon-clientname.svg";
import ProjectNameIcon from "../../assets/img/icons/project-management/icon-projectname.svg";
import ProjectTypeIcon from "../../assets/img/icons/project-management/icon-project-type.svg";
import ProjectStatusIcon from "../../assets/img/icons/project-management/icon-project-status.svg";
import DownloadIcon from "../../assets/img/new-dashboard/download-icon.svg";
import ProfilePic from "../../assets/img/profile.jpg";
import HeartIcon from "../../assets/img/heart.svg";
import ChartCard from "../KnowledgeManagement/ChartCard";
import apiClient from "../../common/http-common";
import LineChart from "../components/LineChart";
import PieChartNew from "../components/piechartNew";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import Papa from "papaparse";
import { Alert } from "../../components/Alert";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../routes";
import Select from "react-select";
import reset from "../../assets/img/brand/reseticon.svg";
import { Modal, Form, Accordion } from "@themesberg/react-bootstrap";
import { connect } from "react-redux";
import StackBarChart from "../components/StackBarChart";
import ActiveUsersIcon from "../../assets/img/new_design/active-users-icon.svg";
import TotalArtifactsIcon from "../../assets/img/new_design/totalartifacts-icon.svg";
import GenAISearchesIcon from "../../assets/img/new_design/genai-icon.svg";
import KnowledgeCreditsIcon from "../../assets/img/new_design/knowledge-credits-icon.svg";
import AverageRateIcon from "../../assets/img/new_design/average-icon.svg";
import TotalEventsIcon from "../../assets/img/new_design/total-events-icon.svg";
import ChampionIcon from "../../assets/img/new_design/champion-icon.svg";
import EmployeeIcon from "../../assets/img/new_design/employee-icon.svg";
import EnrichingTeamIcon from "../../assets/img/new_design/enrichingteam-icon.svg";
import LeadingRegionIcon from "../../assets/img/new_design/leading-icon.svg";
import ViewArticle from "../KnowledgeManagement/ViewArticle";
import PieChart1 from "../components/PieChart1";
import PermissionGuard from "../../common/gaurd/PermissionGuard";


const NewDashboard = ({ user }) => {
  const [logUserId] = useState(user?.Id);
  let [isClientLoading, setIsClientLoading] = useState(true);
  let [isengageLoading, setIsengageLoading] = useState(true);
  let [isClassLoading, setIsClassLoading] = useState(true);
  let [isContributionSummary, setIsContributionSummary] = useState(true);
  const [engagements, setEngagements] = useState([]);
  const [casestudies, setCasestudies] = useState([]);
  const [knowledgewise, setKnowledgewise] = useState([]);
  let [isLoading, setIsLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [vxcount, setvxcount] = useState([]);
  const [overallContribution, setOverallContribution] = useState([]);
  const [popularArticle, setPopularArticle] = useState([]);
  const [classification, setClassification] = useState([]);
  // let [topContributorslist, setTopContributorslist] = useState([])
  let [filteredData, setFilteredData] = useState(tableData);
  const currentDate = new Date();
  const newMonth = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  const newYear = currentDate.getFullYear().toString();
  let [month, setMonth] = useState();
  // let [month, setMonth] = useState(`${newYear}-${newMonth}`);
  const [isInitialReset, setIsInitialReset] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  let [lastmonthArticleCount, setLastMonthArticleCount] = useState(0);
  let [lastUsersCount, setLastUsersCount] = useState(0);
  let [lastCaseStudyCount, setLastCaseStudyCount] = useState(0);
  let [lastBPCount, setLastBPCount] = useState(0);
  let [lastArtifactConsumption, setLastArtifactConsumption] = useState(0);
  let [lastKnowledgeCredits, setLastKnowledgeCredits] = useState(0);
  let [lastEventCount, setLastEventCount] = useState(0);
  let [lastCACount, setLastCACount] = useState(0);
  const [badgesCount, setBadgesCount] = useState([]);
  const navigate = useNavigate();
  const [articleViewDetails, setArticleViewDetails] = useState({});
  const [showDefault, setShowDefault] = useState(false);
  const [ShowDef, setShowDef] = useState(false);
  const handleClose = () => (
    setShowDefault(false), setShowDef(false), setArticleViewDetails({})
  );

  let [projScore, setProjScore] = useState([]);
  const [upvotes, setUpvotes] = useState(0);
  const [downvotes, setDownvotes] = useState(0);

  let [addMyDashboardPermission, setAddMyDashboardPermission] = useState(false);
  function capitalizeFirstLetter(str) {
    return str ? str.charAt(0).toUpperCase() + str.slice(1) : '';
  }
  useEffect(() => {
    if (articleViewDetails?.Id) {
      setShowDefault(true);
    }
  }, [articleViewDetails]);

  const handleArticleClick = (row) => {
    setArticleViewDetails(row);
    setShowDefault(true);
  };

  useEffect(async () => {
    addMyDashboardPermission = await PermissionGuard(Routes.NewArticles);
    setAddMyDashboardPermission(addMyDashboardPermission);
  }, []);

  let [cardValue, setCardValue] = useState({
    totalusers: 0,
    activeusers: 0,
    totalactiveusers: 0,
    starproject: "",
    totalarticle: 0,
    ArtifactsPublishedCount: 0,
    casestudies: 0,
    bestpractices: 0,
    starofmonth: "",
    starofmonthRegion: "",
    starofmonthProjectName: "",
    starprojectRegion: "",
    regionwiseusrCount: 0,
    article_percent: 0,
    activeusers_precent: 0,
    articale_status: "",
    user_percent: 0,
    user_status: "",
    case_percent: 0,
    casestudies_status: "",
    practice_percent: 0,
    bestpractices_status: "",
    leadingRegioncount: "",
    knowledgeCredits: 0,
    KnowledgeCredits_precent: 0,
    knowledgeCredits_status: "",
    artifactConsumption: 0,
    artifactConsumption_precent: 0,
    artifactConsumption_status: "",
    enthusiastBadge: 0,
    catalystBadge: 0,
    proBadge: 0,
    championBadge: 0,
    EventCount: 0,
    EventCount_precent: 0,
    EventCount_status: "",
    CACount: 0,
    CACount_precent: 0,
    CACount_status: "",
    knowledgeCreditsContri: 0,
    knowledgeCreditsConsumption: 0,
    knowledgeCreditsTotalPoints: 0,
    leadingRegionData: [],
    SearchCountData: [],
    EventsData: [],
    enrichingclass : {},
    populararticle : {},
  });

  const [selectVal, setSelectVal] = useState({
    client: { value: null, label: "All" },
    project: { value: null, label: "All" },
    category: { value: null, label: "All" },
    domain: { value: null, label: "All" },
    region: { value: null, label: "All" },
    month: { value: null, label: "All" },
    article : { value : null, label: "All"},
  });

  const [dateValues, setDateValues] = useState({
    pyear: null,
    pmonth: null,
    lastMonth: null,
    lastYear: null
  });

  const [postClientList, setPostClientList] = useState([]);
  const [clientList, setClientList] = useState([{ value: null, label: "All" }]);
  const [projectList, setProjectList] = useState([
    { value: null, label: "All" },
  ]);
  const [categoryList, setCategoryList] = useState([
    { value: null, label: "All" },
  ]);
  const [artifactList, setArtifactList] = useState([
    { value: null, label: "All" },
  ]);

  const [postcategoryList, setPostCategoryList] = useState([]);
  const [domainList, setDomainList] = useState([{ value: null, label: "All" }]);
  const [regionList, setRegionList] = useState([{ value: null, label: "All" }]);
  let [badgeValues, setBadgeValues] = useState({
    Enthusiast: "0",
    Catalyst: "0",
    Pro: "0",
    Champion: "0",
  });
  const [highestBadge, setHighestBadge] = useState();
  let [sortedBadges, setSortedBadges] = useState([]);
  const [ParticipationIndex, setParticipationIndex] = useState();
  const [TotalEvents, setTotalEvents] = useState();

  const resetFilters = () => {
    setIsLoading(true);
    setIsengageLoading(true);
    setIsClientLoading(true);
    setIsClassLoading(true);
    selectVal.client.value = null;
    selectVal.project.value = null;
    selectVal.category.value = null;
    selectVal.domain.value = null;
    selectVal.region.value = null;
    selectVal.month.value = null;
    selectVal.article.value = null;
    setSelectVal({
      client: { value: null, label: "All" },
      project: { value: null, label: "All" },
      category: { value: null, label: "All" },
      domain: { value: null, label: "All" },
      region: { value: null, label: "All" },
      month: { value: null, label: "All" },
      article : {value: null, label : "All"}
    });
    // month = `${newYear}-${newMonth}`;
    // setMonth(`${newYear}-${newMonth}`);
    month = "";
    setMonth("");
    setTimeout(() => {
      filterDashboard();
    }, 2000);
  };

  // const [eventsCount, setEventCount] = useState(0);

  useEffect(() => {
    handleCmt();
    // apiClient
    //   .post("kmarticle/eventList")
    //   .then((res) => {
    //     const eventsWithStatus1 = res.data.filter(
    //       (event) => event.Status === 1
    //     );
    //     setEventCount(eventsWithStatus1.length);
    //   })
    //   .catch((err) => {});

    apiClient
      .get("/lookup/ArticalCategory/1")
      .then((response) => {
        let arr = [{ value: null, label: "All" }];
        let carr = [];
        if (response.data.lookup.length > 0) {
          response.data.lookup.forEach((element) => {
            arr.push({ value: element.Id, label: element.Name });
            carr.push({ value: element.Id, label: element.Name });
          });
        }
        setCategoryList(arr);
        setPostCategoryList(carr);
      })
      .catch(() => {
        Alert("error", "Please Try Again");
      });

      apiClient
      .get("/lookup/ArticleType/1")
      .then((response) => {
        let arr = [{ value: null, label: "All" }];
        if (response.data.lookup.length > 0) {
          response.data.lookup.forEach((element) => {
            arr.push({ value: element.Id, label: element.Name });
          });
        }
        setArtifactList(arr);
      })
      .catch(() => {
        Alert("error", "Please Try Again");
      });

    apiClient
      .get("/lookup/domain/1")
      .then((response) => {
        let arr = [{ value: null, label: "All" }];
        if (response.data.lookup.length > 0) {
          response.data.lookup.forEach((element) => {
            arr.push({ value: element.Id, label: element.Name });
          });
        }
        setDomainList(arr);
      })
      .catch(() => {
        Alert("error", "Please Try Again");
      });

    apiClient
      .get("/lookup/PoLocation/1")
      .then((response) => {
        let arr = [{ value: null, label: "All" }];
        if (response.data.lookup.length > 0) {
          response.data.lookup.forEach((element) => {
            arr.push({ value: element.Id, label: element.Name });
          });
        }
        setRegionList(arr);
      })
      .catch(() => {
        Alert("error", "Please Try Again");
      });

    apiClient
      .post("project/searchall", {})
      .then((response) => {
        let arr = [{ value: null, label: "All" }];
        let parr = [];
        if (response.data.project.length > 0) {
          response.data.project.forEach((element) => {
            arr.push({ value: element.id, label: element.Name });
            parr.push({ value: element.id, label: element.Name });
          });
        }
        setProjectList(arr);
        setPostProjectList(parr);
      })
      .catch(() => {});

    // apiClient
    //   .post("kmarticle/topContributors")
    //   .then((res) => {
    //     setTopContributorslist(res.data)
    //   })
    //   .catch((err) => { })

    apiClient
      .post("/client/search", {
        clientId: "0",
        domainId: "0",
        towerId: "0",
        organizationId: "0",
        userId: "0",
      })
      .then((response) => {
        let arr = [{ value: null, label: "All" }];
        let parr = [];
        if (response.data.client.length > 0) {
          response.data.client.forEach((element) => {
            arr.push({ value: element.Id, label: element.Name });
            parr.push({ value: element.Id, label: element.Name });
          });
        }
        setClientList(arr);
        setPostClientList(parr);
      })
      .catch((error) => {
        Alert("error", "Please Try Again");
      });
    filterDashboard();
  }, []);

  let [SearchSuccessCount, setSearchSuccessCount] = useState(0);

  

  const filterDashboard = () => {
    setIsLoading(true);
    setIsengageLoading(true);
    setIsClientLoading(true);
    setIsClassLoading(true);
    setIsContributionSummary(true);

    let lastMonth,
      lastYear = null;

    let pmonth,
      pyear = null;
    if (month) {
      const lastMonthDate = new Date(month);
      lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);
      lastMonth = lastMonthDate.getMonth() + 1;
      lastYear = lastMonthDate.getFullYear();

      if (month.split("-")[1]) pmonth = month.split("-")[1];
      if (month.split("-")[0]) pyear = month.split("-")[0];
    }
    setDateValues({
      ...dateValues,
      pyear,
      pmonth,
      lastMonth,
      lastYear
    });
  
    
    const data = {
      month: pmonth,
      year: pyear,
      lastMonth: lastMonth,
      lastYear: lastYear,
      projectId: selectVal.project.value,
      ClientId: selectVal.client.value,
      CategoryId: selectVal.category.value,
      domainId: selectVal.domain.value,
      regionId: selectVal.region.value,
      type : selectVal.article.value,
    };

    apiClient
      .post("kmarticle/getsuccsearchcount ", {
        year: parseInt(pyear, 10),
        month: parseInt(pmonth, 10),
      })
      .then((res) => {
        setSearchSuccessCount(res.data[0]?.SearchSuccessCount);
      })
      .catch((err) => {
        Alert("error",err);
      });

    // Request for upvotes
    apiClient
      .post("kmarticle/kmarticle_get_up_down_vote", {
        type: 1,
        month: pmonth,
        year: pyear,
        articleType : selectVal.article.value,
      })
      .then((res) => {
        const upvotes =
          res.data.data.length > 0 && res.data.data[0].votes !== undefined
            ? res.data.data[0].votes
            : 0;
        setUpvotes(upvotes);
      })
      .catch((err) => {
        Alert("error",err)
      });

    // Request for downvotes
    apiClient
      .post("kmarticle/kmarticle_get_up_down_vote", {
        type: 2,
        month: pmonth,
        year: pyear,
        articleType: selectVal.article.value,
      })
      .then((res) => {
        const downvotes =
          res.data.data.length > 0 && res.data.data[0].votes !== undefined
            ? res.data.data[0].votes
            : 0;
        setDownvotes(downvotes);
      })
      .catch((err) => {
        Alert("error", err);
      });

    apiClient
      .post("kmarticle/kmdashboardContributionSummary", data)
      .then((res) => {
        setIsContributionSummary(false);
        setProjScore(res.data.teamcontribution);
        setFilteredData(res.data.teamcontribution);
        setTableData(res.data.teamcontribution);
      })
      .catch((err) => {
        setIsContributionSummary(false);
        setProjScore([]);
        setFilteredData([]);
        setTableData([]);
      });
    apiClient.post("kmarticle/kmdashboard", data).then((res) => {
      let kmartical = res.data.kmartical[0];
      //Total Article
      let totalarticle = kmartical.totalarticle;
      let ArtifactsPublishedCount = kmartical.ArtifactsPublishedCount;
      //Active users
      let activeusers = kmartical.activeusers;
      //Total Users
      let totalusers = kmartical.totalusers;
      //Case Studies
      let casestudies = kmartical.casestudies;
      //Best Practices
      let bestpractices = kmartical.bestpractices;
      let artifactConsumption = kmartical.artifactConsumption;
      let knowledgeCredits = kmartical.knowledgeCredits;
      let EventCount = kmartical.EventCount;
      let CACount = kmartical.customerAccolades;
      let enthusiastBadge = kmartical.enthusiastBadge;
      let catalystBadge = kmartical.catalystBadge;
      let proBadge = kmartical.proBadge;
      let championBadge = kmartical.championBadge;

      const updatedCardValue = {
        totalusers: totalusers || 0,
        activeusers: activeusers || 0,
        totalactiveusers: kmartical.totalactiveusers || 0,
        totalarticle: totalarticle || 0,
        ArtifactsPublishedCount: ArtifactsPublishedCount || 0,
        casestudies: casestudies || 0,
        starproject: kmartical.starproject || "",
        bestpractices: bestpractices || 0,
        starofmonth: kmartical.starofmonth || "",
        starofmonthRegion: kmartical.starofmonthRegion || "",
        starprojectRegion: kmartical.starprojectRegion || "",
        starofmonthProjectName: kmartical.starofmonthProjectName || "",
        leadingRegioncount: kmartical.leadingRegioncount || "",
        regionwiseusrCount: kmartical.leadingRegionuserscount || 0,
        knowledgeCredits: kmartical.knowledgeCredits || 0,
        artifactConsumption: kmartical.artifactConsumption || 0,
        enthusiastBadge: enthusiastBadge || 0,
        catalystBadge: catalystBadge || 0,
        proBadge: proBadge || 0,
        championBadge: championBadge || 0,
        EventCount: EventCount || 0,
        CACount: CACount || 0,
        knowledgeCreditsContri: kmartical.knowledgeCreditsContri || 0,
        knowledgeCreditsConsumption: kmartical.knowledgeCreditsConsumption || 0,
        knowledgeCreditsTotalPoints: kmartical.knowledgeCreditsTotalPoints || 0,
        leadingRegionData: kmartical.leadingRegionData || [],
        SearchCountData: kmartical.SearchCountData || [],
        EventsData: kmartical.EventsData || [],
        enrichingclass : kmartical.enrichingclass || {},
        populararticle : kmartical.populararticle || {},
      };
      setCardValue(updatedCardValue);

      const totalRegisteredUsers = updatedCardValue?.EventsData[0]?.ParticipationCount || 0;
      const totalActiveUsers = updatedCardValue.activeusers;

      let overallPercentage = 0;
      
      if (totalActiveUsers > 0) {
          overallPercentage = Math.round((totalRegisteredUsers / totalActiveUsers) * 100);
      }
      
      overallPercentage = Math.max(0, Math.min(overallPercentage, 100));
      setTotalEvents(updatedCardValue.EventsData.length);
      setParticipationIndex(overallPercentage);
      setIsLoading(false);
    })
    .catch((error) => {
      Alert("error", "Please Try Again")
    });

    apiClient
      .post("user/get_users_badges_details", {
        domainId: selectVal.domain.value,
        regionId: selectVal.region.value,
        month : pmonth,
        year : pyear,
        ClientId : selectVal.client.value,
        projectId : selectVal.project.value,
        CategoryId : selectVal.category.value,
      })
      .then((res) => {
        setBadgesCount(res.data.user);
      })
      .catch((err) => {
        Alert("error", err);
      });

    apiClient.post("kmarticle/kmdashboardcasestudies", data).then((res) => {
      if (res.data.engagement.length > 0) {
        let earr = res.data.engagement.map((row) => ({
          label: row.month,
          value: row.count,
          color: "#9de6a1",
        }));
        setEngagements(earr);
      } else {
        setEngagements([]);
      }
      if (res.data.search.length > 0) {
        let earr = res.data.search.map((row) => ({
          label: row.month,
          value: row.search_count,
          color: "#9de6a1",
        }));
        setCasestudies(earr);
      } else {
        setCasestudies([]);
      }
      setIsengageLoading(false);
    })
    .catch((error) => {
      Alert("error", "Please Try Again");
    });

    apiClient.post("kmarticle/get_usersdata_barchart", data).then((res) => {
      const transformedData = res.data.reduce((result, item) => {
        const { month, total_count, active_count } = item;
        const inactive_count = total_count - active_count;

        const existingItem = result.find((entry) => entry.label === month);

        if (existingItem) {
          existingItem.active_count = active_count;
          existingItem.inactive_count = inactive_count;
        } else {
          // const newItem = { label: month, active_count, inactive_count };
          const newItem = {
            label: month,
            "Active Student": active_count,
            "Inactive Student": inactive_count,
          };

          result.push(newItem);
        }

        return result;
      }, []);

      setOverallContribution(transformedData);
      setIsClientLoading(false);
    })
    .catch(()=> {
      Alert("error", "Please Try Again");
    });
  };

  useEffect(() => {
    setIsClassLoading(true);
  
    if (badgesCount.length > 0) {
      const totalBadgeCount = badgesCount.reduce((acc, curr) => acc + curr.count, 0);
  
      const badgeValues = {};
      const classification = [];
      const colarr = ["#7F0127", "#8B4A9E", "#5A9ECB", "#EC954E", "#00b4b0"];
  
      for (const badge of badgesCount) {
        const percentage = (badge.count / totalBadgeCount) * 100;
        badgeValues[badge.Badge] = Number(Math.abs(percentage)).toFixed(0);
  
        if (badgeValues[badge.Badge] > 0) {
          classification.push({
            label: badge.Badge.charAt(0).toUpperCase() + badge.Badge.slice(1), // Capitalize first letter
            value: Number(Math.abs(percentage)).toFixed(0),
            color: colarr.pop(), // Pop a color from the array
          });
        }
      }
  
      setBadgeValues(badgeValues);
      setClassification(classification);
      setIsClassLoading(false);
  
      const badgeEntries = Object.entries(badgeValues);
      badgeEntries.sort((a, b) => b[1] - a[1]);
  
      setHighestBadge(badgeEntries[0][0]);
  
      const sortedBadges = badgeEntries.slice(1).map(([badge, value]) => {
        return { badge, value };
      });
      setSortedBadges(sortedBadges);
    } else {
      setBadgeValues({});
      setClassification([]);
      setIsClassLoading(false);
      setHighestBadge("");
      setSortedBadges([]);
    }
  }, [badgesCount]);
  
  //active users calc
  const totalUsers = cardValue.totalusers;
  const activeUsers = cardValue.activeusers;
  const totalactiveusers = cardValue.totalactiveusers;

  const percentageActiveUsers =
    Math.round((activeUsers / totalUsers) * 100) || 0;

  let [regUsers, setRegUsers] = useState(0);
  let [searchCount, setSearchCount] = useState(0);
  let [searchSuccCount, setSearchSuccCount] = useState(0);
  let [regionwiseuserCount, setregionwiseuserCount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiClient.get("kmarticle/registereduserscount");
        setRegUsers(response.data[0]?.TotalUserCount);
      } catch (error) {
        Alert("error", error);
      }
    };
    fetchData();
  }, []);

  let regionUserCount = cardValue.regionwiseusrCount;

  const GensearchCount = cardValue.SearchCountData[0]?.SearchCount;

  const GenSuccCount = SearchSuccessCount;

  let clickThruRate =
    GensearchCount > 0
      ? Math.min(
          100,
          Math.max(0, Math.round((GenSuccCount / GensearchCount) * 100))
        ) || 0
      : 0;

  let regionwiseusrCount = Math.round((regionUserCount / totalUsers) * 100);

  let [maxCNTValue, setMaxCNTValue] = useState(0);

  useEffect(() => {
    let totalMaxCNT = cardValue.leadingRegionData.reduce(
      (sum, data) => sum + data.maxCNT,
      0
    );
    setMaxCNTValue(totalMaxCNT);
  }, [cardValue.leadingRegionData]); // Add cardValue.leadingRegionData as a dependency

  // Now you can use maxCNTValue in your calculation
  let leadingRegionContri =
    Math.min(
      100,
      Math.max(
        0,
        Math.round((cardValue.leadingRegionData[0]?.maxCNT / maxCNTValue) * 100)
      )
    ) || 0;

  const totalVotes = upvotes + downvotes;
  const satisfactionRate =
    totalVotes > 0 ? Math.round((upvotes / totalVotes) * 100) : 0;

  let kcConsumption = cardValue.knowledgeCreditsConsumption;
  let kcContribution = cardValue.knowledgeCreditsContri;
  let kcTotalPoints = cardValue.knowledgeCreditsTotalPoints;

  const kCConsumption = Math.min(
    100,
    Math.max(0, Math.round((kcConsumption / kcTotalPoints) * 100))
  );
  const kCContribution = Math.min(
    100,
    Math.max(0, Math.round((kcContribution / kcTotalPoints) * 100))
  );

  const highestTotalPublishedProject = projScore.reduce(
    (maxProject, currentProject) => {
      return currentProject?.current_published > maxProject?.current_published
        ? currentProject
        : maxProject;
    },
    projScore[0]
  );

  let ProjContribution;

  if (cardValue.ArtifactsPublishedCount !== 0) {
    ProjContribution = Math.min(
      Math.round(
        (highestTotalPublishedProject?.current_published /
          cardValue?.ArtifactsPublishedCount) *
          100
      ) || 0,
      100
    );
  } else {
    ProjContribution = 0;
  }

  const handleSearch = (event) => {
    setSearchValue(event.target.value);
    filterData(event.target.value);
  };
  function handleExport(data) {
    const headers = [
      { label: "Student", key: "team" },
      { label: "Class", key: "ProjectName" },
      { label: "Department", key: "ClientName" },
      { label: "Total ", key: "totalpublished" },
      { label: "Submitted", key: "current_submitted" },
      { label: "Published", key: "current_published" },
    ];

    const exportData = data.map((row) =>
      headers.reduce((acc, header) => {
        if (row[header.key] === "start_date" || row[header.key] === "end_date")
          acc[header.label] = changeStartDateFormat(row[header.key]);
        else acc[header.label] = row[header.key];
        return acc;
      }, {})
    );
    const csvData = Papa.unparse(exportData);

    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
    saveAs(blob, "Article Contribution Summary.csv");
  }
  const filterData = (value) => {
    const lowerCaseValue = value.toLowerCase().trim();
    const filtereddData = tableData.filter(
      (item) =>
        item.team.toLowerCase().includes(lowerCaseValue) ||
        item.ProjectName.toLowerCase().includes(lowerCaseValue) ||
        item.ClientName.toLowerCase().includes(lowerCaseValue)
    );
    setFilteredData(filtereddData);
  };

  const columns = [
    {
      name: "Student",
      selector: (param) => capitalizeFirstLetter(param?.team),
      sortable: true,
      filter: (
        <input
          type="text"
          placeholder="Search"
          value={searchValue}
          onChange={handleSearch}
        />
      ),
    },
    {
      name: "Class",
      selector: (param) => capitalizeFirstLetter(param?.ProjectName),
      sortable: true,
    },
    {
      name: "Department",
      selector: (param) => capitalizeFirstLetter(param?.ClientName),
      sortable: "true",
    },
    {
      name: "Total",
      selector: (param) => param?.totalpublished,
      sortable: "true",
    },
    {
      name: "Submitted",
      selector: (param) => param?.current_submitted,
      sortable: "true",
    },
    {
      name: "Published",
      selector: (param) => param?.current_published,
      sortable: "true",
    },
  ];

  function capitalizeFirstLetter(str) {
    return str ? str.charAt(0).toUpperCase() + str.slice(1) : '';
  }
  
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const toggleAccordion = () => {
    setIsAccordionOpen((prevState) => !prevState);
  };

  const [submittedCount, setSubmittedCount] = useState(0);
  useEffect(() => {
    apiClient
      .post("kmarticle/kmarticleunpublished", { UserId: logUserId })
      .then((res) => {
        setSubmittedCount(res?.data?.length);
      })
      .catch((err) => {
        Alert("error", err);
      });
  }, []);

  const handleCmt = () => {
    apiClient
      .post("kmarticle/latestArticlenew", {
        UserId: logUserId,
        year: currentDate.getFullYear(),
        month: currentDate.getMonth() + 1,
        sort: 2,
        limit: 5,
        start: 0,
        des: null,
        articleId: null,
      })
      .then((res) => {
        setPopularArticle(res.data.data);
      })
      .catch(() => {});
  };
  useEffect(() => {
    getKMWiseCHart();
  }, [badgeValues]);
  const [kmArticleRewardChart, setKmArticleRewardChart] = useState({
    label: [],
    value: [],
    color: [],
  });
  const getKMWiseCHart = () => {
    const transformedArray = Object.entries(badgeValues).map(
      ([badge, value]) => ({
        [badge]: value,
      })
    );
    const colarr = ["#7F0127", "#8B4A9E", "#5A9ECB", "#EC954E", "#00b4b0"];

    const transformedData = transformedArray.map((item, index) => {
      const label = Object.keys(item)[0];
      const value = parseInt(Object.values(item)[0], 10);
      const color = colarr[index];
      return {
        label: label,
        value: value,
        color: color,
      };
    });

    setKmArticleRewardChart(transformedData);
  };

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  const maxDate = `${currentYear}-${currentMonth.toString().padStart(2, '0')}`;

  return (
    <>
      <div className="flex justify-end mt-2">
      {addMyDashboardPermission && (
        <button
          className="mt-4 maincontent__btn maincontent__btn--primaryblue w-fit md:m-0"
          onClick={() => {
            navigate(Routes.NewArticles);
          }}
        >
          My Dashboard
        </button>
      )}
      </div>
      <Accordion activeKey={isAccordionOpen ? "1" : "0"} className="my-2">
        <Accordion.Item eventKey="1">
          <Accordion.Header
            onClick={toggleAccordion}
            className="accordionheader"
          >
            Search
          </Accordion.Header>
          <Accordion.Body
            style={{ visibility: "visible" }}
            className="accordionnew"
          >
            <div
              className="grid grid-cols-1 gap-3 md:grid-cols-3 lg:grid-cols-5"
              style={{ width: "97%" }}
            >
              {/* <Form.Group id="domain">
                <Form.Label>Domain</Form.Label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="inputIconCont input-group-text icon-container">
                      <img
                        src={ProjectTypeIcon}
                        alt="client name"
                        className="input-icon"
                      />
                    </span>
                  </div>
                  <Select
                    className="selectOptions"
                    options={domainList.map((domain) => ({
                      value: domain.value,
                      label: domain.label,
                    }))}
                    placeholder=""
                    value={selectVal.domain}
                    onChange={(e) => {
                      selectVal.domain = e;
                      setSelectVal({ ...selectVal, domain: e });
                      filterDashboard();
                    }}
                  />
                </div>
              </Form.Group> */}

              {/* <Form.Group id="region">
                <Form.Label>Region</Form.Label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="inputIconCont input-group-text icon-container">
                      <img
                        src={ProjectStatusIcon}
                        alt="client name"
                        className="input-icon"
                      />
                    </span>
                  </div>
                  <Select
                    className="selectOptions"
                    options={regionList.map((region) => ({
                      value: region.value,
                      label: region.label,
                    }))}
                    placeholder=""
                    value={selectVal.region}
                    onChange={(e) => {
                      selectVal.region = e;
                      setSelectVal({ ...selectVal, region: e });
                      filterDashboard();
                    }}
                  />
                </div>
              </Form.Group> */}

              <Form.Group id="client">
                <Form.Label>Department</Form.Label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="inputIconCont input-group-text icon-container">
                      <img
                        src={ClientNameIcon}
                        alt="client name"
                        className="input-icon"
                      />
                    </span>
                  </div>
                  <Select
                    className="selectOptions"
                    options={clientList.map((client) => ({
                      value: client.value,
                      label: client.label,
                    }))}
                    placeholder="Select Client"
                    value={selectVal.client}
                    onChange={(e) => {
                      selectVal.client = e;
                      setSelectVal({ ...selectVal, client: e });
                      filterDashboard();
                    }}
                  />
                </div>
              </Form.Group>

              <Form.Group id="project">
                <Form.Label>Class</Form.Label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="inputIconCont input-group-text icon-container">
                      <img
                        src={ProjectNameIcon}
                        alt="client name"
                        className="input-icon"
                      />
                    </span>
                  </div>
                  <Select
                    className="selectOptions"
                    options={projectList.map((project) => ({
                      value: project.value,
                      label: project.label,
                    }))}
                    placeholder=""
                    value={selectVal.project}
                    onChange={(e) => {
                      selectVal.project = e;
                      setSelectVal({ ...selectVal, project: e });
                      filterDashboard();
                    }}
                  />
                </div>
              </Form.Group>


              <Form.Group id="project">
                <Form.Label>Period</Form.Label>
                <div>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="inputIconCont input-group-text icon-container">
                        <img
                          src={ProjectStatusIcon}
                          alt="client name"
                          className="input-icon"
                        />
                      </span>
                    </div>
                    <Form.Control
                      className="selectOptions"
                      required
                      type="month"
                      value={month}
                      placeholder="mm/dd/yyyy"
                      onChange={(e) => {
                        month = e.target.value;
                        setMonth(e.target.value);
                        filterDashboard();
                      }}
                      max={maxDate}
                    />
                  </div>
                </div>
              </Form.Group>



              <Form.Group id="category">
                <Form.Label>Category</Form.Label>
                <div>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="inputIconCont input-group-text icon-container">
                        <img
                          src={ClientNameIcon}
                          alt="client name"
                          className="input-icon"
                        />
                      </span>
                    </div>
                    <Select
                      className="selectOptions"
                      options={categoryList.map((category) => ({
                        value: category.value,
                        label: category.label,
                      }))}
                      placeholder=""
                      value={selectVal.category}
                      onChange={(e) => {
                        selectVal.category = e;
                        setSelectVal({ ...selectVal, category: e });
                        filterDashboard();
                      }}
                    />
                  </div>
                </div>
              </Form.Group>
              <Form.Group id="category">
                <Form.Label>Artifacts Type</Form.Label>
                <div>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="inputIconCont input-group-text icon-container">
                        <img
                          src={ClientNameIcon}
                          alt="client name"
                          className="input-icon"
                        />
                      </span>
                    </div>
                    <Select
                      className="selectOptions"
                      options={artifactList.map((artifact) => ({
                        value: artifact.value,
                        label: artifact.label,
                      }))}
                      placeholder=""
                      value={selectVal.article}
                      onChange={(e) => {
                        selectVal.article = e;
                        setSelectVal({ ...selectVal, article: e });
                        filterDashboard();
                      }}
                    />
                  </div>
                  <img
                    className="resetIconKM"
                    style={{ color: "#1658a0", cursor: "pointer" }}
                    title="Reset"
                    src={reset}
                    onClick={() => {
                      resetFilters();
                    }}
                  />
                </div>
              </Form.Group>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-2 text-black">
        {isLoading ? (
          <div class="circle__loader items-center my-0 mx-auto"></div>
        ) : (
          <div className="overflow-hidden text-black bg-[#e6e8f0] maincontent__card--content maincontent__card--content-woheader cursor-pointer"  onClick={() => {
            navigate(Routes.UsersList, {
              state: {
                domain: selectVal.domain.value,
                region: selectVal.region.value,
                lastmonth: dateValues.lastMonth ? dateValues.lastMonth : null,
                lastyear : dateValues.lastYear ? dateValues.lastYear : null,
                month : dateValues.pmonth ? dateValues.pmonth : null,
                year : dateValues.pyear ? dateValues.pyear : null,
                client : selectVal.client.value,
                project : selectVal.project.value,
                category : selectVal.category.value,
                article : selectVal.article.value,
                card : "users"
              },
            });
          }}>
            <div className="flex flex-col gap-1 w75">
              <div className="flex flex-col gap-1">
                <span className="text-base font-bold text-[#2d9800]">
                  {cardValue.activeusers.toLocaleString()}
                </span>
                <span className="text-sm font-semibold">Active Students</span>
              </div>
              <span className="card__line"></span>
              <span className="text-[10px] whitespace-nowrap w-[130%] text-ellipsis overflow-hidden">
                {<>{percentageActiveUsers}% of Total Students</>}
              </span>
            </div>
            <div className="bg-[#d2d4db] maincontent__card--content-circleWithIcon bg-[rgba(0,0,0,10%)]"></div>
            <img
              src={ActiveUsersIcon}
              className="z-0 maincontent__card--content-icon"
            />
          </div>
        )}

        {isLoading ? (
          <div class="circle__loader items-center my-0 mx-auto"></div>
        ) : (
          <div className="overflow-hidden text-black maincontent__card--content maincontent__card--content-woheader bg-[#e0e2ea] cursor-pointer" onClick={() => {
            navigate(Routes.ArtifactList, {
              state: {
                domain: selectVal.domain.value,
                region: selectVal.region.value,
                lastmonth: dateValues.lastMonth ? dateValues.lastMonth : null,
                lastyear : dateValues.lastYear ? dateValues.lastYear : null,
                month : dateValues.pmonth ? dateValues.pmonth : null,
                year : dateValues.pyear ? dateValues.pyear : null,
                client : selectVal.client.value,
                project : selectVal.project.value,
                category : selectVal.category.value,
                article : selectVal.article.value,
                card : "artifact"
              },
            });
          }}>
            <div className="flex flex-col gap-1 w75">
              <div className="flex flex-col gap-1">
                <span className="text-base font-bold text-[#ff3169]">
                  {cardValue.totalarticle.toLocaleString()}
                </span>
                <span className="text-sm font-semibold">Total Artifacts</span>
              </div>
              <span className="card__line"></span>
              <span className="text-[10px] whitespace-nowrap w-[130%] text-ellipsis overflow-hidden">
                {
                  <>
                    Published:{" "}
                    {cardValue.ArtifactsPublishedCount.toLocaleString()}
                    {cardValue.totalarticle > 0 && (
                      <>
                        {" "}
                        (
                        {Math.round(
                          (cardValue.ArtifactsPublishedCount /
                            cardValue.totalarticle) *
                            100
                        )}
                        %)
                      </>
                    )}
                  </>
                }
              </span>
            </div>
            <div className="maincontent__card--content-circleWithIcon bg-[#d7d9e0]"></div>
            <img
              src={TotalArtifactsIcon}
              className="z-0 maincontent__card--content-icon"
            />
          </div>
        )}

        {isLoading ? (
          <div class="circle__loader items-center my-0 mx-auto"></div>
        ) : (
          <div className="overflow-hidden text-black bg-[#e6e8f0] maincontent__card--content maincontent__card--content-woheader cursor-pointer" onClick={() => {
            navigate(Routes.GenAiList, {
              state: {
                domain: selectVal.domain.value,
                region: selectVal.region.value,
                lastmonth: dateValues.lastMonth ? dateValues.lastMonth : null,
                lastyear : dateValues.lastYear ? dateValues.lastYear : null,
                month : dateValues.pmonth ? dateValues.pmonth : null,
                year : dateValues.pyear ? dateValues.pyear : null,
                client : selectVal.client.value,
                project : selectVal.project.value,
                category : selectVal.category.value,
                article : selectVal.article.value,
                card : "genAi"
              },
            });
          }}>
            <div className="flex flex-col gap-1 w75">
              <div className="flex flex-col gap-1">
                <span className="text-base font-bold text-[#00a0cf]">
                  {GensearchCount?.toLocaleString()}
                </span>
                <span className="text-sm font-semibold">GenAI Searches</span>
              </div>
              <span className="card__line"></span>
              <span className="text-[10px] whitespace-nowrap w-[130%] text-ellipsis overflow-hidden">
                {<>Click through rate: {clickThruRate}%</>}
              </span>
            </div>
            <div className="bg-[#d3d5dc] maincontent__card--content-circleWithIcon"></div>
            <img
              src={GenAISearchesIcon}
              className="z-0 maincontent__card--content-icon"
            />
          </div>
        )}
        {isLoading ? (
          <div class="circle__loader items-center my-0 mx-auto"></div>
        ) : (
          <div className="overflow-hidden text-black maincontent__card--content maincontent__card--content-woheader bg-[#e1e3ea] cursor-pointer" onClick={() => {
            navigate(Routes.UsersList, {
              state: {
                domain: selectVal.domain.value,
                region: selectVal.region.value,
                lastmonth: dateValues.lastMonth ? dateValues.lastMonth : null,
                lastyear : dateValues.lastYear ? dateValues.lastYear : null,
                month : dateValues.pmonth ? dateValues.pmonth : null,
                year : dateValues.pyear ? dateValues.pyear : null,
                client : selectVal.client.value,
                project : selectVal.project.value,
                category : selectVal.category.value,
                article : selectVal.article.value,
                card : "knowledgeCredits"
              },
            });
          }}>
            <div className="flex flex-col gap-1 w75">
              <div className="flex flex-col gap-1">
                <span className="text-base font-bold text-[#9f49a3]">
                  {cardValue.knowledgeCredits.toLocaleString()}
                </span>
                <span className="text-sm font-semibold">Knowledge Credits</span>
              </div>
              <span className="card__line"></span>
              <span className="text-[10px] whitespace-nowrap w-[130%] text-ellipsis overflow-hidden">
                {
                  <>
                    Contribution : {kCContribution ? kCContribution : 0}%;
                    Consumption : {kCConsumption ? kCConsumption : 0}%
                  </>
                }
              </span>
            </div>
            <div className="maincontent__card--content-circleWithIcon bg-[#d8dae1]"></div>
            <img
              src={KnowledgeCreditsIcon}
              className="z-0 maincontent__card--content-icon w-[32px]"
            />
          </div>
        )}

        {isLoading ? (
          <div class="circle__loader items-center my-0 mx-auto"></div>
        ) : (
          <div className="overflow-hidden text-black bg-[#e0e2ea] maincontent__card--content maincontent__card--content-woheader cursor-pointer" onClick={() => {
            navigate(Routes.ArtifactList, {
              state: {
                domain: selectVal.domain.value,
                region: selectVal.region.value,
                lastmonth: dateValues.lastMonth ? dateValues.lastMonth : null,
                lastyear : dateValues.lastYear ? dateValues.lastYear : null,
                // month : dateValues.pmonth ? dateValues.pmonth : null,
                // year : dateValues.pyear ? dateValues.pyear : null,
                month :  null,
                year :  null,
                client : selectVal.client.value,
                project : selectVal.project.value,
                category : selectVal.category.value,
                article : selectVal.article.value,
                card : "asr"
              },
            });
          }}>
            <div className="flex flex-col gap-1 w75">
              <div className="flex flex-col gap-1">
                <span className="text-base font-bold text-[#00aaa6]">
                  {satisfactionRate} %
                </span>
                <span className="text-sm font-semibold">
                  Average Satisfaction Rate
                </span>
              </div>
              <span className="card__line"></span>
              <span className="text-[10px] whitespace-nowrap w-[130%] text-ellipsis overflow-hidden">
                {
                  <>
                    Upvotes: {upvotes}; Downvotes: {downvotes}
                  </>
                }
              </span>
            </div>
            <div className="bg-[#d3d5dc] maincontent__card--content-circleWithIcon"></div>
            <img
              src={AverageRateIcon}
              className="z-0 maincontent__card--content-icon"
            />
          </div>
        )}

        {isLoading ? (
          <div class="circle__loader items-center my-0 mx-auto"></div>
        ) : (
          <div className="overflow-hidden text-black bg-[#e1e3ea] maincontent__card--content maincontent__card--content-woheader cursor-pointer" onClick={() => {
            navigate(Routes.EventsCard, {
              state: {
                domain: selectVal.domain.value,
                region: selectVal.region.value,
                lastmonth: dateValues.lastMonth ? dateValues.lastMonth : null,
                lastyear : dateValues.lastYear ? dateValues.lastYear : null,
                month : dateValues.pmonth ? dateValues.pmonth : null,
                year : dateValues.pyear ? dateValues.pyear : null,
                client : selectVal.client.value,
                project : selectVal.project.value,
                category : selectVal.category.value,
                article : selectVal.article.value,
              },
            });
          }}>
            <div className="flex flex-col gap-1 w75">
              <div className="flex flex-col gap-1">
                <span className="text-base font-bold text-[#00a0cf]">
                  {TotalEvents.toLocaleString()}
                </span>
                <span className="text-sm font-semibold"> Total Events</span>
              </div>
              <span className="card__line"></span>
              <span className="text-[10px] whitespace-nowrap w-[130%] text-ellipsis overflow-hidden">
                {<>Participation Index: {ParticipationIndex}&#37;</>}
              </span>
            </div>
            <div className="maincontent__card--content-circleWithIcon bg-[#d8dae1]"></div>
            <img
              src={TotalEventsIcon}
              className="z-0 maincontent__card--content-icon"
            />
          </div>
        )}
        {isLoading ? (
          <div class="circle__loader items-center my-0 mx-auto"></div>
        ) : (
          <div className="overflow-hidden text-black bg-[#e6e8f0] maincontent__card--content maincontent__card--content-woheader cursor-pointer"  onClick={() => {
            navigate(Routes.UsersList, {
              state: {
                domain: selectVal.domain.value,
                region: selectVal.region.value,
                lastmonth: dateValues.lastMonth ? dateValues.lastMonth : null,
                lastyear : dateValues.lastYear ? dateValues.lastYear : null,
                month : dateValues.pmonth ? dateValues.pmonth : null,
                year : dateValues.pyear ? dateValues.pyear : null,
                client : selectVal.client.value,
                project : selectVal.project.value,
                category : selectVal.category.value,
                article : selectVal.article.value,
                card : "badges"
              },
            });
          }}>
            <div className="flex flex-col gap-1 w75">
              <div className="flex flex-col gap-1">
                <span className="text-base font-bold text-[#9f49a3]">
                  {badgeValues[highestBadge]}%{" "}
                </span>
                <span className="text-sm font-semibold">{capitalizeFirstLetter(highestBadge)}</span>
              </div>
              <span className="card__line"></span>
              <span className="text-[10px] whitespace-nowrap w-[130%] text-ellipsis overflow-hidden">
                {sortedBadges.map((entry) => (
                  <span key={entry.badge}>
                    {capitalizeFirstLetter(entry.badge)}: {entry.value}%;{" "}
                  </span>
                ))}
              </span>
            </div>
            <div className="bg-[#d3d5dc] maincontent__card--content-circleWithIcon"></div>
            <img
              src={ChampionIcon}
              className="z-0 maincontent__card--content-icon w-[35px]"
            />
          </div>
        )}
        {isLoading ? (
          <div class="circle__loader items-center my-0 mx-auto"></div>
        ) : (
          <div className="overflow-hidden text-black bg-[#e1e3ea] maincontent__card--content maincontent__card--content-woheader  cursor-pointer" onClick={() => {
            navigate(Routes.StarMonth, {
              state: {
                domain: selectVal.domain.value,
                region: selectVal.region.value,
                lastmonth: dateValues.lastMonth ? dateValues.lastMonth : null,
                lastyear : dateValues.lastYear ? dateValues.lastYear : null,
                month : dateValues.pmonth ? dateValues.pmonth : null,
                year : dateValues.pyear ? dateValues.pyear : null,
                client : selectVal.client.value,
                project : selectVal.project.value,
                category : selectVal.category.value,
                article : selectVal.article.value,
              },
            });
          }}>
            <div className="flex flex-col gap-1 w75">
              <div className="flex flex-col gap-1">
                <span className="text-base font-bold text-[#2d9800]">
                  {capitalizeFirstLetter(cardValue.starofmonth)}
                </span>
                <span className="text-sm font-semibold">
                  Outstanding Contributor
                </span>
              </div>
              <span className="card__line"></span>
              <span className="text-[10px] whitespace-nowrap w-[130%] text-ellipsis overflow-hidden">
                {
                  <>
                    Class:{" "} 
                    {capitalizeFirstLetter(cardValue.starofmonthProjectName)}
                  </>
                }
              </span>
            </div>
            <div className="bg-[#d6d7de] maincontent__card--content-circleWithIcon maincontent__card--content-circleWithIcon-purple"></div>
            <img
              src={EmployeeIcon}
              className="z-0 maincontent__card--content-icon w-[30px]"
            />
          </div>
        )}

        {isLoading ? (
          <div class="circle__loader items-center my-0 mx-auto"></div>
        ) : (
          <div className="overflow-hidden text-black bg-[#e6e8f0] maincontent__card--content maincontent__card--content-woheader  cursor-pointer" onClick={() => {
            navigate(Routes.StarProject, {
              state: {
                domain: selectVal.domain.value,
                region: selectVal.region.value,
                lastmonth: dateValues.lastMonth ? dateValues.lastMonth : null,
                lastyear : dateValues.lastYear ? dateValues.lastYear : null,
                month : dateValues.pmonth ? dateValues.pmonth : null,
                year : dateValues.pyear ? dateValues.pyear : null,
                client : selectVal.client.value,
                project : selectVal.project.value,
                category : selectVal.category.value,
                article : selectVal.article.value,
              },
            });
          }}>
            <div className="flex flex-col gap-1 w75">
              <div className="flex flex-col gap-1">
                <span className="text-base font-bold text-[#00aaa6]">
                  {capitalizeFirstLetter(cardValue.enrichingclass.Name)}
                </span>
                <span className="text-sm font-semibold">Enriching Class</span>
              </div>
              <span className="card__line"></span>
              <span className="text-[10px] whitespace-nowrap w-[130%] text-ellipsis overflow-hidden">
                {
                  <>
                    Number of Articles: 
                    {cardValue.enrichingclass.ArticleCount}
                  </>
                }
              </span>
            </div>
            <div className="bg-[#cfd0d8] maincontent__card--content-circleWithIcon maincontent__card--content-circleWithIcon-litepurple"></div>
            <img
              src={EnrichingTeamIcon}
              className="z-0 maincontent__card--content-icon"
            />
          </div>
        )}
        {isLoading ? (
          <div class="circle__loader items-center my-0 mx-auto"></div>
        ) : (
          <div className="overflow-hidden text-black bg-[#e1e3ea] maincontent__card--content maincontent__card--content-woheader  cursor-pointer" onClick={() => {
            navigate(Routes.NewArticles,{
              state: {
                card : "new-dashboard"
              },
            })
          }}>
            <div className="flex flex-col gap-1 w75">
              <div className="flex flex-col gap-1">
                <span className="text-base font-bold text-[#fc3b70]">
                  {capitalizeFirstLetter(cardValue.populararticle.AuthorName)}
                </span>
                <span className="text-sm font-semibold">Popular Article</span>
              </div>
              <span className="card__line"></span>
              <span className="text-[10px] whitespace-nowrap w-[130%] text-ellipsis overflow-hidden">
                {
                  <>
                    Class: 
                    {cardValue.populararticle.ClassName}
                  </>
                }
              </span>
            </div>

            <div className="bg-[#ced0d7] maincontent__card--content-circleWithIcon"></div>
            <img
              src={LeadingRegionIcon}
              className="z-0 maincontent__card--content-icon"
            />
          </div>
        )}
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2 my-2 text-black">
        <div className="p-2 bg-[rgba(0,0,0,10%)] rounded-3xl h-[300px]">
          {isLoading ? (
            <div class="circle__loader items-center my-0 mx-auto"></div>
          ) : (
            <ChartCard title="Students Engagement">
              <StackBarChart data={overallContribution} title={"Artifacts"} />
            </ChartCard>
          )}
        </div>
        <div className="p-2 bg-[rgba(0,0,0,5%)] rounded-3xl h-[300px]">
          {isengageLoading ? (
            <div class="circle__loader items-center my-0 mx-auto"></div>
          ) : (
            <ChartCard title="Usage Summary">
              <LineChart
                data={engagements}
                casedata={casestudies}
                title={"Artifacts"}
              />
            </ChartCard>
          )}
        </div>
        <div className="p-2 bg-[rgba(0,0,0,5%)] rounded-3xl h-[300px]">
          {isLoading ? (
            <div class="circle__loader items-center my-0 mx-auto"></div>
          ) : (
            <ChartCard title="Knowledge Expertise">
              {/* <PieChart1 data={classification} title={""} /> */}
              <PieChartNew data={classification} title={""} />
            </ChartCard>
          )}
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-2 text-black">
        <div className="col-span-2 bg-[#e3e5ec] card__container rounded-3xl">
          <div className="bg-[#d0d2d9] card__header">Contribution Summary</div>
          <div className="card__header">
            <div className="maincontent__card--tableheader">
              <div className="maincontent__card--tableheader-right">
                <div className="relative search-containerKMArti kmarticle-seactform">
                  <input
                    type="search"
                    placeholder="Search by Student, Class, Department"
                    className="w-full pt-2 pb-2 pl-2 pr-[26%] text-xs border-0 rounded-[28px] outline-0 h-[34px]"
                    value={searchValue}
                    onChange={handleSearch}
                  />
                  <button
                    type="button"
                    className="absolute top-1 right-1 bg-[rgba(0,0,0,60%)] rounded-[28px] h-[26px] text-white text-[10px] font-bold button"
                  >
                    Search
                  </button>
                </div>
                <Link className="flexVerandHorCenter rounded-[5px] bg-[rgba(0,0,0,60%)]">
                {filteredData.length >0 &&(
                  <img
                    src={DownloadIcon}
                    onClick={() => handleExport(filteredData)}
                    className="p-2 bg-[0,0,0,60%] rounded-md"
                  ></img>
                                  )}
                </Link>
              </div>
            </div>
            {isContributionSummary ? (
              <div class="circle__loader items-center my-0 mx-auto"></div>
            ) : (
              <DataTable
                title=""
                columns={columns}
                data={filteredData}
                pagination
                paginationRowsPerPageOptions={[5, 10, 15]}
                paginationPerPage={5}
                highlightOnHover
                className="mt-4 kmdash_cont_table"
                defaultSortField="team"
                defaultSortAsc={true}
              />
            )}
          </div>
        </div>
        <Modal
          as={Modal.Dialog}
          centered
          show={showDefault}
          onHide={handleClose}
          backdrop="static"
        >
          <ViewArticle
            article={articleViewDetails}
            postRender={handleCmt}
            postVoteRender={handleCmt}
            onClose={handleClose}
          />
        </Modal>
        <div className="bg-[#e3e5ec] card__container card__container--articles">
          <div className="bg-[#d0d2d9] card__header">Popular Articles</div>
          <div className="round-3xl">
            {popularArticle.map((row, i) => {
              return (
                <>
                  <div className="card__articles--listsec" key={i}>
                    <div className="grid grid-cols-3 p-4">
                      <div className="flex items-center col-span-2 gap-2">
                        <img
                          src={row.Profile ? row.Profile : ProfilePic}
                          className="card__profilepic"
                        />
                        <div
                          className="card__articles--title cursor-pointer text-[13px]"
                          title={row.title}
                          onClick={(e) => handleArticleClick(row)}
                        >
                          {row.title.length > 25
                            ? row.title.substring(0, 25) + "..."
                            : row.title}
                        </div>
                      </div>
                      <div
                        className="flex items-center justify-end gap-2"
                        title="Like"
                      >
                        <img src={HeartIcon} />
                        <span className="text-purple-500">{row.Likes}</span>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
            {popularArticle.length === 0 ? (
              <span className="block mx-auto mt-4 text-xs font-semibold px-4 py-2">
                No Articles Found
              </span>
            ) : (
            addMyDashboardPermission && (
              <button
                className="px-4 py-2 m-4 text-xs font-semibold bg-[rgba(0,0,0,10%)] rounded-3xl"
                onClick={() => navigate(Routes.NewArticles,{
                  state: {
                    card : "new-dashboard"
                  },
                })}
              >
                READ MORE
              </button>
              )
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  rewards: state.rewards,
});

export default connect(mapStateToProps)(NewDashboard);
