import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import store from "../../store";

async function PermissionGuard(path) {
  let user = store.getState().user;
  let roles = store.getState().roles;
  let isAdmin = store.getState().isAdmin;

  try {
    if (isAdmin) return true;
    else {
      let findRole = roles.find((role) => role.Path === path);
      if (Object.keys(user).length === 0 || findRole === undefined) {
        return false;
      } else {
        return true;
      }
    }
  } catch (error) {
    return false;
  }
}

export default PermissionGuard;
