import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Routes } from "../../routes";
import "./Signin.css";
import apiClient from "../../common/http-common";
import addRewardPoints from "../../common/AddRewardPoints";
import { connect } from "react-redux";
import {
  addUser,
  addRoles,
  isAdmin,
  defaultPage,
  leveDetails,
  approveReview,
  rewardPoints,
  userRewardPoints,
  token,
  systemDetails,
  badgesDetails,
} from "../../actions/actions";
import { Routes as appRoutes } from "../../routes";
import { Alert } from "../../components/Alert";
import ElementsLogo from "../../assets/img/new-dashboard/5 Elements_update_final-01-01.png";
import KeyIcon from "../../assets/img/new_design/key-solid.svg";
import SSOAPP from "../components/SsoComponents";
import CryptoJS from 'crypto-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
const SignIn = ({ dispatch }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [pwd, setPwd] = useState("");
  const [message, setMessage] = useState("");
  const [apiError, setApiError] = useState();
  const [emailInputClass, setEmailInputClass] = useState();
  const [pwdInputClass, setPwdInputClass] = useState();
  const [isdisable, setIsDisable] = useState(true);
  const [emailError, setEmailError] = useState("");
  const [pwdError, setPwdError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [configSSO, setConfigSSO] = useState([]);
  const [ssoactive, setSSOActive] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);


  const emailValidation = (email) => {
    setEmail(email);
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!email) {
      setEmailError("Please enter Email Id");
      setApiError("");
      setEmailInputClass("input-error");
    } else if (regex.test(email) === false) {
      setEmailError("Please enter a valid Email Id");
      setApiError("");
      setEmailInputClass("input-error");
    } else {
      setEmailError("");
      setEmailInputClass("input-success");
      if (email && pwd) setIsDisable(false);
    }
  };

  const passwordValidation = (val) => {
    setPwd(val);
    if (!val) {
      setPwdError("Please enter Password");
      setPwdInputClass("input-error");
      setApiError("");
    } else {
      setPwdError("");
      setPwdInputClass("input-success");
      if (email && pwd) setIsDisable(false);
    }
  };

  const isFormValid = () => {
    if (email !== "" && pwd !== "") return true;
    else {
      emailValidation(email);
      passwordValidation(pwd);
    }
  };

  const fetchConfigList = async () => {
    try {
        const response = await apiClient.get("auth/sso_details_get_by_login");
        const ConfigSSO = response.data;
        if (ConfigSSO?.length > 0) {
        setSSOActive(ConfigSSO[0]?.active_status);
        setConfigSSO(ConfigSSO);
        } else {
          setSSOActive(false);
          setConfigSSO([]);
        }
    } catch (error) {
        Alert("error", error.message);
    }
};

useEffect(() => {
    fetchConfigList();
}, []);

useEffect(() => {
  setIsDisabled(isLoader);
}, [isLoader]);

  const [submitClicked, setSubmitClicked] = useState(false);


  let handleSubmitForSSO = async (userData) => {
        setSubmitClicked(true);

       var request = {
        access_Token: userData.accessToken,
      };

      apiClient
        .post("/auth/loginsso", request)
        .then((response) => {
          storeRedux(response)
        })
        .catch((err) => {
          Alert("error",err)
          setApiError("Bad Request!!");
        });
  };
  
async function storeRedux(response){
  if (response.data.user.error) {
    setApiError(response.data.user.error);
  } else {
    if (response.data.user.length > 0) {
      dispatch(addRoles(response.data.user));
      dispatch(token(response?.data?.token));
      dispatch(systemDetails(response?.data?.systemDetails));
      dispatch(badgesDetails(response?.data?.badgesDetails));
      apiClient.get(`/user`).then((response) => {
        if (response.data.data.user.error) {
          setApiError(response.data.data.user.error);
        } else {
          dispatch(
            userRewardPoints(
              response?.data?.data?.user[0]?.RewardPoints
            )
          );
          addRewardPoints(
            response.data.data.rewards
              ? response.data.data.rewards[0].Points
              : 0,
            response.data.data.user
              ? response.data.data.user[0].Id
              : null,
            response.data.data.rewards
              ? response.data.data.rewards[0].Id
              : null,
            response.data.data.user
              ? response.data.data.user[0].Id
              : null
          );
          dispatch(approveReview(response.data.data.reviewApprove));
          dispatch(leveDetails(response.data.data.level[0]));
          dispatch(rewardPoints(response.data.data.rewards));
          dispatch(
            isAdmin(
              response.data.data.rolelist.some(
                (item) => item.RoleId === 1
              )
            )
          );
          dispatch(addUser(response.data.data.user[0]));
          if (response.data.data.user[0].defaultPage) {
            dispatch(
              defaultPage(response.data.data.user[0].defaultPage)
            );
            navigate(response.data.data.user[0].defaultPage);
          } else {
            if (response.data.data.defaultPage) {
              dispatch(defaultPage(response.data.data.defaultPage));
              navigate(response.data.data.defaultPage);
            } else navigate(appRoutes.DefaultPage);
          }
        }
      });
    } else navigate(appRoutes.DefaultPage);
  }
}
  let handleSubmit = async (e) => {
    setIsLoader(true);
    e.preventDefault();
    setSubmitClicked(true);
    if (isFormValid() && !emailError && !pwdError) {
      var request = {
        email: CryptoJS.AES.encrypt(email, process.env.REACT_APP_USERKEY).toString(), 
        password: CryptoJS.AES.encrypt(pwd, process.env.REACT_APP_PASSKEY).toString(),
      };

      apiClient
        .post("/auth/login", request)
        .then((response) => {
          storeRedux(response)
          setIsLoader(false);
        })
        .catch((err) => {
          console.log(err);
          Alert("error",err)
          setIsLoader(false);
          setApiError("Bad Request!!");
        });
    } else {
      !email ? emailValidation() : passwordValidation();
    }
  };
  const togglePasswordVisibility = () => {
    if(pwd !== ""){
    setShowPassword(!showPassword);
    }
  };
  return (
    <>
      <div className="flex flex-col Main signin-bg">
        {/* <p className="flex justify-end w-full pr-8 text-white">New user? <a className="pl-2 underline"> Create an account</a></p> */}
        <div className="newUser"></div>
        <div className="w-2/3">
          <img
            src={ElementsLogo}
            className="w-[330px] pt-[1rem] pb-[2rem] my-0 mx-auto"
          />
          <div className="mainContainer bg-[rgba(255,255,255,70%)] rounded-[20px] p-8">
            <div className="flex flex-col w-full gap-4">
              <h5 className="font-medium text-[24px] text-[#202020]">
                {" "}
                Sign In
              </h5>
              {/* <div className="flex gap-4 bg-[#989898] p-4 rounded-xl">
                <img src={KeyIcon} />
                <span className="text-white">Sign up with SSO</span>
              </div>
              <div className="relative pt-[15px]">
                <hr/>
                <span className="absolute bg-[#ccc] top-[3px] right-[50%] pl-[12px] pr-[12px]">or</span>
              </div> */}
              <form onSubmit={handleSubmit}>
                <div className="midSection" id="signin">
                  <label className="pl-0 text-[#4a5073] text-sm font-semibold">
                    Email
                  </label>
                  <input
                    className={email ? emailInputClass : ""}
                    type="email"
                    value={email}
                    onChange={(e) => emailValidation(e.target.value)}
                  />
                  {submitClicked && emailError ? (
                    <div className="errorsignin">
                      <p>{emailError}</p>
                    </div>
                  ) : null}
                </div>
                <div className="midSection" id="signin">
                  <label className="pl-0 text-[#4a5073] text-sm font-semibold">
                    Password
                  </label>
                  <div className="relative">
                  <input
                      className={`${pwd ? pwdInputClass : ""} pr-[40px]`}
                      type={showPassword ? 'text' : 'password'}                    value={pwd}
                    autoComplete="off"
                    onChange={(e) => passwordValidation(e.target.value)}
                  />
               {pwd && pwd.length > 0 && (
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className="absolute right-0 flex items-center px-3 focus:outline-none"
              style={{ top: "50%", transform: "translateY(-50%)" }}
            >
              <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
            </button>
          )}
                    </div>
                  {submitClicked && pwdError ? (
                    <div className="errorsignin">
                      <p>{pwdError}</p>
                    </div>
                  ) : null}
                </div>
                {apiError ? (
                  <div className="errorsignin">
                    <p>{apiError}</p>
                  </div>
                ) : null}
                <div className="downSection">
                  <a
                    href="javascript:void(0)"
                    onClick={() => {
                      navigate(Routes.ForgotPassword);
                    }}
                  >
                    Forgot password?
                  </a>
                  <div className="flex gap-4">
                    {/* <button className="signinBtn bg-[#737373]" type="submit">
                      Register
                    </button> */}
                    <button className="signinBtn" type="submit" disabled={isLoader} >
                      {isLoader ? "Signing In..." : "Sign In"}
                    </button>
                  </div>
                </div>
              </form>
              
              <div className="message">{message ? <p>{message}</p> : null}</div>
              {/* {ssoactive && configSSO[0]?.api_id && configSSO[0]?.redirect_url && configSSO[0]?.tenant_id ? ( 
              
              <SSOAPP handleSubmit={handleSubmitForSSO} ssoConfig={configSSO[0]} />) : null } */}
            </div>{" "}
          </div>{" "}
          <footer className="flex justify-center w-full py-8">
            <p className="text-[12px] text-[#fff]">
              {/* Protected by reCAPTCHA and subject to the{" "} */}
              Subject to the <a href="javascript:void(0)">
                Privacy Policy
              </a> and <a href="javascript:void(0)">Terms of Service</a>.
            </p>
          </footer>
        </div>

      </div>
    </>
  );
};

export default connect()(SignIn);
