import { createStore } from "redux";
import rootReducer from "./reducers/rootReducer";

const loadState = () => {
  try {
    const serializedState = sessionStorage.getItem('state');
    if(serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (e) {
    return undefined;
  }
};

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem('state', serializedState);
  } catch (e) {
    // Ignore write errors;
  }
};

const peristedState = loadState();

const store = createStore(rootReducer, peristedState);

store.subscribe(() => {
  saveState(store.getState());
});

export default store;