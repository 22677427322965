import { combineReducers } from "redux";

const user = (state = null, action) => {
  switch (action.type) {
    case "USER":
      return action.user;
    default:
      return state;
  }
};

const rewardPoints = (state = null, action) => {
  switch (action.type) {
    case "REWARDPOINTS":
      return action.rewardPoints;
    default:
      return state;
  }
};

const roles = (state = [], action) => {
  switch (action.type) {
    case "ROLES":
      return action.roles;
    default:
      return state;
  }
};

const collapse = (state = [], action) => {
  switch (action.type) {
    case "COLLAPSE":
      return action.collapse;
    default:
      return state;
  }
};

const defaultpage = (state = [], action) => {
  switch (action.type) {
    case "DEFAULTPAGE":
      return action.defaultpage;
    default:
      return state;
  }
};

const isAdmin = (state = [], action) => {
  switch (action.type) {
    case "ISADMIN":
      return action.isAdmin;
    default:
      return state;
  }
};

const levels = (state = [], action) => {
  switch (action.type) {
    case "LEVELS":
      return action.levels;
    default:
      return state;
  }
};

const rewards = (state = [], action) => {
  switch (action.type) {
    case "REWARDS":
      return action.rewards;
    default:
      return state;
  }
};

const showSideBar = (state = [], action) => {
  switch (action.type) {
    case "SIDEBARSHOW":
      return action.showSideBar;
    default:
      return state;
  }
};

const approveReview = (state = [], action) => {
  switch (action.type) {
    case "APPROVEREVIEW":
      return action.approveReview;
    default:
      return state;
  }
};

const token = (state = null, action) => {
  switch (action.type) {
    case "TOKEN":
      return action.token;
    default:
      return state;
  }
};

const systemDetails = (state = [], action) => {
  switch (action.type) {
    case "SYSTEMDETAILS":
      return action.systemDetails;
    default:
      return state;
  }
};

const unsavedChange = (state = [], action) => {
  switch (action.type) {
    case "UNSAVEDCHANGE":
      return action.unsavedChange;
    default:
      return state;
  }
};

const badgesDetails = (state = [], action) => {
  switch (action.type) {
    case "BADGESDETAILS":
      return action.badgesDetails;
    default:
      return state;
  }
};

const geniePopup = (state = false, action) => {
  switch (action.type) {
    case "GENIEPOPUP":
      return action.geniePopup;
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  user,
  roles,
  collapse,
  levels,
  rewards,
  defaultpage,
  isAdmin,
  showSideBar,
  approveReview,
  token,
  rewardPoints,
  systemDetails,
  unsavedChange,
  badgesDetails,
  geniePopup,
});

export default rootReducer;
