import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate,useLocation } from "react-router-dom";
import "react-toggle/style.css";
import DataTable from "react-data-table-component";
import apiClient from "../../../common/http-common";
import { Card } from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Routes } from "../../../routes";
import { Alert } from "../../../components/Alert";
import HomeOutlineIcon from "../../../assets/img/icons/home-outline-icon.svg";
import DownloadIcon from "../../../assets/img/new-dashboard/download-icon.svg";
import { faPenToSquare, faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import Papa from "papaparse";
import { getPoints } from "../../../common/Helper";
const UsersTable = (state) => {
    const location = useLocation();
    const [card, setcard] = useState(location?.state?.card || null);
    let currentpath = window.location.hash.replace("#", "");
    let haumbstatus = state.defaultpage === currentpath ? false : true;
    const [isAdmin] = useState(state.isAdmin);
    const [HideHomeBreadCumb] = useState(haumbstatus)
    const navigate = useNavigate();
    const [logUserId] = useState(state?.user?.Id);
    const [articleList, setArticleList] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [tableData, setTableData] = useState([]);


    const [loading, setLoading] = useState(true);

    const fetchArticleList = async () => {
        setLoading(true);
        try {
            const response = await apiClient.post("user/get_all_users_details", {
                CategoryId : location.state.category,
                ClientId : location.state.client,
                domainId : location.state.domain,
                month : location.state.month,
                projectId : location.state.project,
                regionId : location.state.region,
                year : location.state.year
            });
            const articles = response.data.user;
            setArticleList(articles);
            setTableData(articles);
            setLoading(false);
        } catch (error) {
            Alert("error", error.message);
        }
    };

    useEffect(() => {
        fetchArticleList();
    }, []);



    const handleSearch = (event) => {
        setSearchValue(event.target.value);
        filterData(event.target.value);
    };
    

    const filterData = (value) => {
        const lowerCaseValue = value.toLowerCase().trim();
        const filteredData = tableData.filter(
            (item) =>
                item.FirstName.toLowerCase().includes(lowerCaseValue) ||
                item.EmailId.toLowerCase().includes(lowerCaseValue)
                //  ||  item.ProjectName.toLowerCase().includes(lowerCaseValue)
        );
        setArticleList(filteredData);
    };
    

    function handleExport(data) {
        const headers = card === 'users' ? [
            { label: "Student Name", key: "FirstName" },
            { label: "EmailId", key: "EmailId" },
            { label: "Knowledge Credits", key: "TotalPoints" },
            { label: "Total Artifacts", key: "TotalArtifacts" },
            { label: "Badges", key: "BadgeName" }, // Include "Badges" header for users
            { label: "Status", key: "UserStatus" }
        ] : card === 'knowledgeCredits' ? [
            { label: "Student Name", key: "FirstName" },
            { label: "EmailId", key: "EmailId" },
            { label: "Knowledge Credits", key: "TotalPoints" },
            { label: "Consumption Points", key: "ConsumptionPoints" },
            { label: "Contribution Points", key: "ContributionPoints" }
        ] : card === 'badges' ? [
            { label: "Student Name", key: "FirstName" },
            { label: "EmailId", key: "EmailId" },
            { label: "Knowledge Credits", key: "TotalPoints" },
            { label: "Reward Points", key: "RewardPoints" },
            { label: "Badges", key: "BadgeName" },
        ] : [];
    
        const exportData = data.map((row) =>
            headers.reduce((acc, header) => {
                if (row[header.key] === "start_date" || row[header.key] === "end_date") {
                    acc[header.label] = changeStartDateFormat(row[header.key]);
                } else {
                    acc[header.label] = row[header.key];
                }
                return acc;
            }, {})
        );
        
        const csvData = Papa.unparse(exportData);
        const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
        saveAs(blob, "Student Details.csv");
    }

    function capitalizeFirstLetter(title) {
        if (!title) return "";
        return title.charAt(0).toUpperCase() + title.slice(1);
    }

    function capitalizeFirstLetter(str) {
        return str ? str.charAt(0).toUpperCase() + str.slice(1) : '';
    }


    const columns = [
        ...(card === 'users' || 'knowledgeCredits' ? [
            {
                name: " Student Name",
                selector: (param) => capitalizeFirstLetter(param.FirstName),
                format: (param) => {
                    const firstName = param.FirstName || "";
                    const lastName = param.LastName || "";
                    const title = firstName + (firstName && lastName ? " " : "") + lastName;
                    if (title.length > 30) {
                        return (
                            <span title={title}>
                                {capitalizeFirstLetter(title.substring(0, 30)) + "..."}
                            </span>
                        );
                    } else {
                        return (
                            <span title={title}>
                                {capitalizeFirstLetter(title)}
                            </span>
                        );
                    }
                },
                sortable: true,
            },
            {
                name: "Email Id",
                selector: (param) => capitalizeFirstLetter(param.EmailId),
                format: (param) => {
                    const title = param.EmailId || "";
                    if (title.length > 30) {
                        return (
                            <span title={title}>
                                {title.substring(0, 30) + "..."}
                            </span>
                        );
                    } else {
                        return (
                            <span title={title}>
                                {title}
                            </span>
                        );
                    }
                },
                sortable: true,
            },
            {
                name: "Knowledge Credits",
                selector: (param) => param.TotalPoints,
                format: (param) => (
                    <span title={param.TotalPoints}>{param.TotalPoints}</span>
                ),
                sortable: true,
            },    
        ] : []),
        ...(card === 'knowledgeCredits' ? [
            {
                name: "Consumption",
                selector: (param) => param.ConsumptionPoints,
                format: (param) => (
                    <span title={param.ConsumptionPoints}>{param.ConsumptionPoints}</span>
                ),
                sortable: true,
            },
            {
                name: "Contribution",
                selector: (param) => param.ContributionPoints,
                format: (param) => (
                    <span title={param.ContributionPoints}>{param.ContributionPoints}</span>
                ),
                sortable: true,
            }
        ] : []),
        ...(card === 'badges' ? [
            {
                name: "Reward Points",
                selector: (param) => param.RewardPoints,
                format: (param) => (
                    <span title={param.RewardPoints}>{param.RewardPoints}</span>
                ),
                sortable: true,
            },
            {
                name: "Badges",
                selector: (param) => capitalizeFirstLetter(param.BadgeName),
                format: (param) => (
                    <span title={capitalizeFirstLetter(param.BadgeName)}>{capitalizeFirstLetter(param.BadgeName)}</span>
                ),  
                sortable: true,
            },
        ] : []),

        ...(card === 'users' ? [
            {
                name: "Total Artifacts",
                selector: (param) => param.TotalArtifacts,
                format: (param) => (
                    <span title={param.TotalArtifacts}>{param.TotalArtifacts}</span>
                ),
                sortable: true,
            },    
            {
                name: "Badges",
                selector: (param) => capitalizeFirstLetter(param.BadgeName),
                format: (param) => (
                    <span title={capitalizeFirstLetter(param.BadgeName)}>{capitalizeFirstLetter(param.BadgeName)}</span>
                ),  
                sortable: true,
            },
            {
                name: "Status",
                id : "status",
                sortable: true,
                selector: (param) => param.UserStatus,
                format: (param) => (
                    <>
                        {param.UserStatus === "Active" ? (
                            <div class="maincontent__table--status maincontent__table--status-updated">
                                Active
                            </div>
                        ) : (
                            <div class="maincontent__table--status maincontent__table--status-notupdated draftbtnMyArticle" style={{ backgroundColor: "#eb954f" }}>
                                Inactive
                            </div>
                        )}
                    </>
                ),
            },
        ] : []),
        ];
    
    return (
        <>
            <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center justify-between">
                {HideHomeBreadCumb && (
                    <div className="maincontent__breadcrumb mb-2 lg:mb-0">
                        <img
                            className="cursor_pointer"
                            src={HomeOutlineIcon}
                            alt="home"
                            onClick={() => {
                                navigate(state.defaultpage);
                            }}
                        />
                        <span className="maincontent__breadcrumb--divider">/</span>
                        <span>
                        {card === "users" ? "Students List" : card === "knowledgeCredits" ? "Knowledge Credits List" : card === "badges" ? "Student Badges" : ""}
                        </span>
                    </div>
                )}

                <div className="flex  gap-2 mt-2 lg:mt-0">
                    <div className="relative search-containerKMArti kmarticle-seactform mb-2 lg:mb-0">
                        <input
                            type="search"
                            placeholder="Search Student Name, Email Id, "
                            className="w-full pt-2 pb-2 pl-2 pr-[26%] text-xs border-0 rounded-[28px] outline-0 h-[34px]"
                            value={searchValue}
                            onChange={handleSearch}
                        />
                        <button
                            type="button"
                            className="absolute top-1 right-1 bg-[rgba(0,0,0,60%)] rounded-[28px] h-[26px] text-white text-[10px] font-bold button"
                        >
                            Search
                        </button>
                    </div>
                    <Link className="flexVerandHorCenter ml-auto">
                        <img
                            src={DownloadIcon}
                            onClick={() => { setcard(card) ; handleExport(articleList)}}
                            className="p-2 bg-[rgba(0,0,0,60%)] rounded-md"
                        ></img>
                    </Link>
                </div>
            </div>

            <div className=" mt-2 maincontent__card--body">
                <div className="maincontent__card--content">
                    <Card.Body id="unpublishedDatatble" align="center">
                        {loading ? (
                            <div class="circle__loader"></div>
                        ) : (
                            <DataTable
                                columns={columns}
                                data={articleList}
                                highlightOnHover
                                pagination
                                paginationRowsPerPageOptions={[5, 10, 15]}
                                paginationPerPage={10}
                                defaultSortFieldId="status"
                                defaultSortAsc={true}
                                className="myartifactsDT"
                            />

                        )}
                    </Card.Body>
                    <div className="flex justify-center gap-4 mt-8 lg:justify-end">
                        <button onClick={() => { navigate(state.defaultpage);}}className="maincontent__btn maincontent__btn--primaryblue space_btn">
                            Back
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    ...state,
});
export default connect(mapStateToProps)(UsersTable);
