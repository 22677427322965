import React, { useState, useEffect } from "react";
import chatgptPic from "../../assets/img/new-dashboard/5Elements-logo.png";
import { connect, useDispatch } from "react-redux";
import apiClient from "../../common/http-common";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../routes";
import CommentIcon from "../../assets/img/new_design/comment.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCompress,faExpand,faMessage,faTimes ,faStop} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import { Alert } from "../../components/Alert";
import { geniePopup } from "../../actions/actions";

const GenieChat = ({  user, defaultpage}) => {

  let currentpath = window.location.hash.replace("#", "");
  const [logUserId] = useState(user);
  let haumbstatus = defaultpage === currentpath ? false : true;
  const [HideHomeBreadCumb] = useState(haumbstatus);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const savedQuestions = JSON.parse(localStorage.getItem('chatGPTQuestions')) || [];
  const [questions, setQuestions] = useState(savedQuestions);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryResult, setSearchQueryResult] = useState([]);
  const [isSidebarPinned, setIsSidebarPinned] = useState(true);
  const [resume, setResume] = useState(false);
  const [ChatId, setChatId] = useState(null);
  const [chatRecord, setChatRecord] = useState([]);
  let chatGptUrl = process.env.REACT_APP_CHAT_CONV_URL;
  const todayQuestions = ["Select Payment Type?", "Enter Amount", "Understanding Credit Card Machines" /* Add more today's questions */];
  const yesterdayQuestions = ["Insert/Swipe/Dip Card", "Verify and Confirm", "Power On Turn On" /* Add more yesterday's questions */];
  const lastWeekQuestions = ["Mastering Credit Card", "Operate Credit Card Machine", "Credit Card Machines"  /* Add more last week's questions */];
  const lastMonthQuestions = ["Processing Payments", "Credit Card Machines", "Verify and Confirm" /* Add more last month's questions */];
  const [chathistory, setChatHistory] = useState([]);
  const [freqChat, setFreqChat] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [stopFetchVisible, setStopFetchVisible] = useState(false);

  const changeDateFormat = (pdate) => {
    const datePart = pdate.split('T')[0];
    const [year, month, day] = datePart.split('-');
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const ChatHistory = () => {

    apiClient
    .get("/chat/chatdetails_by_userid_get")
    .then((res) => {
      const questions = res.data.map(chat => {
        const conversation = JSON.parse(chat.Conversation);
        if (conversation.length > 0) {
          return {
            id: chat.Id,
            question: conversation[0].question,
            date : changeDateFormat(chat.CreatedAt)
          };
        }
        return null;
      });
      setChatHistory(questions.filter(q => q)); // Filter out null values
    })
    .catch((err) => {
      console.log(err);
    });

    apiClient
    .get("/chat/chathistory_by_userid")
    .then((res) => {
      const questions = res.data.map(chat => {
        const conversation = JSON.parse(chat.Conversation);
        if (conversation.length > 0) {
          return {
            id: chat.Id,
            question: conversation[0].question
          };
        }
        return null;
      });
      setFreqChat(questions.filter(q => q)); // Filter out null values
    })
    .catch((err) => {
      console.log(err);
    });
  };

  useEffect(() => {
      ChatHistory();
  }, []);


 
  const getchatgptAnswer = () => {
    let exitlist = searchQueryResult;
    exitlist.push({ question: searchQuery, answer: "" });
    setSearchQueryResult(exitlist);
    setSearchQuery("");
    setQuestions([...questions, searchQuery]); // Add the question to the list
    setIsFetching(true);
    setStopFetchVisible(true);
    
    apiClient
      .post(chatGptUrl, {
        input_text: searchQuery,
        conversationId : ChatId,
        userId : parseInt(logUserId, 10),
      })
      .then((res) => {
        setChatId(res.data.conversationId)
        setIsFetching(false);
        setStopFetchVisible(false);
        let cnt = searchQueryResult.length;
        let resultList = [...searchQueryResult];
        if (res.data.response) {
          resultList[cnt - 1].answer = res.data.response;
        } else {
          resultList[cnt - 1].answer =
            "Is there anything else I can help you with?";
        }
        setSearchQueryResult(resultList);
      })
      .catch((err) => {
        console.log(err);
        setIsFetching(false);
        setStopFetchVisible(false);
      });
  };

  useEffect(() => {
    localStorage.setItem('chatGPTQuestions', JSON.stringify(questions));
  }, [questions])

  const ChatRecord = (id) => {
    setChatId(id);

    apiClient
      .post("chat/chatdetails_by_chatid_get", {
        Id : id
      })
      .then((res) => {
        const conversation = JSON.parse(res.data[0].Conversation);
        const conversationArray = Object.keys(conversation).map(key => conversation[key]);
        setSearchQueryResult(conversationArray);
        // setChatId(res.data[0].Id);
      })
      .catch((err) => {
        console.log(err);
      });
      
  };

  const ChatgptHistory = () => {

    apiClient
      .post("/chat/chat_conversation_apply", {
        Id: ChatId,
        Conversation : JSON.stringify(searchQueryResult),
        UserId : parseInt(logUserId, 10),
      })
      .then((res) => {
        setChatId(res.data[0].Id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (searchQueryResult && searchQueryResult.length > 0) {
      // ChatgptHistory();
    }
  }, [searchQueryResult]);


  const toggleSidebarPin = () => {
    setIsSidebarPinned(!isSidebarPinned);
  };

  const today = new Date();
const yesterday = new Date(today);
yesterday.setDate(yesterday.getDate() - 1);

const lastWeek = new Date(today);
lastWeek.setDate(lastWeek.getDate() - 7);

const lastMonth = new Date(today);
lastMonth.setMonth(lastMonth.getMonth() - 1);

const todayChats = [];
const yesterdayChats = [];
const last7DaysChats = [];
const last30DaysChats = [];

chathistory.forEach(chat => {
  const chatDate = new Date(chat.date);
  
  if (chatDate.toDateString() === today.toDateString()) {
    todayChats.push(chat);
  } else if (chatDate.toDateString() === yesterday.toDateString()) {
    yesterdayChats.push(chat);
  } else if (chatDate >= lastWeek && chatDate < yesterday) {
    last7DaysChats.push(chat);
  } else if (chatDate >= lastMonth && chatDate < lastWeek) {
    last30DaysChats.push(chat);
  }
});

const [hoveredIndex, setHoveredIndex] = useState(null);

// Function to handle mouse enter event
const handleMouseEnter = (index) => {
  setHoveredIndex(index);
}

// Function to handle mouse leave event
const handleMouseLeave = () => {
  setHoveredIndex(null);
}

const handleDelete = (id) => {
  // setLoading(true);
  Swal.fire({
    title: "",
    text: "Are you sure, you want to Delete?",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#1658a0",
    cancelButtonColor: "#1658a0",
    confirmButtonText: "Delete",
    allowOutsideClick: false,
    allowEscapeKey: false,
  }).then((result) => {
    if (result.isConfirmed) {
      apiClient
        .post("chat/chat_history_delete_apply", { Id: id })
        .then((res) => {
          Alert("succ", "History deleted successfully");
          ChatHistory();
        })
        .catch((error) => {
        });
    }
  })
}

const handleGenieChat = () => {
  setSearchQueryResult([]);
  setChatId(null);
  ChatHistory();
  dispatch(geniePopup(false));
};

const renderTable = (answer) => {
  const rows = answer.split("\n").filter((row) => row.includes("|"));
  const headers = rows[0].split("|").map((header) => header.trim()).filter(Boolean);
  const dataRows = rows.slice(2).map((row) => row.split("|").map((cell) => cell.trim()).filter(Boolean));

  return (
    <table className="table-auto w-full border-collapse border border-black rounded-lg">
      <thead>
        <tr>
          {headers.map((header, index) => (
            <th key={index} className="border border-gray-300 px-4 py-2 border-black bg-gray-400">{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {dataRows.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {row.map((cell, cellIndex) => (
              <td key={cellIndex} className="border border-black px-4 py-2">{cell}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};


  return (
    <>
    { !resume && (
      <div className="geniechat transition-all rounded-t-2xl">
      <div className="headerbg flex justify-between items-center">
        <h2 className="maincontent__card--header-title text-white">
            GenieChat
        </h2>
        <div className="flex items-center">
        <FontAwesomeIcon
              icon={faExpand}
              title="Maximize"
              onClick={() => {
                dispatch(geniePopup(false));
                navigate(Routes.ChatGPT, {
                  state: {
                    searchQueryResult
                  }
                });
              }}
              className="text-white mx-2 text-xl cursor-pointer"
            />
            <FontAwesomeIcon icon={faCompress} title="Minimize" onClick={() => {setResume(true)}} className="text-white mx-2 text-xl cursor-pointer" />
            <FontAwesomeIcon icon={faTimes} onClick={() => handleGenieChat()} title="Close" className="text-white mx-2 text-xl cursor-pointer" />
        </div>
      </div>

        <div className=" flex flex-col flex-1 overflow-y-hidden scroll-margin-top-4">
          <div className="fixed-box-container min-h-[70vh] chat-ai-backimage">
            {searchQueryResult?.map((row, key) => {
              return (
                <>
                  <div className="question" key={key}>
                    <div className="flex gap-4">
                      <span style={{ marginTop: "8px", flex: "none" }}>
                        You :
                      </span>
                      <div className="flex flex-col w-full">
                        <span className="font-semibold chatgpt-question">
                          {row.question}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="question p-2">
                    <div className="flex gap-4 p-2">
                      <img
                        height={30}
                        width={30}
                        src={chatgptPic}
                        alt="Profile Picture"
                        className="maincontent__card--kmDashboard-contributorsPic block"
                      />
                      {row.answer ? (
                        <>
                          <div className="flex flex-col w-full">
                            <span className="font-bold mt-1 ml-1" style={{ color: "#006787" }}>
                              AskGenie
                            </span>
                            <span className="font-semibold chatgpt-answer whitespace-pre-line	">
                            {row.answer.includes("|") ? (
                              renderTable(row.answer)
                            ) : (
                              row.answer
                              )}
                            </span>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="dot-typing-loader">
                            <span className="dot_loader"></span>
                            <span className="dot_loader"></span>
                            <span className="dot_loader"></span>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </>
              );
            })}
          </div>

          <div className="mt-2 bg-[#ECEEF6]">
            <div className="flex gap-4">
              <div className="flex flex-col w-full items-center justify-start gap-y-4 md:flex-row md:justify-between mb-4 mt-4">
                <div className="relative flex w-full">
                  <div className="articles__comments">
                    <textarea
                      className="articles__comments--input h-12"
                      style={{ backgroundColor: "white" }}
                      placeholder="Ask your question"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" && e.target.value.trim()) {
                          e.preventDefault();
                          getchatgptAnswer();
                        }
                      }}
                    ></textarea>
                   {stopFetchVisible ? (
                <button
                  type="button"
                  className="articles__comments--btn py-3"
                  onClick={() => {
                    setIsFetching(false);
                    setStopFetchVisible(false);
                  }}
                >
                  <FontAwesomeIcon icon={faStop} />
                </button>
              ) : (
                <button
                  type="button"
                  className="articles__comments--btn py-2"
                  onClick={() => {
                    if (searchQuery !== "") {
                      getchatgptAnswer();
                    }
                  }}
                >
                  <img src={CommentIcon} alt="Comment Icon" />
                </button>
              )}
                  </div>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 gap-2 text-black">
            {freqChat.map((chat, index) => (
              <div
              key={index}
                class="overflow-hidden text-black maincontent__card--content maincontent__card--content-woheader mr-2 mb-2 cursor-pointer" onClick={() => setSearchQuery(chat.question)}
                style={{ backgroundColor: "#f6f7fb" }}
              >
                <div class="flex flex-col gap-1 w75">
                  <div class="flex flex-col gap-1">
                    <span class="text-sm font-sm">
                    {chat.question}
                    </span>
                  </div>
                </div>
              </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    )}
    { resume && (
      <div className="resumechatcontainer cursor-pointer" title="Click to Resume Geniechat" >
    <div className="resumechat" onClick={() => {setResume(false)}}> 
        <FontAwesomeIcon icon={faMessage} className="resumechaticon"/>
        <div className="resumechattext">RESUME</div>
    </div>
    </div>
    )}
    </>
  );
};

const mapStateToProps = (state) => ({
    user : state?.user?.Id,
    defaultpage : state?.defaultpage
});
export default connect(mapStateToProps)(GenieChat);
