import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../usermanagement/Users/user.css";
import DataTable from "react-data-table-component";
import apiClient from "../../common/http-common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faTrashAlt,
  faPenToSquare,
  faTrash,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import "react-toggle/style.css";
import DownloadIcon from "../../assets/img/new-dashboard/download-icon.svg";
import {
  Card,
} from "@themesberg/react-bootstrap";
import Swal from "sweetalert2";
import { Routes } from "../../routes";
import Papa from "papaparse";
import { Link } from "react-router-dom";
import HomeOutlineIcon from "../../assets/img/icons/home-outline-icon.svg";
import { Alert } from "../../components/Alert";
import { connect } from "react-redux";
 
 
const AwsBucketList = (state) => {
  // const location = useLocation();
  const [UserTabData, setUserTabData] = useState([]);
    const [isdisabled, setdisabled] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const [logUserId] = useState(state?.user?.Id);
 
 
  function capitalizeFirstLetter(name) {
    if (!name) return "";
    return name.charAt(0).toUpperCase() + name.slice(1);
  }
 
  const navigate = useNavigate();
 
  const column = [
    {
        name: "Bucket Name",
        selector: (param) => {
          const bucketname = param.bucket_name || "";
          return (
            <span title={bucketname}>
              {bucketname.length > 25 ? bucketname.slice(0, 25) + "..." : bucketname}
            </span>
          );
        },
        sortable: true,
    },
    {
        name: "Access Key",
        selector: (param) => {
          const accesskey = param.access_key || "";
          return (
            <span title={accesskey}>
              {accesskey.length > 25 ? accesskey.slice(0, 25) + "..." : accesskey}
            </span>
          );
        },
        sortable: true,
      },
      {
        name: "Secret Access Key",
        selector: (param) => {
          const secretkey = param.secret_access_key || "";
          return (
            <span title={secretkey}>
              {secretkey.length > 25 ? secretkey.slice(0, 25) + "..." : secretkey}
            </span>
          );
        },
        sortable: true,
      },
      {
        name: "Bucket Region",
        selector: (param) => {
          const bucketregion = param.bucket_region || "";
          return (
            <span title={bucketregion}>
              {bucketregion.length > 25 ? bucketregion.slice(0, 25) + "..." : bucketregion}
            </span>
          );
        },
        sortable: true,
      },
      // {
      //   name: "Active Status",
      //   selector : (param) => param.active_status,
      //   format: (param) => {
      //     const status = param.active_status;
      //     if (status === 1) {
      //       return (
      //         <div
      //           className="maincontent__table--status maincontent__table--status-updated"
      //         >
      //           Enabled
      //         </div>
      //       );
      //     } else {
      //       return (
      //         <div
      //           className="maincontent__table--status maincontent__table--status-notupdated draftbtnMyArticle"
      //           style={{ backgroundColor: "#eb954f" }}
      //         >
      //           Disabled
      //         </div>
      //       );
      //     }
      //   },
      //   sortable: true,
      // },      
    // {
    //   name: "Client Id",
    //   selector: (param) => <span title={param.client_id}>
    //     {param.client_id.length > 25 ? param.client_id.slice(0, 25) + "..." : param.client_id}</span>,
    //   sortable: true,
    // },
    // {
    //     name: "Client Secret",
    //     selector: (param) => <span title={param.client_secret}>
    //     {param.client_secret.length > 25 ? param.client_secret.slice(0, 25) + "..." : param.client_secret}</span>,
    //     sortable: true,
    //   },
    //   {
    //     name: "Grant Type",
    //     selector: (param) => <span title={param.grant_type}>
    //     {param.grant_type.length > 25 ? param.grant_type.slice(0, 25) + "..." : param.grant_type}</span>,
    //     sortable: true,
    //   },
    //   {
    //     name: "Scope",
    //     selector: (param) => <span title={param.scope}>
    //     {param.scope.length > 25 ? param.scope.slice(0, 25) + "..." : param.scope}</span>,
    //     sortable: true,
    //   },
      {
        name : "Actions",
        selector : (param) => (
          <>
          <FontAwesomeIcon
              style={{ color: "#1658a0", cursor: 'pointer' }}
              icon={faEdit}
              onClick={() => navigate(Routes.AwsBucketAdd, {
                state: {
                  param
                },
              })}
              title="Edit Data"
            />
                        {UserTabData.length < 1 && (

            <FontAwesomeIcon
              style={{ color: "#1658a0", cursor: 'pointer' }}
              icon={faTrash}
              onClick={() => DeleteData(param.Id)}
              title="Delete Data"
            />
                        )}
          </>
        )
      },
  ];
 
  const DeleteData = (id) => {
    // setLoading(true);
    Swal.fire({
      title: "",
      text: "Are you sure, you want to Delete?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1658a0",
      cancelButtonColor: "#1658a0",
      confirmButtonText: "Delete",
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((result) => {
      if (result.isConfirmed) {
        apiClient
          .post("cloud/aws/bucketlist_apply", { Id: id })
          .then((res) => {
            getAllUsers();
            Alert("succ", "Data Deleted successfully");
          })
          .catch((error) => {
            Alert("error", "Please Try Again")
          });
      }
    })
  }
 
  const getAllUsers = () => {
    setdisabled(true);
    apiClient
  .get("cloud/aws/bucketlist_get")
  .then((response) => {
          setUserTabData(response.data);
          setFilteredData(response.data);
          setdisabled(false);
          setLoading(false);
      })
      .catch((error) => {
        if (error.response && error.response.status === 500) {
          Alert("error", "Internal Server Error");
        } else {
          Alert("error", "Please Try Again");
        }
      });
  };
 
  useEffect(() => {
    getAllUsers();
  }, []);
 
  const filterData = (value) => {
    const lowerCaseValue = value.toLowerCase().trim();
    const filteredData = UserTabData.filter(
      (item) =>
        item.bucket_name?.toLowerCase().includes(lowerCaseValue) ||
        item.bucket_region?.toLowerCase().includes(lowerCaseValue) ||
        item.access_key?.toLowerCase().includes(lowerCaseValue) ||
        item.secret_access_key?.toLowerCase().includes(lowerCaseValue)

    );
    setFilteredData(filteredData);
  };
 
  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchValue(value);
    filterData(value);
  };
 
  function handleExport(data) {
    const headers = [
      { label: "Bucket Name", key: "bucket_name" },
      { label: "Access Key", key: "access_key" },
      { label: "Secret Access Key", key: "secret_access_key" },
      {label:"Bucket Region" , key:"bucket_region"},
    //   { label: "Grant Type", key: "grant_type" },
    //   {label:"Scope", key:"scope"},
      // {label:"Lock Status",key:"IsLocked"},
      // {label:"Active Status" , key:"IsActive"}
    ];
 
    const exportData = data.map((row) =>
      headers.reduce((acc, header) => {
        if (row[header.key] === "start_date" || row[header.key] === "end_date")
          acc[header.label] = changeStartDateFormat(row[header.key]);
        else acc[header.label] = row[header.key];
        return acc;
      }, {})
    );
    const csvData = Papa.unparse(exportData);
 
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
    saveAs(blob, "AWS Details.csv");
  }
 
  const handleEnterKey = (e) => {
    if (e.key === "Enter") {
      searchUser();
    }
  };
    return (
    <div className="fsMain">
      <div className="flex  md:flex-row gap-4 md:items-center md:justify-between my-2">
        <div className="maincontent__breadcrumb">
          <img
            className="cursor_pointer"
            src={HomeOutlineIcon}
            alt="home"
            onClick={() => {
              navigate(state.defaultpage);
            }}
          />
          <span className="maincontent__breadcrumb--divider">/</span>
          <span>AWS Configuration</span>
          <span className="maincontent__breadcrumb--divider">/</span>
          <span className="maincontent__breadcrumb--active">Configuration</span>
        </div>
          <button
            className="maincontent__btn maincontent__btn--primaryblue w-fit ml-auto"
            onClick={() => {
              navigate(Routes.AwsBucketAdd);
            }}
          >
            +Add AWS
          </button>
      </div>
 
      <div className="maincontent__card">
        <div
          className="maincontent__card--body"
          style={{ margin: "10px 0 0 0 " }}
        >
          <div className="maincontent__card--header ">
            <h2 className="maincontent__card--header-title text-black">
              AWS BucketList
            </h2>
          </div>
          <div className="maincontent__card--content  rdt_Pagination">
            <div className="secDiv">
              <div
                className="maincontent__card--tableheader-right"
                style={{ margin: "10px 0px 10px 0px" }}
              >
                <div className="relative search-containerKMArti kmarticle-seactform">
                  <input
                    type="search"
                    placeholder="Search by Bucket name ,Bucket Region , Access Key"
                    className="w-full pt-2 pb-2 pl-2 pr-[26%] text-xs border-0 rounded-[28px] outline-0 h-[34px]"
                    value={searchValue}
                    onChange={handleSearch}
                  />
                  <button
                    type="button"
                    className="absolute top-1 right-1 bg-[rgba(0,0,0,60%)] rounded-[28px] h-[26px] text-white text-[10px] font-bold button"
                  >
                    Search
                  </button>
                </div>
                <Link className="flexVerandHorCenter">
                  <img
                    src={DownloadIcon}
                    onClick={() => handleExport(UserTabData)}
                    className="p-2 bg-[rgba(0,0,0,60%)] rounded-md"
                  ></img>
                </Link>
              </div>
 
              <Card.Body align="center">
                {loading ? (
                  <div class="circle__loader items-center my-0 mx-auto"></div>
                ) : (
                  <DataTable
                    columns={column}
                    data={filteredData}
                    highlightOnHover
                    pagination
                    paginationRowsPerPageOptions={[5, 10, 15]}
                    paginationPerPage={10}
                  />
                )}
              </Card.Body>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
 
const mapStateToProps = (state) => ({
  ...state,
});
export default connect(mapStateToProps)(AwsBucketList);
 