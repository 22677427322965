import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useLocation } from "react-router-dom";
import "react-toggle/style.css";
import DataTable from "react-data-table-component";
import apiClient from "../../common/http-common";
import { Card } from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Routes } from "../../routes";
import { Alert } from "../../components/Alert";
import HomeOutlineIcon from "../../assets/img/icons/home-outline-icon.svg";
import DownloadIcon from "../../assets/img/new-dashboard/download-icon.svg";
import { faPenToSquare, faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import Papa from "papaparse";
import Swal from "sweetalert2";
import { ddmmyyyyFormat, getPoints } from "../../common/Helper";
import addRewardPoints from "../../common/AddRewardPoints";
import PermissionGuard from "../../common/gaurd/PermissionGuard";
const MyArticle = (state) => {
  const location = useLocation();
  let currentpath = window.location.hash.replace("#", "");
  let haumbstatus = state.defaultpage === currentpath ? false : true;
  const [isAdmin] = useState(state.isAdmin);
  const [HideHomeBreadCumb] = useState(haumbstatus);
  let [articleViewPermission, setArticleViewPermission] = useState(false);
  let [articleEditPermission, setArticleEditPermission] = useState(false);

  useEffect(async () => {
    articleViewPermission = await PermissionGuard(Routes.KMViewArticle);
    articleEditPermission = await PermissionGuard(Routes.UpdateArticle);
    setArticleViewPermission(articleViewPermission);
    setArticleEditPermission(articleEditPermission);
  }, []);
  const navigate = useNavigate();
  const [logUserId] = useState(state?.user?.Id);
  const [articleList, setArticleList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [tableData, setTableData] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);

  let [cardValue, setCardValue] = useState({
    level: "",
    Points: 0,
    myarticle: 0,
    published: 0,
    totalLike: 0,
  });

  useEffect(() => {
    if (location.state && location.state.selectedProject) {
      setSelectedProject(location.state.selectedProject);
    }
    if (location.state && location.state.selectedCategory) {
      setSelectedCategory(location.state.selectedCategory);
    }
  }, [location.state]);
  const [loading, setLoading] = useState(true);

  const fetchArticleList = async () => {
    setLoading(true);
    try {
      const response = await apiClient.post("kmarticle/myarticle", { UserId: logUserId });
      const articles = response.data;
      setArticleList(articles);
      setTableData(articles);
      setLoading(false);

      if (articles.StatusId === 1 && articles.length % 10 === 0) {
        addRewardPoints(state.rewards[12].Points, logUserId, state.rewards[12].Id, logUserId);
      }
    } catch (error) {
      Alert("error", error.message);
    }
  };

  useEffect(() => {
    fetchArticleList();
  }, []);

  const handleDeleteDraftArticle = (id) => {
    apiClient
      .post("kmarticle/article_draft_delete", { Id: id })
      .then((res) => {
        fetchArticleList(); // Accessing the fetchArticleList function
      })
      .catch((error) => {
        Alert("error", "Please Try Again")
      });
  };


  useEffect(() => {
    setLoading(true);
    apiClient
      .post("kmarticle/kmarticledashboard", { UserId: logUserId })
      .then((res) => {
        cardValue.level = res.data[0].level;
        cardValue.myarticle = res.data[0].totalArticle;
        cardValue.published = res.data[0].published;
        cardValue.totalLike = res.data[0].totalLike;
        cardValue.Points = getPoints(res.data[0].level);
        setCardValue(cardValue);
        setLoading(false);
      })
      .catch((error) => {
        Alert("error", "Please Try Again")
      })
  }, []);

  const handleSearch = (event) => {
    setSearchValue(event.target.value);
    filterData(event.target.value);
  };

  const filterData = (value) => {
    const lowerCaseValue = value.toLowerCase().trim();
    const filteredData = tableData.filter(
      (item) =>
        item.title.toLowerCase().includes(lowerCaseValue) ||
        item.CategoryName.toLowerCase().includes(lowerCaseValue) ||
        item.ProjectName.toLowerCase().includes(lowerCaseValue)
    );
    setArticleList(filteredData);
  };

  function handleExport(data) {
    const headers = [
      {label:"Date",key:"CreatedAt"},
      { label: "Title", key: "title" },
      { label: "Article Type", key: "ArticleTypeName" },
      { label: "Class", key: "ProjectName" },
      { label: "Category", key: "CategoryName" },
      {label:"Status", key:"Status"},
      {label:"Likes", key:"Likes"},
      {label:"Comments", key:"Comments"}

    ];

    const exportData = data.map((row) =>
      headers.reduce((acc, header) => {
        if (row[header.key] === "start_date" || row[header.key] === "end_date"){
          acc[header.label] = changeStartDateFormat(row[header.key]);

         }
         else if(header.key === "Status"){
          let statusText;
          switch (row.StatusId) {
            case 1:
              statusText = "Published";
              break;
            case 2:
              statusText = "Submitted";
              break;
            case 4:
              statusText = "Draft";
              break;
            default:
              statusText = "Rework";
          }
          acc[header.label] = statusText;
         } else acc[header.label] = row[header.key];
        return acc;
      }, {})
    );
    const csvData = Papa.unparse(exportData);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
    saveAs(blob, "Article Details.csv");
  }

  function capitalizeFirstLetter(title) {
    if (!title) return "";
    return title.charAt(0).toUpperCase() + title.slice(1);
  }

  function capitalizeFirstLetter(str) {
    return str ? str.charAt(0).toUpperCase() + str.slice(1) : '';
  }


  const columns = [
    {
      name: "Date",
      selector: (param) => ddmmyyyyFormat(param.CreatedAt),
      sortable: true,
    },
    {
      name: "Title",
      selector: (param) => {
        const title = param.title || "";
        if (title.length > 30) {
          return (
            <span title={title}>
              {capitalizeFirstLetter(title.substring(0, 30)) + "..."}
            </span>
          );
        } else {
          return (
            <span title={title}>
              {capitalizeFirstLetter(title)}
            </span>
          );
        }
      },
      sortable: true,
      sortFunction: (a, b) => capitalizeFirstLetter(a.title).localeCompare(capitalizeFirstLetter(b.title)),

      filter: (
        <input
          type="text"
          placeholder="Search"
          value={searchValue}
          onChange={handleSearch}
        />
      ),
    },
    {
      name: "Article Type",
      selector : (param) => param.type,
      format: (param) => (
        <span title={param.ArticleTypeName}>{capitalizeFirstLetter(param.ArticleTypeName)}</span>
      ),
      sortable: true,
    },
    {
      name: "Class",
      selector: (param) => (
        <span title={param.ProjectName}>{capitalizeFirstLetter(param.ProjectName)}</span>
      ),
      sortable: true,
      sortFunction: (a, b) => capitalizeFirstLetter(a.ProjectName).localeCompare(capitalizeFirstLetter(b.ProjectName)),

      filter: (
        <input
          type="text"
          placeholder="Search"
          value={selectedProject || ""}
          onChange={(e) => setSelectedProject(e.target.value)}
        />
      ),
    },
    {
      name: "Category",
      selector: (param) => (
        <span title={param.CategoryName}>{param.CategoryName}</span>
      ),
      sortable: true,
      sortFunction: (a, b) => a.CategoryName.localeCompare(b.CategoryName),

      filter: (
        <input
          type="text"
          placeholder="Search"
          value={selectedCategory || ""}
          onChange={(e) => setSelectedCategory(e.target.value)}
        />
      ),
    },
    {
      name: "Status",
      id : "status",
      sortable: true,
      selector: (param) => param.StatusId,
      format: (param) => (
        <>
          {param.StatusId === 1 ? (
            <div class="maincontent__table--status maincontent__table--status-updated">
              Published
            </div>
          ) : param.StatusId === 2 ? (
            <div class="maincontent__table--status maincontent__table--status-notupdated bg-info scheduled">
              Submitted
            </div>
          ) : param.StatusId === 4 ? (
            <div class="maincontent__table--status maincontent__table--status-notupdated draftbtnMyArticle">
              Draft
            </div>
          ) : (
            <div
              class="maincontent__table--status maincontent__table--status-review"
              style={{ backgroundColor: "#eb954f" }}
            >
              Rework
            </div>
          )}
        </>
      ),
    },
    {
      name: "Likes",

      selector: (param) => param.Likes,
      sortable: true,
    },
    {
      name: "Comments",
      selector: (param) => param.Comments,
      sortable: true,
    },
    {
      name: "Action",
      omit: !articleViewPermission && !articleEditPermission ? true : false,
      selector: (param) => (
        <>
          {param.StatusId !== 2 && articleEditPermission && (
            <FontAwesomeIcon
              style={{ color: "#1658a0" }}
              icon={faPenToSquare}
              onClick={() => {
                navigate(Routes.UpdateArticle, { state: { user: param } });
              }}
              title="Edit Article"
            />
          )}
          {isAdmin && (
            <FontAwesomeIcon
              style={{ color: "#1658a0", cursor: 'pointer' }}
              icon={faTrash}
              onClick={() => deletedraft(param.Id)}
              title="Delete Artifact"
            />
          )}
          {!isAdmin && (param.StatusId === 3 || param.StatusId === 4) && (
            <FontAwesomeIcon
              style={{ color: "#1658a0", cursor: 'pointer' }}
              icon={faTrash}
              onClick={() => deletedraft(param.Id)}
              title="Delete Artifact"
            />
          )}
        </>
      ),
    },
  ];

 
  const deletedraft = (id) => {
    // setLoading(true);
    Swal.fire({
      title: "",
      text: "Are you sure, you want to Delete?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1658a0",
      cancelButtonColor: "#1658a0",
      confirmButtonText: "Delete",
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((result) => {
      if (result.isConfirmed) {
        apiClient
          .post("kmarticle/article_draft_delete", { Id: id })
          .then((res) => {
            handleDeleteDraftArticle(id);
            Alert("succ", "Artifact deleted successfully");
            filterData();
            // setLoading(false)
          })
          .catch((error) => {
          });
      }
    })
  }


  const [showNoDataComponent, setShowNoDataComponent] = useState(false);
  const [startTime, setStartTime] = useState(null);

  useEffect(() => {
    if (articleList.length === 0 && !startTime) {
      setStartTime(Date.now());
    } else if (articleList.length > 0) {
      setShowNoDataComponent(false);
      setStartTime(null);
    }
  }, [articleList]);

  useEffect(() => {
    if (startTime) {
      const checkDelay = () => {
        if (Date.now() - startTime >= 4000) {
          setShowNoDataComponent(true);
        }
      };
      const interval = setInterval(checkDelay, 100); // Check every 100ms

      // Cleanup the interval if the component is unmounted or articleList changes
      return () => clearInterval(interval);
    }
  }, [startTime]);


  return (
    <>
<div className="mt-2 flex flex-col lg:flex-row justify-between items-start lg:items-center justify-between">
        {HideHomeBreadCumb && (
          <div className="maincontent__breadcrumb mb-2 lg:mb-0">
            <img
              className="cursor_pointer"
              src={HomeOutlineIcon}
              alt="home"
              onClick={() => {
                navigate(state.defaultpage);
              }}
            />
            <span className="maincontent__breadcrumb--divider">/</span>
            <span>My Articles</span>
          </div>
        )}



<div className="flex  gap-2 mt-2 lg:mt-0">
    <div className="relative search-containerKMArti kmarticle-seactform mb-2 lg:mb-0">
            <input
              type="search"
              placeholder="Search Title, Category, Project"
              className="w-full pt-2 pb-2 pl-2 pr-[26%] text-xs border-0 rounded-[28px] outline-0 h-[34px]"
              value={searchValue}
              onChange={handleSearch}
            />
            <button
              type="button"
              className="absolute top-1 right-1 bg-[rgba(0,0,0,60%)] rounded-[28px] h-[26px] text-white text-[10px] font-bold button"
            >
              Search
            </button>
          </div>
          <Link className="flexVerandHorCenter ml-auto">
            <img
              src={DownloadIcon}
              onClick={() => handleExport(articleList)}
              className="p-2 bg-[rgba(0,0,0,60%)] rounded-md"
            ></img>
          </Link>
        </div>
      </div>

      <div className=" mt-2 maincontent__card--body">
        <div className="maincontent__card--content">
          <Card.Body id="unpublishedDatatble" align="center">
            {loading ? (
              <div class="circle__loader"></div>
            ) : (
              <DataTable
      columns={columns}
      data={articleList}
      highlightOnHover
      pagination
      paginationRowsPerPageOptions={[5, 10, 15]}
      paginationPerPage={10}
      className="myArticleDT"
      defaultSortFieldId={location?.state?.notify === "yes" ? "status" : undefined}
      defaultSortAsc={location?.state?.notify === "yes" ? false : ""}
      loading={articleList.length === 0} // Set loading prop based on whether articleList is empty
      noDataComponent={showNoDataComponent ? <div className="p-6">There are no records to display</div> : <div className="circle__loader"></div>}
      onRowClicked={
        articleViewPermission
          ? (row) => {
              navigate(Routes.KMViewArticle, {
                state: { user: row },
              });
            }
          : undefined
      }
    />

            )}
          </Card.Body>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  ...state,
});
export default connect(mapStateToProps)(MyArticle);
