import React, { useEffect, useState } from "react";
import apiClient from "../../common/http-common";
import "../usermanagement/CreateRoles/createrole.css";
import { useNavigate, useLocation } from "react-router-dom";
import { Routes } from "../../routes";
import HomeOutlineIcon from "../../assets/img/icons/home-outline-icon.svg";
import {  Form } from "@themesberg/react-bootstrap";
import { Alert } from "../../components/Alert";
import { connect ,useDispatch} from "react-redux";
import Swal from "sweetalert2";
 import { unsavedChange } from "../../actions/actions";
const JiraAdd = (state) => {
  const location = useLocation();
  const Id = location?.state?.param?.Id ? location?.state?.param?.Id : null;
  const [baseUrl, setBaseUrl] = useState(location?.state?.param?.BaseUrl ? location?.state?.param?.BaseUrl : "");
  const [apiId, setApiId] = useState(location?.state?.param?.Api ? location?.state?.param?.Api : "");
  const [userEmail, setUserEmail] = useState(location?.state?.param?.UserEmail ? location?.state?.param?.UserEmail : "");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [logUserId] = useState(state?.user?.Id);
  const [isdisabled, setIsdisabled] = useState(false);
 const [unsavedChanges,setunsavedChanges] =useState(false);
 const [initialValues] = useState({
  baseUrl:location?.state?.param?.BaseUrl ? location?.state?.param?.BaseUrl : "",
  apiId:location?.state?.param?.Api ? location?.state?.param?.Api : "",
 userEmail: location?.state?.param?.UserEmail ? location?.state?.param?.UserEmail : ""
 })
  const handleKeyDown = (e) => {
    if (e.key === "Enter"&& !isdisabled) {
      e.preventDefault();
      postJiraConfig();
    }
  };
 
  const postJiraConfig = () => {
    if (saveButtonText === "Saving") {
        return;
    }
     else if (baseUrl.trim() === ""){
        Alert("warn", "Base URL is required");
        return;
    } else if (apiId.trim() ==="") {
        Alert("warn", "API Id is required");
        return;
    } else if (userEmail.trim() === "") {
        Alert("warn", "User Email is required");
        return;
    } else {
        setSaveButtonText("Saving");

        apiClient.post("/cloud/jira/config_apply" , {
          Id : Id,
          BaseUrl : baseUrl,
          Api : apiId,
          UserEmail : userEmail
        })
        .then((res) => {
            setSaveButtonText("Save");
            Alert("succ", "Jira Data Added Successfully");
            navigate(Routes.JiraList);
            setunsavedChanges(false);
            dispatch(unsavedChange(false));
            
        })
        .catch((err) => {
            Alert("error", "Please Try Again")
            setSaveButtonText("Save");
        })
    }
  };
 
  // Add state for the button text
  const [saveButtonText, setSaveButtonText] = useState('Save');
  useEffect(()=>{
    if(baseUrl != initialValues.baseUrl || apiId != initialValues.apiId || userEmail !=initialValues.userEmail){
      setunsavedChanges(true);
      dispatch(unsavedChange(true));
    }else{
      setunsavedChanges(false);
      dispatch(unsavedChange(false));
    }
  },[baseUrl,apiId,userEmail])
  const handleCloseRole = () => {
    if (unsavedChanges) {
      Swal.fire({
        title: "Discard Changes?",
        text: "Do you want to save the changes ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1658a0",
        cancelButtonColor: "#d33",
        confirmButtonText: "Save ",
        cancelButtonText: "Discard ",
        allowOutsideClick: false,
        allowEscapeKey: false  
      }).then((result) => {
        if (result.isConfirmed) {
          // Save changes and navigate away
          postJiraConfig();
        } else {
          navigate(Routes.JiraList);
        }
      });
    } else {
      navigate(Routes.JiraList);
    }
  };

   
  return (
    <div>
      <div className="maincontent__breadcrumb">
        <img
          className="cursor_pointer"
          src={HomeOutlineIcon}
          alt="home"
          onClick={() => {
            navigate(state.defaultpage);
          }}
        />
        <span className="maincontent__breadcrumb--divider">/</span>
        <span
          className="cursor_pointer"
          onClick={() => {
            navigate(Routes.JiraList);
          }}
        >
          Jira Configuration
        </span>
        <span className="maincontent__breadcrumb--divider">/</span>
        <span className="maincontent__breadcrumb--active">Create Jira</span>
      </div>
 
      <div className="mt-4 maincontent__card--body">
        <div className="maincontent__card--header">
          <h2 className="maincontent__card--header-title">Create Jira</h2>
        </div>
 
        <div className="maincontent__card--content ">
          <Form className="grid grid-cols-1 gap-4 md:grid-cols-3">
            <Form.Group className="mb-3 mb3form">
              <Form.Label>BaseUrl <span className="required">*</span></Form.Label>
              <Form.Control
                className="formconus"
                value={baseUrl}
                onChange={(e) => setBaseUrl(e.target.value)}
                onKeyDown={handleKeyDown}
                type="text"
                placeholder="Base URL"
              />
            </Form.Group>
 
            <Form.Group className="mb-3 mb3form">
              <Form.Label>API Id <span className="required">*</span></Form.Label>
              <Form.Control
                className="formconus"
                value={apiId}
                onChange={(e) => setApiId(e.target.value)}
                onKeyDown={handleKeyDown}
                type="text"
                placeholder="API Id"
              />
            </Form.Group>
            <Form.Group className="mb-3 mb3form">
              <Form.Label>User Email <span className="required">*</span></Form.Label>
              <Form.Control
                className="formconus"
                value={userEmail}
                onChange={(e) => setUserEmail(e.target.value)}
                onKeyDown={handleKeyDown}
                type="text"
                placeholder="User Email"
              />
            </Form.Group>
          </Form>
          <div className="flex justify-center gap-4 mt-4 md:justify-end">
          <button
              className="maincontent__btn maincontent__btn--primaryblue"
              onClick={() => postJiraConfig()}
              disabled={isdisabled}
            >
              {saveButtonText}
            </button>
            <button
              className="maincontent__btn maincontent__btn--primaryblue"
              onClick={() => {
                handleCloseRole();
                // navigate(Routes.SharePointList);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
 
const mapStateToProps = (state) => ({
  ...state,
});
export default connect(mapStateToProps)(JiraAdd);