import React, { useEffect, useState } from "react";
import apiClient from "../../common/http-common";
import { useNavigate, useLocation } from "react-router-dom";
import { Routes } from "../../routes";
import  HomeOutlineIcon  from "../../assets/img/icons/home-outline-icon.svg";
import {  Form } from "@themesberg/react-bootstrap";
import { Alert } from "../../components/Alert";
import { connect ,useDispatch } from "react-redux";
import Swal from "sweetalert2";
 import { unsavedChange } from "../../actions/actions";
const SSOConfigAdd = (state) => {
  const location = useLocation();
  const Id = location?.state?.param?.Id ? location?.state?.param?.Id : null;
  const [tenantId, setTenantid] = useState(location?.state?.param?.tenant_id ? location?.state?.param?.tenant_id : "");
  const [apiId, setapiId] = useState(location?.state?.param?.api_id ? location?.state?.param?.api_id : "");
  const [redirecturi, setredirecturi] = useState(location?.state?.param?.redirect_url ? location?.state?.param?.redirect_url : "");
  const [enableStatus, setEnableStatus] = useState(location?.state?.param?.active_status ? location?.state?.param?.active_status : false);
  const navigate = useNavigate();
  const dispatch =useDispatch();
  const [logUserId] = useState(state?.user?.Id);
  const [isdisabled, setIsdisabled] = useState(false);
const [initialValues] = useState({
  tenantId: location?.state?.param?.tenant_id ? location?.state?.param?.tenant_id : "",
  apiId: location?.state?.param?.api_id ? location?.state?.param?.api_id : "",
  redirecturi: location?.state?.param?.redirect_url ? location?.state?.param?.redirect_url : "",
  enableStatus: location?.state?.param?.active_status ? location?.state?.param?.active_status : false}) 
  const handleKeyDown = (e) => {
    if (e.key === "Enter"&& !isdisabled) {
      e.preventDefault();
      postSharepoint();
    }
  };
 
  const postSharepoint = () => {
    if (saveButtonText === "Saving") {
        return;
    }
     else if (tenantId.trim() === ""){
        Alert("warn", "Tenant Id is required");
        return;
    } else if (apiId.trim() ==="") {
        Alert("warn", "Api Id is required");
        return;
    } else if (redirecturi.trim() === "") {
        Alert("warn", "Redirect Uri is required");
        return;
    } else {
        setSaveButtonText("Saving");
        let enableStatusVal = "";
      if (enableStatus) {
        enableStatusVal = "1";
      } else {
        enableStatusVal = "0";
      }

        apiClient.post("/auth/sso_details_apply" , {
          Id : Id,
          tenant_id : tenantId,
          api_id : apiId,
          redirect_url : redirecturi,
          active_status : enableStatusVal
        })
        .then((res) => {
            setSaveButtonText("Save");
            Alert("succ", "SSODetails Data Added Successfully");
            navigate(Routes.SSOConfigList);
            setunsavedChanges(false);
            dispatch(unsavedChange(false));
        })
        .catch((err) => {
            Alert("error", "Please Try Again")
            setSaveButtonText("Save");
        })
    }
  };
 
  // Add state for the button text
  const [saveButtonText, setSaveButtonText] = useState('Save');
const [unsavedChanges ,setunsavedChanges] = useState(false);
useEffect(() => {
  if (
    (tenantId.trim().length > 0 || apiId.trim().length > 0 || redirecturi.trim().length > 0)&&
  (
    tenantId !== initialValues.tenantId ||
    apiId !== initialValues.apiId ||
    redirecturi !== initialValues.redirecturi
  ) ){
    setunsavedChanges(true);
    dispatch(unsavedChange(true));
  } else {
    setunsavedChanges(false);
    dispatch(unsavedChange(false));
  }
}, [tenantId, apiId, redirecturi, enableStatus, initialValues]);
  const handleCloseRole = () => {
    if (unsavedChanges) {
      Swal.fire({
        title: "Discard Changes?",
        text: "Do you want to save the changes ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1658a0",
        cancelButtonColor: "#d33",
        confirmButtonText: "Save ",
        cancelButtonText: "Discard ",
        allowOutsideClick: false,
        allowEscapeKey: false  
      }).then((result) => {
        if (result.isConfirmed) {
          // Save changes and navigate away
          postSharepoint();
        } else {
          navigate(Routes.SSOConfigList);
        }
      });
    } else {
      navigate(Routes.SSOConfigList);
    }
  };

  const handleLockStatusChange = (event) => {
    setEnableStatus(event.target.checked);
  };

   
  return (
    <div>
      <div className="maincontent__breadcrumb">
        <img
          className="cursor_pointer"
          src={HomeOutlineIcon}
          alt="home"
          onClick={() => {
            navigate(state.defaultpage);
          }}
        />
        <span className="maincontent__breadcrumb--divider">/</span>
        <span
          className="cursor_pointer"
          onClick={() => {
            navigate(Routes.SSOConfigList);
          }}
        >
          SSO Configuration
        </span>
        <span className="maincontent__breadcrumb--divider">/</span>
        <span className="maincontent__breadcrumb--active">Create SSOConfiguration</span>
      </div>
 
      <div className="mt-4 maincontent__card--body">
        <div className="maincontent__card--header">
          <h2 className="maincontent__card--header-title">Create    SSOConfiguration</h2>
        </div>
 
        <div className="maincontent__card--content ">
          <Form className="grid grid-cols-1 gap-4 md:grid-cols-4">
            <Form.Group className="mb-3 mb3form">
              <Form.Label>Tenant Id <span className="required">*</span></Form.Label>
              <Form.Control
                className="formconus"
                value={tenantId}
                onChange={(e) => setTenantid(e.target.value)}
                onKeyDown={handleKeyDown}
                type="text"
                placeholder="Tenant Id"
              />
            </Form.Group>
 
            <Form.Group className="mb-3 mb3form">
              <Form.Label>Api Id <span className="required">*</span></Form.Label>
              <Form.Control
                className="formconus"
                value={apiId}
                onChange={(e) => setapiId(e.target.value)}
                onKeyDown={handleKeyDown}
                type="text"
                placeholder="Api Id"
              />
            </Form.Group>
            <Form.Group className="mb-3 mb3form">
              <Form.Label>Redirect Uri <span className="required">*</span></Form.Label>
              <Form.Control
                className="formconus"
                value={redirecturi}
                onChange={(e) => setredirecturi(e.target.value)}
                onKeyDown={handleKeyDown}
                type="text"
                placeholder="Redirect Uri"
              />
            </Form.Group>
            <div className="flex items-center justify-start gap-4 md:mt-3">
                <p>Enable Status : </p>
                <div class="form-check form-switch">
                  <input
                    class="form-check-input cursor-pointer"
                    type="checkbox"
                    id="flexSwitchCheckDefault"
                    value={enableStatus}
                    onChange={() => {
                      setEnableStatus(!enableStatus);
                    }}
                    checked={enableStatus ? "checked" : ""}
                  />
                  {enableStatus && (
                    <label
                      class="form-check-label"
                      for="flexSwitchCheckDefault"
                    >
                      Enabled
                    </label>
                  )}
                  {!enableStatus && (
                    <label
                      class="form-check-label"
                      for="flexSwitchCheckDefault"
                    >
                      Disabled
                    </label>
                  )}
                </div>
              </div>

          </Form>
          <div className="flex justify-center gap-4 mt-4 md:justify-end">
          <button
              className="maincontent__btn maincontent__btn--primaryblue"
              onClick={() => postSharepoint()}
              disabled={isdisabled}
            >
              {saveButtonText}
            </button>
            <button
              className="maincontent__btn maincontent__btn--primaryblue"
              onClick={() => {
                handleCloseRole();
                // navigate(Routes.SharePointList);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
 
const mapStateToProps = (state) => ({
  ...state,
});
export default connect(mapStateToProps)(SSOConfigAdd);