import React, { useEffect, useState } from "react";
import apiClient from "../../common/http-common";
import { useNavigate, useLocation } from "react-router-dom";
import { Routes } from "../../routes";
import  HomeOutlineIcon  from "../../assets/img/icons/home-outline-icon.svg";
import {  Form } from "@themesberg/react-bootstrap";
import { Alert } from "../../components/Alert";
import { connect ,useDispatch} from "react-redux";
import Swal from "sweetalert2";
 import { unsavedChange } from "../../actions/actions";
const AwsBucketAdd = (state) => {
  const location = useLocation();
  const Id = location?.state?.param?.Id ? location?.state?.param?.Id : null;
  const [bucketname, setBucketname] = useState(location?.state?.param?.bucket_name ? location?.state?.param?.bucket_name : "");
  const [accesskey, setAccesskey] = useState(location?.state?.param?.access_key ? location?.state?.param?.access_key : "");
  const [secretaccesskey, setSecretaccesskey] = useState(location?.state?.param?.secret_access_key ? location?.state?.param?.secret_access_key : "");
  const [bucketregion, setBucketregion] = useState(location?.state?.param?.bucket_region ? location?.state?.param?.bucket_region : "");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [logUserId] = useState(state?.user?.Id);
  const [isdisabled, setIsdisabled] = useState(false);
 
 const[initialValues] =useState({
  bucketname:location?.state?.param?.bucket_name ? location?.state?.param?.bucket_name : "",
  bucketregion:location?.state?.param?.bucket_region ? location?.state?.param?.bucket_region : "",
  secretaccesskey:location?.state?.param?.secret_access_key ? location?.state?.param?.secret_access_key : "" ,
  accesskey:location?.state?.param?.access_key ? location?.state?.param?.access_key : ""
 })
  const handleKeyDown = (e) => {
    if (e.key === "Enter"&& !isdisabled) {
      e.preventDefault();
      postAwsConfig();
    }
  };
 
  const postAwsConfig = () => {
    if (saveButtonText === "Saving") {
        return;
    }
     else if (bucketname.trim() === ""){
        Alert("warn", "Bucket Name is required");
        return;
    } else if (accesskey.trim() ==="") {
        Alert("warn", "Access Key is required");
        return;
    } else if (secretaccesskey.trim() === "") {
        Alert("warn", "Secret Access Key is required");
        return;
    }
    else if(bucketregion.trim()===""){
        Alert("warn","Bucket Region is required");
    } else {
        setSaveButtonText("Saving");
      
 
        apiClient.post("cloud/aws/bucketlist_apply" , {
          Id : Id,
          bucket_name : bucketname,
          access_key : accesskey,
          secret_access_key : secretaccesskey,
          bucket_region : bucketregion
        })
        .then((res) => {
            setSaveButtonText("Save");
            Alert("succ", "AWS Data Added Successfully");
            navigate(Routes.AwsBucketList);
            setunsavedChanges(false);
            dispatch(unsavedChange(false));
        })
        .catch((err) => {
            Alert("error", "Please Try Again")
            setSaveButtonText("Save");
        })
    }
  };
 
  // Add state for the button text
  const [saveButtonText, setSaveButtonText] = useState('Save');
 const [unsavedChanges,setunsavedChanges] =useState(false);

 console.log( initialValues.bucketname != bucketname
 );
 useEffect(()=>{
  if(
    // (bucketname.trim().length > 0 || accesskey.trim().length > 0 || secretaccesskey.trim().length > 0 || bucketregion.trim().length>0) &&
   (
    bucketname !=initialValues.bucketname ||bucketregion !=initialValues.bucketregion || accesskey != initialValues.accesskey|| secretaccesskey !=initialValues.secretaccesskey
  )){
    setunsavedChanges(true);
    dispatch(unsavedChange(true));

  }else{
    setunsavedChanges(false);
    dispatch(unsavedChange(false));
  }
 },[bucketname,bucketregion,secretaccesskey,accesskey])
  const handleCloseRole = () => {
    if (unsavedChanges) {
      Swal.fire({
        title: "Discard Changes?",
        text: "Do you want to save the changes ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1658a0",
        cancelButtonColor: "#d33",
        confirmButtonText: "Save ",
        cancelButtonText: "Discard ",
        allowOutsideClick: false,
        allowEscapeKey: false  
      }).then((result) => {
        if (result.isConfirmed) {
          // Save changes and navigate away
          postAwsConfig();
        } else {
          navigate(Routes.AwsBucketList);
          dispatch(unsavedChange(false))
        }
      });
    } else {
      navigate(Routes.AwsBucketList);
    }
  };
 
 
 
   
  return (
    <div>
      <div className="maincontent__breadcrumb">
        <img
          className="cursor_pointer"
          src={HomeOutlineIcon}
          alt="home"
          onClick={() => {
            navigate(state.defaultpage);
          }}
        />
        <span className="maincontent__breadcrumb--divider">/</span>
        <span
          className="cursor_pointer"
          onClick={() => {
            navigate(Routes.AwsBucketList);
          }}
        >
           AWS Configuration
        </span>
        <span className="maincontent__breadcrumb--divider">/</span>
        <span className="maincontent__breadcrumb--active">Create AWS Configuration</span>
      </div>
 
      <div className="mt-4 maincontent__card--body">
        <div className="maincontent__card--header">
          <h2 className="maincontent__card--header-title">Create AWS Configuration</h2>
        </div>
 
        <div className="maincontent__card--content ">
          <Form className="grid grid-cols-1 gap-4 md:grid-cols-4">
            <Form.Group className="mb-3 mb3form">
              <Form.Label>Bucket Name <span className="required">*</span></Form.Label>
              <Form.Control
                className="formconus"
                value={bucketname}
                onChange={(e) => setBucketname(e.target.value)}
                onKeyDown={handleKeyDown}
                type="text"
                placeholder="Bucket Name"
              />
            </Form.Group>
 
            <Form.Group className="mb-3 mb3form">
              <Form.Label>Access  Key <span className="required">*</span></Form.Label>
              <Form.Control
                className="formconus"
                value={accesskey}
                onChange={(e) => setAccesskey(e.target.value)}
                onKeyDown={handleKeyDown}
                type="text"
                placeholder="Access Key"
              />
            </Form.Group>
            <Form.Group className="mb-3 mb3form">
              <Form.Label>Secret Access Key <span className="required">*</span></Form.Label>
              <Form.Control
                className="formconus"
                value={secretaccesskey}
                onChange={(e) => setSecretaccesskey(e.target.value)}
                onKeyDown={handleKeyDown}
                type="text"
                placeholder="Secret Access Key"
              />
            </Form.Group>
           
            <Form.Group className="mb-3 mb3form">
              <Form.Label>Bucket Region <span className="required">*</span></Form.Label>
              <Form.Control
                className="formconus"
                value={bucketregion}
                onChange={(e) => setBucketregion(e.target.value)}
                onKeyDown={handleKeyDown}
                type="text"
                placeholder="Bucket Region"
              />
            </Form.Group>
 
          </Form>
          <div className="flex justify-center gap-4 mt-4 md:justify-end">
          <button
              className="maincontent__btn maincontent__btn--primaryblue"
              onClick={() => postAwsConfig()}
              disabled={isdisabled}
            >
              {saveButtonText}
            </button>
            <button
              className="maincontent__btn maincontent__btn--primaryblue"
              onClick={() => {
                handleCloseRole();
                // navigate(Routes.SharePointList);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
 
const mapStateToProps = (state) => ({
  ...state,
});
export default connect(mapStateToProps)(AwsBucketAdd);
  