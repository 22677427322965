import React, { useEffect, useState } from "react";
import apiClient from "../../../common/http-common";
import "../../usermanagement/CreateRoles/createrole.css";
import { useNavigate, useLocation } from "react-router-dom";
import { Routes } from "../../../routes";
import HomeOutlineIcon from "../../../assets/img/icons/home-outline-icon.svg";
import {  Form } from "@themesberg/react-bootstrap";
import { Alert } from "../../../components/Alert";
import { connect  , useDispatch} from "react-redux";
import Swal from "sweetalert2";
import { unsavedChange } from "../../../actions/actions";
 
const CreateSharePoint = (state) => {
  const location = useLocation();
  const Id = location?.state?.param?.Id ? location?.state?.param?.Id : null;
  const [tenantId, setTenantid] = useState(location?.state?.param?.tenant_id ? location?.state?.param?.tenant_id : "");
  const [clientId, setClientId] = useState(location?.state?.param?.client_id ? location?.state?.param?.client_id : "");
  const [clientSecret, setClientSecret] = useState(location?.state?.param?.client_secret ? location?.state?.param?.client_secret : "");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  console.log(location.state);
  const [logUserId] = useState(state?.user?.Id);
  const [isdisabled, setIsdisabled] = useState(false);
 const [unsavedChanges ,setunsavedChanges] = useState(false);
 const[ initialValues] = useState({
    tenantId :location?.state?.param?.tenant_id ? location?.state?.param?.tenant_id : "",
    clientId:location?.state?.param?.client_id ? location?.state?.param?.client_id : "",
    clientSecret:location?.state?.param?.client_secret ? location?.state?.param?.client_secret : ""
 })
  const handleKeyDown = (e) => {
    if (e.key === "Enter"&& !isdisabled) {
      e.preventDefault();
    postSharepoint();
    }
  };
 
  const postSharepoint = () => {
    if (saveButtonText === "Saving") {
        return;
    }
     else if (tenantId.trim() === ""){
        Alert("warn", "Tenant Id is required");
        return;
    } else if (clientId.trim() ==="") {
        Alert("warn", "Client Id is required");
        return;
    } else if (clientSecret.trim() === "") {
        Alert("warn", "Client Secret is required");
        return;
    } else {
        setSaveButtonText("Saving");

        apiClient.post("/chat/int_sharepoint_apply" , {
          Id : Id,
          TenantId : tenantId,
          ClientId : clientId,
          ClientSecret : clientSecret
        })
        .then((res) => {
            setSaveButtonText("Save");
            Alert("succ", "SharePoint Data Added Successfully");
            navigate(Routes.SharePointList);
            setunsavedChanges(false);
            dispatch(unsavedChange(false));
        })
        .catch((err) => {
            Alert("error", "Please Try Again")
            setSaveButtonText("Save");
        })
    }
  };
 
  // Add state for the button text
  const [saveButtonText, setSaveButtonText] = useState('Save');

  useEffect(()=>{
    if(tenantId!= initialValues.tenantId || clientId !=initialValues.clientId||clientSecret!=initialValues.clientSecret){
      setunsavedChanges(true);
      dispatch(unsavedChange(true));
    }else{
      setunsavedChanges(false);
      dispatch(unsavedChange(false));
    }
  },[tenantId,clientId,clientSecret])
  const handleCloseRole = () => {
    if (unsavedChanges) {
      Swal.fire({
        title: "Discard Changes?",
        text: "Do you want to save the changes ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1658a0",
        cancelButtonColor: "#d33",
        confirmButtonText: "Save ",
        cancelButtonText: "Discard ",
        allowOutsideClick: false,
        allowEscapeKey: false  
      }).then((result) => {
        if (result.isConfirmed) {
          // Save changes and navigate away
          postSharepoint();
        } else {
          navigate(Routes.SharePointList);
        }
      });
    } else {
      navigate(Routes.SharePointList);
    }
  };

   
  return (
    <div>
      <div className="maincontent__breadcrumb">
        <img
          className="cursor_pointer"
          src={HomeOutlineIcon}
          alt="home"
          onClick={() => {
            navigate(state.defaultpage);
          }}
        />
        <span className="maincontent__breadcrumb--divider">/</span>
        <span
          className="cursor_pointer"
          onClick={() => {
            navigate(Routes.SharePointList);
          }}
        >
          SharePoint Configuration
        </span>
        <span className="maincontent__breadcrumb--divider">/</span>
        <span className="maincontent__breadcrumb--active">Create SharePoint</span>
      </div>
 
      <div className="mt-4 maincontent__card--body">
        <div className="maincontent__card--header">
          <h2 className="maincontent__card--header-title">Create SharePoint</h2>
        </div>
 
        <div className="maincontent__card--content ">
          <Form className="grid grid-cols-1 gap-4 md:grid-cols-3">
            <Form.Group className="mb-3 mb3form">
              <Form.Label>Tenant Id <span className="required">*</span></Form.Label>
              <Form.Control
                className="formconus"
                value={tenantId}
                onChange={(e) => setTenantid(e.target.value)}
                onKeyDown={handleKeyDown}
                type="text"
                placeholder="Tenant Id"
              />
            </Form.Group>
 
            <Form.Group className="mb-3 mb3form">
              <Form.Label>Client Id <span className="required">*</span></Form.Label>
              <Form.Control
                className="formconus"
                value={clientId}
                onChange={(e) => setClientId(e.target.value)}
                onKeyDown={handleKeyDown}
                type="text"
                placeholder="Client Id"
              />
            </Form.Group>
            <Form.Group className="mb-3 mb3form">
              <Form.Label>Client Secret <span className="required">*</span></Form.Label>
              <Form.Control
                className="formconus"
                value={clientSecret}
                onChange={(e) => setClientSecret(e.target.value)}
                onKeyDown={handleKeyDown}
                type="text"
                placeholder="Client Secret"
              />
            </Form.Group>
          </Form>
          <div className="flex justify-center gap-4 mt-4 md:justify-end">
          <button
              className="maincontent__btn maincontent__btn--primaryblue"
              onClick={() => postSharepoint()}
              disabled={isdisabled}
            >
              {saveButtonText}
            </button>
            <button
              className="maincontent__btn maincontent__btn--primaryblue"
              onClick={() => {
                handleCloseRole();
                // navigate(Routes.SharePointList);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
 
const mapStateToProps = (state) => ({
  ...state,
});
export default connect(mapStateToProps)(CreateSharePoint);