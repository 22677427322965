import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate,useLocation } from "react-router-dom";
import "react-toggle/style.css";
import DataTable from "react-data-table-component";
import apiClient from "../../../common/http-common";
import { Card } from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Routes } from "../../../routes";
import { Alert } from "../../../components/Alert";
import HomeOutlineIcon from "../../../assets/img/icons/home-outline-icon.svg";
import DownloadIcon from "../../../assets/img/new-dashboard/download-icon.svg";
import { faPenToSquare, faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import Papa from "papaparse";
const StarAuthorCard = (state) => {
    const location = useLocation();
    let currentpath = window.location.hash.replace("#", "");
    let haumbstatus = state.defaultpage === currentpath ? false : true;
    const [isAdmin] = useState(state.isAdmin);
    const [HideHomeBreadCumb] = useState(haumbstatus)
    const navigate = useNavigate();
    const [logUserId] = useState(state?.user?.Id);
    const [articleList, setArticleList] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [tableData, setTableData] = useState([]);


    const [loading, setLoading] = useState(true);

    const fetchArticleList = async () => {
        setLoading(true);
        try {
            const response = await apiClient.post("kmarticle/get_all_starofmonth_details", {
                 CategoryId : location.state.category,
                 ClientId : location.state.client,
                 domainId : location.state.domain,
                 month: location.state.month,
                 projectId : location.state.project,
                 regionId : location.state.region,
                 year : location.state.year,
                 type : location.state.article
                 });
            const articles = response.data;
            setArticleList(articles);
            setTableData(articles);
            setLoading(false);
        } catch (error) {
            Alert("error", error.message);
        }
    };

    useEffect(() => {
        fetchArticleList();
    }, []);



    const handleSearch = (event) => {
        setSearchValue(event.target.value);
        filterData(event.target.value);
    };

    const filterData = (value) => {
        const lowerCaseValue = value.toLowerCase().trim();
        const filteredData = tableData.filter(
            (item) =>
                item.statofmonth.toLowerCase().includes(lowerCaseValue) ||
                item.projectName.toLowerCase().includes(lowerCaseValue) ||
                item.RegionName.toLowerCase().includes(lowerCaseValue)
        );
        setArticleList(filteredData);
    };

    function handleExport(data) {
        const headers = [
            { label: "Student Name", key: "statofmonth" },
            { label: "Class Name", key: "projectName" },
            { label: "RegionName", key: "RegionName" },
            { label: "ArtifactsPublished", key: "cnt" },


        ];
    
        const exportData = data.map((row) =>
            headers.reduce((acc, header) => {
                if (header.transform) {
                    acc[header.label] = header.transform(row[header.key]);
                } else if (row[header.key] === "start_date" || row[header.key] === "end_date") {
                    acc[header.label] = changeStartDateFormat(row[header.key]);
                } else {
                    acc[header.label] = row[header.key];
                }
                return acc;
            }, {})
        );
        const csvData = Papa.unparse(exportData);
        const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
        saveAs(blob, "Outstanding Contributor's List.csv");
    }
    
    function capitalizeFirstLetter(title) {
        if (!title) return "";
        return title.charAt(0).toUpperCase() + title.slice(1);
    }

    function capitalizeFirstLetter(str) {
        return str ? str.charAt(0).toUpperCase() + str.slice(1) : '';
    }


const columns = [
    {
        name: "Student Name",
        selector: (param) => capitalizeFirstLetter(param.statofmonth),
        format: (param) => {
            const title = param.statofmonth || "No Student";
            if (title.length > 30) {
                return (
                    <span title={title}>
                        {capitalizeFirstLetter(title.substring(0, 30)) + "..."}
                    </span>
                );
            } else {
                return (
                    <span title={title}>
                        {capitalizeFirstLetter(title)}
                    </span>
                );
            }
        },
        sortable: true,
    },
    {
        name: "Class Name",
        selector: (param) => param.projectName,
        format: (param) => {
            const title = param.projectName || "No Class";
            if (title.length > 30) {
                return (
                    <span title={title}>
                        {capitalizeFirstLetter(title.substring(0, 30)) + "..."}
                    </span>
                );
            } else {
                return (
                    <span title={title}>
                        {capitalizeFirstLetter(title)}
                    </span>
                );
            }
        },
        sortable: true,
    },
    {
        name: "Region Name",
        selector: (param) => param.RegionName,
        format: (param) => {
            const title = param.RegionName || "No Region";
            if (title.length > 30) {
                return (
                    <span title={title}>
                        {capitalizeFirstLetter(title.substring(0, 30)) + "..."}
                    </span>
                );
            } else {
                return (
                    <span title={title}>
                        {capitalizeFirstLetter(title)}
                    </span>
                );
            }
        },
        sortable: true,
    },
    {
        name: "Artifacts Published",
        selector: (param) => param.cnt,
        format: (param) => (
            <span title={param.cnt}>{param.cnt}</span>
        ),
        sortable: true,
    }
];

    return (
        <>
            <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center justify-between">
                {HideHomeBreadCumb && (
                    <div className="maincontent__breadcrumb mb-2 lg:mb-0">
                        <img
                            className="cursor_pointer"
                            src={HomeOutlineIcon}
                            alt="home"
                            onClick={() => {
                                navigate(state.defaultpage);
                            }}
                        />
                        <span className="maincontent__breadcrumb--divider">/</span>
                        <span>Outstanding Contributor's List</span>
                    </div>
                )}

                <div className="flex  gap-2 mt-2 lg:mt-0">
                    <div className="relative search-containerKMArti kmarticle-seactform mb-2 lg:mb-0">
                        <input
                            type="search"
                            placeholder="Search by Student Name, Class Name, Region Name"
                            className="w-full pt-2 pb-2 pl-2 pr-[26%] text-xs border-0 rounded-[28px] outline-0 h-[34px]"
                            value={searchValue}
                            onChange={handleSearch}
                        />
                        <button
                            type="button"
                            className="absolute top-1 right-1 bg-[rgba(0,0,0,60%)] rounded-[28px] h-[26px] text-white text-[10px] font-bold button"
                        >
                            Search
                        </button>
                    </div>
                    <Link className="flexVerandHorCenter ml-auto">
                        <img
                            src={DownloadIcon}
                            onClick={() => handleExport(articleList)}
                            className="p-2 bg-[rgba(0,0,0,60%)] rounded-md"
                        ></img>
                    </Link>
                </div>
            </div>

            <div className=" mt-2 maincontent__card--body">
                <div className="maincontent__card--content">
                    <Card.Body id="unpublishedDatatble" align="center">
                        {loading ? (
                            <div class="circle__loader"></div>
                        ) : (
                            <DataTable
                                columns={columns}
                                data={articleList}
                                highlightOnHover
                                pagination
                                paginationRowsPerPageOptions={[5, 10, 15]}
                                paginationPerPage={10}
                                className="contributorlist"

                            />

                        )}
                    </Card.Body>
                    <div className="flex justify-center gap-4 mt-8 lg:justify-end">
                        <button onClick={() => { navigate(state.defaultpage);}}className="maincontent__btn maincontent__btn--primaryblue space_btn">
                            Back
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    ...state,
});
export default connect(mapStateToProps)(StarAuthorCard);
