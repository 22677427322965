import React, { useEffect, useState } from "react"
import apiClient from "../../../common/http-common"
import DataTable from "react-data-table-component"
import { useNavigate } from "react-router-dom"
import { useLocation } from "react-router-dom"
import { Modal, Card, Form, Accordion } from "@themesberg/react-bootstrap"
import Swal from "sweetalert2"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash } from "@fortawesome/free-solid-svg-icons"
import { Alert } from "../../../components/Alert"
import { Routes } from "../../../routes"
import HomeOutlineIcon from "../../../assets/img/icons/home-outline-icon.svg"
import Select from "react-select"
import { unsavedChange } from "../../../actions/actions"
import { connect, useDispatch } from "react-redux";

const EditRole = (state) => {
  const dispatch = useDispatch();
  const [Rname, setRname] = useState("")
  const [Rdes, setRdes] = useState("")
  const [ModuleTabData, setModuleTabData] = useState([])
  const [moduleTabList, setModuleTabListData] = useState([])
  let [UserTabData, setUserTabData] = useState([])
  const [addModuleTabData, setAddModuleTabData] = useState([])
  const [addUserTabData, setAddUserTabData] = useState([])
  const [ShowDef, setShowDef] = useState(false)
  const [showDefault, setShowDefault] = useState(false)
  const [isdisabled, setIsdisabled] = useState(false)
  const handleClose = () => (setShowDefault(false), setShowDef(false),
   setSearchValue(""),
    setSearchValueUser(""),
    setFilteredUserData(addUserTabData),
    setFilteredData(addModuleTabData)
  )
  const navigate = useNavigate()
  const location = useLocation()
  const [getrows, setgetrows] = useState([])
  const [getusers, setgetusers] = useState([])
  const [defaultlPage, setdefaultlPage] = useState({})
  let [filteredData, setFilteredData] = useState();
  let [filteredUserData, setFilteredUserData] = useState();
  let [filteredusers,setfilteredusers] = useState();
  let[filteredmodules,setfilteredmodules] = useState();
  const [initialvalues,setinitialValues]=useState({
    Rname:"",
    Rdes:"",
    defaultlPage:""

  })
  const[unsavedchanges,setunsavedchanges]=useState(false);

  const AddModulePermission = (param) => {
    setgetrows(param.selectedRows)
  }
const [addbuttonmodule,setaddbuttonmodule] = useState("Add");
  const postModulePermission = async (obj) => {
    if(addbuttonmodule === "Adding..."){
      return;
    }
    setaddbuttonmodule("Adding...")
    setIsdisabled(true);
    try {
      if (getrows.length > 0) {
        const promises = getrows.map((data) => {
          let params = {
            roleId: parseInt(location?.state?.id?.Id, 10),
            moduleId: parseInt(data.id, 10),
            userId: parseInt(1, 10),
          };
  
          return apiClient.post("user/roles/module", params);
        });
  
        await Promise.all(promises);
        Alert("succ", "Module Updated");
        await fetchModuleData();
        handleClose();
        setgetrows([]);
        setIsdisabled(false);
        setaddbuttonmodule("Add");
      } else {
        Alert("warn", "At least Please Choose One Module");
        setIsdisabled(false);
        setaddbuttonmodule("Add");
      }
    } catch (error) {
      Alert("error", "Please Try Again!..");
      setIsdisabled(false);
      setaddbuttonmodule("Add");
    }
  };

  const handleClosemodal = () => {
    setShowDefault(false), setShowDef(false);
    setgetrows([]);
    if (getrows.length >0) {
 
      Swal.fire({
        title: "Discard Changes?",
        text: "Do you want to save the changes ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1658a0",
        cancelButtonColor: "#d33",
        confirmButtonText: "Save ",
        cancelButtonText: "Discard ",
        allowOutsideClick: false,
        allowEscapeKey: false
      }).then((result) => {
        if (result.isConfirmed) {
          // Save changes and navigate away
          postModulePermission();
          setSearchValue("");
          setFilteredData(addModuleTabData);
        } else {
          setSearchValue("");
          setFilteredData(addModuleTabData);
        }
      });
    } else {
      setSearchValue("");
      setFilteredData(addModuleTabData);
    }
  };

  

  const AddUsers = (param) => {
    setgetusers(param.selectedRows)
  }
const [addbuttontext ,setAddbuttontext] = useState('Add');
  const postUsers = async (obj) => {
    if(addbuttontext === "Adding..."){
      return;
    }
    setAddbuttontext("Adding...")
    setIsdisabled(true);
    if (getusers.length > 0) {
      try {
        const promises = getusers.map((data) => {
          let params = {
            roleId: parseInt(location?.state?.id?.Id, 10),
            userId: parseInt(data.Id, 10),
          };
  
          return apiClient.post("/user/role", params);
        });
  
        await Promise.all(promises);
        Alert("succ", "User Updated");
        await fetchUserData();
        handleClose();
        setgetusers([]);
        setAddbuttontext("Add");
        setIsdisabled(false);
      } catch (error) {
        Alert("error", "Please Try Again!..");
        setAddbuttontext("Add");
        setIsdisabled(false);
      }
    } else {
      Alert("warn", "At least Please Choose One User");
      setAddbuttontext("Add");
      setIsdisabled(false);
    }
  };

  const handleCloseuser = () => {
    setShowDefault(false), setShowDef(false)
    setgetusers([]);
    if (getusers.length >0) {
 
      Swal.fire({
        title: "Discard Changes?",
        text: "Do you want to save the changes ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1658a0",
        cancelButtonColor: "#d33",
        confirmButtonText: "Save ",
        cancelButtonText: "Discard ",
        allowOutsideClick: false,
        allowEscapeKey: false
      }).then((result) => {
        if (result.isConfirmed) {
          // Save changes and navigate away
          postUsers();
          setSearchValueUser("");
          setFilteredUserData(addUserTabData);
        } else {
          setSearchValueUser("");
          setFilteredUserData(addUserTabData);
        }
      });
    } else {
          setSearchValueUser("");
          setFilteredUserData(addUserTabData);
    }
  };

  

  const handleModuleDelete = (id) => {
    Swal.fire({
      title: "",
      text: "Are you sure, you want to remove?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1658a0",
      cancelButtonColor: "#1658a0",
      confirmButtonText: "Yes",
      allowOutsideClick: false,
      allowEscapeKey: false
    }).then((result) => {
      if (result.isConfirmed) {
        apiClient
          .delete("/user/roles/module", {
            data: {
              roleId: parseInt(location.state.id.Id, 10),
              moduleId: parseInt(id, 10),
              userId: parseInt(1, 10)
            }
          })
          .then((response) => {
            if (response.data.error) {
              Alert("error", response.data.error)
            } else {
              Alert("succ", response.data.user)
            }
          })
          .catch((err) => {
            Alert("error", "Please Try Again")
          })

        const modadd = ModuleTabData.filter((module) => {
          return module.id !== id
        })
        setModuleTabData(modadd)
      } else {
        handleClose()
      }
    })
  }

  const handleUserDelete = async (Id) => {
    Swal.fire({
      title: "",
      text: "Are you sure, you want to remove?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1658a0",
      cancelButtonColor: "#1658a0",
      confirmButtonText: "Yes",
      allowOutsideClick: false,
      allowEscapeKey: false
    }).then((result) => {
      if (result.isConfirmed) {
        apiClient
          .delete("/user/role", {
            data: {
              roleId: parseInt(location.state.id.Id, 10),
              userId: parseInt(Id, 10)
            }
          })
          .then((response) => {
            if (response.data.error) {
              Alert("error", response.data.error)
            } else {
              Alert("succ", response.data.user)
            }
          })

        const useradd = UserTabData.filter((user) => {
          return user.Id !== Id
        })
        setUserTabData(useradd)
      } else {
        handleClose()
      }
    })
  }

  function capitalizeFirstLetter(str) {
    return str ? str.charAt(0).toUpperCase() + str.slice(1) : '';
  }


  const isAdmin = location?.state?.id?.Id === 1

  const column1 = [
    // {
    //   name: "SEMO_ID",
    //   selector: (param) => param.id,
    //   sortable: true
    // },
    {
      name: "Module Name",
      selector: (param) => capitalizeFirstLetter(param.ModuleName),
      sortable: true
    },
    {
      name: "Description",
      selector: (param) => capitalizeFirstLetter(param.ModuleDescription),
      sortable: true
    },
    {
      name: "Actions",
      id: "id",
      cell: (param) => (
        <a>
          <FontAwesomeIcon
            icon={faTrash}
            size="lg"
            color="#1475DF"
            onClick={() => {
              handleModuleDelete(param.id)
            }}
          />
        </a>
      ),

      ignoreRowClick: true,
      allowOverflow: true
    }
  ]

  const columnAddModulePermission = [
    {
      name: "Module Name",
      selector: (param) => capitalizeFirstLetter(param.ModuleName),
      sortable: true
    },
    {
      name: "Description",
      selector: (param) => capitalizeFirstLetter(param.ModuleDescription),
      sortable: true
    }
  ]

  const columnAddUser = [
    {
      name: "User Name",
      selector: (param) => capitalizeFirstLetter(param.FirstName + " " + param.LastName),
      sortable: true
    },
    {
      name: "User ID",
      selector: (param) => param.EmailId,
      sortable: true
    }
  ]

  const column2 = [
    // {
    //   name: "SEUS_ID",
    //   selector: (param) => param.Id,
    //   sortable: true
    // },
    {
      name: "User Name",
      selector: (param) => capitalizeFirstLetter(param.FirstName + " " + param.LastName),
      sortable: true
    },
    {
      name: "User ID",
      selector: (param) => param.EmailId,
      sortable: true
    },
    {
      name: "Actions",
      cell: (param) => (
        <a>
          <FontAwesomeIcon
            icon={faTrash}
            size="lg"
            color="#1475DF"
            onClick={() => {
              handleUserDelete(param.Id)
            }}
          />
        </a>
      ),
      ignoreRowClick: true,
      allowOverflow: true
    }
  ]
 
  
 
  useEffect(async () => {
    await apiClient.get("/user/roles/role/").then((response) => {
      if (response.data.user) {
      }
    })
    .catch(()=> {});

    if (location?.state?.id) {
      const roleName = location?.state?.id?.RoleName
      const roleDescription = location?.state?.id?.RoleDescription
      setRname(roleName)
      setRdes(roleDescription)
      setinitialValues((prevValues) => ({
        ...prevValues,
        Rname: roleName,
        Rdes: roleDescription ,
        defaultlPage : location.state.id.DefaultPageId
      }));
      
    }
  }, []);


  useEffect(() => {
    if (
      initialvalues.Rname !== Rname || initialvalues.Rdes !== Rdes || initialvalues.defaultlPage !== defaultlPage.value
    ) {
      setunsavedchanges(true);
      dispatch(unsavedChange(true));

    } else {
      setunsavedchanges(false);
      dispatch(unsavedChange(false));
    }
  }, [Rname, Rdes,defaultlPage]);

  const handlecloserole = () =>{
    if(unsavedchanges){
    Swal.fire({
      title: "",
      text: "Are you sure, you want to remove?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1658a0",
      cancelButtonColor: "#1658a0",
      confirmButtonText: "Save",
      cancelButtonText:"Discard",
      allowOutsideClick: false,
      allowEscapeKey: false
    }).then((result)=>{
      if(result.isConfirmed){
        updateRole();
      }
    })
  }
  else{
    navigate(Routes.Roles);
  }

  }


  useEffect(() => {
    fetchModuleData()
  }, [])

  useEffect(() => {
    fetchUserData()
  }, [])

  const fetchModuleData = async () => {
    try {
    const response = await apiClient.get(
      `/user/modules/${location?.state?.id?.Id}`
    )
    setModuleTabData(response.data.user)
    }
    catch {

    }
  }

  useEffect(() => {
    const fetchFilteredModules = async () => {
      try {
      const response = await apiClient.get("/user/role/module/list")
      const filteredModules = response.data.user
      setdefaultlPage({
        value: location.state.id.DefaultPageId,
        label: location.state.id.ModuleName
      })
      let defVal = []
      filteredModules.map((row) => {
        defVal.push({ value: row.id, label: row.ModuleName })
      })
      setModuleTabListData(defVal)
      const uniqueModules = filteredModules.filter((module) => {
        return !ModuleTabData.some(
          (existingModule) => existingModule.id === module.id
        )
      })

      setAddModuleTabData(uniqueModules)
      if (searchValue) {
        filterData(searchValue, uniqueModules)
      } else {
        setFilteredData(uniqueModules);
      }
    }
    catch {

    }
  }
    fetchFilteredModules()
  }, [ModuleTabData])

  const filterData = (value) => {
    const lowerCaseValue = value.toLowerCase().trim();
    filteredData = addModuleTabData.filter(
      (item) =>
        item.ModuleName.toLowerCase().includes(lowerCaseValue)
    );
    setFilteredData(filteredData);
  };
  const [searchValue, setSearchValue] = useState("");
  const handleSearch = (event) => {
    setSearchValue(event.target.value);
    filterData(event.target.value);
  };
  

  useEffect(() => {
    fetchUserData()
  }, [])

  const fetchUserData = () => {
    apiClient.get(`/user/role/${location?.state?.id?.Id}`).then((response) => {
      setUserTabData(response.data.user)
    })
    .catch(()=> {});
  }

  useEffect(() => {
    const fetchFilteredModules = async () => {
      try {
      const response = await apiClient.post("/user/search", {
        firstName: "",
        lastName: "",
        emailId: "",
        isApproved: null,
        isActive: null,
        isLocked: null,
        userId: parseInt(1, 10)
      })
      const filteredModules = response.data.user
      const uniqueArray = filteredModules.filter(
        (item1) => !UserTabData.some((item2) => item2.Id === item1.Id)
      )

      setAddUserTabData(uniqueArray)
      setFilteredUserData(uniqueArray)
    }
    catch {

    }
  }

    fetchFilteredModules()
  }, [UserTabData])

  const filterUserData = (value) => {
    const lowerCaseValue = value.toLowerCase().trim();
    filteredUserData = addUserTabData.filter(
      (item) =>
        item.FirstName.toLowerCase().includes(lowerCaseValue) ||
        item.EmailId.toLowerCase().includes(lowerCaseValue)
    );
    setFilteredUserData(filteredUserData);
  };
  const [searchValueUser, setSearchValueUser] = useState("");
  const handleUserSearch = (event) => {
    setSearchValueUser(event.target.value);
    filterUserData(event.target.value);
  };
  

  const [isSaving, setIsSaving] = useState(false);
  const updateRole = () => {
    if(!unsavedchanges){
      navigate(Routes.Roles);
    return;
    }
    else{
      navigate(Routes.Roles);

    }
    
    if(isSaving){
      return
    }
    setIsdisabled(true);
    setIsSaving(true);

    if (
      Rname.trim() === "" ||
      defaultlPage.value === undefined ||
      defaultlPage.value === ""
    ) {
      Alert("warn", "Role Name, Default Page can't be empty!");
      setIsdisabled(false);
      setIsSaving(false);
    }else if (Rname.trim().length > 100) {
      Alert("warn", "Role Name cannot exceed 100 characters!");
      setIsdisabled(false);
      setIsSaving(false);
    }  else if (Rdes.trim().length > 250) {
      Alert("warn", "Role Description cannot exceed 250 characters!");
      setIsdisabled(false);
    } else { 
      const apiCallPromise = apiClient.put("/user/roles", {
        id: parseInt(location.state.id.Id, 10),
        roleName: Rname.trim(),
        roleDescription: Rdes.trim(),
        defaultlPage: parseInt(defaultlPage.value, 10),
        isActive: parseInt(1, 10),
        userId: parseInt(1, 10)
      });

      const showAlertPromise = apiCallPromise.then(() => {
        Alert("succ", "Role Modified Successfully");
        dispatch(unsavedChange(false));
      });
      

      Promise.all([apiCallPromise, showAlertPromise])
        .then(() => {
          // Both promises have resolved, update state accordingly
          setIsdisabled(false);
          setIsSaving(false);
        })
        .catch((error) => {
          // Handle errors if needed
          setIsdisabled(false);
          setIsSaving(false);
        });
    }
   
  };
  const filterusers = (value) =>{
    const lowerCaseValue = value?.toLowerCase().trim();
    filteredusers = UserTabData.filter((item)=>
    item.FirstName.toLowerCase().includes(lowerCaseValue)
    )
    setfilteredusers(filteredusers);
  }

const [searchbar,setsearchbar]=useState("");
const handleSearchs = (event) =>{
  setsearchbar(event.target.value)
  filterusers(event.target.value)
}
const filtermodules = (value) => {
  const lowerCaseValue = value?.toLowerCase().trim();
  filteredmodules = ModuleTabData.filter((item)=>
  item.ModuleName.toLowerCase().includes(lowerCaseValue)
  )
  setfilteredmodules(filteredmodules);
}
const [searchmodule,setsearchmodule] =useState("");
const handlesearchmod =(event)=>{
  setsearchmodule(event.target.value);
  filtermodules(event.target.value);
}
  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !isdisabled) {
      e.preventDefault()
      updateRole()
    }
  }

  const [nameError, setNameError] = useState("");
  const [desError, setDesError] = useState("");

  const handleNameChange = (e) => {
    const value = e.target.value;
    setRname(value);

    if (value.trim().length > 100) {
      setNameError('Role Name cannot exceed 100 characters!');
    } else {
      setNameError('');
    }
  };

  const handleDesChange = (e) => {
    const value = e.target.value;
    setRdes(value);

    if (value.trim().length > 250) {
      setDesError('Role Description cannot exceed 250 characters!');
    } else {
      setDesError('');
    }
  };


  return (
    <div>
      <div className="maincontent__breadcrumb">
        <img
          className="cursor_pointer"
          src={HomeOutlineIcon}
          alt="home"
          onClick={() => {
            navigate(state.defaultpage)
          }}
        />
        <span className="maincontent__breadcrumb--divider">/</span>
        <span
          onClick={() => {
            navigate(Routes.Roles)
          }}
          style={{ cursor: "pointer" }}
        >
          Roles
        </span>
        <span className="maincontent__breadcrumb--divider">/</span>
        <span className="maincontent__breadcrumb--active">Edit Role</span>
      </div>

      <div className="mt-4 maincontent__card--body">
        <div className="maincontent__card--header">
          <h2 className="maincontent__card--header-title">Edit Role</h2>
        </div>
        <div className="maincontent__card--content ">
          <div className="grid lg:grid-cols-4 gap-4 items-end">
            <Form.Group className="flex flex-col">
              <Form.Label className="formlblus">Role Name <span className="required">*</span></Form.Label>
              <Form.Control
                className="formconus"
                value={Rname}
                disabled={isAdmin ? true : false}
                type="text"
                placeholder="Role Name"
                onChange={handleNameChange}
                onKeyDown={handleKeyDown}
              />
              {nameError && (  
                    <p className="error-message">{nameError}</p>
                  )}
            </Form.Group>
            <Form.Group className="flex flex-col">
              <Form.Label className="formlblus">Role Description</Form.Label>
              <Form.Control
                className="formconus"
                value={Rdes}
                onChange={handleDesChange}
                type="text"
                placeholder=""
                onKeyDown={handleKeyDown}
              />
              {desError && (  
                    <p className="error-message">{desError}</p>
                  )}
            </Form.Group>
            <Form.Group className="flex flex-col">
              <Form.Label className="formlblus">Default Page <span className="required">*</span></Form.Label>
              <Select
                options={moduleTabList}
                placeholder=""
                // isDisabled={true}
                value={defaultlPage}
                onChange={(e) => {
                  setdefaultlPage(e)
                }}
                onKeyDown={handleKeyDown}
              />
            </Form.Group>

            {/* <div
              style={{
                width: "10%",
                display: "inline-block",
                marginLeft: "10px"
              }}
            >
              <button
                className="maincontent__btn maincontent__btn--primaryblue"
                onClick={updateRole}
                disabled={isdisabled}
              >
                {isSaving ? 'Updating...' : 'Update'}
              </button> */}
            
              <div className="flex justify-center gap-2 mt-8 lg:justify-end">
                <button
                  onClick={updateRole}
                  disabled={isdisabled}
                  className="maincontent__btn maincontent__btn--primaryblue"
                >
                  {isSaving ? "Updating..." : "Update"}
                </button>
                <button
                  onClick={() => {
                    // handleGetInputValues()
                      handlecloserole()
                  }}
                  className="maincontent__btn maincontent__btn--primaryblue space_btn"
                >
                  Cancel
                </button>
            </div>
          </div>
        </div>
      </div>
      <Accordion defaultActiveKey="1" style={{ marginTop: "10px" }}>
        <Accordion.Item eventKey="1">
          <Accordion.Header className="accordionheader">
            MODULE PERMISSIONS
          </Accordion.Header>
          <Accordion.Body
            className="accordionnew"
            style={{ visibility: "visible", color: "#1658a0", padding: "0px" }}
          >
            <div className="maincontent__card--content accordionnew rounded-[20px]">
              {isAdmin ? (
                <p className="text-center text-gray-500">
                  Admin will have all the Module Permissions
                </p>
              ) : (
                <>
                  <div className="flex justify-end maincontent__pageheader-right">
                  <div className="relative search-containerKMArti kmarticle-seactform mr-2">
                  <input
                    type="search"
                    placeholder="Search by  Module Name"
                    className="w-full pt-2 pb-2 pl-2 pr-[26%] text-xs border-0 rounded-[28px] outline-0 h-[34px]"
                    value={searchmodule}
                    onChange={handlesearchmod}
                  />
                  <button
                    type="button"
                    className="absolute top-1 right-1 bg-[rgba(0,0,0,60%)] rounded-[28px] h-[26px] text-white text-[10px] font-bold button"
                  >
                    Search
                  </button>
                </div>     
                    <button
                      className="maincontent__btn maincontent__btn--primaryblue"
                      onClick={() => setShowDefault(true)}
                      style={{ margin: "0 0 20px 0" }}
                    >
                      +Add Module Permissions
                    </button>
                    <Modal
                      as={Modal.Dialog}
                      centered
                      show={showDefault}
                      onHide={handleClose}
                      className="midpop"
                    >
                      <Card
                        centered
                        show={showDefault}
                        onHide={handleClose}
                        className="usrCrd"
                      >
                        <Card.Header className="rhdivCard mb-4">
                          <div className="maincontent__card--header">
                            <h2 className="maincontent__card--header-title">
                              Modules
                            </h2>
                          </div>
                        </Card.Header>
                        <div className="maincontent__card--tableheader-right mb-4 mr-2">
                            <div className="relative search-containerKMArti kmarticle-seactform">
                          <input
                            type="search"
                            placeholder="Search by Module Name"
                            className="w-full pt-2 pb-2 pl-2 pr-[26%] text-xs border-0 rounded-[28px] outline-0 h-[34px]"
                            value={searchValue}
                            onChange={handleSearch}
                          />
                          <button
                            type="button"
                            className="absolute top-1 right-1 bg-[rgba(0,0,0,60%)] rounded-[28px] h-[26px] text-white text-[10px] font-bold button"
                          >
                            Search
                          </button>
                        </div>
                        </div>
                        <div className="cardContent Cctent">
                          <Card.Body>
                            <DataTable
                              columns={columnAddModulePermission}
                              data={filteredData}
                              highlightOnHover
                              pagination
                              onSelectedRowsChange={AddModulePermission}
                              selectableRows
                              paginationRowsPerPageOptions={[5, 10, 15]}
                              paginationPerPage={5}
                            />
                          </Card.Body>
                        </div>

                        <div className="flex justify-end gap-4 p-4">
                          <button
                            className="maincontent__btn maincontent__btn--primaryblue"
                            onClick={postModulePermission}
                            disabled={isdisabled}
                          >
                            {addbuttonmodule}
                          </button>
                          <button
                            className="maincontent__btn maincontent__btn--primaryblue"
                            onClick={()=> handleClosemodal()}
                          >
                            Cancel
                          </button>
                        </div>
                      </Card>
                    </Modal>
                  </div>
                  <Card.Body>
                    <DataTable
                      columns={column1}
                      data={filteredmodules||ModuleTabData}
                      highlightOnHover
                      pagination
                      paginationRowsPerPageOptions={[5, 10, 15]}
                      paginationPerPage={5}
                    />
                  </Card.Body>
                </>
              )}
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <Accordion defaultActiveKey="1" style={{ marginTop: "10px" }}>
        <Accordion.Item eventKey="1">
          <Accordion.Header className="accordionheader">USERS</Accordion.Header>
          <Accordion.Body
            className="accordionnew"
            style={{ visibility: "visible", color: "#1658a0", padding: "0px" }}
          >
            <div className="maincontent__card--content">
              <div className="flex justify-end maincontent__pageheader-right ">
              <div className="relative search-containerKMArti kmarticle-seactform mr-2">
                  <input
                    type="search"
                    placeholder="Search by User Name"
                    className="w-full pt-2 pb-2 pl-2 pr-[26%] text-xs border-0 rounded-[28px] outline-0 h-[34px]"
                    value={searchbar}
                    onChange={handleSearchs}
                  />
                  <button
                    type="button"
                    className="absolute top-1 right-1 bg-[rgba(0,0,0,60%)] rounded-[28px] h-[26px] text-white text-[10px] font-bold button"
                  >
                    Search
                  </button>
                </div>
                <button
                  className="mb-4 maincontent__btn maincontent__btn--primaryblue"
                  onClick={() => setShowDef(true)}
                >
                  +Add Users
                </button>

                <Modal
                  className="mdlclspop midpop"
                  as={Modal.Dialog}
                  centered
                  show={ShowDef}
                  onHide={handleClose}
                >
                  <Card
                    centered
                    show={ShowDef}
                    onHide={handleClose}
                    className="usrCrd"
                  >
                    <Card.Header className="rhdivCard">
                      <div className="maincontent__card--header">
                        <h2 className="maincontent__card--header-title">
                          Users
                        </h2>
                      </div>
                    </Card.Header>
                    <div className="maincontent__card--tableheader-right mt-4 mr-2">
                        <div className="relative search-containerKMArti kmarticle-seactform">
                      <input
                        type="search"
                        placeholder="Search by User Name, User Email"
                        className="w-full pt-2 pb-2 pl-2 pr-[26%] text-xs border-0 rounded-[28px] outline-0 h-[34px]"
                        value={searchValueUser}
                        onChange={handleUserSearch}
                      />
                      <button
                        type="button"
                        className="absolute top-1 right-1 bg-[rgba(0,0,0,60%)] rounded-[28px] h-[26px] text-white text-[10px] font-bold button"
                      >
                        Search
                      </button>
                    </div>
                    </div>
                    <div className="cardContent Cctent rdt_Pagination">
                      <Card.Body className="rmroldcb rdt_Pagination">
                        <DataTable
                          columns={columnAddUser}
                          data={filteredUserData}
                          highlightOnHover
                          pagination
                          onSelectedRowsChange={AddUsers}
                          selectableRows
                          paginationRowsPerPageOptions={[5, 10, 15]}
                          paginationPerPage={5}
                        />
                      </Card.Body>
                    </div>

                    <div className="flex justify-end gap-4 p-4">
                      <button
                        className="maincontent__btn maincontent__btn--primaryblue"
                        onClick={postUsers}
                        disabled={isdisabled}
                      >
                        {addbuttontext}
                      </button>
                      <button
                        className="maincontent__btn maincontent__btn--primaryblue"
                        onClick={()=> handleCloseuser()}
                      >
                        Cancel
                      </button>
                    </div>
                  </Card>
                </Modal>
              </div>
              <Card.Body>
                <DataTable
                  columns={column2}
                  data={filteredusers || UserTabData }
                  highlightOnHover
                  pagination
                  paginationRowsPerPageOptions={[5, 10, 15]}
                  paginationPerPage={5}
                />
              </Card.Body>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  )
}

const mapStateToProps = (state) => ({
  ...state
})
export default connect(mapStateToProps)(EditRole)
