import React, { useEffect } from "react";
import { Col, Row, Card, Modal, Form } from "@themesberg/react-bootstrap";
import { useState } from "react";
import "./ManageLookUp.css";
import { DataTableStyle } from "../../components/DataTableStyle";
import apiClient from "../../common/http-common";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import HomeOutlineIcon from "../../assets/img/icons/home-outline-icon.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import {
  faPenToSquare,
  faSearch,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import { Alert } from "../../components/Alert";
import { connect } from "react-redux";

const ManageLookUp = (state) => {
  const [editData, setEditData] = useState({
    Id: "",
    Name: "",
    Description: "",
  });
  let [singleSelections, setSingleSelections] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const getState = sessionStorage.getItem("state");
  const getUserId = JSON.parse(getState);
  const [logUserId] = useState(getUserId?.user?.Id);
  const [showEdit, setShowEdit] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [ShowDef, setShowDef] = useState(false);
  const [showDefault, setShowDefault] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [lookupselect,setLookupSelect] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [originalName, setOriginalName] = useState(""); // Added for tracking originalName during editing
  const [lookup, setLookup] = useState([]);
  const handleClose = () => {
    setEditData({ Id: "", Name: "", Description: "" });
    setIsEdit(false);
    setShowDefault(false);
    setShowDef(false);
  };
  const navigate = useNavigate();
  const options = [];

  function capitalizeFirstLetter(str) {
    return str ? str.charAt(0).toUpperCase() + str.slice(1) : '';
  }

  const columns = [
    {
      name: "Id",
      selector: (param) => param.Id,
      sortable: true,
    },
    {
      name: "Name",
      selector: (param) => capitalizeFirstLetter(param.Name),
      sortable: true,
    },
    {
      name: "Description",
      selector: (param)=> capitalizeFirstLetter(param.Description),
      sortable: true,
    },
    {
      name: "Action",
      cell: (param) => (
        <>
          {" "}
          <span
            title="Edit Lookup"
            style={{ cursor: "pointer" }}
            class="lookup-play-icon"
            href="#"
            onClick={() => {
              clickHandler(param);
            }}
          >
            <FontAwesomeIcon icon={faPenToSquare} size="lg" color="#1475DF" />
          </span>
          <span
            title="Delete Lookup"
            style={{ cursor: "pointer" }}
            href="#"
            onClick={() => {
              deleteMetaData(param);
            }}
          >
            <FontAwesomeIcon icon={faTrashAlt} size="lg" color="red" />
          </span>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      style: {},
    },
  ];

  const clickHandler = (val) => {
    setIsEdit(true);
    setOriginalName(val.Name); // Set the originalName for comparison
    setEditData(val);
    setShowDef(true);
  };

  useEffect(() => {
    getMetaDataList();
  },[]);

    const getMetaDataList = async () => {
    setIsLoading(true);

    try {
      const response = await apiClient.get("/lookup");
      const Newoptions = response.data.lookup.map((element) => ({
        value: element.Id,
        label: element.Name,
        MetaDataId: element.MetaDataId,
      }));
      setLookupSelect(Newoptions);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };


  const fetchMetadataDtls = () => {
    setLoading(true);
    apiClient
      .get(`/lookup/${singleSelections.MetaDataId}/1`)
      .then((response) => {
        setLookup(response.data.lookup);
        setFilteredData(response.data.lookup);
        setShowEdit(true);
        // if (response.data.lookup.length > 0) {
        //   setShowEdit(true);
        // }
        setLoading(false);
      });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (isEdit) {
      updateMetaData();
    } else {
      addMetaData();
    }
  };
  const updateMetaData = async () => {
    setDisabled(true);
  
    if (editData.Name !== null && editData.Name.trim() !== "") {
      try {
        const updateResponse = await apiClient.post(`/lookup/update`, {
          metadataId: singleSelections.MetaDataId,
          id: parseInt(editData.Id, 10),
          name: editData.Name.trim(),
          description: editData.Description ? editData.Description.trim() : "",
          isActive: parseInt(1, 10),
          userId: parseInt(logUserId, 10),
        });
  
        setEditData({ Id: "", Name: "", Description: "" });
        setIsEdit(false);
        handleClose();
        setDisabled(false);
  
        if (updateResponse.data.error) {
          Alert("error", updateResponse.data.error);
          setDisabled(false);
        } else {
          Alert("succ", updateResponse.data.lookup);
          setDisabled(false);
  
          // Use a promise to wait for fetchMetadataDtls
          await new Promise((resolve) => {
            fetchMetadataDtls();
            resolve();
          });
  
          // Code here will run after fetchMetadataDtls is successful
        }
      } catch (error) {
        Alert("error", "An error occurred. Please try again.");
        setDisabled(false);
      }
    } else {
      Alert("warn", "MetaData Name is Required");
      setDisabled(false);
    }
  };
  

  const addMetaData = async () => {
    setDisabled(true);
  
    if (editData.Name.trim() !== "" && editData.Description !== null) {
      try {
        const newName = editData.Name.toLowerCase();
  
        const nameExists = filteredData.some(item => item.Name.toLowerCase() === newName);
  
        if (nameExists) {
          Alert("warn", "MetaData Name already exists");
          setDisabled(false);
          return;
        }
  
        const addResponse = await apiClient.post(`/lookup/add`, {
          metadataId: singleSelections.MetaDataId,
          id: parseInt(editData.Id, 10),
          name: editData.Name.trim(),
          description: editData.Description.trim() == null ? "" : editData.Description.trim(),
          isActive: parseInt(1, 10),
          userId: parseInt(logUserId, 10),
        });
  
        setEditData({ Id: "", Name: "", Description: "" });
        setIsEdit(false);
        handleClose();
        setDisabled(false);
  
        if (addResponse.data.error) {
          Alert("error", addResponse.data.error);
        } else {
          Alert("succ", addResponse.data.lookup);
          setDisabled(false);
  
          await new Promise((resolve) => {
            fetchMetadataDtls();
            resolve();
          });
        }
      } catch (error) {
        Alert("error", "An error occurred. Please try again.");
        setDisabled(false);
      }
    } else {
      Alert("warn", "MetaData Name is Required");
      setDisabled(false);
    }
  };
  
  
  const deleteMetaData = async (data) => {
    Swal.fire({
      title: "",
      text: "Are you sure, you want to remove?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1658a0",
      cancelButtonColor: "#1658a0",
      confirmButtonText: "Yes",
      allowOutsideClick: false,
      allowEscapeKey: false
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await apiClient.delete(`/lookup/${singleSelections.MetaDataId}/${data.Id}`);
  
          setEditData({ Id: "", Name: "", Description: "" });
          setIsEdit(false);
  
          if (response.data.error) {
            Alert("error", response.data.error);
          } else {
            Alert("succ", response.data.lookup);
            await fetchMetadataDtls();
          }
  
          fetchMetadataDtls();
        } catch (error) {
            Alert("warn", "Can't delete, data is already in use!");
        }
      }
    });
  };
  

  const selectionChange = async (e) => {
    try {
      setLoading(true);
      setShowEdit(false);
      setFilteredData("");
      singleSelections = e;
      setSingleSelections(e);
  
      await fetchMetadataDtls();
    } catch (error) {
      // Handle the error as needed
    } finally {
      setLoading(false);
    }
  };

  const filterData = (value) => {
    const lowerCaseValue = value.toLowerCase().trim();
    const filteredData = lookup.filter((item) => (
      (item.Name && typeof item.Name === 'string' && item.Name.toLowerCase().includes(lowerCaseValue))
    ));
    setFilteredData(filteredData);
  };

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchValue(value);
    filterData(value);
  };
  const handleCloseedit = () => {
    if (isEdit) {
      // If there are unsaved changes (editData is different from the original value)
      if (
        editData.Name.trim().length > 0 &&
        editData.Name.trim() !== originalName.trim() ||
        editData.Description.trim().length > 0
      ) {
        Swal.fire({
          title: "Discard Changes?",
          text: "Do you want to save the changes?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#1658a0",
          cancelButtonColor: "#d33",
          confirmButtonText: "Save",
          cancelButtonText: "Discard",
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then((result) => {
          if (result.isConfirmed) {
            // Save changes and close the modal
            updateMetaData();
          } else {
            // Discard changes and close the modal
            handleClose();
            setErrorMessage("");
          }
        });
      } else {
        // If no changes, simply close the modal
        handleClose();
        setErrorMessage("");
      }
    }   };
  const handleclosemodal = ()=>{
    setShowDefault(false),setShowDef(false)
    if(editData.Name.trim().length>0 || editData.Description.trim().length > 0){
      Swal.fire({
        title: "Discard Changes?",
        text: "Do you want to save the changes ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1658a0",
        cancelButtonColor: "#d33",
        confirmButtonText: "Save ",
        cancelButtonText: "Discard ",
        allowOutsideClick: false,
        allowEscapeKey: false
      }).then((result) => {
        if (result.isConfirmed) {
          // Save changes and navigate away
        addMetaData();
        }
        else{
          handleClose()
          setErrorMessage("");
        }
    });
    } else {
      setErrorMessage("");
    }
  }
  const handleCancelClick = () => {   if (isEdit) {     handleCloseedit();   } else {     handleclosemodal();   } };

  
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e) => {
    const inputValue = e.target.value;
    const regex = /^(?=.*[a-zA-Z0-9])[\w.@ ]+$/;
  
    if (regex.test(inputValue) || inputValue === "") {
      setEditData({
        Id: editData.Id,
        Name: inputValue,
        Description: editData.Description,
      });
      setErrorMessage(''); // Clear error message if input is valid
    } else {
      setErrorMessage('Invalid Format. The input must contain at least one letter or number.');
    }
  };
  

  return (
    <div>
      <div>
        <div className="maincontent__breadcrumb">
          <img
            className="cursor_pointer"
            src={HomeOutlineIcon}
            alt="home"
            onClick={() => {
              navigate(state.defaultpage);
            }}
          />
          <span className="maincontent__breadcrumb--divider">/</span>
          <span>Manage Lookups (Add/Edit)</span>
        </div>
      </div>
      <div className="mt-4 maincontent__card--body">
        <div className="maincontent__card--header">
          <h2 className="text-black maincontent__card--header-title">
            Fetch Lookup Details
          </h2>
        </div>
        <div className="maincontent__card--content">
          {isLoading ? (
            <div class="circle__loader items-center my-0 mx-auto"></div>
          ) : (
          <Form>
            <Row>
              <Col md={4} className="mb-4">
                <Form.Group id="client">
                  <Form.Label>Lookup Type</Form.Label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text icon-container">
                        <FontAwesomeIcon icon={faSearch} />
                      </span>
                    </div>
                    <Select
                      labelKey="name"
                      onChange={(e) => {
                        selectionChange(e);
                      }}
                      options={lookupselect}
                      placeholder=""
                      selected={singleSelections}
                    />
                  </div>
                </Form.Group>
              </Col>
            </Row>
          </Form>
           )}
        </div>
      </div>
      {showEdit && (<div className="mt-4 maincontent__card--body">
        <div className="maincontent__card--header">
          <h2
            className="text-black maincontent__card--header-title"
            style={{ cursor: "pointer" }}
          >
            Look Up Details
          </h2>
        </div>
        <div className="maincontent__card--content">
          <div
            className="maincontent__card--tableheader"
            style={{ margin: "0 0 10px 0" }}
          >
            <div className="maincontent__card--tableheader-right">
              {showEdit && (
                 <>
<div className="sm:flex "> {/* Stack items vertically for small screens */}
  <div className="mb-4">
    <div className="relative search-containerKMArti kmarticle-seactform mb-2 lg:mb-0">                <input
                  type="search"
                  placeholder="Search by Name"
                  className="w-full pt-2 pb-2 pl-2 pr-[26%] text-xs border-0 rounded-[28px] outline-0 h-[34px]"
                  value={searchValue}
                  onChange={handleSearch}
                />
                <button
                  type="button"
                  className="absolute top-1 right-1 bg-[rgba(0,0,0,60%)] rounded-[28px] h-[26px] text-white text-[10px] font-bold button"
                >
                  Search
                </button>
              </div>
              </div>
              <div className="mb-4 sm:ml-4"> {/* Add margin to separate from search bar in desktop view */}

                <button
                  className="maincontent__btn maincontent__btn--primaryblue ml-auto"
                  onClick={() => {
                    setShowDef(true);
                  }}
                >
                  + Add
                </button>
                </div>
                </div>

                </>
              )}
            </div>
          </div>
          {loading ? (
            <div class="circle__loader items-center my-0 mx-auto"></div>
          ) : (
            <DataTable
              title=""
              columns={columns}
              data={filteredData}
              customStyles={DataTableStyle}
              pagination
              highlightOnHover
              paginationRowsPerPageOptions={[5, 10, 15]}
              paginationPerPage={5}
            />
          )}
        </div>
      </div> )}
      {showEdit && (
        <>
          <Modal
            className="mdlclspop"
            as={Modal.Dialog}
            centered
            show={ShowDef}
            onHide={handleClose}
          >
            <div className="px-4 mt-2">
              <Card
                border="light"
                centered
                show={ShowDef}
                onHide={handleClose}
                className="usrCrd"
              >
                <div className="maincontent__card--header">
                  <h2 className="maincontent__card--header-title">
                    {isEdit ? "EDIT" : "ADD"} DETAILS
                  </h2>
                </div>
                <Card.Body>
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col md={12} className="mb-3">
                      <Form.Group id="client">
                        <Form.Label>Name <span className="required">*</span></Form.Label>
                        <Form.Control
                          type="text"
                          value={editData.Name}
                          onChange={handleChange}
                        />
                        {errorMessage && <div className="text-danger">{errorMessage}</div>}
                      </Form.Group>
                      </Col>
                      <Col md={12} className="mb-3">
                        <Form.Group id="client">
                          <Form.Label>Description</Form.Label>
                          <Form.Control
                            type="text"
                            value={editData.Description}
                            onChange={(e) =>
                              setEditData({
                                Id: editData.Id,
                                Name: editData.Name,
                                Description: e.target.value,
                              })
                            }
                          />
                        </Form.Group>
                      </Col>
                      <Col md={12} className="flex justify-center gap-4">
                        <button
                          className="maincontent__btn maincontent__btn--primaryblue"
                          type="submit"
                          disabled={disabled}
                        >
                          {isEdit ? "Update" : "Add"}
                        </button>
                        <label
                          className="cursor-pointer maincontent__btn maincontent__btn--primaryblue"
                          onClick={()=>{handleCancelClick()}}
                        >
                          Cancel
                        </label>
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
            </div>
          </Modal>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  ...state,
});
export default connect(mapStateToProps)(ManageLookUp);
