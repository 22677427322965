import React, { useEffect } from "react";
import zingchart from "zingchart/es6"; // Make sure to import zingchart

const StackBarChart = ({ data }) => {
  useEffect(() => {
    const chartId = "myChart";

    const countryKeys = Array.from(
      new Set(
        data.flatMap((item) =>
          Object.keys(item).filter((key) => key !== "label")
        )
      )
    );

    var myTheme = {
      palette: {
        bar3d: [
          // ["#99C55A"],
          // ["#7E5CA3"],
          ["#31E3E2"],
          // ["#50BCFF"],
          ["#003f77"],

          // ["#7F0127"],
          // ["#8B4A9E"],
          // ["#225587"],
        ],
      },
    };

    const totalValues = data.slice(-6).map((item) => {
      const total = Object.values(item).reduce((acc, val) => {
        if (typeof val === "number") {
          return acc + val;
        }
        return acc;
      }, 0);
      return total === 0 ? 1 : total; // Avoid division by zero
    });

    const chartConfig = {
      backgroundColor: "#d4d6dd",
      type: "bar3d",
      "3d-aspect": {
        true3d: false,
        depth: "25px",
        "x-angle": 0,
        "y-angle": 0,
        "z-angle": 0,
      },
      plot: {
        tooltip: {
          text: "%v%",
          decimals: 0,
        },
        animation: {
          delay: 600,
          effect: "ANIMATION_FADE_IN",
          sequence: "ANIMATION_BY_NODE",
          speed: 700,
        },
        stacked: true,
        valueBox: {
          visible: true,
          text: "%v%",
          placement: "middle",
          fontColor: "#FFFFFF",
          decimals: 0,
        },
      },
      plotarea: {
        margin: "dynamic",
        backgroundColor: "#d4d6dd",
      },
      scaleX: {
        maxItems: 12,
        labels: data.slice(-6).map((item) => {
          const date = new Date(item.label);
          const month = date.toLocaleString("default", { month: "short" });
          const day = date.getDate();
          return `${month} ${day}`;
        }),
        item: {
          fontSize: 12,
          offsetStart: 50,
        },
      },
      scaleY: {
        label: {
          text: "Users",
        },
      },
      legend: {
        layout: "x2",
        backgroundColor: "#d4d6dd",
        "vertical-align": "bottom",
        align: "center",
        marginBottom: "0px",
        border: "none",
      },
      series: countryKeys.map((region, regionIndex) => ({
        values: data.slice(-6).map((item, index) => {
          const total = totalValues[index];
          return ((item[region] || 0) / total) * 100; // Convert to percentage
        }),
        backgroundColor: myTheme.palette.bar3d[regionIndex],
        text: region,
      })),
    };

    zingchart.render({
      id: chartId,
      data: chartConfig,
      height: "100%",
      width: "100%",
      defaults: myTheme,
    });

    return () => {
      zingchart.exec(chartId, "destroy");
    };
  }, [data]);

  return <div id="myChart" style={{ minHeight: "260px", width: "100%", height: "100%" }}></div>;
};

export default StackBarChart;
